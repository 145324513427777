import axios from '../../../../helpers/axios';

type Params = {
  businessId: number;
};

export const getCreditLineRequest = async ({ businessId }: Params) => {
  try {
    const { data } = await axios.get(
      `/api/business/${businessId}/creditline/balance`
    );
    return data;
  } catch (error) {
    throw new Error(error?.response?.data?.errorCode);
  }
};

import React from 'react';
import { styled, SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { xTheme } from '@xepelinapp/x-ds';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const UploadCloudIcon = props => {
  const { style } = props;
  return (
    <CustomSvgIcon
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M1.0098e-06 12.5022C-0.000614785 11.3977 0.280422 10.3112 0.816552 9.34551C1.35268 8.37981 2.1262 7.56676 3.064 6.98321C3.31151 5.05329 4.2538 3.27971 5.71452 1.99436C7.17524 0.709017 9.05428 0 11 0C12.9457 0 14.8248 0.709017 16.2855 1.99436C17.7462 3.27971 18.6885 5.05329 18.936 6.98321C20.0992 7.70689 21.0041 8.77961 21.5213 10.0482C22.0385 11.3168 22.1417 12.7163 21.8161 14.0471C21.4906 15.3778 20.7529 16.5716 19.7083 17.4581C18.6638 18.3445 17.366 18.8783 16 18.9832L6 19.0022C2.644 18.7282 1.0098e-06 15.9242 1.0098e-06 12.5022ZM15.848 16.9892C16.7938 16.9165 17.6925 16.5467 18.4156 15.9327C19.1387 15.3188 19.6493 14.492 19.8745 13.5704C20.0996 12.6489 20.0278 11.6798 19.6692 10.8016C19.3107 9.92334 18.6837 9.18086 17.878 8.68021L17.071 8.17721L16.951 7.23521C16.7641 5.78889 16.0569 4.46014 14.9616 3.49729C13.8663 2.53444 12.4578 2.00339 10.9995 2.00339C9.54116 2.00339 8.13272 2.53444 7.03742 3.49729C5.94213 4.46014 5.23493 5.78889 5.048 7.23521L4.928 8.17721L4.123 8.68021C3.3173 9.1808 2.69038 9.9232 2.3318 10.8014C1.97321 11.6795 1.90129 12.6485 2.12631 13.57C2.35133 14.4915 2.86177 15.3183 3.58475 15.9324C4.30773 16.5464 5.20625 16.9163 6.152 16.9892L6.325 17.0022H15.675L15.848 16.9892ZM12 11.0022V15.0022H10V11.0022H7L11 6.00221L15 11.0022H12Z"
        fill={style.fill ?? xTheme.common.white}
      />
    </CustomSvgIcon>
  );
};

UploadCloudIcon.defaultProps = {
  style: {},
};

UploadCloudIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default UploadCloudIcon;

/* eslint-disable no-param-reassign */
import produce from 'immer';
import create from 'zustand';
import { pipe } from 'ramda';
import { persist, devtools } from 'zustand/middleware';

const STORE_NAME = 'WithdrawalStore';

const immer = config => (set, get, api) =>
  config(
    (partial, replace) => {
      const nextState =
        typeof partial === 'function' ? produce(partial) : partial;
      return set(nextState, replace);
    },
    get,
    api
  );

const persistence = config =>
  persist(config, {
    name: STORE_NAME,
  });

const createStore = pipe(persistence, immer, devtools, create);

const WithdrawalZustandStore = createStore(set => ({
  bankAccounts: [],
  setBankAccounts: bankAccounts => {
    set(state => {
      state.bankAccounts = bankAccounts;
    });
  },
  fetchingBankAccounts: false,
  setFetchingBankAccounts: isFetching => {
    set(state => {
      state.fetchingBankAccounts = isFetching;
    });
  },

  addBankAccount: bankAccount => {
    set(state => {
      state.bankAccounts = [...state.bankAccounts, bankAccount];
    });
  },
  isAddingBankAccount: false,
  setIsAddingBankAccount: isAdding => {
    set(state => {
      state.isAddingBankAccount = isAdding;
    });
  },

  withdrawalForm: {
    amount: null,
    accountNumber: null,
  },
  setwithdrawalFormAmount: (amount: number) => {
    set(state => {
      state.withdrawalForm.amount = amount;
    });
  },
  setwithdrawalFormAccountNumber: (accountNumber: string) => {
    set(state => {
      state.withdrawalForm.accountNumber = accountNumber;
    });
  },
  resetForm: () => {
    set(state => {
      state.withdrawalForm = {
        amount: null,
        accountNumber: null,
      };
    });
  },
  resetStore: () => {
    set(state => {
      state.withdrawalAccounts = [];
      state.withdrawalForm = {
        amount: null,
        accountNumber: null,
      };
    });
  },
}));

export default WithdrawalZustandStore;

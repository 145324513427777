import { GridSelectionModel } from '@material-ui/data-grid';
import axios from '../../../../../helpers/axios';
import { InvoicesResponse } from '../invoicesServicesTypes';

type GetInvoicesByIdsParams = {
  ids: GridSelectionModel[];
  businessId: number;
  token: string;
};

export const getInvoicesByIds = async ({
  ids,
  businessId,
  token,
}: GetInvoicesByIdsParams): Promise<InvoicesResponse> => {
  try {
    const idsParam = ids.join(',');

    const path = `/api/business/${businessId}/invoices?invoiceIds=${idsParam}&size=9999`;

    const response = await axios.get<InvoicesResponse>(path, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.errorCode);
  }
};

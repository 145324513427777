import React from 'react';
import { DataGrid, DataGridProps } from '@material-ui/data-grid';

export type TableProps = DataGridProps & {
  enableColumnMenu?: boolean;
};

function Table(props: TableProps) {
  const showColumnMenu = () => {
    const { enableColumnMenu } = props;
    return enableColumnMenu ? {} : { disableColumnMenu: true };
  };

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        autoHeight
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        {...showColumnMenu()}
        {...props}
      />
    </div>
  );
}

export default Table;

import { ResourceElement } from '../types';
import global from './global.json';
import suppliers from './suppliers.json';
import payments_invoices from './payments-invoices.json';

const enResources: ResourceElement = {
  global,
  suppliers,
  payments_invoices,
};

export default enResources;

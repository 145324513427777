import React from 'react';
import { SvgIcon, styled } from '@material-ui/core';
import PropTypes from 'prop-types';
import { xTheme } from '@xepelinapp/x-ds';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const InfoIcon = props => {
  const { style, color } = props;

  return (
    <CustomSvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M16 0C7.1625 0 0 7.1625 0 16C0 24.8375 7.1625 32 16 32C24.8375 32 32 24.8375 32 16C32 7.1625 24.8375 0 16 0ZM16 8C17.1044 8 18 8.89563 18 10C18 11.1044 17.1044 12 16 12C14.8956 12 14 11.1062 14 10C14 8.89375 14.8938 8 16 8ZM18.5 24H13.5C12.675 24 12 23.3312 12 22.5C12 21.6688 12.6719 21 13.5 21H14.5V17H14C13.1719 17 12.5 16.3281 12.5 15.5C12.5 14.6719 13.175 14 14 14H16C16.8281 14 17.5 14.6719 17.5 15.5V21H18.5C19.3281 21 20 21.6719 20 22.5C20 23.3281 19.3312 24 18.5 24Z"
        fill={color}
      />
    </CustomSvgIcon>
  );
};

InfoIcon.defaultProps = {
  style: {},
  color: xTheme.info.main,
};

InfoIcon.propTypes = {
  style: PropTypes.objectOf(Object),
  color: PropTypes.string,
};

export default InfoIcon;

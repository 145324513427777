import { Invoice } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';

const key = 'selectedInvoices';

export const deleteInvoices = (): void => {
  sessionStorage.removeItem(key);
};

export const addInvoices = (invoices: Invoice[]): void => {
  sessionStorage.setItem(key, JSON.stringify(invoices));
};

export const getInvoices = (): Invoice[] => {
  const storage = sessionStorage.getItem(key);

  try {
    return storage ? JSON.parse(storage) : null;
  } catch (err) {
    return null;
  }
};

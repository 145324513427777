// eslint-disable-next-line import/prefer-default-export
export const MX = {
  cards: {
    hasSurplus: false,
  },
  orderCreation: {
    totalTableTooltip: true,
    resumeInteresRateTooltip: true,
  },
  orderDetails: {
    hasDocuments: true,
  },
  legal: {
    stakeholdersConjugal: false,
    stakeholdersHasDocuments: true,
  },
  invoicesTable: {
    totalAmountTooltip: true,
  },
  bankAccountActions: {
    hasXepelinAccountType: true,
    showCurrencyInput: true,
  },
  profileSidebar: [
    {
      key: 'profile',
      label: 'Información general',
      path: '/user/profile',
      dataQa: 'topbar-profile-navigation-link',
    },
    {
      key: 'legal',
      label: 'Información legal',
      path: '/user/profile/legal',
      dataQa: 'topbar-profile-legal-navigation-link',
    },
    {
      key: 'bankAccounts',
      label: 'Cuentas bancarias',
      path: '/user/profile/bankaccounts',
      dataQa: 'topbar-profile-bankaccounts-navigation-link',
    },
    {
      key: 'sat',
      label: 'SAT',
      path: '/user/profile/sat',
      dataQa: 'topbar-profile-sat-navigation-link',
    },
  ],
};

import React from 'react';
import { Layout } from 'components/layout';
import { FetchBusiness } from '../../../features/shared/FetchBusiness';
import { useFetchHolidays } from '../../../features/shared/hooks/useFetchHolidays';
import { HomeFinancePage } from '../../../src/pages/home-finance';

function HomeFinance() {
  useFetchHolidays();
  return (
    <FetchBusiness>
      <Layout>
        <HomeFinancePage />
      </Layout>
    </FetchBusiness>
  );
}

export default HomeFinance;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import XLogoHorizontal from './x-logo-horizontal.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

const NavbarLogo = () => {
  const classes = useStyles();
  return (
    <img
      className={classes.root}
      alt="xepelin-horizontal-logo"
      src={XLogoHorizontal.src}
      height={26.48}
      width={94.1}
    />
  );
};

export default NavbarLogo;

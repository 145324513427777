import { InvoiceCategories } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';
import { OrderType } from '@finance/useCases/OperationLineValues.type';
import { DIRECT_FINANCING, EARLY_PAYMENT } from 'helpers/constants';

type IsFinanceableInvoiceProps = {
  categories: InvoiceCategories[];
  allInvoices?: boolean;
  operationType: `${OrderType}`;
};

export const isFinanceableInvoice = ({
  categories,
  operationType,
  allInvoices = false,
}: IsFinanceableInvoiceProps): boolean => {
  const hasUniqueCategory = categories?.length === 1;
  const isDirectFInancing =
    categories?.find(
      category =>
        category === InvoiceCategories.directFinancing &&
        operationType === DIRECT_FINANCING
    ) && hasUniqueCategory;
  const isEarlyPayment =
    categories?.find(
      category =>
        category === InvoiceCategories.earlyPayment &&
        operationType === EARLY_PAYMENT
    ) && hasUniqueCategory;
  return allInvoices || isDirectFInancing || isEarlyPayment;
};

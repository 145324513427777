/* eslint-disable import/prefer-default-export */
import Hashids from 'hashids';

const hashids = new Hashids();

export const HashKey = key => {
  return hashids.encode(key);
};

export const decodeKey = key => {
  return hashids.decode(key);
};

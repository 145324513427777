import React from 'react';
import { Box, Button, Divider } from '@material-ui/core';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { BaseDialog } from '../commons';
import useCashInInfoModal from './useCashInInfoModal';
import { Link, Spacer } from '../../../../../features/shared';
import { Typography } from '../../../../design-system/atoms';
import Icon from '../../../../design-system/icons/Icon';
import { RowInfo } from './RowInfo';

type CashInInfoModalProps = {
  show: boolean;
  onClose: () => void;
  amountToPay?: number;
  onClick?: () => void;
};

export default function CashInInfoModal({
  show,
  onClose,
  amountToPay = null,
  onClick = () => null,
}: CashInInfoModalProps) {
  const { countryId, title, description, items, copyAccountNumberToClipboard } =
    useCashInInfoModal(amountToPay);

  return (
    <BaseDialog
      title={title}
      description={description}
      isOpen={show}
      handleClose={onClose}
      maxWidth="xs"
    >
      <Box display="flex" flexDirection="column">
        {items?.map(({ label, value, amount }, index) => (
          <Box display="flex" flexDirection="column" key={label}>
            {index === 0 ? <Divider light /> : null}
            <Spacer spacing={2} />
            <RowInfo
              countryId={countryId}
              label={label}
              value={value}
              amount={amount}
            />
            <Spacer spacing={2} />
            <Divider light />
          </Box>
        ))}
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Spacer spacing={3} />
          <Button
            aria-label="copy"
            size="small"
            color="primary"
            variant="text"
            startIcon={<Icon iconDefinition={faCopy} fontSize="small" />}
            onClick={copyAccountNumberToClipboard}
          >
            Copiar datos bancarios
          </Button>
        </Box>
        {amountToPay ? (
          <Box display="flex" flexDirection="column">
            <Spacer spacing={3} />
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={onClick}
            >
              Ya transferí
            </Button>
          </Box>
        ) : null}
        <Box display="flex" flexDirection="column">
          <Spacer spacing={3} />
          <Typography
            component="div"
            align="center"
            variant="tiny"
            color="textSecondary"
          >
            <Box fontWeight="fontWeightRegular">
              Contáctanos para cualquier duda o problema que tengas a: <br />
              <Link href="mailto:hello@xepelin.com">hello@xepelin.com</Link>
            </Box>
          </Typography>
        </Box>
      </Box>
    </BaseDialog>
  );
}

import { BusinessInfo } from '@features/auth/domain';
import { Navbar } from '../types/Navbar';

const countryId = process.env
  .NEXT_PUBLIC_COUNTRY_ID as BusinessInfo['countryId'];

const DIRECT_FINANCE_LINK_OBJECT = {
  label: 'Financiamiento Directo',
  description: 'Adelanta el pago de tus facturas',
  dataQa: 'topbar-finance-navigation-link',
  path: '/finance/directo',
};

const EARLY_PAYMENT_LINK_OBJECT = {
  label: 'Pronto Pago',
  description: 'Finánciate con mejores condiciones',
  dataQa: 'topbar-finance-navigation-link',
  path: '/finance/prontopago',
};

const ORDERS_LINK_OBJECT = {
  label: 'Estado de operaciones',
  description: 'Haz seguimiento de tus solicitudes',
  dataQa: 'topbar-orders-navigation-link',
  path: '/orders',
};

const PAYMENTS_LINK_OBJECT = {
  label: 'Payments',
  description: 'Financiate y paga, en un solo lugar',
  dataQa: 'topbar-payments-navigation-link',
  path: '/payments',
};

const XTRACKER_LINK_OBJECT = {
  label: 'Xtracker',
  description: 'Salud financiera de tu empresa',
  dataQa: 'topbar-xtracker-navigation-link',
  path: '/xtracker',
};

const DUE_PAYS_LINK_OBJECT = {
  label: 'Pagos Xepelin',
  description: 'Pagos pendientes y por recibir',
  dataQa: 'topbar-due-pays-navigation-link',
  path: '/due-pays',
  trackEvent: 'CLICK_PAYS_XEPELIN',
};

const SUPPLIERS_LINK_OBJECT = {
  label: 'Mis proveedores',
  description: 'Contacto y cuentas bancarias',
  dataQa: 'topbar-suppliers-navigation-link',
  path: '/suppliers',
};

const navbarItems: Navbar = [
  {
    navButtonLabel: 'Inicio',
    dataQa: 'topbar-home-navigation-link',
    content: null,
    path: '/',
  },
  {
    navButtonLabel: 'Cuentas por cobrar',
    content: {
      headerTitle: 'Obtén liquidez para llevar adelante tu empresa',
      items: [
        DIRECT_FINANCE_LINK_OBJECT,
        EARLY_PAYMENT_LINK_OBJECT,
        ORDERS_LINK_OBJECT,
      ],
    },
  },
  {
    navButtonLabel: 'Cuentas por pagar',
    content: {
      headerTitle: 'Financia y programa tus pagos a proveedores',
      items: [PAYMENTS_LINK_OBJECT, SUPPLIERS_LINK_OBJECT],
    },
  },
  {
    navButtonLabel: 'Mi empresa',
    content: {
      headerTitle: 'Visualiza y analiza la actividad de tu empresa',
      items: [
        ORDERS_LINK_OBJECT,
        XTRACKER_LINK_OBJECT,
        ...(countryId === 'MX' ? [DUE_PAYS_LINK_OBJECT] : []),
      ],
    },
  },
];

export default navbarItems;

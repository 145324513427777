import React from 'react';
import { SvgIcon, styled } from '@material-ui/core';
import PropTypes from 'prop-types';
import { xTheme } from '@xepelinapp/x-ds';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  width: 32,
  height: 32,
  ...props.style,
}));

const DeleteIcon = props => {
  const { style } = props;

  return (
    <CustomSvgIcon viewBox="0 0 32 32" fill="none" style={style}>
      <path
        d="M11.5 21.25C11.5 22.075 12.175 22.75 13 22.75H19C19.825 22.75 20.5 22.075 20.5 21.25V13.75C20.5 12.925 19.825 12.25 19 12.25H13C12.175 12.25 11.5 12.925 11.5 13.75V21.25ZM13.75 13.75H18.25C18.6625 13.75 19 14.0875 19 14.5V20.5C19 20.9125 18.6625 21.25 18.25 21.25H13.75C13.3375 21.25 13 20.9125 13 20.5V14.5C13 14.0875 13.3375 13.75 13.75 13.75ZM18.625 10L18.0925 9.4675C17.9575 9.3325 17.7625 9.25 17.5675 9.25H14.4325C14.2375 9.25 14.0425 9.3325 13.9075 9.4675L13.375 10H11.5C11.0875 10 10.75 10.3375 10.75 10.75C10.75 11.1625 11.0875 11.5 11.5 11.5H20.5C20.9125 11.5 21.25 11.1625 21.25 10.75C21.25 10.3375 20.9125 10 20.5 10H18.625Z"
        fill={xTheme.text.secondary}
      />
    </CustomSvgIcon>
  );
};

DeleteIcon.defaultProps = {
  style: {},
};

DeleteIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default DeleteIcon;

import { makeStyles } from '@material-ui/core';
import colors from 'design-system/theme/colors';

export const useStyles = makeStyles(theme => ({
  tableHeaderActions: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'flex-end',
    },
  },
  inputTableActions: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(3),
      minWidth: 300,
    },
  },
  tableContainer: {
    backgroundColor: colors.background.paper,
    boxShadow: '0px 10px 24px #c9d2e0',
    minHeight: '400px',
  },
  alignRight: {},
  actionButton: {
    padding: '0 32px',
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
  table: {
    '& .MuiDataGrid-columnHeader .MuiCheckbox-root': {
      display: 'none',
    },
  },
}));

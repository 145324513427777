import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  tabs: {
    position: 'sticky',
    top: 64,
    zIndex: 30,
    backgroundColor: theme.palette.background.default,
  },
}));

import SegmentZustandStore from './SegmentZustandStore';

const useEventID = () => SegmentZustandStore(state => state.eventID);
const useSetEventID = () => SegmentZustandStore(state => state.setEventID);

const useIsModified = () => SegmentZustandStore(state => state.isModified);
const useSetIsModified = () =>
  SegmentZustandStore(state => state.setIsModified);

const usePaybackTerm = () => SegmentZustandStore(state => state.paybackTerm);
const useSetPaybackTerm = () =>
  SegmentZustandStore(state => state.setPaybackTerm);

const usePaybackTermDate = () =>
  SegmentZustandStore(state => state.paybackTermDate);
const useSetPaybackTermDate = () =>
  SegmentZustandStore(state => state.setPaybackTermDate);

export {
  useEventID,
  useSetEventID,
  useIsModified,
  useSetIsModified,
  usePaybackTerm,
  useSetPaybackTerm,
  usePaybackTermDate,
  useSetPaybackTermDate,
};

import { useMutation } from 'react-query';
import { useOperationType } from '@finance/hooks/migration/useOperationType';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers/rootReducer';
import {
  submitOrderService,
  InvoiceShort,
} from '../services/submitOrderService/submitOrderService';
import { Invoice, Order } from '../services/invoicesServicesTypes';

const useSubmitOrder = ({
  financeableInvoices,
  onSuccess,
  onError,
}: {
  financeableInvoices: Invoice[];
  onSuccess: (data: Order) => void;
  onError: (error: unknown) => void;
}) => {
  const orderType = useOperationType();

  const { id: businessId } = useSelector(
    (state: RootState) => state.businessState.business
  );

  const mutation = useMutation<Order, unknown, InvoiceShort[]>(
    invoices => {
      return submitOrderService({ invoices, orderType, businessId });
    },
    {
      onSuccess: data => {
        onSuccess(data);
      },
      onError,
    }
  );

  const handleSubmit = () => {
    mutation.mutate(
      financeableInvoices.map(invoice => ({
        invoiceId: invoice.id,
        expirationDate: invoice.expirationDate,
      }))
    );
  };

  return { ...mutation, handleSubmit };
};

export default useSubmitOrder;

import axios from '../../../../helpers/axios';
import { GetInvoicesParams, InvoicesResponse } from './invoicesServicesTypes';

export const getInvoices = async ({
  businessId,
  pagination,
  token,
  shoudFetchAllInvoices,
}: GetInvoicesParams): Promise<InvoicesResponse> => {
  try {
    const PATH = `/api/business/${businessId}/invoices`;
    const { orderType, ...paginationRest } = pagination;
    const params = shoudFetchAllInvoices
      ? { ...paginationRest }
      : { ...pagination };
    const response = await axios.get<InvoicesResponse>(PATH, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.errorCode);
  }
};

export * from './invoicesServicesTypes';

import { useState } from 'react';
import useWallet from '../useWallet';

const useWalletBalanceWidget = () => {
  const {
    balance,
    wallet,
    isWalletEnabled,
    getWalletBalance,
    getBusinessWallet,
  } = useWallet();

  const [shouldShowCashInModal, setShouldShowCashInModal] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      label: 'Abonar',
      key: 'cashin',
      icon: 'cashin',
      onClick: () => {
        setShouldShowCashInModal(true);
      },
    },
    {
      label: 'Retirar',
      key: 'cashout',
      icon: 'cashout',
      onClick: () => {
        handleClose();
      },
      disabled: true,
    },
    {
      label: 'Cobrar',
      key: 'payment',
      icon: 'payment',
      onClick: () => {
        handleClose();
      },
      disabled: true,
    },
  ];

  return {
    getBusinessWallet,
    getWalletBalance,
    handleClick,
    handleClose,
    setShouldShowCashInModal,
    menuItems,
    anchorEl,
    wallet,
    balance,
    shouldShowCashInModal,
    isWalletEnabled,
  };
};

export default useWalletBalanceWidget;

import { PURGE } from 'redux-persist';
import {
  FETCH_DOCUMENT_START,
  FETCH_DOCUMENT_ERROR,
  FETCH_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_START,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_ERROR,
  DOWNLOAD_DOCUMENT_START,
  DOWNLOAD_DOCUMENT_SUCCESS,
  DOWNLOAD_DOCUMENT_ERROR,
  LOCAL_PURGE,
  VERIFY_DOCUMENTS_START,
  VERIFY_DOCUMENTS_SUCCESS,
  VERIFY_DOCUMENTS_FAILURE,
  VERIFY_DOCUMENTS_RESET,
  FETCH_DOCUMENT_BASE64_START,
  FETCH_DOCUMENT_BASE64_SUCCESS,
  FETCH_DOCUMENT_BASE64_FAILURE,
} from '../actions/types';
import { t13s } from '../../config/translationKeys';

const initialState = {
  businessIdDocumentsOwner: null,
  businessDocuments: [],
  businessDocumentsIsLoading: false,
  businessDocumentsError: null,

  stakeholderIdDocumentsOwner: null,
  stakeholderDocuments: [],
  stakeholderDocumentsIsLoading: false,
  stakeholderDocumentsError: null,

  orderIdDocumentsOwner: null,
  orderDocuments: [],
  orderDocumentsIsLoading: false,
  orderDocumentsError: null,

  documentWasUpload: false,
  uploadDocumentIsLoading: false,
  uploadDocumentError: null,

  documentWasDownload: false,
  downloadDocumentIsLoading: false,
  downloadDocumentError: null,

  stakeholderVerifyDocuments: {},
  stakeholderVerifyDocumentsLoading: false,
  stakeholderVerifyDocumentsError: null,

  documentBase64: {},
  fetchDocumentBase64IsLoading: false,
  fetchDocumentBase64Error: null,
};

const sortDocuments = (a, b) => {
  return a.order - b.order;
};

const fetchDocumentsStart = (state, payload) => {
  const { documentableType, documentableId } = payload;
  return {
    ...state,
    [`${documentableType}Documents`]: [],
    [`${documentableType}IsLoading`]: true,
    [`${documentableType}DocumentsError`]: null,
    [`${documentableType}IdDocumentsOwner`]: documentableId,
  };
};

const fetchDocumentsSuccess = (state, payload) => {
  const { documentableType, documents } = payload;
  return {
    ...state,
    [`${documentableType}IsLoading`]: false,
    [`${documentableType}DocumentsError`]: null,
    [`${documentableType}Documents`]: documents.sort((a, b) =>
      sortDocuments(a, b)
    ),
  };
};

const fetchDocumentsError = (state, payload) => {
  const { documentableType, errorCode } = payload;
  return {
    ...state,
    businessDocuments: [],
    [`${documentableType}IsLoading`]: false,
    [`${documentableType}DocumentsError`]:
      errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const uploadDocumentsStart = state => {
  return {
    ...state,
    documentWasUpload: false,
    uploadDocumentIsLoading: true,
    uploadDocumentError: null,
  };
};

const uploadDocumentsSuccess = (state, payload) => {
  const { documentableType, updatedDocument } = payload;

  const documents = state[`${documentableType}Documents`]
    .map(doc => (doc.id !== updatedDocument.id ? doc : updatedDocument))
    .sort((a, b) => sortDocuments(a, b));

  return {
    ...state,
    [`${documentableType}Documents`]: documents,
    uploadDocumentIsLoading: false,
    uploadDocumentError: null,
    documentWasUpload: true,
  };
};

const uploadDocumentsError = (state, payload) => {
  return {
    ...state,
    documentWasUpload: false,
    uploadDocumentIsLoading: false,
    uploadDocumentError: payload.errorCode,
  };
};

const downloadDocumentsStart = state => {
  return {
    ...state,
    documentWasDownload: false,
    downloadDocumentIsLoading: true,
    downloadDocumentError: null,
  };
};

const downloadDocumentsSuccess = state => {
  return {
    ...state,
    documentWasDownload: true,
    downloadDocumentIsLoading: false,
    downloadDocumentError: null,
  };
};

const downloadDocumentsError = (state, payload) => {
  return {
    ...state,
    documentWasDownload: false,
    downloadDocumentIsLoading: false,
    downloadDocumentError: payload.errorCode,
  };
};

const verifyDocumentsStart = (state, payload) => {
  const { documentableType, documentableId } = payload;
  switch (documentableType) {
    case 'business':
      return {
        ...state,
        [`${documentableType}VerifyDocumentsLoading`]: true,
        [`${documentableType}VerifyDocumentsError`]: null,
      };
    case 'stakeholder': {
      const verifiedStakeholder = {
        ...state.stakeholderVerifyDocuments,
        [documentableId]: {
          verifyIsLoading: true,
          verifyError: null,
        },
      };
      return {
        ...state,
        [`${documentableType}VerifyDocuments`]: verifiedStakeholder,
      };
    }
    case 'order':
      return {
        ...state,
        [`${documentableType}VerifyDocumentsLoading`]: true,
        [`${documentableType}VerifyDocumentsError`]: null,
      };
    default:
      return state;
  }
};

const verifyDocumentsSuccess = (state, payload) => {
  const { documentableType, allDocumentsCompleted, documentableId } = payload;

  switch (documentableType) {
    case 'business':
      return {
        ...state,
        [`${documentableType}VerifyDocuments`]: allDocumentsCompleted,
        [`${documentableType}VerifyDocumentsLoading`]: false,
      };
    case 'stakeholder': {
      const verifiedStakeholder = {
        ...state.stakeholderVerifyDocuments,
        [documentableId]: {
          verifyIsLoading: false,
          verifyError: null,
          allDocumentsCompleted,
        },
      };
      return {
        ...state,
        [`${documentableType}VerifyDocuments`]: verifiedStakeholder,
      };
    }
    case 'order':
      return {
        ...state,
        [`${documentableType}VerifyDocuments`]: allDocumentsCompleted,
        [`${documentableType}VerifyDocumentsLoading`]: false,
      };
    default:
      return state;
  }
};

const verifyDocumentsFailure = (state, payload) => {
  const { documentableType, documentableId, error } = payload;

  switch (documentableType) {
    case 'business':
      return {
        ...state,
        [`${documentableType}VerifyDocumentsLoading`]: false,
        [`${documentableType}VerifyDocumentsError`]: error,
      };
    case 'stakeholder': {
      const errorStakeholder = {
        ...state.stakeholderVerifyDocuments,
        [documentableId]: {
          verifyIsLoading: false,
          verifyError: error,
        },
      };
      return {
        ...state,
        [`${documentableType}VerifyDocuments`]: errorStakeholder,
      };
    }
    case 'order':
      return {
        ...state,
        [`${documentableType}VerifyDocumentsLoading`]: false,
        [`${documentableType}VerifyDocumentsError`]: error,
      };
    default:
      return state;
  }
};

const verifyDocumentsReset = (state, payload) => {
  const { documentableType } = payload;
  switch (documentableType) {
    case 'business':
      return {
        ...state,
        [`${documentableType}VerifyDocuments`]: null,
        [`${documentableType}VerifyDocumentsLoading`]: false,
        [`${documentableType}VerifyDocumentsError`]: null,
      };
    case 'stakeholder': {
      return {
        ...state,
        [`${documentableType}VerifyDocuments`]: {},
      };
    }
    case 'order':
      return {
        ...state,
        [`${documentableType}VerifyDocuments`]: null,
        [`${documentableType}VerifyDocumentsLoading`]: false,
        [`${documentableType}VerifyDocumentsError`]: null,
      };
    default:
      return state;
  }
};

const fetchDocumentBase64Start = state => {
  return {
    ...state,
    documentBase64: null,
    fetchDocumentBase64IsLoading: true,
    fetchDocumentBase64Error: null,
  };
};

const fetchDocumentBase64Success = (state, payload) => {
  return {
    ...state,
    documentBase64: payload.file,
    fetchDocumentBase64IsLoading: false,
  };
};

const fetchDocumentBase64Failure = (state, payload) => {
  return {
    ...state,
    documentBase64: null,
    fetchDocumentBase64IsLoading: false,
    fetchDocumentBase64Error: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_DOCUMENT_START:
      return fetchDocumentsStart(state, payload);
    case FETCH_DOCUMENT_SUCCESS:
      return fetchDocumentsSuccess(state, payload);
    case FETCH_DOCUMENT_ERROR:
      return fetchDocumentsError(state, payload);
    case UPLOAD_DOCUMENT_START:
      return uploadDocumentsStart(state);
    case UPLOAD_DOCUMENT_SUCCESS:
      return uploadDocumentsSuccess(state, payload);
    case UPLOAD_DOCUMENT_ERROR:
      return uploadDocumentsError(state, payload);
    case DOWNLOAD_DOCUMENT_START:
      return downloadDocumentsStart(state);
    case DOWNLOAD_DOCUMENT_SUCCESS:
      return downloadDocumentsSuccess(state);
    case DOWNLOAD_DOCUMENT_ERROR:
      return downloadDocumentsError(state, payload);
    case VERIFY_DOCUMENTS_START:
      return verifyDocumentsStart(state, payload);
    case VERIFY_DOCUMENTS_SUCCESS:
      return verifyDocumentsSuccess(state, payload);
    case VERIFY_DOCUMENTS_FAILURE:
      return verifyDocumentsFailure(state, payload);
    case VERIFY_DOCUMENTS_RESET:
      return verifyDocumentsReset(state, payload);
    case FETCH_DOCUMENT_BASE64_START:
      return fetchDocumentBase64Start(state);
    case FETCH_DOCUMENT_BASE64_SUCCESS:
      return fetchDocumentBase64Success(state, payload);
    case FETCH_DOCUMENT_BASE64_FAILURE:
      return fetchDocumentBase64Failure(state, payload);
    case LOCAL_PURGE:
      return initialState;
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

import React from 'react';
import { Alert as AlertMui, AlertTitle } from '@material-ui/lab';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useStyles } from './styles';

interface AlertProps {
  severity: 'error' | 'info' | 'success' | 'warning';
  title?: string;
  children: React.ReactNode;
  icon?: React.ReactNode | false;
}

function Alert({ severity, title = null, children, icon }: AlertProps) {
  const classes = useStyles({ severity });

  return (
    <AlertMui
      className={classes.alert}
      iconMapping={{
        success: <CheckCircleIcon fontSize="inherit" />,
        error: <CancelIcon fontSize="inherit" />,
        info: <InfoIcon fontSize="inherit" />,
      }}
      severity={severity}
      icon={icon}
    >
      {title && <AlertTitle className={classes.alertTitle}>{title}</AlertTitle>}
      {children}
    </AlertMui>
  );
}

export default Alert;

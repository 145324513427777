import { PURGE } from 'redux-persist';
import {
  FETCH_IMPERSONATE_TOKEN_START,
  FETCH_IMPERSONATE_TOKEN_ERROR,
  FETCH_IMPERSONATE_TOKEN_SUCCESS,
  LOCAL_PURGE,
} from '../actions/types';
import { t13s } from '../../config/translationKeys';

const initialState = {
  accessToken: '',
  isLoading: true,
  impersonateError: null,
};

const fetchImpersonateTokenStart = state => {
  return {
    ...state,
    accessToken: '',
    isLoading: true,
    impersonateError: null,
  };
};

const fetchImpersonateTokenSuccess = (state, payload) => {
  return {
    ...state,
    accessToken: payload.accessToken,
    isLoading: false,
    impersonateError: null,
  };
};
const fetchImpersonateTokenError = (state, payload) => {
  return {
    ...state,
    accessToken: '',
    isLoading: false,
    impersonateError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_IMPERSONATE_TOKEN_START:
      return fetchImpersonateTokenStart(state);
    case FETCH_IMPERSONATE_TOKEN_SUCCESS:
      return fetchImpersonateTokenSuccess(state, payload);
    case FETCH_IMPERSONATE_TOKEN_ERROR:
      return fetchImpersonateTokenError(state, payload);
    case LOCAL_PURGE:
      return initialState;
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import { styled, SvgIcon } from '@material-ui/core';
import { xTheme } from '@xepelinapp/x-ds';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  width: 13,
  height: 13,
  ...props.style,
}));

const EditPencilIcon = props => {
  return (
    <CustomSvgIcon style={props.style} viewBox="0 0 13 13" fill="none">
      <path
        d="M0 10.4997V12.9997H2.5L9.87333 5.62638L7.37333 3.12638L0 10.4997ZM11.8067 3.69305C12.0667 3.43305 12.0667 3.01305 11.8067 2.75305L10.2467 1.19305C9.98667 0.933047 9.56667 0.933047 9.30667 1.19305L8.08667 2.41305L10.5867 4.91305L11.8067 3.69305Z"
        fill={xTheme.text.secondary}
      />
    </CustomSvgIcon>
  );
};

EditPencilIcon.defaultProps = {
  style: {},
};

EditPencilIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default EditPencilIcon;

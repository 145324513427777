import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SvgIcon } from '@material-ui/core';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { IconProp as IconPropFA } from '@fortawesome/fontawesome-svg-core';

export type IconProps = {
  iconDefinition: IconDefinition;
  color?: 'action' | 'disabled' | 'error' | 'inherit' | 'primary' | 'secondary';
  fontSize?: 'inherit' | 'large' | 'medium' | 'small';
  className?: string;
  htmlColor?: string;
};

function Icon({
  iconDefinition,
  color = 'inherit',
  fontSize = 'medium',
  className = '',
  htmlColor = '',
}: IconProps) {
  return (
    <SvgIcon
      color={color}
      fontSize={fontSize}
      className={className}
      htmlColor={htmlColor}
    >
      <FontAwesomeIcon icon={iconDefinition as IconPropFA} />
    </SvgIcon>
  );
}

export default Icon;

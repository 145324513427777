// eslint-disable-next-line import/prefer-default-export
export const MX = {
  decimalSeparator: '.',
  thousandSeparator: ',',
  localization: 'America/Mexico_City',
  currencySymbol: '$',
  currencyCode: 'MXN',
  currencyDecimalPoint: 2,
  shordateformat: 'DD-MM-YYYY',
  longdateformat: 'DD MMMM YYYY',
  phoneformat: '+52##########',
  readableDateFormat: 'dddd DD [de] MMMM',
  readableTimeFormat: 'hh:mm a',
};

import { NextRouter, useRouter } from 'next/router';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import setAuthToken from '../../../src/helpers/setAuthToken';
import { SplashScreen } from '../../auth/ui/common/SplashScreen';
import { User, useUser } from '../../shared/hooks/useUser';
import { useRouterProxy } from '../adapter/useRouteProxy';
import { useCheckAuthExpiration } from './hooks/useCheckAuthExpiration';
import { useIsMounted } from '../../shared/hooks/useIsMounted';
import publicPaths from '../common/publicPaths';
import getPathnameFromUrl from '../libs/getPathnameFromUrl';
import urlExists from '../libs/urlExists';

const redirectIfNeeded = (
  user: User,
  router: NextRouter,
  setIsRedirecting: Dispatch<SetStateAction<boolean>>,
  isMaintenance: boolean
) => {
  const { asPath: routerPathname } = router;
  if (!router.isReady || !urlExists(routerPathname)) {
    return null;
  }
  const { destination } = useRouterProxy(user, router.asPath, isMaintenance);
  const { query } = router;
  delete query.dynamicRoutes;

  if (destination) {
    setIsRedirecting(true);
    const pathname = getPathnameFromUrl(router.asPath);
    if (!publicPaths.has(pathname)) {
      const url = window.location.href.split('?')[0];
      query.redirect = url;
    }
    router.push({
      pathname: destination,
      query,
    });
  } else {
    setIsRedirecting(false);
  }
  return destination;
};

export function RouterProxy({
  children,
  isFlagsmithInitialized,
}: {
  children: JSX.Element;
  isFlagsmithInitialized: boolean;
}) {
  const router = useRouter();
  const user = useUser();
  const isMounted = useIsMounted();
  const isMaintenance = process.env.NEXT_PUBLIC_MAINTENANCE === 'true';
  const [isRedirecting, setIsRedirecting] = useState(false);

  if (!isMounted?.current && !user.isLoading && isFlagsmithInitialized) {
    isMounted.current = true;
    redirectIfNeeded(user, router, setIsRedirecting, isMaintenance);
    setAuthToken(user.status.token.value);
  }

  useCheckAuthExpiration();

  useEffect(() => {
    if (isFlagsmithInitialized) {
      setIsRedirecting(false);
      redirectIfNeeded(user, router, setIsRedirecting, isMaintenance);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, isFlagsmithInitialized]);

  if (isRedirecting || user.isLoading || !isFlagsmithInitialized) {
    return <SplashScreen />;
  }
  return children;
}

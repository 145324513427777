import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { OrderType } from '@finance/useCases/OperationLineValues.type';
import { useOperationType } from '@finance/hooks/migration/useOperationType';
import { Container } from 'design-system/atoms/layout';
import HomeCards from '../../../../components/HomeCards';
import { checkTaxServiceCredential } from '../../../../store/actions/taxServiceActions';
import { ONBOARDING_STEP_CASES } from '../../../../helpers/constants';
import tracker from '../../../../helpers/tracker';
import { FinanceTabs } from '../finance-tabs';
import Spacer from '../../../../../features/shared/Spacer';
import OnboardingBanner from '../../../../components/OnboardingBanner';
import type { BusinessState, RootState, TaxServiceState } from '../../types';
import { useStyles } from './finance-wrapper-styles';

export function FinanceWrapper({ children }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const operationType = useOperationType();

  const {
    countryId,
    id: businessId,
    onboardingStep,
  } = useSelector<RootState, BusinessState>(
    state => state.businessState.business
  );

  const wsLoadStatus = useSelector<RootState, boolean>(
    state => state.wsState.loadStatus
  );

  const { taxStatus } = useSelector<RootState, TaxServiceState>(
    state => state.taxServiceState
  );

  const { hasCredential = false } = taxStatus;

  useEffect(() => {
    if (countryId) {
      dispatch(checkTaxServiceCredential(businessId));
    }
  }, [countryId, businessId, dispatch]);

  useEffect(() => {
    setTimeout(() => {
      tracker.event(
        operationType === OrderType.DIRECT_FINANCING
          ? 'Viewed_FD_tab'
          : 'Viewed_PP_tab'
      );
    }, 3000);
  }, [operationType]);

  useEffect(() => {
    if (!hasCredential && !!wsLoadStatus && countryId) {
      dispatch(checkTaxServiceCredential(businessId));
    }
  }, [taxStatus, wsLoadStatus, dispatch, businessId, countryId, hasCredential]);

  return (
    <Container>
      <Spacer spacing={2} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {onboardingStep === ONBOARDING_STEP_CASES.ONBOARDING_COMPLETED ? (
            <HomeCards />
          ) : (
            <OnboardingBanner />
          )}
          <>
            <Grid item className={classes.tabs}>
              <FinanceTabs />
            </Grid>
            <Grid item xs={12}>
              {children}
            </Grid>
          </>
        </Grid>
      </Grid>
    </Container>
  );
}

/* eslint-disable no-param-reassign */
import produce from 'immer';
import create from 'zustand';
import { pipe } from 'ramda';
import { persist, devtools } from 'zustand/middleware';

const STORE_NAME = 'SegmentStore';

const immer = config => (set, get, api) =>
  config(
    (partial, replace) => {
      const nextState =
        typeof partial === 'function' ? produce(partial) : partial;
      return set(nextState, replace);
    },
    get,
    api
  );

const persistence = config =>
  persist(config, {
    name: STORE_NAME,
    partialize: state =>
      Object.fromEntries(
        Object.entries(state).filter(([key]) => !['eventID'].includes(key))
      ),
  });

const createStore = pipe(persistence, immer, devtools, create);

const SegmentZustandStore = createStore(set => ({
  eventID: null,
  paybackTerm: null,
  paybackTermDate: null,
  clearSegmentStore: () =>
    set(state => {
      state.eventID = null;
      state.paybackTerm = null;
      state.paybackTermDate = null;
    }),
  setEventID: id =>
    set(state => {
      state.eventID = id;
    }),
  setIsModified: isModified =>
    set(state => {
      state.isModified = isModified;
    }),
  setPaybackTerm: days =>
    set(state => {
      state.paybackTerm = days;
    }),
  setPaybackTermDate: date =>
    set(state => {
      state.paybackTermDate = date;
    }),
}));

export default SegmentZustandStore;

import axios from '../../helpers/axios';
import {
  FETCH_BUSINESS_BANK_ACCOUNTS_START,
  FETCH_BUSINESS_BANK_ACCOUNTS_SUCCESS,
  FETCH_BUSINESS_BANK_ACCOUNTS_FAILURE,
  CREATE_BUSINESS_BANK_ACCOUNT_START,
  CREATE_BUSINESS_BANK_ACCOUNT_SUCCESS,
  CREATE_BUSINESS_BANK_ACCOUNT_FAILURE,
  UPDATE_BUSINESS_BANK_ACCOUNT_START,
  UPDATE_BUSINESS_BANK_ACCOUNT_SUCCESS,
  UPDATE_BUSINESS_BANK_ACCOUNT_FAILURE,
  SET_DEFAULT_BANK_ACCOUNT_START,
  SET_DEFAULT_BANK_ACCOUNT_SUCCESS,
  SET_DEFAULT_BANK_ACCOUNT_FAILURE,
  DELETE_BANK_ACCOUNT_START,
  DELETE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_FAILURE,
  BANK_ACCOUNT_RESET,
  FETCH_BANKS_START,
  FETCH_BANKS_SUCCESS,
  FETCH_BANKS_FAILURE,
  GET_ACCOUNT_VALIDATION_STATUS_START,
  GET_ACCOUNT_VALIDATION_STATUS_FAILURE,
  GET_ACCOUNT_VALIDATION_STATUS_SUCCESS,
} from './types';
import { t13s } from '../../config/translationKeys';

import { enqueueSnackbar } from './notificationActions';

export const fetchBusinessBankAccounts = id => dispatch => {
  dispatch({ type: FETCH_BUSINESS_BANK_ACCOUNTS_START });
  axios
    .get(`/api/business/${id}/bankaccount`)
    .then(res => {
      dispatch({
        type: FETCH_BUSINESS_BANK_ACCOUNTS_SUCCESS,
        payload: res.data,
      });
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({
        type: FETCH_BUSINESS_BANK_ACCOUNTS_FAILURE,
        payload: { errorCode },
      });
    });
};

export const createBusinessBankAccount =
  (businessId, bankAccountData) => dispatch => {
    dispatch({ type: CREATE_BUSINESS_BANK_ACCOUNT_START });
    axios
      .post(`/api/business/${businessId}/bankaccount`, {
        ...bankAccountData,
      })
      .then(res => {
        dispatch({
          type: CREATE_BUSINESS_BANK_ACCOUNT_SUCCESS,
          payload: { ...res.data },
        });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.CREATE_BANK_ACCOUNT_SUCCESS,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({
          type: CREATE_BUSINESS_BANK_ACCOUNT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const updateBusinessBankAccount =
  (businessId, bankAccountId, bankAccountData) => dispatch => {
    dispatch({ type: UPDATE_BUSINESS_BANK_ACCOUNT_START });
    axios
      .put(
        `/api/business/${businessId}/bankaccount/${bankAccountId}`,
        bankAccountData
      )
      .then(res => {
        dispatch({
          type: UPDATE_BUSINESS_BANK_ACCOUNT_SUCCESS,
          payload: { ...res.data },
        });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.UPDATE_BANK_ACCOUNT_SUCCESS,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({
          type: UPDATE_BUSINESS_BANK_ACCOUNT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const setDefaultBusinessBankAccount =
  (businnessId, bankAccountId) => dispatch => {
    dispatch({ type: SET_DEFAULT_BANK_ACCOUNT_START });
    // This is temporal and its used for sending the right data to each server
    axios
      .put(`/api/business/${businnessId}/bankaccount/${bankAccountId}/`, {
        isDefault: true,
      })
      .then(() => {
        dispatch({
          type: SET_DEFAULT_BANK_ACCOUNT_SUCCESS,
          payload: bankAccountId,
        });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.UPDATE_DEFAULT_BANK_ACCOUNT_SUCCESS,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({
          type: SET_DEFAULT_BANK_ACCOUNT_FAILURE,
          payload: { errorCode },
        });
      });
  };

export const deleteBusinessBankAccount =
  (businessId, bankAccountId) => dispatch => {
    dispatch({ type: DELETE_BANK_ACCOUNT_START });
    axios
      .delete(`/api/business/${businessId}/bankaccount/${bankAccountId}`)
      .then(() => {
        dispatch({
          type: DELETE_BANK_ACCOUNT_SUCCESS,
          payload: bankAccountId,
        });
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.DELETE_BANK_ACCOUNT_SUCCESS,
            options: {
              variant: 'success',
            },
          })
        );
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({ type: DELETE_BANK_ACCOUNT_FAILURE, payload: { errorCode } });
      });
  };

export const resetBankAccount = () => dispatch => {
  dispatch({ type: BANK_ACCOUNT_RESET });
};

export const fetchBanks = () => dispatch => {
  dispatch({ type: FETCH_BANKS_START });
  axios
    .get('/api/banks')
    .then(res => {
      const resData = res.data;
      dispatch({ type: FETCH_BANKS_SUCCESS, payload: resData });
      return resData;
    })
    .catch(err => {
      dispatch({ type: FETCH_BANKS_FAILURE, payload: err });
    });
};

export const getAccountsVerificationStatus = accountsData => dispatch => {
  dispatch({ type: GET_ACCOUNT_VALIDATION_STATUS_START });
  const accountDataTransformed = accountsData?.map(
    ({ accountHolder, sourceIdentifier, accountNumber }) => {
      return {
        name: accountHolder,
        identifier: sourceIdentifier,
        accountNumber,
      };
    }
  );
  axios
    .post(`/api/wallet/account/check-verification-status`, {
      accounts: accountDataTransformed,
    })
    .then(res => {
      dispatch({
        type: GET_ACCOUNT_VALIDATION_STATUS_SUCCESS,
        payload: res.data.accounts,
      });
    })
    .catch(err => {
      dispatch({ type: GET_ACCOUNT_VALIDATION_STATUS_FAILURE, payload: err });
    });
};

import { makeStyles } from '@material-ui/core';
import colors from 'design-system/theme/colors';

export const useStyles = makeStyles(theme => ({
  tableContainer: {
    backgroundColor: colors.background.paper,
    boxShadow: '0px 10px 24px #c9d2e0',
  },
  actionButton: {
    padding: '0 32px',
    background: theme.palette.common.white,
    color: theme.palette.primary.main,
  },
  MuiDataGrid: {
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: '0px',
      margin: '0px',
    },
    '& .MuiDataGrid-columnHeader:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none',
    },

    '& .MuiDataGrid-cell': {
      cursor: 'pointer',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
  },
}));

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useWallet } from '@wallet/account';
import { WS_COMMANDS } from '../../../helpers/constants';
import {
  wsConnect,
  wsCreateConnection,
  wsRemoveFirstMessage,
  wsTaxScrapeUpdated,
} from '../../../store/actions/websocketActions';

const { TAX_SCRAPE_DATA, WALLET_BALANCE_CHANGED } = WS_COMMANDS;

const useWebSocketManager = () => {
  const dispatch = useDispatch();
  const { getWalletBalance } = useWallet();

  const { wsBusinessNotified, wsconnected, messages } = useSelector(
    (state: any) => state.wsState
  );
  const { business } = useSelector((state: any) => state.businessState);

  const connectWithWebSocketServer = () => {
    dispatch(wsConnect());
  };

  const notifyBusinessForBroadcast = businessId => {
    dispatch(wsCreateConnection(businessId));
  };

  /*
   * INCOMING MESSAGES HANDLERS
   */
  const taxScrapeDataHandler = message => {
    dispatch(wsTaxScrapeUpdated(message));
  };

  const walletBalanceChangeHandler = ({ businessId }) => {
    if (businessId) {
      getWalletBalance(businessId);
    }
  };

  useEffect(() => {
    const [message] = messages;

    if (message) {
      switch (message.command) {
        case TAX_SCRAPE_DATA: {
          taxScrapeDataHandler(message);
          break;
        }
        case WALLET_BALANCE_CHANGED: {
          walletBalanceChangeHandler(message);
          break;
        }
        default:
          break;
      }
      dispatch(wsRemoveFirstMessage());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  return {
    wsBusinessNotified,
    wsconnected,
    business,
    shouldNotifyBusinessId: wsconnected && business?.id && !wsBusinessNotified,
    connectWithWebSocketServer,
    notifyBusinessForBroadcast,
  };
};

export default useWebSocketManager;

import { Invoice } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';
import { GridCellParams } from '@material-ui/data-grid';
import create from 'zustand';
import { cleanDataInvoices } from '@finance/utils/cleanDataInvoices';
import * as selectedCache from '@finance/services/selectedCache';
import type { InvoicesSelectedStore, Simulation } from './types';

const initialState = {
  selectedInvoices: [],
  invoicesWithoutSimulation: [],
  simulation: null,
};

export const useSelectedInvoiceStore = create<InvoicesSelectedStore>(
  (set, get) => ({
    ...initialState,
    handleSimulation: (newSimulation: Simulation) => {
      const { selectedInvoices } = get();

      if (!selectedInvoices.length) return;

      const invoicesWithSimulation = selectedInvoices.map(invoice => {
        const simulation = newSimulation.invoicesData.find(
          ({ invoiceId }) => invoiceId === invoice.id
        );

        return {
          ...invoice,
          ...simulation,
        };
      });

      set(state => ({
        ...state,
        simulation: newSimulation,
        selectedInvoices: invoicesWithSimulation,
      }));
    },
    handleAdd: (invoices: Invoice[]) => {
      if (!invoices) return;

      const cleanInvoices = invoices.map(cleanDataInvoices);

      selectedCache.addInvoices(cleanInvoices);

      set(state => ({
        ...state,
        selectedInvoices: invoices,
        invoicesWithoutSimulation: invoices,
      }));
    },
    handleDelete: ({ id }: GridCellParams) => {
      const state = get();

      const newInvoices = state.selectedInvoices.filter(
        invoice => invoice.id !== id
      );

      const simulation = !newInvoices?.length ? state.simulation : null;

      set({
        ...state,
        selectedInvoices: newInvoices,
        simulation,
      });

      const cleanInvoices = newInvoices.map(cleanDataInvoices);

      selectedCache.addInvoices(cleanInvoices);

      return newInvoices;
    },
    onChangeDate: (invoice: Invoice) => {
      const { selectedInvoices } = get();

      const newInvoices = selectedInvoices.map(item =>
        item.id === invoice.id ? invoice : item
      );

      set(state => ({
        ...state,
        selectedInvoices: newInvoices,
      }));

      return newInvoices;
    },
    resetState: () => {
      set(state => ({ ...state, ...initialState }));
    },
  })
);

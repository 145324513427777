import axios from '../../helpers/axios';
import {
  FETCH_HOLIDAYS_START,
  FETCH_HOLIDAYS_SUCCESS,
  FETCH_HOLIDAYS_ERROR,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const fetchHolidays = () => dispatch => {
  dispatch({ type: FETCH_HOLIDAYS_START });
  axios
    .get(`/api/holiday`)
    .then(data => {
      dispatch({ type: FETCH_HOLIDAYS_SUCCESS, payload: data.data });
    })
    .catch(err => {
      const errorCode = err?.response?.data?.errorCode;
      dispatch({
        type: FETCH_HOLIDAYS_ERROR,
        payload: { errorCode },
      });
    });
};

import { User } from '../../shared/hooks/useUser';
import { maintenanceRequirementsUseCase } from '../useCases/maintenanceRequirementsUseCase';
import { registerRequirementsUseCase } from '../useCases/registerRequirementsUseCase';

export const useRouterProxy = (
  user: User,
  url: string,
  isMaintenance: boolean
) => {
  return {
    destination:
      maintenanceRequirementsUseCase(url, { user, isMaintenance }) ||
      registerRequirementsUseCase(url, { user }),
  };
};

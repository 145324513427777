import * as React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import { theme } from '../../design-system/theme';

type InfoIconSquareProps = Omit<SvgIconProps, 'color'> & {
  color: string;
};

function InfoIconSquare(props: InfoIconSquareProps) {
  const { color } = props;
  return (
    <SvgIcon width={23} height={23} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.375.75H2.958A2.376 2.376 0 0 0 .583 3.125v17.417a2.345 2.345 0 0 0 2.375 2.375h17.417a2.376 2.376 0 0 0 2.375-2.375V3.125c0-1.286-1.088-2.375-2.375-2.375Zm.792 19.792c0 .445-.396.791-.792.791H2.958a.781.781 0 0 1-.791-.791V3.125c0-.396.346-.792.791-.792h17.417c.396 0 .792.396.792.792v17.417ZM9.885 16.583a.602.602 0 0 0-.593.594v.396c0 .346.247.594.593.594h3.563a.602.602 0 0 0 .594-.594v-.396c0-.297-.297-.594-.594-.594h-.594V9.656c0-.297-.297-.594-.593-.594H9.885a.602.602 0 0 0-.593.594v.396c0 .346.247.594.593.594h.594v5.937h-.594Zm1.782-11.875c-.89 0-1.584.743-1.584 1.584 0 .89.693 1.583 1.584 1.583.84 0 1.583-.693 1.583-1.583 0-.841-.742-1.584-1.583-1.584Z"
        fill={color || theme.palette.info.main}
      />
    </SvgIcon>
  );
}

export default InfoIconSquare;

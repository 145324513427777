import { WithdrawalStore, WithdrawalRepository } from '@wallet/withdrawal';

const fetchBankAccountsUseCase = async ({
  businessId,
  withdrawalRepository,
  withdrawalStore,
}: {
  businessId: number;
  withdrawalRepository: WithdrawalRepository;
  withdrawalStore: WithdrawalStore;
}) => {
  try {
    withdrawalStore.setFetchingBankAccounts(true);
    const wallet = await withdrawalRepository.getBankAccounts(businessId);
    withdrawalStore.setBankAccounts(wallet);
    return wallet;
  } catch (error) {
    throw new Error(error);
  } finally {
    withdrawalStore.setFetchingBankAccounts(false);
  }
};

export default fetchBankAccountsUseCase;

import { OrderType } from '@finance/useCases/OperationLineValues.type';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCartInvoicesCount } from '../../../../../store/actions/cartActions';
import { useOperationType } from '../useOperationType';
import type { RootState, BusinessState } from './useInvoicesFromTab.types';

export const useInvoicesFromTab = (): void => {
  const operationType = useOperationType();

  const firstRender = useRef(true);

  const dispatch = useDispatch();
  const { id: businessId } = useSelector<RootState, BusinessState>(
    state => state.businessState.business
  );

  const dispatchers = useCallback(() => {
    if (firstRender) {
      dispatch(fetchCartInvoicesCount(businessId, OrderType.DIRECT_FINANCING));
      dispatch(fetchCartInvoicesCount(businessId, OrderType.EARLY_PAYMENT));
      firstRender.current = false;
      return;
    }

    dispatch(fetchCartInvoicesCount(businessId, operationType));
  }, [businessId, dispatch, operationType]);

  useEffect(() => {
    dispatchers();
  }, [dispatchers]);
};

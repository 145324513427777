import { LinearProgress, Box, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CachedIcon from '@material-ui/icons/Cached';
import useGetCreditBalance from '@integration-services/xepelin-server-global/credit-balance/hooks/useCreditBalance';
import CreditLineAlertTooltip from '@home/components/widgets/CreditLine/CreditLineAlertTooltip';
import { Skeleton } from '@material-ui/lab';
import { CreditLineStatus } from '@integration-services/xepelin-server-global/credit-balance/types/creditBalance.type';
import { Typography } from '../../../design-system/atoms';
import Card from './Card';
import CountryFormatHelper from '../CountryFormatHelper';
import { useTranslation } from '../../../../features/i18n/infra/useTranslation';
import { RootState } from '../../../store/reducers/rootReducer';
import colors from '../../../design-system/theme/colors';

const useStyles = makeStyles(() => ({
  cardRoot: {
    height: '100%',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    width: 48,
    height: 48,
  },
  linearProgress: {
    height: 8,
    borderRadius: 100,
    marginBottom: 16,
  },
  marginBottom05x: {
    'margin-bottom': 4,
  },
  marginBottom1x: {
    'margin-bottom': 8,
  },
  marginBottom2x: {
    'margin-bottom': 16,
  },
}));

function CreditBalanceCard() {
  const { t } = useTranslation();
  const classes = useStyles();

  const { data, isLoading } = useGetCreditBalance();

  const countryId = useSelector(
    (state: RootState) => state.businessState.business.countryId
  );
  const {
    available: availableCredit,
    total: totalCredit,
    used: usedCredit,
    status: creditLineStatus,
  } = data || {};
  const availableCreditStatuses: CreditLineStatus[] = [
    CreditLineStatus.ACTIVE,
    CreditLineStatus.CANCELLED,
    CreditLineStatus.INACTIVE,
    CreditLineStatus.REJECTED,
  ];
  const showAvailableCredit =
    availableCreditStatuses.includes(creditLineStatus);
  const usedCreditPercentaje =
    ((totalCredit - availableCredit) * 100) / totalCredit;

  if (Number.isNaN(+totalCredit)) {
    return (
      <Card variant="white" border className={classes.cardRoot}>
        <Box p={3} display="flex">
          <Box>
            <Box className={classes.iconContainer} bgcolor={colors.grey.m200}>
              <CachedIcon />
            </Box>
          </Box>
          <Box pl={2}>
            <Typography className={classes.marginBottom1x} variant="body2">
              <Box fontWeight="fontWeightBold">
                {t('CREDIT_BALANCE_CARD_AVAILABLE_CREDIT')}
              </Box>
              <CreditLineAlertTooltip creditLineStatus={creditLineStatus} />
            </Typography>
            <Typography className={classes.marginBottom2x} variant="tiny">
              {t('CREDIT_BALANCE_PENDING')}
            </Typography>
          </Box>
        </Box>
      </Card>
    );
  }

  return (
    <Card variant="white" border className={classes.cardRoot}>
      <Box p={3}>
        <Box sx={{ display: 'flex' }}>
          <Typography className={classes.marginBottom1x} variant="body2">
            <Box fontWeight="fontWeightBold">
              {t('CREDIT_BALANCE_CARD_AVAILABLE_CREDIT')}
            </Box>
          </Typography>
          <CreditLineAlertTooltip creditLineStatus={creditLineStatus} />
        </Box>
        <Typography
          className={classes.marginBottom2x}
          color="primary"
          variant="h5"
        >
          {isLoading ? (
            <Skeleton width={150} />
          ) : (
            showAvailableCredit && (
              <CountryFormatHelper
                value={availableCredit}
                countryId={countryId}
                variant="currency"
              />
            )
          )}
        </Typography>
        {isLoading ? (
          <Skeleton width={150} />
        ) : (
          <LinearProgress
            className={classes.linearProgress}
            variant="determinate"
            value={usedCreditPercentaje}
          />
        )}

        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography className={classes.marginBottom05x} variant="tiny">
              {t('CREDIT_BALANCE_CARD_USED_CREDIT')}
            </Typography>
            <Typography variant="body1">
              {isLoading ? (
                <Skeleton width={100} />
              ) : (
                <CountryFormatHelper
                  value={usedCredit}
                  countryId={countryId}
                  variant="currency"
                />
              )}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.marginBottom05x} variant="tiny">
              {t('CREDIT_BALANCE_CARD_CREDIT_LINE')}
            </Typography>
            <Typography variant="body1">
              {isLoading ? (
                <Skeleton width={100} />
              ) : (
                <CountryFormatHelper
                  value={totalCredit}
                  countryId={countryId}
                  variant="currency"
                />
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default CreditBalanceCard;

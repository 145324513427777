import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountCheckbox: {
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(0),
      padding: theme.spacing(2, 2, 2, 0),
      border: `1px solid ${theme.palette.text.secondary}`,
      alignItems: 'start',
      color: theme.palette.text.secondary,
      '&.Mui-disabled': {
        border: `1px solid ${theme.palette.text.disabled}`,
      },
    },
    accountCheckboxChecked: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    accountCheckboxValidating: {
      border: `1px solid ${theme.palette.grey[500]}`,
      background: theme.palette.grey[100],
    },
    rootForm: {
      width: '100%',
    },
    radio: {
      padding: theme.spacing(0),
      margin: theme.spacing(0, 3, 0, 2),
    },
    withoutAccounts: {
      color: theme.palette.grey[600],
    },
  })
);

export { useStyles };

import { Box, FormHelperText } from '@material-ui/core';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import Icon from '../../../../../design-system/icons/Icon';
import { Typography } from '../../../../../design-system/atoms';

interface FormInputHelperTextProps {
  show: boolean;
  message: string;
}

function FormInputHelperText({ show, message }: FormInputHelperTextProps) {
  if (!show) return null;
  return (
    <FormHelperText component="div">
      <Box display="flex" alignItems="center" mt={2}>
        <Box display="flex" alignItems="center" mr={1}>
          <Icon iconDefinition={faCircleExclamation} color="error" />
        </Box>
        <Typography variant="body2" color="error" component="div">
          {message}
        </Typography>
      </Box>
    </FormHelperText>
  );
}

export default FormInputHelperText;

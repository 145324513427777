import {
  getLocalStorageKeyOfLastScrap,
  isAvailableToReScraptInvoices,
  TEN_MINUTES_IN_MILISECONDS,
} from '@finance/utils/scrap';
import { useQuery } from 'react-query';
import { scrapInvoicesService } from '../services/scrapInvoicesService';

export const useScrapInvoices = (businessId: string, countryId: string) => {
  const nowTimestamp = Date.now();
  useQuery<void>(
    [useScrapInvoices.name],
    async () => {
      const localStorageKey = getLocalStorageKeyOfLastScrap(businessId);
      const lastInvoiceScrapTimestamp = localStorage.getItem(localStorageKey)
        ? Number(localStorage.getItem(localStorageKey))
        : null;
      if (
        !isAvailableToReScraptInvoices(lastInvoiceScrapTimestamp, nowTimestamp)
      ) {
        return;
      }
      await scrapInvoicesService(businessId, countryId);
      localStorage.setItem(localStorageKey, String(nowTimestamp));
    },
    {
      refetchOnWindowFocus: false,
      refetchInterval: TEN_MINUTES_IN_MILISECONDS,
      refetchIntervalInBackground: true,
      retry: 3,
    }
  );
};

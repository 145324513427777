import { PURGE } from 'redux-persist';
import {
  WS_CONNECTED_STORE,
  WS_DISCONNECTED_STORE,
  WS_BUSINESS_NOTIFIED_STORE,
  WS_ADD_MESSAGE,
  WS_REMOVE_FIRST_MESSAGE,
  LOCAL_PURGE,
} from '../actions/types';

const initialState = {
  wsconnected: false,
  wsBusinessNotified: false,
  messages: [],
};

const wsConnected = state => {
  return { ...state, wsconnected: true };
};

const wsDisconnected = state => {
  return {
    ...state,
    wsconnected: false,
    wsBusinessNotified: false,
  };
};

const businessNotified = state => {
  return {
    ...state,
    wsBusinessNotified: true,
  };
};

const addMessage = (state, payload) => {
  const messages = [...state.messages];
  messages.push(payload.message);
  return {
    ...state,
    messages,
  };
};

const removeFirstMessage = state => {
  const messages = [...state.messages];
  messages.shift();
  return {
    ...state,
    messages,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case WS_CONNECTED_STORE:
      return wsConnected(state);
    case WS_DISCONNECTED_STORE:
      return wsDisconnected(state);
    case WS_BUSINESS_NOTIFIED_STORE:
      return businessNotified(state);
    case WS_ADD_MESSAGE:
      return addMessage(state, payload);
    case WS_REMOVE_FIRST_MESSAGE:
      return removeFirstMessage(state);
    case LOCAL_PURGE:
      return initialState;
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

import React from 'react';
import { SvgIcon, styled } from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const XLogoIcon = props => {
  const { style } = props;
  return (
    <CustomSvgIcon
      width="28"
      height="17"
      viewBox="0 0 28 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M28.0256 4.82021V4.75442L28.0085 4.74111C27.9242 4.42184 27.8039 4.11333 27.65 3.82161C27.5574 3.64695 27.4711 3.50832 27.3995 3.39711C27.3119 3.26141 27.2168 3.13067 27.1149 3.0055L27.0954 2.98043C25.7258 1.28242 23.1218 0.349609 19.761 0.349609C16.355 0.380927 13.0036 1.21457 9.97498 2.78385C8.65616 3.49168 7.43221 4.36573 6.33264 5.38491C6.18911 5.29212 6.04059 5.2074 5.88775 5.13115C5.4674 4.92302 5.01778 4.78117 4.55464 4.71056C4.35462 4.6814 4.15134 4.68325 3.95187 4.71604L1.81298 5.07241C1.6265 5.10337 1.44952 5.17685 1.29557 5.28724C1.14162 5.39763 1.01477 5.542 0.924708 5.70932C0.834651 5.87664 0.783772 6.06247 0.775962 6.25263C0.768151 6.44278 0.803616 6.63221 0.879644 6.80645L2.4352 10.363L2.40642 10.4132C2.32009 10.5698 2.23531 10.7264 2.15442 10.8745C2.08368 11.007 2.06562 11.1616 2.10389 11.3071C2.14216 11.4525 2.23391 11.5778 2.36053 11.6577C2.49353 11.7407 2.63042 11.8261 2.76887 11.9099C2.89409 11.9882 3.0232 12.061 3.15775 12.137L3.45798 15.6615C3.47384 15.8509 3.53254 16.0341 3.62959 16.1971C3.72663 16.3602 3.85945 16.4987 4.01786 16.602C4.17627 16.7053 4.35607 16.7708 4.54348 16.7934C4.73089 16.8159 4.92095 16.795 5.09909 16.7322L7.14853 16.0124C7.33936 15.946 7.51863 15.8498 7.67975 15.7273C8.05131 15.4394 8.37706 15.096 8.64575 14.7091C8.71498 14.6104 8.78031 14.5055 8.84409 14.3958C9.92875 14.6456 11.034 14.794 12.1458 14.8391L12.2422 14.9629C12.6748 15.5229 13.2607 15.9432 13.9278 16.1717C14.5948 16.4003 15.3137 16.4271 15.9958 16.2489L19.5346 15.3278C20.0943 15.1811 20.5927 14.8581 20.9572 14.4059C21.3217 13.9538 21.5332 13.3962 21.5608 12.8145C24.8484 11.2904 27.1483 9.23834 27.8779 7.17534C27.8816 7.16509 27.8847 7.15463 27.8872 7.14401C27.9936 6.79544 28.0617 6.43613 28.0902 6.07257C28.1236 5.65408 28.1019 5.23297 28.0256 4.82021Z"
        fill="white"
      />
      <path
        d="M2.8879 10.7038C2.80157 10.8604 2.72068 11.0084 2.64368 11.1533L27.4136 4.91423C27.3408 4.63055 27.2348 4.35659 27.0978 4.09811C27.0114 3.93521 26.9306 3.80754 26.8722 3.71825C26.7944 3.59607 26.7089 3.48329 26.6179 3.36502C21.6355 2.29828 13.9891 3.63837 8.31756 6.41957C6.73323 7.19523 5.22717 8.12349 3.82045 9.19137C3.72012 9.34097 3.62212 9.48978 3.52801 9.63546C3.28923 10.0059 3.07923 10.3654 2.8879 10.7038Z"
        fill="#FFAE70"
      />
      <path
        d="M26.6178 3.36738L26.6061 3.35171C23.8233 -0.0983578 15.6566 0.395852 10.2191 3.30472C7.6401 4.71109 5.44216 6.73186 3.81726 9.19059C5.22398 8.12271 6.73003 7.19445 8.31437 6.41879C13.989 3.64385 21.6369 2.29829 26.6178 3.36738Z"
        fill="#FFCBA5"
      />
      <path
        d="M10.0411 12.6148C8.32339 12.6989 6.60167 12.6268 4.89685 12.3994C7.24382 13.5453 9.80466 14.1792 12.4117 14.2596L12.6777 14.601C13.0409 15.0683 13.5316 15.4187 14.0896 15.6091C14.6476 15.7996 15.2487 15.8219 15.8192 15.6733L19.3588 14.7522C19.8552 14.623 20.2881 14.3169 20.5774 13.8908C20.8668 13.4646 20.9928 12.9473 20.9323 12.4347C24.0294 11.0632 26.5541 9.06683 27.2976 6.97485C23.2975 10.0317 16.1521 12.318 10.0411 12.6148Z"
        fill="#FF5C1E"
      />
      <path
        d="M27.2977 6.97647C23.2976 10.031 16.1537 12.318 10.0411 12.6156C8.32352 12.7005 6.6018 12.6292 4.89691 12.4026C4.39836 12.1676 3.93791 11.9217 3.5218 11.6852C3.35458 11.5904 3.19436 11.4948 3.04036 11.4016C2.90191 11.3181 2.76891 11.2366 2.64136 11.1573L27.4112 4.91504C27.4791 5.28183 27.4993 5.65596 27.4711 6.02799C27.4495 6.34979 27.3913 6.66803 27.2977 6.97647Z"
        fill="#FF8153"
      />
      <path
        d="M6.35671 6.15478C6.12273 5.96068 5.86752 5.79408 5.59605 5.65822C5.22962 5.47757 4.83809 5.35398 4.43482 5.29167C4.29619 5.2712 4.15521 5.27306 4.01716 5.29716L1.87749 5.65822C1.77986 5.67467 1.68726 5.71337 1.60676 5.77137C1.52626 5.82936 1.45998 5.90513 1.41301 5.99286C1.36603 6.0806 1.33959 6.17798 1.33571 6.27758C1.33182 6.37718 1.3506 6.47636 1.3906 6.56753C1.94697 7.84 2.50282 9.11168 3.05816 10.3826C3.18493 10.1656 3.32027 9.94398 3.46416 9.71763C4.28456 8.41866 5.25558 7.22263 6.35671 6.15478Z"
        fill="#9000FF"
      />
      <path
        d="M4.01102 15.6051C4.01952 15.7044 4.05042 15.8005 4.10137 15.8859C4.15232 15.9714 4.22197 16.044 4.30501 16.0982C4.38806 16.1525 4.4823 16.1869 4.58056 16.1989C4.67883 16.2109 4.77851 16.2002 4.87202 16.1675L6.92146 15.4477C7.05377 15.4028 7.17801 15.3367 7.28935 15.2519C7.61399 15.003 7.89862 14.7053 8.13324 14.3692C8.28003 14.1584 8.40586 13.9336 8.50891 13.698C6.8229 13.2668 5.1996 12.617 3.67969 11.765C3.79117 13.0448 3.90161 14.3248 4.01102 15.6051Z"
        fill="#9000FF"
      />
    </CustomSvgIcon>
  );
};

XLogoIcon.defaultProps = {
  style: {},
};

XLogoIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default XLogoIcon;

import { ONBOARDING_STEP_CASES } from 'helpers/constants';

const addCredentailsStep = ONBOARDING_STEP_CASES.ADD_TAX_CREDENTIALS;

export const verifyRenderTableOptions = ({
  hasInvoiceHistory,
  hasInvoices,
  onboardingStep,
  filter,
}: {
  hasInvoiceHistory: boolean;
  hasInvoices: boolean;
  onboardingStep: string;
  filter: null | string;
}) => {
  const finishState =
    hasInvoiceHistory && hasInvoices && onboardingStep !== addCredentailsStep;

  const unfinishState =
    !hasInvoiceHistory && hasInvoices && onboardingStep !== addCredentailsStep;

  return finishState || unfinishState || filter;
};

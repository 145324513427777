// @ts-nocheck
import { ElementType } from 'react';
import {
  Typography as MuiTypography,
  TypographyProps,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  display1: theme.typography.display1,
  display2: theme.typography.display2,
  body3: theme.typography.body3,
  tiny: theme.typography.tiny,
}));

const components = {
  display1: 'h1',
  display2: 'h2',
  body3: 'p',
  tiny: 'p',
};

export type TypographyVariant =
  | 'display1'
  | 'display2'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'button'
  | 'srOnly'
  | 'tiny';
interface ITypographyProps extends TypographyProps {
  variant: TypograhyVariant;
  component?: ElementType;
}

const typographyDefaultProps = {
  component: undefined,
};

/**
 * Overrides default material UI typography with some additional variants
 */
function Typography(props: ITypographyProps) {
  const classes = useStyles();
  const { variant, className, ...otherProps } = props;
  const isCustom = Object.keys(classes).indexOf(variant) > -1;
  return (
    <MuiTypography
      className={isCustom ? clsx(classes[variant], className) : className}
      variant={isCustom ? undefined : variant}
      component={isCustom ? components[variant] : undefined}
      {...otherProps}
    />
  );
}

Typography.defaultProps = typographyDefaultProps;

export default Typography;

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box, Button, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { NextLinkComposed } from '../../../../features/shared';
import { urls } from '../../../../features/libs/urls';
import { useDebtServiceStore } from '../../../../features/auth/infra/store/useDebtServiceStore';
import { useTranslation } from '../../../../features/i18n/infra/useTranslation.ts';
import Card from './Card';
import {
  AVAILABLE_COUNTRIES,
  COUNTRY_CODE_CL,
  COUNTRY_CODE_MX,
} from '../../../helpers/constants';
import CountryFormatHelper from '../CountryFormatHelper';
import { t13s } from '../../../config/translationKeys';
import DicomIcon from '../../icons/DicomIcon';
import BuroIcon from '../../icons/BuroIcon';
import LockIcon from '../../icons/LockIcon';
import colors from '../../../design-system/theme/colors.ts';
import { Typography } from '../../../design-system/atoms/index.ts';

const useStyles = makeStyles({
  cardRoot: {
    height: '100%',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    minWidth: 48,
    height: 48,
  },
});

const useGetPropsAccordingToTheState = (
  useTranslation,
  canConsult,
  totalAmount,
  createdAt,
  icon,
  countryId
) => {
  const { t } = useTranslation();
  const { isLegalEntity } = useDebtServiceStore();
  // CANT CONSULT
  if (!canConsult) {
    return {
      title: `Autoriza tu ${t(t13s.LABEL.DEBT_SERVICE)}`,
      iconComponent: <LockIcon color="primary" />,
      iconBgColor: colors.grey.m200,
      text: (
        <Typography variant="tiny" color="textPrimary" component="p">
          {`Para financiarte necesitamos consultar tu ${t(
            t13s.LABEL.DEBT_SERVICE
          )}, ingresa tus credenciales para verificarlo.`}
        </Typography>
      ),
      body: (
        <Button
          variant="outlined"
          color="primary"
          component={NextLinkComposed}
          to={
            isLegalEntity
              ? urls.legalEntityBuroAuth
              : urls.naturalPersonBuroAuth
          }
        >
          {`Autorizar ${t(t13s.LABEL.DEBT_SERVICE)}`}
        </Button>
      ),
    };
  }
  // CONSULTED
  if (totalAmount !== undefined) {
    return {
      title: `${t(t13s.LABEL.DEBT_SERVICE)}`,
      iconComponent: icon[countryId],
      iconBgColor: colors.grey.m200,
      text: (
        <Typography variant="body2" color="textPrimary" component="p">
          <CountryFormatHelper
            value={totalAmount}
            countryId={countryId}
            variant="currency"
          />
        </Typography>
      ),
      body: (
        <>
          <Typography variant="tiny" color="textPrimary" component="p">
            Última consulta
          </Typography>
          <Typography variant="body3" color="textPrimary">
            <Box mb={0.5} fontWeight="fontWeightBold">
              <CountryFormatHelper
                value={createdAt}
                variant="longdate"
                countryId={countryId}
              />
            </Box>
          </Typography>
        </>
      ),
    };
  }
  //  NOT CONSULTED
  if (canConsult && !totalAmount) {
    return {
      title: `Estamos consultando tu ${t(t13s.LABEL.DEBT_SERVICE)}`,
      iconComponent: <LockIcon color="primary" />,
      iconBgColor: colors.grey.m200,
      text: (
        <Typography variant="tiny" color="textPrimary" component="p">
          {`Haz autorizado la consulta de ${t(
            t13s.LABEL.DEBT_SERVICE
          )} con éxito, podrás visualizarlo aquí una vez consultado.`}
        </Typography>
      ),
      body: undefined,
    };
  }
  return {
    title: `${t(t13s.LABEL.DEBT_SERVICE)}`,
    iconComponent: <ReportProblemIcon htmlColor={colors.ui.danger.main} />,
    iconBgColor: colors.ui.danger.light,
    text: (
      <Box mb={4}>
        <Typography variant="tiny" color="textPrimary" component="p">
          {`Se produjo un error al consultar tu ${t(
            t13s.LABEL.DEBT_SERVICE
          )}, refresca la página`}
        </Typography>
      </Box>
    ),
    body: undefined,
  };
};

const DebtServiceCard = ({ countryId }) => {
  const classes = useStyles();
  const { canConsult, debtService, fetchIsLoading } = useSelector(
    state => state.debtServiceState
  );
  const { totalAmount, createdAt } = debtService || {};

  const icon = {
    [COUNTRY_CODE_MX]: <BuroIcon className={classes.icon} color="primary" />,
    [COUNTRY_CODE_CL]: <DicomIcon className={classes.icon} color="primary" />,
  };

  const { title, iconComponent, text, body, iconBgColor } =
    useGetPropsAccordingToTheState(
      useTranslation,
      canConsult,
      totalAmount,
      createdAt,
      icon,
      countryId
    );

  return (
    <Card variant="white" border className={classes.cardRoot}>
      <Box p={3} display="flex">
        <Box className={classes.iconContainer} bgcolor={iconBgColor}>
          {!fetchIsLoading && iconComponent}
        </Box>
        <Box pl={2}>
          <Box marginBottom={1}>
            <Typography variant="body2" color="textPrimary">
              {fetchIsLoading ? (
                <Skeleton width="100%" />
              ) : (
                <Box fontWeight="fontWeightBold">{title}</Box>
              )}
            </Typography>
          </Box>
          {fetchIsLoading ? <Skeleton /> : text}
          <Box pt={2} />
          {body}
        </Box>
      </Box>
    </Card>
  );
};

export default DebtServiceCard;

DebtServiceCard.propTypes = {
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
};

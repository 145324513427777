import { Box } from '@material-ui/core';
import LogoMessage from '../../../../components/elements/LogoMessage';
import rocketLogo from '../../../../assets/rocketLogo.png';
import { useTranslation } from '../../../../../features/i18n/infra/useTranslation';

export function InvoicesDocumentationRequired() {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="400px"
    >
      <LogoMessage
        logo={rocketLogo.src}
        title={t('INVOICES_DOCUMENTATION_REQUIRED_TITLE')}
        subtitle={t('INVOICES_DOCUMENTATION_REQUIRED_DESCRIPTION')}
      />
    </Box>
  );
}

import { useEffect, useRef } from 'react';
import Dropdown from 'components/elements/Dropdown';
import InputSearch from 'components/elements/InputSearch';
import { Grid, Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useInvoiceSelection } from '@finance/hooks/migration/useInvoiceSelection';
import {
  useManageFilters,
  connectClearInput,
} from '@finance/hooks/migration/useManageFilters';
import { useSearchBar } from 'components/elements/SearchBar/hooks';
import { Typography } from 'design-system/atoms';
import useGetInvoices from '@integration-services/xepelin-server-global/invoices/hooks/useGetInvoices';
import { useTranslation } from '../../../../../features/i18n/infra/useTranslation';
import { useSpacingStyles } from '../../../../features/shared/styles/spacings';
import type { RootState, BusinessState } from '../../types';
import { verifyRenderTableOptions } from '../../utils/verifyRenderTableOptions';
import SwitchFilterButton from '../../../../components/elements/SwitchFilterButton';
import { useStyles } from './table-options-styles';
import useTracker from '../../hooks/useTracker';
import { SortingTypes } from '../../hooks/migration/useManageFilters/useManageFilters.types';

export function TableOptions() {
  const { t } = useTranslation();
  const spacing = useSpacingStyles();

  const firstRender = useRef(true);

  const classes = useStyles();

  const { state, callbacks } = useInvoiceSelection();
  const { data, allInvoices, handleAllInvoices } = useGetInvoices();

  const { financeCallbacks } = useTracker();

  const { onboardingStep, hasInvoiceHistory } = useSelector<
    RootState,
    BusinessState
  >(store => store.businessState.business);

  const { inputValue, changeInput, clearInput } = useSearchBar({
    handleChangeInput: callbacks.handleFilterChange,
    filterValue: state.filter,
  });

  const { setInitialState } = useManageFilters();

  const getPlaceholder = (countryId: string) => {
    const placeholders = {
      MX: t('INVOICES_SEARCH_TEXT_FIELD_PLACEHOLDER_MX'),
      CL: t('INVOICES_SEARCH_TEXT_FIELD_PLACEHOLDER_CL'),
    };

    return placeholders[countryId]
      ? placeholders[countryId]
      : t('INVOICES_SEARCH_TEXT_FIELD_PLACEHOLDER_NO_COUNTRY');
  };

  const handleSwitchSelectorButton = () => {
    handleAllInvoices();
  };

  const handleClearInput = () => {
    if (!inputValue) return;
    clearInput();
    setInitialState();
  };

  const handleSortingChange = (value: `${SortingTypes}`) => {
    financeCallbacks.trackFilterUsed({ filter_used: value });
    callbacks.handleSortingChange(value);
  };

  const canRender = verifyRenderTableOptions({
    hasInvoiceHistory,
    hasInvoices: !!data?.data?.length,
    onboardingStep,
    filter: state.filter,
  });

  useEffect(() => {
    if (firstRender.current) {
      connectClearInput(clearInput);
      firstRender.current = false;
    }
  }, [clearInput]);

  if (!canRender) return null;

  return (
    <Grid
      container
      spacing={3}
      alignItems="flex-end"
      justifyContent="space-between"
    >
      <Grid item xs={12} md={3}>
        <InputSearch
          inputSearchText={inputValue}
          placeholder={getPlaceholder(state.countryId)}
          handleChangeInput={changeInput}
          resetInput={handleClearInput}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Typography
          variant="body3"
          color="textSecondary"
          className={spacing.py1}
        >
          {t('INVOICES_SELECTION_SORTING_TITLE')}
        </Typography>
        <Dropdown
          id="TableInvoicesSorting"
          items={state.sorting.sortingItems}
          value={state.sorting.selectedValue}
          onChange={handleSortingChange}
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.alignRight}>
        <Box display="flex" justifyContent="flex-end">
          <SwitchFilterButton
            handleSwitchSelectorButton={handleSwitchSelectorButton}
            isLeftSelectorActivated={!allInvoices}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

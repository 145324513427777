import React from 'react';
import clsx from 'clsx';
import { Typography } from 'design-system/atoms';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { Spacer } from '@features/shared';
import { Alert } from '@wallet/withdrawal/components/common';
import { BankAccountState } from '@wallet/core';
import { ACCOUNT_TYPES } from 'helpers/constants';
import CreateWithdrawalAccountModal from '../../../CreateWithdrawalAccountModal/CreateWithdrawalAccountModal';
import { StepperStore } from '../../../common/Stepper/types';
import useAccount from './useAccount';
import { useStyles } from './styles';

interface AccountProps {
  stepperStore?: StepperStore;
}

function Account({ stepperStore }: AccountProps) {
  const classes = useStyles();
  const {
    banks,
    bankAccounts,
    currentStepPosition,
    showCreateWithdrawalAccountDialog,
    totalSteps,
    existsBankAccounts,
    selectedAccountNumber,
    setSelectedAccountNumber,
    onSubmit,
    truncateBankName,
    setShowCreateWithdrawalAccountDialog,
  } = useAccount(stepperStore);

  const locales = {
    STEP: `Paso ${currentStepPosition + 1} de ${totalSteps}`,
    TITLE: 'Retira fondos',
    BODY_WITH_ACCOUNTS:
      'Selecciona la cuenta en la que quieres recibir tu dinero.',
    BODY_WITHOUT_ACCOUNTS:
      'Aún no tienes una cuenta asociada donde podamos transferir tu dinero. Añade una y prosigue con la transferencia.',
    VERIFICATION_ALERT:
      'Tu CLABE está en revisión, te enviaremos un email en los próximos minutos, cuando haya terminado su verificación.',
    ADD_ACCOUNT: 'Agregar nueva cuenta',
    NEXT: 'Siguiente',
    ACCOUNT_IN_REVIEW: 'En revisión',
  };

  return (
    <>
      <Box>
        <Typography variant="body2" color="primary">
          {locales.STEP}
        </Typography>
        <Spacer spacing={2} />
        <Typography variant="h6">{locales.TITLE}</Typography>
        <Spacer spacing={2} />
        {!existsBankAccounts ? (
          <Typography variant="body2" className={classes.withoutAccounts}>
            {locales.BODY_WITHOUT_ACCOUNTS}
          </Typography>
        ) : (
          <Typography variant="body2" color="textSecondary">
            {locales.BODY_WITH_ACCOUNTS}
          </Typography>
        )}
        {bankAccounts.length === 1 &&
          bankAccounts[0].state === BankAccountState.VERIFYING && (
            <>
              <Spacer spacing={4} />
              <Alert severity="info" icon={false}>
                {locales.VERIFICATION_ALERT}
              </Alert>
            </>
          )}
        <Spacer spacing={4} />

        <FormControl component="fieldset" className={classes.rootForm}>
          <RadioGroup
            aria-label="account"
            name="account"
            value={selectedAccountNumber}
            onChange={event => {
              setSelectedAccountNumber(event.target.value);
            }}
          >
            {bankAccounts?.map(
              ({ state, accountNumber, accountType, bankId, alias }) => {
                const bankName = truncateBankName(
                  banks.find(bank => bankId === bank.id)?.name,
                  state
                );
                let clsxItem = clsx(classes.accountCheckbox);
                if (selectedAccountNumber === accountNumber) {
                  clsxItem = clsx(
                    classes.accountCheckbox,
                    classes.accountCheckboxChecked
                  );
                }
                if (state === BankAccountState.VERIFYING) {
                  clsxItem = clsx(
                    classes.accountCheckbox,
                    classes.accountCheckboxValidating
                  );
                }
                return (
                  <React.Fragment key={accountNumber}>
                    <FormControlLabel
                      key={accountNumber}
                      className={clsxItem}
                      value={accountNumber}
                      disabled={state === BankAccountState.VERIFYING}
                      control={
                        <Radio className={classes.radio} color="primary" />
                      }
                      label={
                        <Box>
                          <Typography
                            color="inherit"
                            variant="body3"
                            component="div"
                          >
                            {bankName} -{' '}
                            {
                              ACCOUNT_TYPES.MX.find(
                                type => type.value === accountType
                              )?.label
                            }
                            {state === BankAccountState.VERIFYING && (
                              <Typography
                                color="inherit"
                                variant="body3"
                                display="inline"
                              >
                                {' '}
                                ({locales.ACCOUNT_IN_REVIEW})
                              </Typography>
                            )}
                          </Typography>
                          {alias && (
                            <>
                              <Spacer spacing={0.5} />
                              <Typography color="inherit" variant="body3">
                                {alias}
                              </Typography>
                            </>
                          )}
                          <Spacer spacing={0.5} />
                          <Typography color="inherit" variant="body3">
                            {accountNumber}
                          </Typography>
                        </Box>
                      }
                    />
                    <Spacer key={`${accountNumber}-spacer`} spacing={2} />
                  </React.Fragment>
                );
              }
            )}
          </RadioGroup>
        </FormControl>

        <Spacer spacing={2} />
        <Button
          variant={existsBankAccounts ? 'text' : 'contained'}
          fullWidth={!existsBankAccounts}
          color="primary"
          onClick={() => setShowCreateWithdrawalAccountDialog(true)}
        >
          {locales.ADD_ACCOUNT}
        </Button>
        <Spacer spacing={4} />
        {existsBankAccounts && (
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            fullWidth
            disabled={!selectedAccountNumber}
          >
            {locales.NEXT}
          </Button>
        )}
      </Box>
      <CreateWithdrawalAccountModal
        open={showCreateWithdrawalAccountDialog}
        onClose={() => setShowCreateWithdrawalAccountDialog(false)}
      />
    </>
  );
}

export default React.memo(Account);

import { PURGE } from 'redux-persist';
import {
  FETCH_TASKS_START,
  FETCH_TASKS_ERROR,
  FETCH_TASKS_SUCCESS,
  LOCAL_PURGE,
} from '../actions/types';
import { t13s } from '../../config/translationKeys';

const initialState = {
  tasks: [],
  tasksIsLoading: false,
  tasksError: null,
};

const fetchTasksStart = state => {
  return {
    ...state,
    tasks: [],
    tasksIsLoading: true,
    tasksError: null,
  };
};

const fetchTasksSuccess = (state, payload) => {
  return {
    ...state,
    tasksIsLoading: false,
    tasksError: null,
    tasks: payload.tasks,
  };
};
const fetchTasksError = (state, payload) => {
  return {
    ...state,
    tasks: [],
    tasksIsLoading: false,
    tasksError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_TASKS_START:
      return fetchTasksStart(state);
    case FETCH_TASKS_SUCCESS:
      return fetchTasksSuccess(state, payload);
    case FETCH_TASKS_ERROR:
      return fetchTasksError(state, payload);
    case LOCAL_PURGE:
      return initialState;
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

/* eslint-disable no-param-reassign */
import produce from 'immer';
import create from 'zustand';
import { pipe } from 'ramda';
import { persist, devtools } from 'zustand/middleware';

const STORE_NAME = 'WalletStore';

const immer = config => (set, get, api) =>
  config(
    (partial, replace) => {
      const nextState =
        typeof partial === 'function' ? produce(partial) : partial;
      return set(nextState, replace);
    },
    get,
    api
  );

const persistence = config =>
  persist(config, {
    name: STORE_NAME,
  });

const createStore = pipe(persistence, immer, devtools, create);

const WalletZustandStore = createStore(set => ({
  wallet: null,
  setWallet: wallet => {
    set(state => {
      state.wallet = wallet;
    });
  },
  fetchingWallet: false,
  setFetchingWallet: isFetching => {
    set(state => {
      state.fetchingWallet = isFetching;
    });
  },

  balance: 0,
  setBalance: amount => {
    set(state => {
      state.balance = amount;
    });
  },
  fetchingBalance: false,
  setFetchingBalance: isFetching => {
    set(state => {
      state.fetchingBalance = isFetching;
    });
  },

  resetStore: () => {
    set(state => {
      state.wallet = null;
      state.balance = 0;
    });
  },
}));

export default WalletZustandStore;

import React from 'react';
import { styled, SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { xTheme } from '@xepelinapp/x-ds';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const SliderIcon = props => {
  const { style, active } = props;

  if (active) {
    return (
      <CustomSvgIcon
        width="31"
        height="18"
        viewBox="0 0 31 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path
          d="M0 9C0 4.30558 3.80558 0.5 8.5 0.5H22.5C27.1944 0.5 31 4.30558 31 9C31 13.6944 27.1944 17.5 22.5 17.5H8.5C3.80558 17.5 0 13.6944 0 9Z"
          fill={xTheme.primary.dark}
          fillOpacity="0.2"
        />
        <circle cx="22.5" cy="9" r="8.5" fill={xTheme.primary.main} />
      </CustomSvgIcon>
    );
  }
  return (
    <CustomSvgIcon
      width="31"
      height="18"
      viewBox="0 0 31 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M0 9C0 4.30558 3.80558 0.5 8.5 0.5H22.5C27.1944 0.5 31 4.30558 31 9C31 13.6944 27.1944 17.5 22.5 17.5H8.5C3.80558 17.5 0 13.6944 0 9Z"
        fill={xTheme.background.default}
      />
      <circle cx="8.5" cy="9" r="8.5" fill={xTheme.grey[400]} />
    </CustomSvgIcon>
  );
};

SliderIcon.defaultProps = {
  style: {},
  active: false,
};

SliderIcon.propTypes = {
  style: PropTypes.objectOf(Object),
  active: PropTypes.bool,
};

export default SliderIcon;

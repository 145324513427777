import { combineReducers } from 'redux';
import uiStatesReducer from './uiStatesReducer';
import authenticationReducer from './authenticationReducer';
import notificationReducer from './notificationReducer';
import businessReducer from './businessReducer';
import taxServiceReducer from './taxServiceReducer';
import cartReducer from './cartReducer';
import webSocketReducer from './webSocketReducer';
import tasksReducer from './tasksReducer';
import holidaysReducer from './holidaysReducer';
import orderReducer from './orderReducer';
import ordersReducer from './ordersReducer';
import userReducer from './userReducer';
import stakeholdersReducer from './stakeholdersReducer';
import businessBankAccountsReducer from './businessBankAccountsReducer';
import geoReducer from './geoReducer';
import documentsReducer from './documentsReducer';
import debtServiceReducer from './debtServiceReducer';
import bankAccountsReducer from './bankAccountsReducer';
import payrollsReducer from './payrollsReducer';
import paymentDocumentsReducer from './paymentDocumentsReducer';
import pfxReducer from './pfxReducer';
import walletReducer from './walletReducer';
import impersonateReducer from './impersonateReducer';
import { statsReducer as paymentsStatsReducer } from '../../modules/payments/store/stats';

const appReducer = combineReducers({
  authState: authenticationReducer,
  businessState: businessReducer,
  cartState: cartReducer,
  debtServiceState: debtServiceReducer,
  holidaysState: holidaysReducer,
  notificationState: notificationReducer,
  orderState: orderReducer,
  ordersState: ordersReducer,
  stakeholdersState: stakeholdersReducer,
  tasksState: tasksReducer,
  taxServiceState: taxServiceReducer,
  uiState: uiStatesReducer,
  userState: userReducer,
  wsState: webSocketReducer,
  businessBankAccountsState: businessBankAccountsReducer,
  geo: geoReducer,
  documentsState: documentsReducer,
  bankAccountsState: bankAccountsReducer,
  payrollsState: payrollsReducer,
  paymentDocumentsState: paymentDocumentsReducer,
  pfxState: pfxReducer,
  walletState: walletReducer,
  paymentsStatsState: paymentsStatsReducer,
  impersonateState: impersonateReducer,
});

export type RootState = ReturnType<typeof appReducer>;

export default appReducer;

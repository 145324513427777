import { useTranslation } from '../../../../features/i18n/infra/useTranslation';
import { verifyEmail } from '../email';

export const useEmailRules = () => {
  const { t } = useTranslation();

  return {
    required: t('REQUIRED_EMAIL'),
    validate: (email: string) => verifyEmail(email),
  };
};

import React, { useEffect, useState, useRef } from 'react';
import { Box, Card, Grid } from '@material-ui/core';
import Table from 'components/elements/tables/Table';
import { useInvoiceSelected } from '@finance/hooks/migration/useInvoiceSelected';
import { esES } from '@material-ui/data-grid';
import { useOperationType } from '@finance/hooks/migration/useOperationType';
import { Typography } from 'design-system/atoms';
import {
  OperationResume,
  BreadcrumbsFinance,
  CalculateWrapper,
} from '@finance/components';
import { HistoryProxy } from '@finance/hoc/HistoryProxy';
import { HistoryProxyObject } from '@finance/services/historyProxy';
import useTracker from '@finance/hooks/useTracker';
import { useTranslation } from '../../../features/i18n/infra/useTranslation';
import { useSpacingStyles } from '../../features/shared/styles/spacings';
import { useStyles } from './styles';
import { SwitchFilterClientButton } from '../../components/elements/SwitchFilterClientButton';
import { TablesByClient } from '../../modules/finance/components/tables-by-clients/tables-by-clients';

type CalculateProps = {
  historyProxy: HistoryProxyObject;
};

function Calculate({ historyProxy }: CalculateProps) {
  const classes = useStyles();
  const spacing = useSpacingStyles();

  const { financeCallbacks } = useTracker();

  const initialRender = useRef(true);

  const { t } = useTranslation();

  const operationType = useOperationType();

  const { state, callbacks } = useInvoiceSelected();

  const { selectedInvoices } = state;

  const { resetState } = callbacks;

  const isLoading = [state.isLoadingSimulation, state.isLoadingSubmit].includes(
    true
  );

  const [showByClient, setShowByClient] = useState<boolean>(true);

  const disabledButton = [
    isLoading,
    state.selectedInvoices.length === 0,
  ].includes(true);

  useEffect(() => {
    if (initialRender.current) {
      const historyLength = historyProxy.getLengthOfHistory();

      if (historyLength === 0) {
        financeCallbacks.trackerViewOnlyStepTwo();
      }
    }
  }, [historyProxy, financeCallbacks]);

  useEffect(() => {
    return () => {
      resetState();
    };
  }, [resetState]);

  const handleSwitchButton = () => {
    setShowByClient(!showByClient);
  };

  const handleSubmit = () => {
    const historyLength = historyProxy.getLengthOfHistory();

    if (historyLength === 1) {
      financeCallbacks.trackerCreateOrderByUrl();
    }

    callbacks.handleSubmit();
  };

  return (
    <CalculateWrapper>
      <Box className={spacing.my2}>
        <BreadcrumbsFinance />
        <Typography variant="h4" className={spacing.my1}>
          {t('INVOICES_SELECTED_TITLE')}
        </Typography>
        <Typography variant="body2" className={spacing.my1}>
          {t('INVOICES_SELECTED_TITLE_DESCRIPTION')}
        </Typography>
      </Box>

      <Grid container spacing={2} className={`${spacing.my4}`}>
        <Grid item xs={12} lg={9}>
          <Card className={spacing.mb2}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className={spacing.mb2}
            >
              <Typography
                variant="body2"
                className={`${spacing.px4} ${spacing.mt2}`}
              >
                {t('INVOICES_SELECTED_TO_FINANCE_DESCRIPTION')}
              </Typography>
              <SwitchFilterClientButton
                handleSwitchButton={handleSwitchButton}
                isLeftButtonActivated={showByClient}
              />
            </Box>
          </Card>

          {showByClient ? (
            <TablesByClient invoices={selectedInvoices} state={state} />
          ) : (
            <Table
              className={`${classes.MuiDataGrid} ${classes.tableContainer} ${spacing.p3}`}
              columns={state.columns}
              rows={selectedInvoices}
              loading={state.isLoadingInvoices}
              pageSize={state.pageSize}
              rowCount={selectedInvoices.length}
              onPageSizeChange={callbacks.handlePageSize}
              localeText={(esES as any).props.MuiDataGrid.localeText}
            />
          )}
        </Grid>

        <Grid item xs={12} lg={3}>
          <OperationResume
            operationType={operationType}
            simulation={state.simulation}
            disabledLoadingButton={disabledButton}
            isLoading={isLoading}
            onClick={handleSubmit}
          />
        </Grid>
      </Grid>
    </CalculateWrapper>
  );
}

export default HistoryProxy(Calculate);

/* eslint-disable react/prop-types */

// - HISTORY ----------------------
import { useRouter } from 'next/router';
import { useEffect } from 'react';

let delayQueueHistory = [];
let trackerHistory = i => delayQueueHistory.push(i);
let storeLess = { getState: () => ({}) };
const nextLess = () => 1;

let echoLess = null;

const NextTrackerComponent = ({ store }) => {
  const router = useRouter();

  useEffect(() => {
    storeLess = store;
  }, [store]);

  useEffect(() => {
    const handleRouteChange = path => {
      if (echoLess !== path) {
        echoLess = path;
        try {
          trackerHistory({ path, store });
        } catch (e) {
          /* SILENT ERROR */
        }
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, store]);

  return null;
};

// - REDUX MIDDLEWARE ----------------------
let delayQueueActions = [];
let trackerMiddleware = (store, next, action) => {
  const data = { prevState: store.getState(), action };
  const result = next(action);
  data.nextState = store.getState();
  delayQueueActions.push(JSON.stringify(data));
  return result;
};

const middleware = store => {
  return next => action => {
    try {
      trackerMiddleware(store, () => 1, action);
    } catch (e) {
      /* SILENT ERROR */
    }
    return next(action);
  };
};

// - HTTP INTERCEPTORS ----------------------
let delayQueueFetch = [];
let trackerOnResponse = response =>
  delayQueueFetch.push(JSON.stringify(response));
let trackerOnRequest = d => d;

setTimeout(() => {
  delayQueueFetch = []; /* CLEAR QUEUE */
  delayQueueActions = [];
  delayQueueHistory = [];
}, 1000 * 120); /* WAIT 2 MINS FOR GTM SCRIPT */

const onRequest = config => {
  try {
    trackerOnRequest(config);
  } catch (e) {
    /* SILENT ERROR */
  }
  return config;
};

const onResponse = response => {
  try {
    trackerOnResponse(response);
  } catch (e) {
    /* SILENT ERROR */
  }
  return response;
};

// - SINGLE TRACK ----------------------
const track = (type, payload = {}) => {
  try {
    return trackerMiddleware(storeLess, nextLess, { type, payload });
  } catch (e) {
    /* SILENT ERROR */
  }
  return null;
};

// - ON READY ----------------------
if (typeof window !== 'undefined') {
  window.growthTrackerOnReady = (
    fnOnRequest,
    fnOnResponse,
    fnMiddleware,
    fnHistory
  ) => {
    trackerOnRequest = fnOnRequest;
    trackerOnResponse = fnOnResponse;
    trackerMiddleware = fnMiddleware;
    trackerHistory = fnHistory;

    return { delayQueueFetch, delayQueueActions, delayQueueHistory };
  };

  setTimeout(() => {
    Promise.resolve().then(() => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = window.location.hostname.includes('.xepelin.com')
        ? 'https://gth.xepel.in/tracker/tracker-app.js'
        : 'https://s3.amazonaws.com/gth.xepel.in/tracker/tracker-app-stg.js';
      document.body.appendChild(script);
    });
  }, 2000);
}

export default {
  onRequest,
  onResponse,
  middleware,
  NextTrackerComponent,
  event: track,
};

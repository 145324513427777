import { Box } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import { DATE_FORMAT, MINIMUM_FINANCEABLE_DAYS } from 'helpers/constants';
import { theme } from 'design-system/theme';
import { Typography } from 'design-system/atoms';
import { RootState } from 'store/reducers/rootReducer';
import MomentUtils from '@date-io/moment';
import { useTranslation } from '../../../../../features/i18n/infra/useTranslation';
import { useSpacingStyles } from '../../../../features/shared/styles/spacings';

const arrowIconButtonStyle = {
  background: theme.palette.grey[100],
  borderRadius: 8,
};

export type DatePickerDialogProps = {
  selectedDate: Moment;
  handleChange: (date: Moment) => void;
  issueDate: Moment;
};

export function CalendarDateSelector({
  selectedDate,
  handleChange,
  issueDate,
}: DatePickerDialogProps) {
  const { t } = useTranslation();
  const spacing = useSpacingStyles();
  const { holidays } = useSelector((state: RootState) => state.holidaysState);

  const totalDaysToFinance = moment
    .utc(selectedDate)
    .diff(moment().utc(), 'days');

  const disableSaturdaysSundaysAndHolidays = (date: Moment) => {
    return (
      moment(date).isoWeekday() === 6 ||
      moment(date).isoWeekday() === 7 ||
      (Array.isArray(holidays) &&
        holidays.some(h => moment(date).isSame(moment(h.date), 'day')))
    );
  };

  const disableFifteenDaysFromToday = (date: Moment) => {
    const minimunFinanceableDate = moment().add(
      MINIMUM_FINANCEABLE_DAYS,
      'days'
    );
    return date < minimunFinanceableDate;
  };

  const disableNotFinanceableDays = (date: Moment) => {
    return (
      disableFifteenDaysFromToday(date) ||
      disableSaturdaysSundaysAndHolidays(date)
    );
  };

  const MAX_EMISSION_FINANCEABLE_DAYS = 180;
  const MAX_PAYMENT_DATE = moment
    .utc(issueDate)
    .add(MAX_EMISSION_FINANCEABLE_DAYS + 1, 'days');

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
      <Box className={spacing.mb2}>
        <Typography
          variant="body3"
          align="center"
          style={{ fontWeight: theme.typography.fontWeightRegular }}
          color="textSecondary"
        >
          {t('CALENDAR_DIALOG_SUBTITLE')}
        </Typography>
      </Box>
      <DatePicker
        variant="static"
        format={DATE_FORMAT}
        shouldDisableDate={date => disableNotFinanceableDays(date)}
        value={moment.utc(selectedDate)}
        onChange={date => handleChange(date)}
        autoOk
        rightArrowButtonProps={{
          style: arrowIconButtonStyle,
        }}
        leftArrowButtonProps={{
          style: arrowIconButtonStyle,
        }}
        fullWidth
        disablePast
        maxDate={MAX_PAYMENT_DATE}
        ToolbarComponent={() => null}
      />
      <Box className={spacing.mb4}>
        <Typography
          variant="body3"
          align="center"
          style={{ fontWeight: theme.typography.fontWeightRegular }}
          color="textSecondary"
        >
          {`${t('SELECTED_PAYMENT_TERM')} `}
          <Box fontWeight="fontWeightBold" component="span">
            {`${totalDaysToFinance} ${t('SELECTED_PAYMENT_TERM_DAYS')}`}
          </Box>
        </Typography>
      </Box>
    </MuiPickersUtilsProvider>
  );
}

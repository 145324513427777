import { useClearWalletStore } from '../account';
import { useClearWithdrawalStore } from '../withdrawal';
import { useClearMovementsStore } from '../movements';

const useClearWalletStores = () => {
  const walletStore = useClearWalletStore();
  const withdrawalStore = useClearWithdrawalStore();
  const movementsStore = useClearMovementsStore();

  const clearWalletStores = () => {
    walletStore.clear();
    withdrawalStore.clear();
    movementsStore.clear();
  };

  return { clearWalletStores };
};

export { useClearWalletStores };

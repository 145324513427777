import { useState, useEffect } from 'react';

const useSearchBar = ({ handleChangeInput, filterValue }) => {
  const SEARCHBAR_TIMEOUT = 500;

  const [timer, setTimer] = useState(null);
  const [inputValue, setInputValue] = useState(filterValue);

  useEffect(() => setInputValue(filterValue), [filterValue]);

  const changeInput = e => {
    const {
      target: { value },
    } = e;
    clearTimeout(timer);
    setInputValue(value);
    const targetValue = value.trim();

    setTimer(
      setTimeout(() => {
        if (!targetValue && inputValue) handleChangeInput('');
        if (targetValue) handleChangeInput(targetValue);
      }, SEARCHBAR_TIMEOUT)
    );
  };

  const clearInput = () => {
    setInputValue('');
    handleChangeInput('');
  };

  return {
    inputValue,
    changeInput,
    clearInput,
  };
};

export default useSearchBar;

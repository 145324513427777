import { FunctionComponent, useEffect } from 'react';
import {
  createHistoryProxy,
  HistoryProxyObject,
} from '@finance/services/historyProxy';

const keyFinance = '@finance/history';

export const historyProxy = createHistoryProxy(keyFinance);

export function HistoryProxy(
  Component: FunctionComponent<unknown & { historyProxy: HistoryProxyObject }>
) {
  return function TrackerPage(props: unknown) {
    useEffect(() => {
      historyProxy.setPage(Component);
    }, []);

    return <Component {...props} historyProxy={historyProxy} />;
  };
}

import React from 'react';
import { SvgIcon, styled } from '@material-ui/core';
import PropTypes from 'prop-types';
import { xTheme } from '@xepelinapp/x-ds';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const InboxIcon = props => {
  const { style } = props;

  return (
    <CustomSvgIcon
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      style={style}
    >
      <circle cx="24" cy="24" r="24" fill={xTheme.grey[100]} />
      <path
        d="M24.9998 14.03V14.05V16.05C29.3898 16.59 32.4998 20.58 31.9598 24.97C31.4998 28.61 28.6398 31.5 24.9998 31.93V33.93C30.4998 33.38 34.4998 28.5 33.9498 23C33.4998 18.25 29.7298 14.5 24.9998 14.03ZM22.9998 14.06C21.0498 14.25 19.1898 15 17.6698 16.26L19.0998 17.74C20.2198 16.84 21.5698 16.26 22.9998 16.06V14.06ZM16.2598 17.67C14.9998 19.19 14.2498 21.04 14.0498 23H16.0498C16.2398 21.58 16.7998 20.23 17.6898 19.1L16.2598 17.67ZM27.4998 20.5L22.6198 25.38L20.4998 23.26L19.4398 24.32L22.6198 27.5L28.5598 21.56L27.4998 20.5ZM14.0598 25C14.2598 26.96 15.0298 28.81 16.2698 30.33L17.6898 28.9C16.8098 27.77 16.2398 26.42 16.0598 25H14.0598ZM19.0998 30.37L17.6698 31.74C19.1798 33 21.0398 33.79 22.9998 34V32C21.5798 31.82 20.2298 31.25 19.0998 30.37Z"
        fill={xTheme.grey[900]}
      />
    </CustomSvgIcon>
  );
};

InboxIcon.defaultProps = {
  style: {},
};

InboxIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default InboxIcon;

import React from 'react';
import Image from 'next/image';
import { Box, LinearProgress } from '@material-ui/core';
import { useSplashScreenStyles } from './styles/useSplashScreenStyles';
import { Container } from '../../../../src/design-system/atoms/layout';

export function SplashScreen() {
  const classes = useSplashScreenStyles();
  return (
    <Container>
      <div className={classes.mainContainer}>
        <Image
          alt="XepelinLogo"
          src="/assets/xepelin-logo.svg"
          width="300px"
          height="300px"
          unoptimized
        />
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      </div>
    </Container>
  );
}

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useClearWalletStores } from '@wallet/core';
import { useClearSelection } from '@payments/modules/invoices/infrastructure/store/invoice-selection-store';
import { useAuthStore } from '@features/auth/infra/store/useAuthStore';
import { urls } from '@features/libs/urls';
import { BusinessInfo } from '@features/auth/domain';
import { RootState } from 'store/reducers/rootReducer';
import { logoutUser } from 'store/actions/authenticationActions';
import navbarItems from '../navbarItems';

const useTopbarPrivate = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [anchorElMenu, setAnchorElMenu] = useState<HTMLElement | null>(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const authStore = useAuthStore();
  const [isNavbarDropdownOpen, setIsNavbarDropdownOpen] = useState(false);

  const { clearWalletStores } = useClearWalletStores();
  const paymentsClearInvoiceSelection = useClearSelection();

  useEffect(() => {
    if (!authStore.isAuthenticated) router.push(urls.login);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.isAuthenticated]);

  const { business }: { business: BusinessInfo } = useSelector(
    (state: RootState) => state.authState
  );

  const { name: businessName } = business;

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const logoutClick = () => {
    handleCloseMenu();
    clearWalletStores();
    paymentsClearInvoiceSelection();
    dispatch(logoutUser());
  };

  return {
    anchorElMenu,
    openDrawer,
    businessName,
    handleOpenDrawer,
    handleCloseDrawer,
    handleClickMenu,
    handleCloseMenu,
    logoutClick,
    currentPath: router.asPath,
    navItems: navbarItems,
    isNavbarDropdownOpen,
    setIsNavbarDropdownOpen,
  };
};

export default useTopbarPrivate;

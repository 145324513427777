import { MovementsStoreType } from '@wallet/movements';
import MovementsZustandStore from './MovementsZustandStore';

export const useClearMovementsStore = (): { clear: () => void } => {
  return {
    clear: MovementsZustandStore(
      (state: MovementsStoreType) => state.resetStore
    ),
  };
};

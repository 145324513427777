import React from 'react';
import { useInvoiceSelection } from '@finance/hooks/migration/useInvoiceSelection';
import SelectionBar from 'features/shared/SelectionBar';
import { useTheme, Divider, Button, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CountryFormatHelper from 'components/elements/CountryFormatHelper';
import { useRouter } from 'next/router';
import { Typography } from 'design-system/atoms';
import { encode } from 'helpers/base64';
import { groupArrayByKey } from '@payments/commons/utils/array';
import { useTranslation } from '../../../../../features/i18n/infra/useTranslation';
import { useStyles } from './finance-cart-styles';

export function FinanceCart() {
  const { state } = useInvoiceSelection();

  const classes = useStyles();
  const router = useRouter();
  const theme = useTheme();

  const { t } = useTranslation();

  const isDesktopMode = useMediaQuery(theme.breakpoints.up('sm'));

  const providerQuantity = Object.keys(
    groupArrayByKey({
      array: state.selectedInvoices,
      key: 'payerIdentifier',
    })
  ).length;

  const totalSelectedCartInvoices = state.totalSelectedCartInvoices();

  const invoicesIds = state?.selectedInvoices?.map(({ id }) => id);

  return (
    <SelectionBar
      direction="up"
      in={!!totalSelectedCartInvoices}
      mountOnEnter
      unmountOnExit
    >
      <Grid container>
        <Grid container item xs={12} sm={8} md={6}>
          <Grid item xs={3} md={3}>
            <Typography variant="body3">Facturas</Typography>
            <Typography variant="h5">
              {state.selectedInvoices.length}
            </Typography>
          </Grid>
          <Divider light orientation="vertical" variant="middle" flexItem />
          <Grid item xs={3} md={3}>
            <Typography variant="body3">Clientes</Typography>
            <Typography variant="h5">{providerQuantity}</Typography>
          </Grid>
          <Divider light orientation="vertical" variant="middle" flexItem />
          <Grid item xs={3} md={3}>
            <Typography variant="body3">
              {t('INVOICES_SELECTION_CART_RESUME_FINNANCE')}
            </Typography>
            <Typography variant="h5">
              <CountryFormatHelper
                value={totalSelectedCartInvoices || '-'}
                variant="currency"
                countryId={state.countryId}
                dataQa="cart-resume-total-selected-cart-invoices-string"
              />
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          item
          sm={4}
          md={6}
          justifyContent={!isDesktopMode ? 'center' : 'flex-end'}
          alignItems="center"
        >
          <Button
            className={classes.actionButton}
            onClick={() => {
              router.push(`${router.asPath}calculate/#${encode(invoicesIds)}`);
            }}
            variant="contained"
          >
            {t('INVOICES_SELECTION_CART_NEXT_BUTTON')}
          </Button>
        </Grid>
      </Grid>
    </SelectionBar>
  );
}

import React from 'react';
import { MuiThemeProvider, makeStyles } from '@material-ui/core';

import { TopbarPublic } from 'components/layout/navigation';
import { utmTracker } from '@features/libs/utmTracker';
import { usePrefetchHomeHook } from '@features/auth/adapters/common/usePrefetchHomeHook';
import { Container } from 'design-system/atoms/layout';
import { theme } from '@features/auth/ui/common/theme/theme';

interface AuthLayoutProps {
  children: React.ReactNode;
}

export const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
    paddingTop: 64,
    backgroundColor: theme.palette.common.white,
  },
});

function AuthLayout({ children }: AuthLayoutProps) {
  const classes = useStyles();
  utmTracker();
  usePrefetchHomeHook();

  return (
    <>
      <TopbarPublic />

      <MuiThemeProvider theme={theme}>
        <div className={classes.container}>
          <Container maxWidth="sm">{children}</Container>
        </div>
      </MuiThemeProvider>
    </>
  );
}

export default AuthLayout;

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, makeStyles, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import Card from './Card';
import excedentes from '../../../assets/excedentes.svg';
import excedentes2 from '../../../assets/excedentes2.svg';
import CountryFormatHelper from '../CountryFormatHelper';
import { Typography } from '../../../design-system/atoms/index.ts';
import {
  AVAILABLE_COUNTRIES,
  LONG_DATE_FORMAT,
} from '../../../helpers/constants';
import LoadingButton from '../LoadingButton';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    height: '100%',
  },
  rootCardExcedentes: {
    padding: '15px 10px',
  },
  icon: {
    marginLeft: 4,
    marginRight: 13,
  },
  title: {
    marginBottom: 6,
    color: theme.palette.text.primary,
  },
  surplusTitle: {
    marginBottom: 6,
    color: theme.palette.text.light,
  },
  body: {
    marginBottom: 25,
  },
  availableLabel: {
    color: theme.palette.text.light,
    marginBottom: 10,
  },
  amount: {
    color: theme.palette.text.light,
  },
  button: {
    borderColor: theme.palette.common.white,
    background: 'none',
    color: theme.palette.text.light,
    width: '100%',
  },
}));

const SurplusCard = props => {
  const classes = useStyles();
  const {
    balance,
    loading,
    countryId,
    lastPendingWalletRequest = {},
    createWalletRequest,
  } = props;

  const { createWalletRequestIsLoading } = useSelector(
    state => state.walletState
  );

  // this function was added to avoid rounding up to zero keeping the - sign (edge case. i.e.: -0.12345 )
  const fixBalance = () => {
    const fixedBalance = balance < 0 && balance > -0.5 ? 0 : balance;
    return Number.parseFloat(fixedBalance)
      ? parseFloat(fixedBalance).toFixed(0)
      : fixedBalance;
  };

  const hasPendingWalletRequests = !!lastPendingWalletRequest;
  const finalBalance = fixBalance();
  const balanceImage = finalBalance ? excedentes2 : excedentes;

  const getButtonText = () => {
    if (finalBalance > 0 && hasPendingWalletRequests) {
      return 'Excedentes solicitados';
    }
    return 'Solicitar devolución';
  };

  const getAmountText = () => {
    if (finalBalance < 0) {
      return 'Deuda';
    }
    if (hasPendingWalletRequests) {
      return `Solicitados el ${moment(
        lastPendingWalletRequest.createdAt
      ).format(LONG_DATE_FORMAT)}`;
    }
    return 'Disponibles';
  };
  const requestSurplus = () => {
    const formattedBalance = balance.toLocaleString().replaceAll(',', '.');
    if (window.hsTunnel) {
      window.hsTunnel(formattedBalance);
    }
    createWalletRequest();
  };

  const noSurplusBody = (
    <Typography variant="tiny" color="textSecondary" className={classes.body}>
      Dado el estado actual de tus operaciones, no tienes excedentes disponibles
      para solicitar
    </Typography>
  );

  const Body = finalBalance ? (
    <>
      <Typography variant="h6" component="div" className={classes.amount}>
        <Box fontWeight="fontWeightBold">
          <CountryFormatHelper
            value={finalBalance}
            countryId={countryId}
            variant="currency"
          />
        </Box>
      </Typography>
      <Typography
        variant="body3"
        color="textPrimary"
        className={classes.availableLabel}
      >
        {getAmountText()}
      </Typography>
      <LoadingButton
        variant="outlined"
        onClick={requestSurplus}
        isLoading={createWalletRequestIsLoading}
        disabled={
          finalBalance <= 0 ||
          hasPendingWalletRequests ||
          createWalletRequestIsLoading
        }
        className={classes.button}
      >
        {getButtonText()}
      </LoadingButton>
    </>
  ) : (
    noSurplusBody
  );

  return (
    <Card
      className={classes.cardRoot}
      variant={finalBalance > 0 ? 'gradient' : 'grey'}
    >
      <Grid container direction="row" className={classes.rootCardExcedentes}>
        <Grid item xs={4}>
          <img src={balanceImage.src} className={classes.icon} alt="" />
        </Grid>
        <Grid item xs={8}>
          <Typography
            variant="body2"
            component="div"
            className={finalBalance > 0 ? classes.surplusTitle : classes.title}
          >
            <Box fontWeight="fontWeightBold">
              {finalBalance > 0 ? 'Excedentes disponibles' : 'Excedentes'}
            </Box>
          </Typography>
          {loading ? <Skeleton variant="text" /> : Body}
        </Grid>
      </Grid>
    </Card>
  );
};

SurplusCard.defaultProps = {
  balance: 0,
  loading: false,
  lastPendingWalletRequest: null,
};

SurplusCard.propTypes = {
  balance: PropTypes.number,
  loading: PropTypes.bool,
  countryId: PropTypes.oneOf(AVAILABLE_COUNTRIES).isRequired,
  lastPendingWalletRequest: PropTypes.shape({
    createdAt: PropTypes.string,
  }),
  createWalletRequest: PropTypes.func.isRequired,
};

export default SurplusCard;

import { InvoiceCategories } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';
import { DIRECT_FINANCING, EARLY_PAYMENT } from '../../../helpers/constants';

const evaluateFinanceableInvoice = (
  financeableInvoicesSelected,
  categories,
  operationType
) => {
  return (
    financeableInvoicesSelected ||
    categories?.find(
      category =>
        category === InvoiceCategories.directFinancing &&
        operationType === DIRECT_FINANCING
    ) ||
    categories?.find(
      category =>
        category === InvoiceCategories.earlyPayment &&
        operationType === EARLY_PAYMENT
    )
  );
};
const useGetInvoiceStatus = (
  categories: [],
  operationType: String,
  financeableInvoicesSelected?: boolean
) => {
  let isFinanceableInvoice = false;

  const getInvoiceFinanceableStatus = () => {
    isFinanceableInvoice = evaluateFinanceableInvoice(
      financeableInvoicesSelected,
      categories,
      operationType
    );
  };

  getInvoiceFinanceableStatus();

  return {
    isFinanceableInvoice,
  };
};

export default useGetInvoiceStatus;

import { Overrides } from '@material-ui/core/styles/overrides';
import palette from './palette';
import typography from './typography';

const overrides: Overrides = {
  MuiButton: {
    root: {
      borderRadius: 35,
      textTransform: 'none',
      boxShadow: 'none !important',
    },
  },
  MuiCheckbox: {
    root: {
      padding: '5px',
    },
  },
  MuiChip: {
    clickable: {
      '&:active': {
        boxShadow: 0,
      },
    },
  },
  MuiDrawer: {
    paper: {
      background: palette.background.paper,
    },
  },
  MuiFormHelperText: {
    root: {
      fontWeight: 600,
    },
  },
  MuiIconButton: {
    colorPrimary: {
      '&:hover': {
        backgroundColor: palette.background.gradient,
      },
    },
    colorSecondary: {
      '&:hover': {
        backgroundColor: palette.background.gradient,
      },
    },
    root: {
      padding: 5,
      color: palette.text.primary,
      '&.Mui-disabled': {
        color: palette.text.disabled,
      },
    },
    sizeSmall: {
      '& .MuiSvgIcon-root': {
        fontSize: 16,
      },
    },
  },
  MuiInputAdornment: {
    root: {
      color: palette.grey[600],
    },
    marginDense: {
      '& .MuiSvgIcon-root': {
        fontSize: 16,
      },
    },
  },
  MuiInputLabel: {
    outlined: {
      transform: 'translate(14px, 12px) scale(1)',
    },
    marginDense: {
      transform: 'translate(14px, 4px) scale(1) !important',
    },
    shrink: {
      transform: 'translate(14px, -6px) scale(0.75) !important',
    },
  },
  MuiOutlinedInput: {
    root: {
      '& input:valid + fieldset': {
        borderColor: palette.grey[500],
      },
      '&:hover': {
        borderColor: `${palette.grey[800]} !important`,
      },
      '& .Mui-disabled': {
        background: palette.grey[200],
        borderRadius: 40,
      },
      '& fieldset': {
        borderRadius: '40px',
      },
    },
    input: {
      padding: '10.5px 14px',
    },
    inputMarginDense: {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  MuiSelect: {
    selectMenu: {
      paddingTop: '8px',
      paddingBottom: '8px',
      minHeight: 15,
    },
  },
  MuiSvgIcon: {
    fontSizeSmall: {
      fontSize: 16,
    },
    colorDisabled: {
      color: palette.text.disabled,
    },
    colorAction: {
      color: palette.text.primary,
    },
  },
  MuiTableCell: {
    root: {
      fontSize: typography.body3.fontSize,
    },
    head: {
      color: palette.text.primary,
    },
  },
  MuiTableRow: {
    root: {
      transition: 'unset !important',
      '&:hover': {
        backgroundColor: `${palette.grey[200]} !important`,
        '& .MuiStepConnector-line': {
          background: `${palette.grey[300]} !important`,
        },
      },
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: `${palette.grey[100]} !important`,
      },
    },
  },
  MuiTextField: {
    root: {
      marginBottom: 15,
      nativeInput: {
        boxSizing: 'inherit',
      },
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: 10,
      backgroundColor: palette.grey[800],
    },
  },

  MuiInputBase: {
    root: {
      fontSize: typography.body3.fontSize,
    },
  },
  MuiMenuItem: {
    root: {
      fontSize: typography.body3.fontSize,
    },
  },
  MuiFormLabel: {
    root: {
      fontSize: typography.body3.fontSize,
    },
  },
  MuiStepLabel: {
    label: {
      fontSize: typography.body3.fontSize,
    },
  },
};

export default {
  ...overrides,
  MuiDataGrid: {
    root: {
      color: palette.text.secondary,
      fontSize: typography.body3.fontSize,
      backgroundColor: 'white',
      border: 'none',
      '& .MuiDataGrid-selectedRowCount': {
        visibility: 'hidden !important',
      },
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: palette.grey[100],
      },
      '& .MuiDataGrid-columnHeader': {
        backgroundColor: palette.grey[100],
      },
      '& .MuiDataGrid-row': {
        '&.Mui-selected': {
          backgroundColor: palette.grey[200],
        },
        '&:hover': {
          backgroundColor: palette.grey[100],
        },
      },
      '& .MuiDataGrid-cell': {
        minHeight: 56,
        borderColor: `${palette.grey[300]}!important`,
      },
      '& .MuiDataGrid-inactiveRow': {
        backgroundColor: palette.grey[100],
        fontWeight: 400,
        color: palette.text.disabled,
        pointerEvents: 'none !important',
        '& .MuiDataGrid-cellCheckbox': {
          '& .MuiDataGrid-checkboxInput': {
            color: palette.text.disabled,
          },
        },
      },
    },
  },
};

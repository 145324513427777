import { Button, CircularProgress, ButtonProps } from '@material-ui/core';

interface ILoadingButtonProps extends ButtonProps {
  isLoading: boolean;
  children: React.ReactNode;
}

export function LoadingButton({
  isLoading,
  children,
  ...rest
}: ILoadingButtonProps) {
  return (
    <Button
      {...rest}
      endIcon={
        isLoading ? <CircularProgress size={24} color="inherit" /> : null
      }
    >
      {isLoading ? 'Cargando...' : children}
    </Button>
  );
}

import { SvgIcon } from '@material-ui/core';
import { xTheme } from '@xepelinapp/x-ds';
import React from 'react';

function CellInfoIcon(): JSX.Element {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
          fill={xTheme.info.light}
        />
        <path
          d="M16.6873 6.375H7.979C7.31104 6.375 6.7915 6.91927 6.7915 7.5625V16.2708C6.7915 16.9388 7.31104 17.4583 7.979 17.4583H16.6873C17.3306 17.4583 17.8748 16.9388 17.8748 16.2708V7.5625C17.8748 6.91927 17.3306 6.375 16.6873 6.375ZM17.0832 16.2708C17.0832 16.4935 16.8853 16.6667 16.6873 16.6667H7.979C7.75635 16.6667 7.58317 16.4935 7.58317 16.2708V7.5625C7.58317 7.36458 7.75635 7.16667 7.979 7.16667H16.6873C16.8853 7.16667 17.0832 7.36458 17.0832 7.5625V16.2708ZM11.4425 14.2917C11.2694 14.2917 11.1457 14.4401 11.1457 14.5885V14.7865C11.1457 14.9596 11.2694 15.0833 11.4425 15.0833H13.2238C13.3722 15.0833 13.5207 14.9596 13.5207 14.7865V14.5885C13.5207 14.4401 13.3722 14.2917 13.2238 14.2917H12.9269V10.8281C12.9269 10.6797 12.7785 10.5312 12.63 10.5312H11.4425C11.2694 10.5312 11.1457 10.6797 11.1457 10.8281V11.026C11.1457 11.1992 11.2694 11.3229 11.4425 11.3229H11.7394V14.2917H11.4425ZM12.3332 8.35417C11.8879 8.35417 11.5415 8.72526 11.5415 9.14583C11.5415 9.59115 11.8879 9.9375 12.3332 9.9375C12.7537 9.9375 13.1248 9.59115 13.1248 9.14583C13.1248 8.72526 12.7537 8.35417 12.3332 8.35417Z"
          fill={xTheme.info.main}
        />
      </svg>
    </SvgIcon>
  );
}

export default CellInfoIcon;

import axios from 'helpers/axios';
import { OrderType } from '@finance/useCases/OperationLineValues.type';
import { Invoice } from '../invoicesServicesTypes';

export type InvoiceShort = Partial<Invoice> & { invoiceId: number };

export const submitOrderService = async ({
  orderType,
  businessId,
  invoices,
}: {
  orderType: `${OrderType}`;
  businessId: string;
  invoices: InvoiceShort[];
}) => {
  try {
    const preselectedInvoicesIds = invoices.map(({ invoiceId }) => invoiceId);

    const response = await axios.post(
      `api/business/${businessId}/order?orderType=${orderType}`,
      {
        orderInvoices: invoices,
        preselectedInvoicesIds,
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.errorCode);
  }
};

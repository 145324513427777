import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getDocumentStatusSummary } from '../useCases/getDocumentStatusSummary';
import { enqueueSnackbar } from '../../../store/actions/notificationActions';
import { t13s } from '../../../config/translationKeys';
import { DocumentsSummary } from '../types/DocumentSummary.type';

const useBusinessDocumentsSummary = (businessId: number) => {
  const dispatch = useDispatch();
  const [documentSummary, setDocumentSummary] =
    useState<DocumentsSummary>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line func-names
    (async function () {
      try {
        if (!businessId) {
          return;
        }
        setLoading(true);
        const summary = await getDocumentStatusSummary(businessId);
        setDocumentSummary(summary);
      } catch (err) {
        setError(err);
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.ERROR_UNHANDLED_SERVER_ERROR,
            options: { variant: 'error' },
          })
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [businessId, dispatch]);

  return { loading, error, documentSummary };
};

export default useBusinessDocumentsSummary;

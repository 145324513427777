import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Typography } from 'design-system/atoms/index';
import useGetInvoices from '@integration-services/xepelin-server-global/invoices/hooks/useGetInvoices';
import { useTranslation } from '../../../../../features/i18n/infra/useTranslation';
import { useStyles } from './history-processing-header-message-styles';
import { verifyHistory } from '../../utils/verifyHistory';
import type { RootState, BusinessState } from '../../types';

export function HistoryProcessingHeaderMessage() {
  const { t } = useTranslation();
  const classes = useStyles();

  const { data } = useGetInvoices();

  const { onboardingStep, hasInvoiceHistory } = useSelector<
    RootState,
    BusinessState
  >(state => state.businessState.business);

  const canRender = verifyHistory({
    hasInvoiceHistory,
    hasInvoices: !!data?.data?.length,
    onboardingStep,
  });

  if (!canRender) return null;

  return (
    <Box
      style={{ height: '88px' }}
      boxShadow="0px 10px 24px rgba(201, 210, 224, 0.32)"
      mb={4}
      mt={1}
      py={2}
      px={3}
      className={classes.bannerBackground}
    >
      <Typography variant="body1">
        <Box fontWeight="fontWeightBold" color="info.dark" mb={1}>
          {t('INVOICES_HISTORY_PROCESSING_HEADER_TITLE')}
        </Box>
      </Typography>
      <Typography variant="body3">
        <Box color="info.dark">
          {t('INVOICES_HISTORY_PROCESSING_HEADER_DESCRIPTION')}
        </Box>
      </Typography>
    </Box>
  );
}

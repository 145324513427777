import {
  Box,
  createStyles,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  Theme,
} from '@material-ui/core';
import { Typography } from '../../src/design-system/atoms';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: 0,
      backgroundColor: 'transparent',
    },
    stepLabel: {
      '& svg[class*= MuiStepIcon-completed]': {
        color: theme.palette.success.main,
      },
      '& span span[class*= MuiStepLabel-label]': {
        marginTop: theme.spacing(1),
      },
    },
  })
);

interface ICustomStepperProps {
  activeStep: number;
  steps: string[];
  completed?: boolean;
}

function CustomStepper({ activeStep, steps, completed }: ICustomStepperProps) {
  const classes = useStyles();

  const conditionalProps: { completed?: boolean } = {};
  if (completed) conditionalProps.completed = true;

  return (
    <Stepper activeStep={activeStep} alternativeLabel className={classes.root}>
      {steps.map(label => (
        <Step key={label} {...conditionalProps}>
          <StepLabel className={classes.stepLabel}>
            <Typography component="div" variant="tiny" color="textSecondary">
              <Box fontWeight="fontWeightMedium">{label}</Box>
            </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

export default CustomStepper;

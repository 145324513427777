import React, { useState } from 'react';
import { Tooltip, Modal } from '@material-ui/core';
import { OrderType } from '@finance/useCases/OperationLineValues.type';
import { EARLY_PAYMENT_LABEL, DIRECT_FINANCING_LABEL } from 'helpers/constants';
import { getPaths } from '@finance/utils/utils';
import { useRouter } from 'next/router';
import { useInvoiceSelection } from '@finance/hooks/migration/useInvoiceSelection';
import useTracker from '@finance/hooks/useTracker';
import { useStyles } from './SwitchToOtherTab.styles';
import { ModalContent } from './ModalContent';
import { TooltipContent } from './TooltipContent';
import { TypesOfOperations, SwitchToOtherTabProps } from './types';

export const typesOfOperations: TypesOfOperations = {
  [OrderType.DIRECT_FINANCING]: {
    label: DIRECT_FINANCING_LABEL,
    orderType: OrderType.DIRECT_FINANCING,
  },
  [OrderType.EARLY_PAYMENT]: {
    label: EARLY_PAYMENT_LABEL,
    orderType: OrderType.EARLY_PAYMENT,
  },
};

export function SwitchToOtherTab({
  children,
  orderType,
}: SwitchToOtherTabProps) {
  const [showModal, setShowModal] = useState(false);

  const { financeCallbacks } = useTracker();

  const { callbacks } = useInvoiceSelection();

  const classes = useStyles();

  const router = useRouter();

  const typeOfOperation = typesOfOperations[orderType];

  const path = getPaths(orderType);

  const closeModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    financeCallbacks.viewModalAndChangeOrderType(typeOfOperation.orderType);
    setShowModal(true);
  };

  const navigateToOtherTab = () => {
    closeModal();

    callbacks.clearInputRemote();
    callbacks.resetFilter();
    callbacks.resetSelection();
    callbacks.resetTableStates();
    callbacks.handleChangeAllInvoices();
    financeCallbacks.viewModalAndChangeOrderType(typeOfOperation.orderType);
    router.push(`/finance/${path}/`);
  };

  const tooltipContent = (
    <TooltipContent typeOfOperation={typeOfOperation} openModal={openModal} />
  );

  return (
    <>
      <Modal open={showModal} className={classes.modal}>
        <ModalContent
          closeModal={closeModal}
          typeOfOperation={typeOfOperation}
          navigateToOtherTab={navigateToOtherTab}
        />
      </Modal>
      {!showModal && (
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          interactive
          placement="top"
          title={tooltipContent}
        >
          {children}
        </Tooltip>
      )}
    </>
  );
}

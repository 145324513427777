import { TextField, makeStyles, IconButton, Box } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloseIcon from 'components/icons/CloseIcon';
import { Typography } from 'design-system/atoms';
import { useTranslation } from '../../../features/i18n/infra/useTranslation';
import SearchIcon from '../icons/SearchIcon';

type InputSearchProps = {
  inputSearchText: string;
  placeholder?: string;
  handleChangeInput: () => void;
  resetInput: () => void;
};

const useStyles = makeStyles(theme => ({
  inputSearchStyle: {
    width: '100%',
    marginBottom: 0,
    '& fieldset': {
      borderRadius: theme.spacing(1),
      borderColor: theme.palette.grey[400],
    },
  },
}));

function InputSearch({
  inputSearchText,
  placeholder = '',
  handleChangeInput,
  resetInput,
}: InputSearchProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const inputProps = {
    startAdornment: (
      <InputAdornment position="start">
        <SearchIcon />
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position="end">
        <IconButton onClick={resetInput}>
          <CloseIcon />
        </IconButton>
      </InputAdornment>
    ),
    classes: { input: classes.inputSearchStyle },
  };

  return (
    <Box pt={1} width="100%">
      <Typography variant="body3" color="textSecondary">
        {t('INVOICES_SEARCH_TEXT_FIELD_TITLE')}
      </Typography>
      <Box pt={1}>
        <TextField
          className={classes.inputSearchStyle}
          value={inputSearchText}
          onChange={handleChangeInput}
          placeholder={placeholder}
          variant="outlined"
          InputProps={inputProps}
        />
      </Box>
    </Box>
  );
}

export default InputSearch;

import React from 'react';
import { styled, SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { xTheme } from '@xepelinapp/x-ds';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const ArrowDownIcon = props => {
  const { style } = props;
  return (
    <CustomSvgIcon style={style} width="12" height="7" viewBox="0 0 12 7">
      <path
        d="M2.44333 0.516172L6 4.07284L9.55666 0.516172C9.91416 0.158672 10.4917 0.158672 10.8492 0.516172C11.2067 0.873672 11.2067 1.45117 10.8492 1.80867L6.64166 6.01617C6.28416 6.37367 5.70666 6.37367 5.34916 6.01617L1.14166 1.80867C0.78416 1.45117 0.78416 0.873672 1.14166 0.516172C1.49916 0.167838 2.08583 0.158672 2.44333 0.516172Z"
        fill={style.fill ?? xTheme.grey[900]}
      />
    </CustomSvgIcon>
  );
};

ArrowDownIcon.defaultProps = {
  style: {},
};

ArrowDownIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default ArrowDownIcon;

import {
  TypographyOptions,
  TypographyStyleOptions,
} from '@material-ui/core/styles/createTypography';

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    display1: React.CSSProperties;
    display2: React.CSSProperties;
    body3: React.CSSProperties;
    tiny: React.CSSProperties;
  }

  // allow configuration using `createMuiTheme`
  interface TypographyOptions {
    display1: TypographyStyleOptions;
    display2: TypographyStyleOptions;
    body3: TypographyStyleOptions;
    tiny: TypographyStyleOptions;
  }
}

declare module '@material-ui/core/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    display1: true;
    display2: true;
    body3: true;
    tiny: true;
  }
}

const typography: TypographyOptions = {
  fontFamily: 'Inter',
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  display1: {
    fontSize: '5.16rem',
    '@media (max-width:960px)': {
      fontSize: '3.247rem',
    },
    '@media (max-width:600px)': {
      fontSize: '2.178rem',
    },
    lineHeight: 1.3,
  },
  display2: {
    fontSize: '4.3rem',
    '@media (max-width:960px)': {
      fontSize: '2.887rem',
    },
    '@media (max-width:600px)': {
      fontSize: '2.041rem',
    },
    lineHeight: 1.3,
  },
  h1: {
    fontSize: '3.583rem',
    '@media (max-width:960px)': {
      fontSize: '2.566rem',
    },
    '@media (max-width:600px)': {
      fontSize: '1.913rem',
    },
    lineHeight: 1.3,
  },
  h2: {
    fontSize: '2.986rem',
    '@media (max-width:960px)': {
      fontSize: '2.281rem',
    },
    '@media (max-width:600px)': {
      fontSize: '1.793rem',
    },
    lineHeight: 1.3,
  },
  h3: {
    fontSize: '2.488rem',
    '@media (max-width:960px)': {
      fontSize: '2.027rem',
    },
    '@media (max-width:600px)': {
      fontSize: '1.68rem',
    },
    lineHeight: 1.3,
  },
  h4: {
    fontSize: '2.074rem',
    '@media (max-width:960px)': {
      fontSize: '1.802rem',
    },
    '@media (max-width:600px)': {
      fontSize: '1.575rem',
    },
    lineHeight: 1.3,
  },
  h5: {
    fontSize: '1.728rem',
    '@media (max-width:960px)': {
      fontSize: '1.602rem',
    },
    '@media (max-width:600px)': {
      fontSize: '1.476rem',
    },
    lineHeight: 1.3,
  },
  h6: {
    fontSize: '1.44rem',
    '@media (max-width:960px)': {
      fontSize: '1.424rem',
    },
    '@media (max-width:600px)': {
      fontSize: '1.383rem',
    },
    lineHeight: 1.3,
  },
  body1: {
    fontSize: '1.286rem',
    lineHeight: 1.3,
  },
  body2: {
    fontSize: '1.143rem',
    lineHeight: 1.3,
  },
  body3: {
    fontSize: '1rem',
    lineHeight: 1.3,
  },
  tiny: {
    fontSize: '0.857rem',
    lineHeight: 1.3,
  },
  button: {
    fontSize: '0.939rem', // medium size
    lineHeight: 1.3,
    textTransform: 'none',
  },
};

export default typography;

export const TEN_MINUTES_IN_MILISECONDS = 600000;

export const getLocalStorageKeyOfLastScrap = (businessId: string) =>
  `business:${businessId}:invoices:scrap`;

export const isAvailableToReScraptInvoices = (
  lastInvoiceScrapTimestamp: number,
  nowTimestamp: number
): boolean => {
  if (!lastInvoiceScrapTimestamp) {
    return true;
  }

  return nowTimestamp - lastInvoiceScrapTimestamp > TEN_MINUTES_IN_MILISECONDS;
};

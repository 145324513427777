import { CountryId } from '../../../features/shared/types/Country.type';
import { OperationLineValues, OrderType } from './OperationLineValues.type';
import { useTranslation } from '../../../../features/i18n/infra/useTranslation';

type GetCreationOperationLinesParams = {
  countryId: CountryId;
  operationType: `${OrderType}`;
  values: OperationLineValues;
};

type OperationLineProps = {
  title: string;
  value: number | string;
  variant: 'currency' | 'percentage';
  dataQa?: string;
  useFixedDecimalPrecision?: number;
  countries?: any;
  hasPromotions?: boolean;
  financeTypes?: any;
  tooltip?: string;
  totalAmount?: any;
  promotionsLimitAmount?: any;
  interest?: any;
  interestWithPromotions?: any;
};

const GetCreationOperationLines = ({
  countryId,
  operationType,
  values,
}: GetCreationOperationLinesParams) => {
  const { t } = useTranslation();

  const getPromotionsValues = () => {
    return values?.hasPromotions
      ? {
          transferWithPromotions: values?.transferWithPromotions,
          interest: values?.interest,
          interestWithPromotions: values?.interestWithPromotions,
          promotionsLimitAmount: values?.promotionsLimitAmount,
          promotionBaseRate: values?.promotionBaseRate,
          totalAmount: values?.totalAmount,
        }
      : null;
  };

  const calculateInterestValue = () => {
    if (!values?.interestWithPromotions) return values?.interest;
    return Math.min(values?.interestWithPromotions, values?.interest);
  };

  const lines: OperationLineProps[] = [
    {
      title: t('FINANCING_SUMMARY_TOTAL_AMOUNT'),
      value: values?.totalAmount || '-',
      variant: 'currency',
      dataQa: 'operation-resume-totalAmount-string',
    },
    {
      title: t('FINANCING_SUMMARY_PERCENTAGE'),
      value:
        typeof values?.retentionPct === 'number'
          ? 100 - values.retentionPct
          : '-',
      variant: 'percentage',
      dataQa: 'operation-resume-total-a-financiar-string',
    },
    {
      title: t('FINANCING_SUMMARY_SURPLUS'),
      tooltip: t('FINANCING_SUMMARY_SURPLUS_TOOLTIP_CREATION_OP'),
      value: values?.retention || '-',
      variant: 'currency',
      dataQa: 'operation-resume-excedente-string',
      countries: [CountryId.CHILE],
      financeTypes: [OrderType.DIRECT_FINANCING],
    },
    {
      title: t('FINANCING_SUMMARY_SURPLUS'),
      tooltip: t('FINANCING_SUMMARY_SURPLUS_TOOLTIP_CREATION_OP'),
      value: values?.retention || '-',
      variant: 'currency',
      dataQa: 'operation-resume-excedente-string',
      countries: [CountryId.MEXICO],
      financeTypes: [OrderType.EARLY_PAYMENT],
    },
    {
      title: t('FINANCING_SUMMARY_REQUESTED_FINANCING'),
      value: values?.totalAmount ? values.totalAmount - values.retention : '-',
      variant: 'currency',
      dataQa: 'operation-resume-monto-financiado-string',
    },
    {
      title: t('FINANCING_SUMMARY_MONTHLY_RATE'),
      value: values?.baseRate || '-',
      variant: 'percentage',
      useFixedDecimalPrecision: 2,
      dataQa: 'operation-resume-tasa-interes-string',
      hasPromotions: values?.hasPromotions,
      ...getPromotionsValues(),
    },
    {
      title: t('FINANCING_SUMMARY_INTEREST'),
      value: calculateInterestValue() || '-',
      variant: 'currency',
      dataQa: 'operation-resume-costo-financiamiento-string',
    },
    {
      title: t('FINANCING_SUMMARY_OPERATION_COST'),
      value: values?.operationCost || 0,
      variant: 'currency',
      dataQa: 'operation-resume-asesoria-digital-string',
    },
  ];

  const filterValues = line => line.value > 0;

  const filterCountries = line =>
    !line.countries || (line.countries || []).includes(countryId);

  const filterByOperationType = line =>
    !line.financeTypes || (line.financeTypes || []).includes(operationType);

  return lines
    .filter(filterValues)
    .filter(filterCountries)
    .filter(filterByOperationType);
};

export default GetCreationOperationLines;

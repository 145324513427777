import React from 'react';
import { SvgIcon, styled } from '@material-ui/core';
import PropTypes from 'prop-types';
import { xTheme } from '@xepelinapp/x-ds';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const PaidIcon = props => {
  const { style } = props;

  return (
    <CustomSvgIcon
      style={style}
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
    >
      <circle cx="24" cy="24" r="24" fill={xTheme.grey[200]} />
      <path
        d="M25.3333 14.0293V14.0493V16.0493C29.7233 16.5893 32.8333 20.5793 32.2933 24.9693C31.8333 28.6093 28.9733 31.4993 25.3333 31.9293V33.9293C30.8333 33.3793 34.8333 28.4993 34.2833 22.9993C33.8333 18.2493 30.0633 14.4993 25.3333 14.0293ZM23.3333 14.0593C21.3833 14.2493 19.5233 14.9993 18.0033 16.2593L19.4333 17.7393C20.5533 16.8393 21.9033 16.2593 23.3333 16.0593V14.0593ZM16.5933 17.6693C15.3333 19.1893 14.5833 21.0393 14.3833 22.9993H16.3833C16.5733 21.5793 17.1333 20.2293 18.0233 19.0993L16.5933 17.6693ZM27.8333 20.4993L22.9533 25.3793L20.8333 23.2593L19.7733 24.3193L22.9533 27.4993L28.8933 21.5593L27.8333 20.4993ZM14.3933 24.9993C14.5933 26.9593 15.3633 28.8093 16.6033 30.3293L18.0233 28.8993C17.1433 27.7693 16.5733 26.4193 16.3933 24.9993H14.3933ZM19.4333 30.3693L18.0033 31.7393C19.5133 32.9993 21.3733 33.7893 23.3333 33.9993V31.9993C21.9133 31.8193 20.5633 31.2493 19.4333 30.3693Z"
        fill={xTheme.text.primary}
      />
    </CustomSvgIcon>
  );
};

PaidIcon.defaultProps = {
  style: {},
};

PaidIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default PaidIcon;

import React from 'react';
import { SvgIcon, styled } from '@material-ui/core';
import PropTypes from 'prop-types';
import { xTheme } from '@xepelinapp/x-ds';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const CartAddIcon = props => {
  const { style } = props;

  return (
    <CustomSvgIcon style={style} viewBox="0 0 24 24">
      <path
        d="M11 8C11.55 8 12 7.55 12 7V5H14C14.55 5 15 4.55 15 4C15 3.45 14.55 3 14 3H12V1C12 0.45 11.55 0 11 0C10.45 0 10 0.45 10 1V3H8C7.45 3 7 3.45 7 4C7 4.55 7.45 5 8 5H10V7C10 7.55 10.45 8 11 8ZM6 17C4.9 17 4.01 17.9 4.01 19C4.01 20.1 4.9 21 6 21C7.1 21 8 20.1 8 19C8 17.9 7.1 17 6 17ZM16 17C14.9 17 14.01 17.9 14.01 19C14.01 20.1 14.9 21 16 21C17.1 21 18 20.1 18 19C18 17.9 17.1 17 16 17ZM7.1 12H14.55C15.3 12 15.96 11.59 16.3 10.97L19.54 4.83C19.79 4.35 19.62 3.75 19.14 3.49C18.65 3.22 18.04 3.41 17.78 3.9L14.55 10H7.53L3.27 1H1C0.45 1 0 1.45 0 2C0 2.55 0.45 3 1 3H2L5.6 10.59L4.25 13.03C3.52 14.37 4.48 16 6 16H17C17.55 16 18 15.55 18 15C18 14.45 17.55 14 17 14H6L7.1 12Z"
        fill={xTheme.primary.main}
      />
    </CustomSvgIcon>
  );
};

CartAddIcon.defaultProps = {
  style: {},
};

CartAddIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default CartAddIcon;

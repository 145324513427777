import React from 'react';
import { SvgIcon, styled } from '@material-ui/core';
import PropTypes from 'prop-types';
import { xTheme } from '@xepelinapp/x-ds';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const CardViewIcon = props => {
  const { colors, style } = props;

  return (
    <CustomSvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M4 0.5H31.5V31.5H4C2.067 31.5 0.5 29.933 0.5 28V4C0.5 2.067 2.067 0.5 4 0.5Z"
        stroke={xTheme.text.secondary}
        fill={colors.background}
      />
      <path
        d="M21.3333 9.33398H10.6666C9.93325 9.33398 9.33325 9.93398 9.33325 10.6673V21.334C9.33325 22.0673 9.93325 22.6673 10.6666 22.6673H21.3333C22.0666 22.6673 22.6666 22.0673 22.6666 21.334V10.6673C22.6666 9.93398 22.0666 9.33398 21.3333 9.33398ZM21.3333 15.334H16.6666V10.6673H21.3333V15.334ZM15.3333 10.6673V15.334H10.6666V10.6673H15.3333ZM10.6666 16.6673H15.3333V21.334H10.6666V16.6673ZM16.6666 21.334V16.6673H21.3333V21.334H16.6666Z"
        fill={colors.line}
      />
    </CustomSvgIcon>
  );
};

CardViewIcon.defaultProps = {
  style: {},
};

CardViewIcon.propTypes = {
  colors: PropTypes.objectOf(Object).isRequired,
  style: PropTypes.objectOf(Object),
};

export default CardViewIcon;

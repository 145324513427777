import { Box, Button, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CheckIcon from '@material-ui/icons/Check';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers/rootReducer';
import Card from './Card';
import DocumentCounterBreakdown from '../DocumentCounterBreakdown';
import useBusinessDocumentsSummary from '../../../features/documents/hooks/useBusinessDocumentsSummary';
import { DocumentsSummary } from '../../../features/documents/types/DocumentSummary.type';
import colors from '../../../design-system/theme/colors';
import Link from '../../../../features/shared/Link';
import { Typography } from '../../../design-system/atoms';

const useStyles = makeStyles({
  cardRoot: {
    height: '100%',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    width: 48,
    height: 48,
  },
});

const getPropsAccordingToTheState = (documentSummary: DocumentsSummary) => {
  const state = documentSummary?.state;
  if (state === 'ALL_DOCUMENTS_PENDING') {
    return {
      title: 'Documentos empresa',
      iconComponent: <ReportProblemIcon htmlColor={colors.ui.danger.main} />,
      iconBGColor: colors.ui.danger.light,
      ctaText: 'Subir documentos',
      content: (
        <Typography variant="tiny" color="textPrimary" component="p">
          Para comenzar a operar debes subir algunos documentos que serán
          revisados por nuestro equipo.
        </Typography>
      ),
    };
  }
  if (state === 'ALL_DOCUMENTS_APPROVED') {
    return {
      title: 'Revisión de documentos finalizada',
      iconComponent: <CheckIcon />,
      iconBGColor: colors.ui.success.light,
      ctaText: undefined,
      content: (
        <Typography variant="tiny" color="textPrimary" component="p">
          Todos los documentos solicitados se encuentran aprobados. Puedes
          encontrarlos ingresando a la sección de “Mi Perfil”.
        </Typography>
      ),
    };
  }
  return {
    title: 'Estado de documentos',
    iconComponent: <ReportProblemIcon htmlColor={colors.ui.danger.main} />,
    iconBGColor: colors.ui.danger.light,
    ctaText: 'Ir a documentos',
    content: <DocumentCounterBreakdown documentSummary={documentSummary} />,
  };
};

function DocumentSummaryCard() {
  const classes = useStyles();
  const { id: businessId } = useSelector(
    (state: RootState) => state.businessState.business
  );
  const { documentSummary, error, loading } =
    useBusinessDocumentsSummary(businessId);
  const { title, iconBGColor, iconComponent, ctaText, content } =
    getPropsAccordingToTheState(documentSummary);

  if (error) {
    return null;
  }

  return (
    <Card variant="white" border className={classes.cardRoot}>
      <Box p={3} display="flex">
        <Box className={classes.iconContainer} bgcolor={iconBGColor}>
          {!loading && iconComponent}
        </Box>
        <Box pl={2} maxWidth={280} width="100%">
          <Box marginBottom={1}>
            <Typography variant="body2" color="textPrimary">
              {loading ? (
                <Skeleton width="100%" />
              ) : (
                <Box fontWeight="fontWeightBold">{title}</Box>
              )}
            </Typography>
          </Box>
          {loading ? <Skeleton /> : content}
          <Box pt={2} />
          {ctaText && (
            <Link href="/user/profile/legal" style={{ textDecoration: 'none' }}>
              <Button variant="outlined" color="primary">
                {ctaText}
              </Button>
            </Link>
          )}
        </Box>
      </Box>
    </Card>
  );
}

export default DocumentSummaryCard;

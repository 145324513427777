/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import { Typography } from '../../design-system/atoms';
import { useTranslation } from '../../../features/i18n/infra/useTranslation';
import VerifiedIcon from '../../assets/badge-filled.svg';

export const useStyles = makeStyles(themeStyles => ({
  tooltipIcon: {
    borderRadiud: '55px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tooltipContent: {
    padding: themeStyles.spacing(1),
  },
}));

function VerifiedInvoice() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Tooltip
      className={classes.tooltipIcon}
      title={
        <Box className={classes.tooltipContent}>
          <Typography variant="body3">
            {t('VERIFIED_INOVICE_TOOLTIP')}
          </Typography>
        </Box>
      }
      placement="top"
      arrow
    >
      <img src={VerifiedIcon.src} alt="verifiedIcon" />
    </Tooltip>
  );
}

export default VerifiedInvoice;

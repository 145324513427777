import { Wallet } from '@wallet/account';

export const WalletMap = {
  toDomain: ({
    businessId,
    accountNumber,
    alias,
    bankName,
  }: {
    businessId: string;
    accountNumber: string;
    alias?: string;
    bankName: string;
  }): Wallet => ({
    businessId: +businessId,
    accountNumber,
    alias,
    bankName,
  }),
};

/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import useTracker from '@finance/hooks/useTracker';
import { Typography } from '../../design-system/atoms';
import { useTranslation } from '../../../features/i18n/infra/useTranslation';
import { theme } from '../../design-system/theme';
import infoTooltip from '../../assets/infoTooltip.svg';

export const useStyles = makeStyles(themeStyles => ({
  leftFilterToggleButton: {
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  rightFilterToggleButton: {
    marginRight: themeStyles.spacing(2),
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  activeButton: {
    color: theme.palette.text.light,
    backgroundColor: themeStyles.palette.grey[800],
  },
  inactiveButton: {
    color: themeStyles.palette.text.secondary,
    border: `1px solid ${themeStyles.palette.grey[800]}`,
    backgroundColor: theme.palette.common.white,
  },
  tooltipIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tooltipContent: {
    padding: themeStyles.spacing(1),
  },
}));

type SwitchFilterButtonProps = {
  handleSwitchSelectorButton: (mode: boolean) => void;
  isLeftSelectorActivated: boolean;
};

function SwitchFilterButton({
  handleSwitchSelectorButton,
  isLeftSelectorActivated,
}: SwitchFilterButtonProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { financeCallbacks: trackerCallbacks } = useTracker();

  return (
    <Box display="flex" mt={2}>
      <Box
        onClick={() => {
          handleSwitchSelectorButton(true);
          trackerCallbacks.trackFilterUsed({
            filter_used: t('FINANCEABLE_INVOICES'),
          });
        }}
        fontWeight="fontWeightBold"
        sx={{ borderRadius: '8px 0 0 8px' }}
        px={2}
        py={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={`${classes.leftFilterToggleButton} ${
          isLeftSelectorActivated
            ? classes.activeButton
            : classes.inactiveButton
        }`}
      >
        <Typography
          align="center"
          variant="body3"
          style={{ fontWeight: 'bold' }}
        >
          {t('FINANCEABLE_INVOICES')}
        </Typography>
      </Box>
      <Box
        onClick={() => {
          handleSwitchSelectorButton(false);
          trackerCallbacks.trackFilterUsed({
            filter_used: t('ALL_INVOICES'),
          });
        }}
        sx={{ borderRadius: '0 8px 8px 0' }}
        px={2}
        py={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={`${classes.rightFilterToggleButton} ${
          isLeftSelectorActivated
            ? classes.inactiveButton
            : classes.activeButton
        }`}
      >
        <Typography
          align="center"
          variant="body3"
          style={{ fontWeight: 'bold' }}
        >
          {t('ALL_INVOICES')}
        </Typography>
      </Box>
      <Tooltip
        className={classes.tooltipIcon}
        title={
          <Box className={classes.tooltipContent}>
            <Typography variant="body3">
              <Box fontWeight="fontWeightBold" component="span">
                {t('FINANCEABLE_INVOICES')}:{' '}
              </Box>
              {t('FINANCEABLE_INVOICES_HELP_TEXT')}
            </Typography>
            <Typography variant="body3">
              <Box fontWeight="fontWeightBold" component="span">
                {t('ALL_INVOICES')}:{' '}
              </Box>
              {t('ALL_INVOICES_HELP_TEXT')}
            </Typography>
          </Box>
        }
        placement="top"
        arrow
      >
        <img src={infoTooltip.src} alt="infoTooltip" />
      </Tooltip>
    </Box>
  );
}

export default SwitchFilterButton;

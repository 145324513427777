import { BankAccount } from '@wallet/core';
import {
  WithdrawalZustandStoreType,
  WithdrawalStore,
} from '@wallet/withdrawal';
import { WithdrawalForm } from '@wallet/withdrawal/domain';
import WithdrawalZustandStore from './WithdrawalZustandStore';

export const useWithdrawalStore = (): WithdrawalStore => {
  const bankAccounts = WithdrawalZustandStore(
    (state: WithdrawalZustandStoreType) => state.bankAccounts
  );
  const setBankAccountsStore = WithdrawalZustandStore(
    (state: WithdrawalZustandStoreType) => state.setBankAccounts
  );
  const fetchingBankAccounts = WithdrawalZustandStore(
    (state: WithdrawalZustandStoreType) => state.fetchingBankAccounts
  );
  const setFetchingBankAccountsStore = WithdrawalZustandStore(
    (state: WithdrawalZustandStoreType) => state.setFetchingBankAccounts
  );

  const resetStore = WithdrawalZustandStore(
    (state: WithdrawalZustandStoreType) => state.resetStore
  );

  const withdrawalForm = WithdrawalZustandStore(
    (state: WithdrawalZustandStoreType) => state.withdrawalForm
  );

  const setwithdrawalFormAmountStore = WithdrawalZustandStore(
    (state: WithdrawalZustandStoreType) => state.setwithdrawalFormAmount
  );

  const setwithdrawalFormAccountNumberStore = WithdrawalZustandStore(
    (state: WithdrawalZustandStoreType) => state.setwithdrawalFormAccountNumber
  );

  const setIsAddingBankAccountStore = WithdrawalZustandStore(
    (state: WithdrawalZustandStoreType) => state.setIsAddingBankAccount
  );

  const addBankAccountFromStore = WithdrawalZustandStore(
    (state: WithdrawalZustandStoreType) => state.addBankAccount
  );

  const isAddingBankAccountStore = WithdrawalZustandStore(
    (state: WithdrawalZustandStoreType) => state.isAddingBankAccount
  );

  const resetFormStore = WithdrawalZustandStore(
    (state: WithdrawalZustandStoreType) => state.resetForm
  );

  const getBankAccounts = (): BankAccount[] => {
    return bankAccounts;
  };

  const setBankAccounts = (accounts: BankAccount[]) => {
    setBankAccountsStore(accounts);
  };

  const getFetchingBankAccounts = (): boolean => {
    return fetchingBankAccounts;
  };

  const setFetchingBankAccounts = (isFetching: boolean) => {
    setFetchingBankAccountsStore(isFetching);
  };

  const getWithdrawalForm = (): WithdrawalForm => {
    return withdrawalForm;
  };

  const setWithdrawalFormAmount = (amount: number): void => {
    setwithdrawalFormAmountStore(amount);
  };

  const setWithdrawalFormAccountNumber = (accountNumber: string): void => {
    setwithdrawalFormAccountNumberStore(accountNumber);
  };

  const resetWithdrawalAccountStore = (): void => {
    resetStore();
  };

  const resetForm = (): void => {
    resetFormStore();
  };

  const addBankAccount = (bankAccount: BankAccount) => {
    addBankAccountFromStore(bankAccount);
  };

  const setIsAddingBankAccount = (isFetching: boolean) => {
    setIsAddingBankAccountStore(isFetching);
  };

  const getIsAddingBankAccount = () => {
    return isAddingBankAccountStore;
  };
  return {
    getBankAccounts,
    setBankAccounts,
    getFetchingBankAccounts,
    setFetchingBankAccounts,
    getWithdrawalForm,
    setWithdrawalFormAmount,
    setWithdrawalFormAccountNumber,
    resetWithdrawalAccountStore,
    addBankAccount,
    getIsAddingBankAccount,
    setIsAddingBankAccount,
    resetForm,
  };
};

import React from 'react';
import { Box, Button } from '@material-ui/core';
import Image from 'next/image';
import { Spacer } from '@features/shared';
import { Typography } from 'design-system/atoms';
import { StepperStore } from '@wallet/withdrawal/components/common/Stepper/types';

interface SuccessProps {
  stepperStore?: StepperStore;
}

function Success({ stepperStore }: SuccessProps) {
  const { onFinish } = stepperStore || {};

  const locales = {
    TITLE: 'Transferencia en proceso',
    BODY: 'Estamos llevando a cabo la transacción, en máximo 30 minutos podrás ver el dinero en tu cuenta bancaria. Recibirás el comprobante en tu email de preferencia.',
    CLOSE: 'Entendido',
  };

  return (
    <Box display="flex" textAlign="center" flexDirection="column">
      <Box>
        <Image
          alt="success step"
          width={240}
          height={240}
          src="/assets/successSteppers.png"
          unoptimized
        />
      </Box>
      <Spacer spacing={4} />
      <Typography variant="h6" color="textPrimary">
        {locales.TITLE}
      </Typography>
      <Spacer spacing={2} />
      <Typography variant="body2" color="textSecondary">
        {locales.BODY}
      </Typography>
      <Spacer spacing={8} />
      <Button variant="contained" color="primary" fullWidth onClick={onFinish}>
        {locales.CLOSE}
      </Button>
    </Box>
  );
}

export default Success;

import { Box, Button } from '@material-ui/core';
import { Typography } from 'design-system/atoms';
import Image from 'next/image';

type SubmitedOrderProps = {
  imgSrc: string;
  headerTitle: string;
  subTitle: string | JSX.Element;
  ctaText: string;
  href: string;
};

export function SubmitedOrder({
  imgSrc,
  headerTitle,
  subTitle,
  ctaText,
  href,
}: SubmitedOrderProps) {
  return (
    <Box justifyContent="center" maxWidth={504} pb={5}>
      <Box>
        <Box display="flex" justifyContent="center" pb={5}>
          <Image width={254} height={254} src={imgSrc} unoptimized />
        </Box>
        <Box textAlign="center">
          <Typography variant="h4">{headerTitle}</Typography>
        </Box>
        <Box textAlign="center" pt={2} pb={5}>
          <Typography variant="body2">{subTitle}</Typography>
        </Box>
        <Button
          href={href}
          type="submit"
          variant="contained"
          color="primary"
          style={{ borderRadius: 8, minHeight: 56 }}
          size="large"
          fullWidth
        >
          <Typography variant="body2">{ctaText}</Typography>
        </Button>
      </Box>
    </Box>
  );
}

import { Layout } from 'components/layout';
import React from 'react';
import { FetchBusiness } from '../../../../features/shared/FetchBusiness';
import { useFetchHolidays } from '../../../../features/shared/hooks/useFetchHolidays';
import { CalculatePage } from '../../../../src/pages/calculate';

function Calculate() {
  useFetchHolidays();

  return (
    <FetchBusiness>
      <Layout>
        <CalculatePage />
      </Layout>
    </FetchBusiness>
  );
}

export default Calculate;

import axios from '../../helpers/axios';
import {
  FETCH_BUSINESS_START,
  FETCH_BUSINESS_SUCCESS,
  FETCH_BUSINESS_ERROR,
  NAME_BY_IDENTIFIER_START,
  NAME_BY_IDENTIFIER_SUCCESS,
  NAME_BY_IDENTIFIER_ERROR,
  NAME_BY_IDENTIFIER_RESET,
  UPDATE_BUSINESS_START,
  UPDATE_BUSINESS_SUCCESS,
  UPDATE_BUSINESS_ERROR,
  FETCH_TGR_DEBT_START,
  FETCH_TGR_DEBT_SUCCESS,
  FETCH_TGR_DEBT_ERROR,
} from './types';
import { t13s } from '../../config/translationKeys';
import { enqueueSnackbar } from './notificationActions';

export const fetchBusiness = id => dispatch => {
  dispatch({ type: FETCH_BUSINESS_START });
  axios
    .get(`/api/business/${id}`)
    .then(res => {
      dispatch({
        type: FETCH_BUSINESS_SUCCESS,
        payload: { ...res.data },
      });
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({
        type: FETCH_BUSINESS_ERROR,
        payload: { errorCode },
      });
    });
};

export const getNameByIdentifier = (identifier, countryId) => dispatch => {
  dispatch({ type: NAME_BY_IDENTIFIER_START });
  axios
    .get(
      `/api/taxuser/business/name?identifier=${identifier}&country=${countryId}`
    )
    .then(res => {
      const { name } = res.data;
      dispatch({
        type: NAME_BY_IDENTIFIER_SUCCESS,
        payload: { name: name.trim() },
      });
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({
        type: NAME_BY_IDENTIFIER_ERROR,
        payload: { errorCode },
      });
    });
};

export const getNameByIdentifierReset = () => dispatch => {
  dispatch({ type: NAME_BY_IDENTIFIER_RESET });
};

export const updateBusiness = (businessId, business) => dispatch => {
  dispatch({ type: UPDATE_BUSINESS_START });
  axios
    .put(`/api/business/${businessId}`, { ...business })
    .then(res => {
      const resData = res.data;
      dispatch({
        type: UPDATE_BUSINESS_SUCCESS,
        payload: { business: resData },
      });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.UPDATE_BUSINESS_SUCCESS,
          options: {
            variant: 'success',
          },
        })
      );
    })
    .catch(err => {
      const errorCode = err?.response?.data?.errorCode;
      dispatch({ type: UPDATE_BUSINESS_ERROR, payload: { errorCode } });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.UPDATE_BUSINESS_ERROR,
          options: {
            variant: 'error',
          },
        })
      );
    });
};

export const fetchTgrDebt = businessId => dispatch => {
  dispatch({ type: FETCH_TGR_DEBT_START });
  axios
    .get(`/api/business/${businessId}/tgr-debt`)
    .then(res => {
      const resData = res.data;
      dispatch({
        type: FETCH_TGR_DEBT_SUCCESS,
        payload: { tgrDebt: resData },
      });
    })
    .catch(err => {
      const errorCode = err?.response?.data?.errorCode;
      dispatch({ type: FETCH_TGR_DEBT_ERROR, payload: { errorCode } });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.FETCH_TGR_DEBT_ERROR,
          options: {
            variant: 'error',
          },
        })
      );
    });
};

import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTheme, Breadcrumbs, Typography } from '@material-ui/core';
import { useBackListener } from '@finance/hooks/migration/useBackListener';
import useTracker from '@finance/hooks/useTracker';
import { Link } from '../../../../../features/shared';
import { useSpacingStyles } from '../../../../features/shared/styles/spacings';
import ArrowRight from '../../../../assets/arrow-right.svg';
import {
  DIRECT_FINANCING_LABEL,
  EARLY_PAYMENT_LABEL,
} from '../../../../helpers/constants';
import { urls } from '../../../../../features/libs/urls';
import { useTranslation } from '../../../../../features/i18n/infra/useTranslation';

const titles: Record<string, string> = {
  directo: DIRECT_FINANCING_LABEL,
  prontopago: EARLY_PAYMENT_LABEL,
};

enum Buttons {
  navigator = 'Back button navigator',
  breadcrumb = 'Back button in breadcrumb',
}

export function BreadcrumbsFinance() {
  const theme = useTheme();

  const router = useRouter();

  const spacing = useSpacingStyles();

  const { t } = useTranslation();

  const { financeCallbacks } = useTracker();

  useBackListener({
    onBack: () => {
      financeCallbacks.trackBackToStepOne(Buttons.navigator);
    },
    urls: [`${urls.directFinance}/`, `${urls.earlyPayment}/`],
  });

  const prevQuery = router.query.tab as string;

  const prevPage = titles[prevQuery];

  return (
    <Breadcrumbs
      className={`${spacing.mb3} ${spacing.mt4}`}
      separator={
        <Image
          src={ArrowRight}
          unoptimized
          layout="intrinsic"
          alt="arrow-right"
          width={5.33}
          height={9.33}
          color={theme.palette.grey['500']}
        />
      }
    >
      <Link href={`/finance/${prevQuery}`}>
        <Typography
          variant="body2"
          color="textSecondary"
          onClick={() =>
            financeCallbacks.trackBackToStepOne(Buttons.breadcrumb)
          }
        >
          {prevPage}
        </Typography>
      </Link>
      <Typography variant="body2" color="textPrimary">
        {t('INVOICES_SELECTED_BREADCRUMB_STEP_2')}
      </Typography>
    </Breadcrumbs>
  );
}

import React from 'react';
import { Backdrop, Fade, Modal, MuiThemeProvider } from '@material-ui/core';
import { theme } from '@features/auth/ui/common/theme/theme';
import { useStyles } from './styles';

export interface FullScreenDialogProps {
  open: boolean;
  children: React.ReactNode;
}

function FullScreenDialog({ open, children }: FullScreenDialogProps) {
  const classes = useStyles();
  return (
    <MuiThemeProvider theme={theme}>
      <Modal
        disableEnforceFocus
        disableAutoFocus
        closeAfterTransition
        disablePortal
        className={classes.modal}
        open={open}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade style={{ padding: 0 }} in={open}>
          <div className={classes.paper}>{children}</div>
        </Fade>
      </Modal>
    </MuiThemeProvider>
  );
}

export default FullScreenDialog;

import { urls } from '@features/libs/urls';

const publicPaths = new Set([
  urls.login,
  urls.register,
  urls.forgotPassword,
  urls.authLogin,
  urls.authRegister,
  '/start/register',
  '/start/login',
]);

export default publicPaths;

import { createStyles, makeStyles } from '@material-ui/core';

const styles = makeStyles(theme =>
  createStyles({
    widthMenu: {
      minWidth: 180,
    },
    button: {
      height: 40,
      borderRadius: 8,
      fontSize: theme.typography.body2.fontSize,
    },
  })
);

export default styles;

import { urls } from '../../libs/urls';
import { RouteRequirementsUseCase } from '../domain/RouteRequirementsUseCase';

export const maintenanceRequirementsUseCase: RouteRequirementsUseCase = (
  url,
  options
) => {
  const { user, isMaintenance } = options;
  if (isMaintenance) {
    if (url === urls.maintenance) return null;
    if (!user.status.isRehydrated || user.status.isAuthenticated) return null;
    return urls.maintenance;
  }
  if (url === urls.maintenance) return urls.login;

  return null;
};

import { useBusiness } from '@payments/store/business';
import { useWallet } from '@wallet/account';
import { StepperStore } from '@wallet/withdrawal/components/common/Stepper/types';
import { useWithdrawalStore } from '@wallet/withdrawal/infraestructure/store';

const useAmount = (stepperStore: StepperStore) => {
  const { balance } = useWallet();
  const { countryId } = useBusiness();
  const { getWithdrawalForm, setWithdrawalFormAmount } = useWithdrawalStore();
  const { goNext, currentStepPosition, totalSteps } = stepperStore || {};

  const amountRules = {
    required: 'El monto es requerido',
    max: {
      value: balance,
      message: 'Saldo insuficiente',
    },
    min: {
      value: 0,
      message: 'Valor debe ser mayor a 0',
    },
  };

  const onSubmit = (): void => {
    goNext();
  };

  const { amount } = getWithdrawalForm();

  return {
    amount,
    amountRules,
    balance,
    countryId,
    currentStepPosition,
    totalSteps,
    onSubmit,
    goNext,
    setAmount: setWithdrawalFormAmount,
  };
};

export default useAmount;

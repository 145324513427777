import React from 'react';
import { Typography } from 'design-system/atoms';
import { Box, Card } from '@material-ui/core';
import { esES } from '@material-ui/data-grid';
import { useInvoiceByClients } from '@finance/hooks/migration/useInvoicesByClients';
import Loader from 'components/elements/CircularProgressWithLabel';
import { useSpacingStyles } from '../../../../features/shared/styles/spacings';
import Table from '../../../../components/elements/tables/Table';
import { useStyles } from './tables-by-clients-styles';
import { TablesByClientProps } from './tables-by-clients-props';

export function TablesByClient({ invoices, state }: TablesByClientProps) {
  const spacing = useSpacingStyles();
  const classes = useStyles();

  const { data, isLoading: isLoadingByClients } = useInvoiceByClients({
    invoices,
  });

  const isLoading = [state.isLoadingInvoices, isLoadingByClients].includes(
    true
  );

  if (isLoading)
    return (
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Loader />
      </Box>
    );

  return (
    <Box>
      {data?.map(item => {
        const { payerIdentifier } = item.rows[0];

        return (
          <Card className={spacing.mb4}>
            <Typography
              variant="body1"
              className={`${spacing.px4} ${spacing.mt2}`}
            >
              {item.client}
            </Typography>
            <Typography
              variant="body2"
              className={`${spacing.px4} ${spacing.mt2} ${spacing.p1}`}
            >
              {payerIdentifier}
            </Typography>

            <Table
              className={`${classes.MuiDataGrid} ${classes.tableContainer} ${spacing.p3}`}
              columns={state?.columns}
              rows={item.rows}
              localeText={(esES as any).props.MuiDataGrid.localeText}
              hideFooterPagination
            />
          </Card>
        );
      })}
    </Box>
  );
}

import { makeStyles } from '@material-ui/core';

export const useSplashScreenStyles = makeStyles({
  mainContainer: {
    height: 'calc(100vh - 64px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
});

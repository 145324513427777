import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { xTheme } from '@xepelinapp/x-ds';

interface CloseOutlinedIconProps {
  width?: string;
  height?: string;
  line?: string;
  background?: string;
  onClick?: () => void;
}

function CloseOutlinedIcon({
  width = '24',
  height = '24',
  line = xTheme.common.white,
  background = xTheme.error.main,
  onClick,
}: CloseOutlinedIconProps): JSX.Element {
  return (
    <SvgIcon
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      cursor={onClick ? 'pointer' : 'inherit'}
    >
      <circle cx="12" cy="12" r="12" fill={background} />
      <path
        d="M12 11.0002L15.5002 7.5L16.5 8.49984L12.9998 12L16.5 15.5002L15.5002 16.5L12 12.9998L8.49984 16.5L7.5 15.5002L11.0002 12L7.5 8.49984L8.49984 7.5L12 11.0002Z"
        fill={line}
      />
    </SvgIcon>
  );
}

export default CloseOutlinedIcon;

import moment from 'moment';
import { MINIMUM_FINANCEABLE_DAYS } from './constants';

export const getFirstBusinessDateFrom = (startDate, holidays = []) => {
  const isHoliday = holidays.some(h => {
    return startDate.toDate().toISOString() === h.date;
  });
  if (
    startDate.utc().isoWeekday() !== 6 &&
    startDate.utc().isoWeekday() !== 7 &&
    !isHoliday
  ) {
    return startDate;
  }
  return getFirstBusinessDateFrom(startDate.add(1, 'day'));
};

export const isFinanceableDateFromTerm = (
  issueDate,
  suggestedPaymentTerm,
  holidays
) => {
  const termBusinessDate = getFirstBusinessDateFrom(
    moment.utc(issueDate).add(suggestedPaymentTerm, 'days'),
    holidays
  );
  const minimumFinanceableDate = getFirstBusinessDateFrom(
    moment.utc(moment()).add(MINIMUM_FINANCEABLE_DAYS, 'days')
  );
  return termBusinessDate >= minimumFinanceableDate;
};

export const isWeekend = date => {
  const dayOfWeek = date.format('E');
  return dayOfWeek === '6' || dayOfWeek === '7';
};

export const getWeekendDays = (now, businessDays) => {
  return new Array(businessDays)
    .fill(now)
    .map((date, index) => {
      const newDate = date.clone();
      return newDate.add(index, 'days');
    })
    .reduce((acc, date) => {
      if (isWeekend(date)) {
        return acc + 1;
      }
      return acc;
    }, 0);
};

export const blockBusinessDays = (date, days) => {
  const businessDays = days;
  const now = moment().startOf('day');
  const weekendDays = getWeekendDays(now, businessDays);
  // only take in account one weekends
  const offset = Math.ceil(weekendDays / 2) * 2;

  const daysDiff = date.diff(now, 'days');
  return daysDiff < businessDays + offset;
};

export const dateWithoutFormatter = date => {
  return date ? moment(date).utc() : null;
};

export const isTodayDate = date => {
  return moment.utc(date).isSame(moment.utc(), 'day');
};

export const formatToReadableDate = (date, readableDateFormat) => {
  const readableDate = moment.utc(date).format(readableDateFormat);
  return isTodayDate(date) ? `Hoy, ${readableDate}` : readableDate;
};

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    growthUTM: string;
  }
}

export const utmTracker = () => {
  const link = 'https://gth.xepel.in';
  const messageListener = document.querySelector('message');
  const handleUTMMessage = message => {
    if (link === message.origin) {
      window.growthUTM = JSON.parse(message.data);
    }
  };
  messageListener?.addEventListener('message', handleUTMMessage);

  const iframe = document.createElement('iframe');
  iframe.src = `${link}/utm/utm.htm${window.location.search}`;
  iframe.setAttribute(
    'style',
    'border: none;width: 0;height: 0;display: block;'
  );
  document.body.insertBefore(iframe, document.body.firstChild);
};

import { ONBOARDING_STEP_CASES } from 'helpers/constants';

const addCredentailsStep = ONBOARDING_STEP_CASES.ADD_TAX_CREDENTIALS;

let resultCache: boolean = false;

export const verifyHistory = ({
  hasInvoiceHistory,
  hasInvoices,
  onboardingStep,
}: {
  hasInvoiceHistory: boolean;
  hasInvoices: boolean;
  onboardingStep: string;
}): boolean => {
  if (resultCache) return resultCache;

  resultCache =
    !hasInvoiceHistory && hasInvoices && onboardingStep !== addCredentailsStep;

  return resultCache;
};

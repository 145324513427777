import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers/rootReducer';
import { useOperationType } from '@finance/hooks/migration/useOperationType';
import { OperationLineValues } from '@finance/useCases/OperationLineValues.type';
import { simulationService } from '../services/simulationService/simualationService';
import { Invoice } from '../services/invoicesServicesTypes';

type DataMutation = {
  simulation: OperationLineValues;
  selectedInvoices: Invoice[];
};

const useSimulation = ({
  onSuccess,
  onError,
}: {
  onSuccess: (newSimulation: OperationLineValues, selected: Invoice[]) => void;
  onError: (error: unknown) => void;
}) => {
  const operationType = useOperationType();

  const { id: businessId } = useSelector(
    (state: RootState) => state.businessState.business
  );

  const mutation = useMutation<DataMutation, unknown, Invoice[]>(
    async selectedInvoices => {
      const simulation = await simulationService({
        businessId,
        orderType: operationType,
        invoices: selectedInvoices,
      });

      return { simulation, selectedInvoices };
    },
    {
      mutationKey: useSimulation.name,
      onSuccess: ({ simulation, selectedInvoices }) => {
        onSuccess(simulation, selectedInvoices);
      },
      onError,
    }
  );

  return mutation;
};

export default useSimulation;

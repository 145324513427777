import { Container, MuiThemeProvider, makeStyles } from '@material-ui/core';

import { TopbarPrivate, TopbarPublic } from 'components/layout/navigation';
import { usePrefetchHomeHook } from '@features/auth/adapters/common/usePrefetchHomeHook';
import { theme as saasTheme } from '../../../features/auth/ui/common/theme/theme';
import { utmTracker } from '../../../features/libs/utmTracker';

interface AuthEmptyLayoutProps {
  children: React.ReactNode;
  publicTopBar?: boolean;
}

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    paddingTop: 64,
    backgroundColor: theme.palette.common.white,
  },
}));

function AuthEmptyLayout({ children, publicTopBar }: AuthEmptyLayoutProps) {
  const classes = useStyles();
  utmTracker();
  usePrefetchHomeHook();
  const Topbar = publicTopBar ? <TopbarPublic /> : <TopbarPrivate />;

  return (
    <MuiThemeProvider theme={saasTheme}>
      {Topbar}
      <Container disableGutters maxWidth={false} className={classes.container}>
        {children}
      </Container>
    </MuiThemeProvider>
  );
}

AuthEmptyLayout.defaultProps = {
  publicTopBar: false,
};

export default AuthEmptyLayout;

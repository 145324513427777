import { BankAccount, BankAccountState } from '@wallet/core';
import { WithdrawalRepository, WithdrawalStore } from '@wallet/withdrawal';

const createBankAccountUseCase = async ({
  businessId,
  bankAccount,
  withdrawalRepository,
  withdrawalStore,
  notifyCreateWithdrawalAccountError,
}: {
  businessId: number;
  bankAccount: BankAccount;
  withdrawalRepository: WithdrawalRepository;
  withdrawalStore: WithdrawalStore;
  notifyCreateWithdrawalAccountError: () => void;
}) => {
  try {
    withdrawalStore.setIsAddingBankAccount(true);
    const bankAccountCreated = await withdrawalRepository.createBankAccount(
      businessId,
      bankAccount
    );
    bankAccountCreated.state = BankAccountState.VERIFYING;
    withdrawalStore.addBankAccount(bankAccountCreated);
    return bankAccount;
  } catch (error) {
    notifyCreateWithdrawalAccountError();
    return false;
  } finally {
    withdrawalStore.setIsAddingBankAccount(false);
  }
};

export default createBankAccountUseCase;

/* eslint-disable no-param-reassign */
import create from 'zustand';
import { pipe } from 'ramda';
import { persist, devtools } from 'zustand/middleware';
import {
  HandleSelectionChangeParams,
  InvoiceSelectionState,
  SetSelectionParams,
} from './types';
import { PaymentInvoice } from '../../../types';

const STORE_NAME = 'InvoiceSelectionStore';

const persistence = config =>
  persist(config, {
    name: STORE_NAME,
  });

const createStore = pipe(persistence, devtools, create);

const InvoiceSelectionStore = createStore(set => ({
  selectedInvoices: [],
  totalSelectedInvoices: 0,

  setSelection: ({ invoices }: SetSelectionParams): void =>
    set(() => ({
      selectedInvoices: invoices,
      totalSelectedInvoices: invoices.reduce(
        (acc, invoice) => acc + Number(invoice.amount),
        0
      ),
    })),

  setInvoiceWithDiscount: ({ invoiceForDiscount, applyDiscount }) =>
    set((state: InvoiceSelectionState) => {
      const invoiceWithDiscount = {
        ...invoiceForDiscount,
        applyDiscount,
      };
      const selectedInvoices = [
        ...state.selectedInvoices.filter(
          (invoice: PaymentInvoice) => invoice.id !== invoiceForDiscount.id
        ),
        invoiceWithDiscount,
      ];

      const totalSelectedInvoices = state.totalSelectedInvoices - applyDiscount;

      return {
        selectedInvoices,
        totalSelectedInvoices,
      };
    }),

  clearSelection: (): void =>
    set(() => ({
      selectedInvoices: [],
      totalSelectedInvoices: 0,
    })),

  handleSelectionChange: ({
    checkedInvoices,
    nonCheckedInvoices,
  }: HandleSelectionChangeParams): void =>
    set((state: InvoiceSelectionState) => {
      const selectedIds = state.selectedInvoices.map(invoice => invoice.id);
      const nonCheckedIds = nonCheckedInvoices.map(invoice => invoice.id);

      const selectedInvoices = [
        ...state.selectedInvoices.filter(
          (invoice: PaymentInvoice) => !nonCheckedIds.includes(invoice.id)
        ),
        ...checkedInvoices.filter(invoice => !selectedIds.includes(invoice.id)),
      ];

      const totalSelectedInvoices = selectedInvoices.reduce(
        (acc, invoice) => acc + Number(invoice.amount),
        0
      );

      return {
        selectedInvoices,
        totalSelectedInvoices,
      };
    }),
}));

export default InvoiceSelectionStore;

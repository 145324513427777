import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@features/i18n/infra/useTranslation';
import { RootState } from 'store/reducers/rootReducer';
import { GridFilterModel } from '@material-ui/data-grid';
import { Invoice } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';
import useGetInvoices from '@integration-services/xepelin-server-global/invoices/hooks/useGetInvoices';
import { hasAllSelected } from '@finance/utils/hasAllSelected';
import * as selectedCache from '@finance/services/selectedCache';
import { useSelectionInvoiceStore } from '../../../stores/useSelectionInvoiceStore';
import { useManageFilters } from '../useManageFilters';
import { createSortingItems } from './createSortingItems';
import { createColumns } from './createColumns';
import { useInvoicesFromStorage } from '../useInvoicesFromStorage';

export const useInvoiceSelection = () => {
  const { t } = useTranslation();

  const { countryId, id: businessId } = useSelector(
    (state: RootState) => state.businessState.business
  );

  const holidays = useSelector(
    (state: RootState) => state.holidaysState.holidays
  );

  const {
    filter,
    handleFilterChange,
    handlePageChange,
    currentPage,
    pageSize,
    handlePageSize,
    sorting,
    allInvoices,
    handleSortingChange,
    resetTableStates,
    handleAllInvoices,
    handleChangeAllInvoices,
    clearInputRemote,
    resetFilter,
  } = useManageFilters();

  const {
    selectedInvoices,
    totalSelectedCartInvoices,
    setSelectedInvoices,
    setSelectedAll,
    resetSelection,
    setInvoicesFromStorage,
  } = useSelectionInvoiceStore();

  useEffect(() => {
    return () => {
      selectedCache.deleteInvoices();
    };
  }, []);

  const { data } = useGetInvoices();

  useInvoicesFromStorage({
    onSuccess: invoicesFromStorage => {
      setInvoicesFromStorage(invoicesFromStorage);
    },
  });

  const handleFilterModelChange = (filterModel: GridFilterModel) => {
    handleFilterChange(filterModel.items[0].value);
  };

  const onSelectOne = (invoice: Invoice) => {
    setSelectedInvoices(invoice);
  };

  const selectionModel = selectedInvoices.map(({ id }) => id);

  const allSelected = hasAllSelected(data?.data, selectionModel);

  const onSelectAll = () => {
    setSelectedAll(data?.data, allSelected);
  };

  const sortingItems = createSortingItems({ t });

  const columns = createColumns({
    t,
    countryId,
    allInvoices: [allInvoices, Boolean(filter)].includes(true),
    selectionModel,
    allSelected,
    onSelectAll,
    onSelectOne,
  });

  return {
    state: {
      currentPage,
      pageSize,
      filter,
      sorting: { selectedValue: sorting, sortingItems },
      columns,
      countryId,
      businessId,
      holidays,
      selectedInvoices,
      totalSelectedCartInvoices,
    },
    callbacks: {
      handleFilterChange,
      setCurrentPage: handlePageChange,
      setSelectedInvoices,
      handleSortingChange,
      resetTableStates,
      resetSelection,
      handleAllInvoices,
      handleChangeAllInvoices,
      clearInputRemote,
      resetFilter,
    },
    tableCallbacks: {
      onPageChange: handlePageChange,
      onFilterModelChange: handleFilterModelChange,
      onPageSizeChange: handlePageSize,
    },
  };
};

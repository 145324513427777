import Panel from 'components/elements/Panel';
import CreationOperationResume from 'components/elements/CreationOperationResume';
import { LoadingButton } from '@features/shared';
import { useTranslation } from '@features/i18n/infra/useTranslation';
import { OperationResumeProps } from './operation-resume-props';

export function OperationResume({
  operationType,
  simulation,
  disabledLoadingButton,
  isLoading,
  onClick,
}: OperationResumeProps) {
  const { t } = useTranslation();

  return (
    <Panel
      title={t('OPERATION_RESUME_TITLE')}
      titleAlign="center"
      titlePadding="lg lg zero"
      contentPadding="sm lg lg"
    >
      <CreationOperationResume
        operationType={operationType}
        simulation={simulation}
        showButton
        buttonComponent={
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={disabledLoadingButton}
            isLoading={isLoading}
            onClick={onClick}
            fullWidth
            data-qa="operation-resume-loading-button-action"
          >
            {t('OPERATION_RESUME_REQUEST_OPERATION')}
          </LoadingButton>
        }
      />
    </Panel>
  );
}

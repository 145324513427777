export enum CreditLineStatus {
  NOT_REQUESTED = 'NOT_REQUESTED',
  IN_REVIEW = 'IN_REVIEW',
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  INACTIVE = 'INACTIVE',
  REJECTED = 'REJECTED',
}

export type CreditLine = {
  available: number;
  used: number;
  total: number;
  status: CreditLineStatus;
};

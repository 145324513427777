import { useTranslation } from '@features/i18n/infra/useTranslation';
import { useSelector } from 'react-redux';
import { useBusiness } from '@payments/store/business';
import { BankAccount, BankAccountType, Currency } from '@wallet/core';
import { createBankAccountUseCase } from '@wallet/withdrawal/useCases/';
import { useWithdrawalStore } from '@wallet/withdrawal/infraestructure/store';
import { useWithdrawalRepository } from '@wallet/withdrawal/infraestructure/repository';
import { useCreateWithdrawalAccountService } from '@wallet/withdrawal/infraestructure';
import { useEmailRules } from '../../../../../helpers/validations/rules/useEmailRules';
import { validateAccountNumber } from '../../../../../helpers/validations/accountNumber';

const useAccount = ({ stepperStore }) => {
  const { t } = useTranslation();
  const { goNext } = stepperStore || {};
  const { countryId, identifier, businessId } = useBusiness();
  const { required: requiredEmail, validate: validateEmail } = useEmailRules();
  const withdrawalRepository = useWithdrawalRepository();
  const withdrawalStore = useWithdrawalStore();
  const isAddingBankAccount = withdrawalStore.getIsAddingBankAccount();
  const { notifyCreateWithdrawalAccountError } =
    useCreateWithdrawalAccountService();

  const { name } = useSelector(
    (state: { businessState: any }) => state.businessState?.business
  );
  const { banks } = useSelector(
    (state: { businessBankAccountsState: any }) =>
      state.businessBankAccountsState
  );
  const { email: userEmail } = useSelector(
    (state: { authState: any }) => state.authState?.user
  );

  const accountRules = {
    required: t('INPUT_ERROR_ACCOUNT_NUMBER'),
    validate: {
      validateUserIdentifier: accountNumber =>
        validateAccountNumber(accountNumber, countryId) ||
        t('INPUT_ERROR_ACCOUNT_NUMBER'),
    },
  };
  const emailRules = {
    required: requiredEmail,
    validate: {
      validateUserEmail: email =>
        (email && validateEmail(email)) || t('ERROR_INVALID_EMAIL'),
    },
  };
  const bankRules = {
    required: 'Debes seleccionar el banco',
  };

  const onSubmit = async ({ accountNumber, email, bankId, alias }) => {
    const bankAccount: BankAccount = {
      bankId,
      accountType: BankAccountType.CUENTA_CLABE,
      accountNumber,
      sourceIdentifier: identifier.toString(),
      accountHolder: name,
      accountEmail: email,
      alias,
      currency: Currency.MXN,
      state: undefined,
    };

    await createBankAccountUseCase({
      businessId,
      bankAccount,
      withdrawalRepository,
      withdrawalStore,
      notifyCreateWithdrawalAccountError,
    }).then(result => {
      if (result) {
        goNext();
      }
    });
  };

  return {
    t,
    accountRules,
    emailRules,
    bankRules,
    identifier,
    onSubmit,
    banks,
    userEmail,
    isAddingBankAccount,
  };
};

export default useAccount;

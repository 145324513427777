import React from 'react';
import { styled, SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const AddItemIcon = props => {
  const { style } = props;
  return (
    <CustomSvgIcon
      style={style}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 5C9.45 5 9 5.45 9 6V9H6C5.45 9 5 9.45 5 10C5 10.55 5.45 11 6 11H9V14C9 14.55 9.45 15 10 15C10.55 15 11 14.55 11 14V11H14C14.55 11 15 10.55 15 10C15 9.45 14.55 9 14 9H11V6C11 5.45 10.55 5 10 5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
        fill="url(#paint0_linear_115_13644)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_115_13644"
          x1="11.4806"
          y1="18.7621"
          x2="13.218"
          y2="18.664"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9732D" />
          <stop offset="0.470306" stopColor="#DF5B5B" />
          <stop offset="0.981571" stopColor="#C721DF" />
        </linearGradient>
      </defs>
    </CustomSvgIcon>
  );
};

AddItemIcon.defaultProps = {
  style: {},
};

AddItemIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default AddItemIcon;

import React from 'react';
import { styled, SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { xTheme } from '@xepelinapp/x-ds';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const ArrowUpIcon = props => {
  const { style } = props;
  return (
    <CustomSvgIcon style={style} width="12" height="7" viewBox="0 0 12 7">
      <path
        d="M9.54739 6.23187L5.99073 2.67521L2.43406 6.23187C2.07656 6.58938 1.49906 6.58938 1.14156 6.23187C0.784056 5.87437 0.784056 5.29687 1.14156 4.93937L5.34906 0.731872C5.70656 0.374372 6.28406 0.374372 6.64156 0.731872L10.8491 4.93937C11.2066 5.29687 11.2066 5.87437 10.8491 6.23187C10.4916 6.58021 9.9049 6.58938 9.54739 6.23187V6.23187Z"
        fill={style.fill ?? xTheme.grey[900]}
      />
    </CustomSvgIcon>
  );
};

ArrowUpIcon.defaultProps = {
  style: {},
};

ArrowUpIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default ArrowUpIcon;

import { Box, Tooltip } from '@material-ui/core';
import { Typography } from 'design-system/atoms';
import Icon from 'design-system/icons/Icon';
import { faExclamationSquare } from '@fortawesome/pro-regular-svg-icons';
import { CreditLine } from '@integration-services/xepelin-server-global/credit-balance/types/creditBalance.type';

const creditLineStatusToLocalesMap = {
  CANCELLED: {
    tooltipTitle:
      'Tu línea de crédito se encuenta inhabilitada por el momento. Ponte en contacto con tu ejecutivo a través del chat de tu plataforma',
    label: 'Inhabilitado',
  },
};

function CreditLineAlertTooltip({
  creditLineStatus,
}: {
  creditLineStatus: CreditLine['status'];
}) {
  if (!['CANCELLED'].includes(creditLineStatus)) {
    return null;
  }

  return (
    <Tooltip
      title={
        <Typography variant="body3">
          {creditLineStatusToLocalesMap[creditLineStatus].tooltipTitle}
        </Typography>
      }
      arrow
    >
      <Box color="error.main" component="span" display="flex" marginLeft={1}>
        <Box marginRight={0.5} display="flex">
          <Icon fontSize="small" iconDefinition={faExclamationSquare} />
        </Box>
        <Typography component="span" variant="body3">
          {creditLineStatusToLocalesMap[creditLineStatus].label}
        </Typography>
      </Box>
    </Tooltip>
  );
}

export default CreditLineAlertTooltip;

import { Invoice } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';
import { GetInvoicesByClientsProps } from '@finance/components/tables-by-clients/tables-by-clients-props';

export async function getInvoicesByClients({
  invoices,
}: {
  invoices: Invoice[];
}): Promise<GetInvoicesByClientsProps[] | null> {
  if (!invoices?.length) return null;
  const reduction = invoices?.reduce((acc, item) => {
    const previousInvoices = acc[item.payerName]?.rows;

    return {
      ...acc,
      [item.payerName]: {
        ...acc[item.payerName],
        client: item.payerName,
        rows: previousInvoices ? [...previousInvoices, item] : [item],
      },
    };
  }, {});

  return Object.values(reduction);
}

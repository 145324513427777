import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  bannerBackground: {
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.info.light,
    backgroundImage: `url(${'/assets/finance/tubos2.png'})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
  },
}));

import React from 'react';
import { styled, SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';
import { xTheme } from '@xepelinapp/x-ds';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const BulkUploadFolderIcon = props => {
  const { style } = props;
  return (
    <CustomSvgIcon
      width="38"
      height="28"
      viewBox="0 0 38 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M35.6034 4.37528V27.4416H2.19922V2.23611C2.19922 1.67103 2.42369 1.1291 2.82327 0.729524C3.22284 0.329951 3.76478 0.105469 4.32986 0.105469H11.1181C11.6832 0.105469 12.2251 0.329951 12.6247 0.729524C13.0243 1.1291 13.2487 1.67103 13.2487 2.23611H33.4941C34.057 2.24174 34.5947 2.46987 34.9899 2.87067C35.3852 3.27148 35.6057 3.8124 35.6034 4.37528V4.37528Z"
        fill="#F0C634"
      />
      <path
        d="M34.0772 3.71875H3.89453V25.7411H34.0772V3.71875Z"
        fill="#C4A32F"
      />
      <path
        opacity="0.1"
        d="M35.6034 10.6914V25.7465H2.19922V12.6047L16.5001 12.5408L17.1009 11.8292C17.4004 11.473 17.7741 11.1866 18.1959 10.9901C18.6177 10.7935 19.0773 10.6916 19.5426 10.6914H35.6034Z"
        fill={xTheme.common.black}
      />
      <path
        d="M35.6019 27.4386H2.19768L0.305665 14.9147C0.2812 14.7514 0.292179 14.5847 0.337851 14.426C0.383523 14.2673 0.462816 14.1203 0.57035 13.995C0.677885 13.8697 0.81114 13.769 0.961055 13.6997C1.11097 13.6305 1.27403 13.5943 1.43917 13.5937L15.2926 13.5298C15.6692 13.5281 16.0406 13.4423 16.3798 13.2787C16.7189 13.115 17.0172 12.8776 17.2528 12.5838L17.3124 12.5071C17.5437 12.2171 17.8374 11.983 18.1716 11.8222C18.5058 11.6613 18.8719 11.5779 19.2428 11.5781H36.0749C36.3132 11.5785 36.5487 11.6303 36.7652 11.73C36.9817 11.8296 37.1742 11.9748 37.3295 12.1556C37.4848 12.3364 37.5993 12.5485 37.6652 12.7776C37.7311 13.0067 37.7468 13.2472 37.7112 13.4829L35.6019 27.4386Z"
        fill="#F2C738"
      />
    </CustomSvgIcon>
  );
};

BulkUploadFolderIcon.defaultProps = {
  style: {},
};

BulkUploadFolderIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default BulkUploadFolderIcon;

import { useState } from 'react';
import { useWithdrawalStore } from '@wallet/withdrawal/infraestructure/store';
import { useSelector } from 'react-redux';
import { Bank, BankAccountState } from '@wallet/core';
import { StepperStore } from '@wallet/withdrawal/components/common/Stepper/types';

const ACCOUNT_DEFAULT_BANK_NAME_MAX_CARACTERES = 25;
const ACCOUNT_IN_REVIEW_BANK_NAME_MAX_CARACTERES = 16;

const useAccount = (stepperStore: StepperStore) => {
  const { goNext, currentStepPosition, totalSteps } = stepperStore || {};
  const { getBankAccounts, getWithdrawalForm, setWithdrawalFormAccountNumber } =
    useWithdrawalStore();
  const { accountNumber } = getWithdrawalForm();

  const { banks } = useSelector(
    (state: { businessBankAccountsState: { banks: Bank[] } }) =>
      state.businessBankAccountsState
  );

  const [
    showCreateWithdrawalAccountDialog,
    setShowCreateWithdrawalAccountDialogStore,
  ] = useState<boolean>(false);

  const availableBankAccount = getBankAccounts()?.filter(
    account => !account.isXepelin
  );

  const setShowCreateWithdrawalAccountDialog = (shouldShow: boolean): void => {
    setShowCreateWithdrawalAccountDialogStore(shouldShow);
  };

  const onSubmit = (): void => {
    // TODO: Use useCase to generate the withdrawal
    goNext();
  };

  const truncateBankName = (
    bankName: string,
    accountState: BankAccountState
  ): string => {
    if (!bankName) return '';
    const maxCaracteres =
      accountState !== BankAccountState.VERIFYING
        ? ACCOUNT_DEFAULT_BANK_NAME_MAX_CARACTERES
        : ACCOUNT_IN_REVIEW_BANK_NAME_MAX_CARACTERES;
    return bankName.length > maxCaracteres
      ? bankName.substring(0, maxCaracteres - 4).concat('...')
      : bankName;
  };

  return {
    banks,
    existsBankAccounts: !!availableBankAccount?.length,
    bankAccounts: availableBankAccount,
    currentStepPosition,
    showCreateWithdrawalAccountDialog,
    selectedAccountNumber: accountNumber,
    totalSteps,
    setShowCreateWithdrawalAccountDialog,
    setSelectedAccountNumber: setWithdrawalFormAccountNumber,
    onSubmit,
    truncateBankName,
    goNext,
  };
};

export default useAccount;

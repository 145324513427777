import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';

import { useMobile } from 'hooks';
import { NextLinkComposed, Spacer } from '../../features/shared';
import CircularProgressWithLabel from '../../features/shared/CircularProgressWithLabel';
import CustomStepper from '../../features/shared/CustomStepper';
import { Typography } from '../design-system/atoms';
import { Container } from '../design-system/atoms/layout';
import useOnboardingStepper from './useOnboardingStepper';

interface ICustomMobileStepperProps {
  activeStep: number;
  steps: string[];
}

function CustomMobileStepper({ activeStep, steps }: ICustomMobileStepperProps) {
  const currentStep = activeStep;
  const totalSteps = steps.length;
  const stepsProgressLabel = `${currentStep}/${totalSteps}`;
  const progressPercentage = (currentStep * 100) / totalSteps;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      flexGrow={1}
    >
      <CircularProgressWithLabel
        value={progressPercentage}
        label={stepsProgressLabel}
      />
      <Spacer spacing={0.5} />
      <Typography component="div" variant="body3" color="textSecondary">
        <Box fontWeight="fontWeightMedium">{steps[activeStep]}</Box>
      </Typography>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundImage: 'url(/assets/onboarding_banner_desktop.jpeg)',
      [theme.breakpoints.down('xs')]: {
        backgroundImage: 'url(/assets/onboarding_banner_mobile.jpeg)',
      },
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(3),
      border: `1px solid ${theme.palette.grey[200]}`,
    },
  })
);

function OnboardingBanner() {
  const classes = useStyles();
  const isMobile = useMobile();

  const { activeStep, steps, stepContent } = useOnboardingStepper();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      {isMobile ? (
        <CustomMobileStepper activeStep={activeStep} steps={steps} />
      ) : (
        <Container maxWidth="sm" disableGutters>
          <CustomStepper activeStep={activeStep} steps={steps} />
        </Container>
      )}

      <Spacer spacing={isMobile ? 1 : 3} />

      <Typography component="div" variant="body1" align="center">
        <Box fontWeight="fontWeightMedium">
          {stepContent[activeStep].description}
        </Box>
      </Typography>
      <Spacer spacing={2} />
      <Button
        variant="contained"
        color="primary"
        component={NextLinkComposed}
        to={stepContent[activeStep].buttonLinkPath}
      >
        {stepContent[activeStep].buttonLinkLabel}
      </Button>
    </Box>
  );
}

export default OnboardingBanner;

import { WithdrawalRepository } from '@wallet/withdrawal';
import { BankAccount } from '@wallet/core';
import axios from '../../../../helpers/axios';

export const useWithdrawalRepository = (): WithdrawalRepository => {
  const getBankAccounts = async (businessId: number) => {
    try {
      const { data } = await axios.get(
        `/api/business/${businessId}/bankaccount`
      );
      return data;
    } catch (error) {
      const errorCode = error?.response?.data?.errorCode;
      return errorCode;
    }
  };

  const createBankAccount = async (
    businessId: number,
    bankAccount: BankAccount
  ) => {
    try {
      const { data } = await axios.post(
        `/api/business/${businessId}/bankaccount`,
        { ...bankAccount }
      );
      return data;
    } catch (error) {
      return error?.response?.data?.errorCode;
    }
  };

  return {
    getBankAccounts,
    createBankAccount,
  };
};

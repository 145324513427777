function getPathnameFromUrl(rawUrl: string) {
  // take of params
  const url = rawUrl.split('?')[0];
  // take of domain
  const splittedUrl = url.split('.com');
  const pathname =
    splittedUrl.length === 1
      ? splittedUrl[0]
      : splittedUrl[splittedUrl.length - 1];
  if (pathname[pathname.length - 1] === '/') {
    return pathname.slice(0, pathname.length - 1);
  }
  return pathname;
}

export default getPathnameFromUrl;

import request from 'axios';
import axios from '../../../helpers/axios';
import { DocumentsSummary } from '../types/DocumentSummary.type';

class UnexpectedError extends Error {}
export const fetchBusinessDocumentSummary = async (businessId: number) => {
  try {
    const PATH = `/api/business/${businessId}/document/summary`;
    const result = await axios.get<DocumentsSummary>(PATH);
    return result.data;
  } catch (err) {
    if (request.isAxiosError(err)) {
      throw err;
    } else {
      throw new UnexpectedError(err);
    }
  }
};

export default fetchBusinessDocumentSummary;

import create from 'zustand';
import { SortingTypes, ManageFilterState } from './useManageFilters.types';

let clearInput = null;

export const connectClearInput = (callback: () => void) => {
  clearInput = callback;
};

export const initialState = {
  currentPage: 0,
  filter: null,
  sorting: SortingTypes['issueDate:desc'],
  pageSize: 10,
  allInvoices: false,
};

export const useManageFilters = create<ManageFilterState>((set, get) => ({
  ...initialState,
  handleAllInvoices: () => {
    set(state => ({
      ...state,
      ...initialState,
      filter: state.filter,
      currentPage: 0,
      allInvoices: !state.allInvoices,
    }));
  },
  clearInputRemote: () => {
    if (!clearInput) return;

    clearInput();
  },
  handleChangeAllInvoices: () => {
    set(state => ({ ...state, allInvoices: !state.allInvoices }));
  },
  setInitialState: () => {
    set(state => ({ ...state, ...initialState, pageSize: state.pageSize }));
  },
  resetFilter: () => {
    set(state => ({ ...state, filter: null }));
  },
  handleFilterChange: (value: string | null) => {
    const { filter } = get();

    const initSearch = !filter && value;

    const continueSearch = filter && value;

    const stopSearch = !filter && !value;

    const resetSearch = filter && !value;

    if (initSearch) {
      set(state => ({
        ...state,
        currentPage: 0,
        filter: value,
      }));
      return;
    }

    if (stopSearch) {
      set(state => ({ ...state, currentPage: 0, filter: value }));
      return;
    }

    if (resetSearch) {
      set(state => ({ ...state, filter: '' }));
      return;
    }

    if (continueSearch) {
      set(state => ({ ...state, filter: value }));
    }
  },
  handlePageChange: (newPage: number) => {
    set(state => ({ ...state, currentPage: newPage }));
  },
  handlePageSize: (newPageSize: number) => {
    set(state => ({ ...state, pageSize: newPageSize }));
  },
  handleSortingChange: (sorting: `${SortingTypes}`) => {
    set(state => ({ ...state, sorting }));
  },
  resetTableStates: () => {
    set(state => ({
      ...state,
      currentPage: 0,
    }));
  },
}));

import React from 'react';
import { SvgIcon, styled } from '@material-ui/core';
import PropTypes from 'prop-types';
import { xTheme } from '@xepelinapp/x-ds';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const ListViewIcon = props => {
  const { colors, style } = props;

  return (
    <CustomSvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M0.5 0.5H28C29.933 0.5 31.5 2.067 31.5 4V28C31.5 29.933 29.933 31.5 28 31.5H0.5V0.5Z"
        stroke={xTheme.text.secondary}
        fill={colors.background}
      />
      <path
        d="M9.33325 18.3327V19.666H22.6666V18.3327H9.33325ZM9.33325 14.9993V16.3327H22.6666V14.9993H9.33325ZM9.33325 11.666V12.9993H22.6666V11.666H9.33325Z"
        fill={colors.line}
      />
    </CustomSvgIcon>
  );
};

ListViewIcon.defaultProps = {
  style: {},
};

ListViewIcon.propTypes = {
  colors: PropTypes.objectOf(Object).isRequired,
  style: PropTypes.objectOf(Object),
};

export default ListViewIcon;

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchHolidays } from '../../../src/store/actions/holidaysActions';

export const useFetchHolidays = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchHolidays());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

import { Box, Typography, Button } from '@material-ui/core';
import { OrderType } from '@finance/useCases/OperationLineValues.type';
import { useStyles } from './SwitchToOtherTab.styles';
import { TooltipContentProps } from './types';

export function TooltipContent({
  typeOfOperation,
  openModal,
}: TooltipContentProps) {
  const classes = useStyles();

  const isFinancing = typeOfOperation.orderType === OrderType.DIRECT_FINANCING;

  const earlyPaymentText = `Para seleccionar esta factura y financiarla debes ir a Pronto Pago pues posee este convenio`;

  const directFinancingText = `Para seleccionar esta factura y financiarla, debes ir a Financiamiento Directo pues no posee un convenio de Pronto Pago`;

  return (
    <Box className={classes.tooltipContent}>
      <Typography color="textSecondary" variant="body2">
        {isFinancing ? directFinancingText : earlyPaymentText}
      </Typography>
      <Button
        onClick={openModal}
        color="primary"
        variant="contained"
        classes={{ contained: classes.buttonContained }}
      >
        Ir a {typeOfOperation.label}
      </Button>
    </Box>
  );
}

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEntityFeature } from '../../../src/store/actions/authenticationActions';
import { fetchBusiness } from '../../../src/store/actions/businessActions';
import { useTranslation } from '../../i18n/infra/useTranslation';

export const useFetchBusiness = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { businessIsLoading } = useSelector(
    (state: { businessState: { businessIsLoading: boolean } }) =>
      state.businessState
  );
  const isAuth = useSelector(
    (state: { authState: any }) => state.authState.isAuthenticated
  );
  const authBusiness = useSelector(
    (state: { authState: any }) => state.authState.business
  );
  const fetchedBusiness = useSelector(
    (state: { businessState: any }) => state.businessState.business
  );
  useEffect(() => {
    if (isAuth && !fetchedBusiness) {
      dispatch(fetchBusiness(authBusiness.id));
      dispatch(fetchEntityFeature(authBusiness.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      fetchedBusiness?.countryId &&
      i18n.language !== fetchedBusiness.countryId
    ) {
      i18n.changeLanguage(fetchedBusiness.countryId);
    }
  }, [fetchedBusiness, i18n]);

  return {
    isLoading: businessIsLoading || !fetchedBusiness,
    business: fetchedBusiness,
  };
};

import jwtDecode from 'jwt-decode';
import axios from '../../helpers/axios';
import { persistor } from '../../modules/store';
import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SET_CURRENT_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  REGISTER_USER_START,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  COMPLETE_REGISTER_START,
  COMPLETE_REGISTER_SUCCESS,
  COMPLETE_REGISTER_ERROR,
  VERIFY_EMAIL_START,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
  VERIFY_EMAIL_RESET,
  LOCAL_PURGE,
  FETCH_ENTITY_FEATURE_START,
  FETCH_ENTITY_FEATURE_SUCCESS,
  FETCH_ENTITY_FEATURE_ERROR,
} from './types';
import { t13s } from '../../config/translationKeys';
import setAuthToken from '../../helpers/setAuthToken';
import { enqueueSnackbar } from './notificationActions';
import { wsDisconnect } from './websocketActions';
import { fetchBusiness } from './businessActions';

import { setIsSudoUser, setShowTourPostRegister } from './uiStateActions';

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const fetchEntityFeature = businessId => dispatch => {
  dispatch({
    type: FETCH_ENTITY_FEATURE_START,
  });
  axios
    .get(`api/business/${businessId}/entityfeature`, {})
    .then(res => {
      const { data } = res;
      dispatch({
        type: FETCH_ENTITY_FEATURE_SUCCESS,
        payload: data,
      });
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({
        type: FETCH_ENTITY_FEATURE_ERROR,
        payload: { errorCode },
      });
    });
};

export const loginWithToken = token => dispatch => {
  setAuthToken(token);
  const { data } = jwtDecode(token);
  const { business } = data;
  localStorage.setItem('jwtToken', token);
  persistor.persist();

  // Avoids fetching entityFeature on register process
  if (business.id) {
    dispatch(fetchBusiness(business.id));
    dispatch(fetchEntityFeature(business.id));
  }

  dispatch(setCurrentUser({ decoded: data, token }));
  dispatch({
    type: LOGIN_SUCCESS,
  });
};

export const loginUser = user => dispatch => {
  dispatch({
    type: LOGIN_START,
  });
  axios
    .post('/api/user/login', user)
    .then(res => {
      const { token } = res.data;
      dispatch(loginWithToken(token));
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({
        type: LOGIN_ERROR,
        payload: { errorCode },
      });
    });
};

export const logoutUser = () => async dispatch => {
  await dispatch(wsDisconnect());
  await dispatch({ type: LOGOUT_USER });
  await dispatch({ type: LOCAL_PURGE });

  dispatch(setIsSudoUser(false));
  persistor.purge().then(() => {
    localStorage.removeItem('jwtToken');
    setAuthToken(false);
  });
};

export const forgotPassword = email => dispatch => {
  dispatch({
    type: FORGOT_PASSWORD_START,
  });
  axios
    .post('/api/user/resetpassword', { email })
    .then(() => {
      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
      });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.FORGOT_PASSWORD_SUCCESS,
          options: {
            variant: 'success',
          },
        })
      );
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({
        type: FORGOT_PASSWORD_ERROR,
        payload: { errorCode },
      });
    });
};

export const resetPassword = (body, hash) => dispatch => {
  dispatch({
    type: RESET_PASSWORD_START,
  });
  axios
    .post(`/api/user/resetpassword/${hash}`, { ...body })
    .then(() => {
      dispatch({
        type: RESET_PASSWORD_SUCCESS,
      });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.RESET_PASSWORD_SUCCESS,
          options: {
            variant: 'success',
          },
        })
      );
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({
        type: RESET_PASSWORD_ERROR,
        payload: { errorCode },
      });
    });
};

export const registerUser = user => dispatch => {
  dispatch({
    type: REGISTER_USER_START,
  });
  axios
    .post('/api/user', { ...user })
    .then(() => {
      const { email, password } = user;
      dispatch(loginUser({ email, password }));
      dispatch({
        type: REGISTER_USER_SUCCESS,
      });
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.USER_CREATED_SUCCESS,
          options: {
            variant: 'success',
          },
        })
      );
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({
        type: REGISTER_USER_ERROR,
        payload: { errorCode },
      });
    });
};

export const completeRegister =
  ({ user, businessName, identifier, countryId }) =>
  dispatch => {
    dispatch({
      type: COMPLETE_REGISTER_START,
      payload: { user },
    });

    Promise.all([
      axios.put(`/api/user/${user.id}`, { name: user.name, phone: user.phone }),
      axios.post('/api/business', {
        name: businessName,
        identifier,
        countryId,
      }),
    ])
      .then(responses => {
        dispatch(
          enqueueSnackbar({
            message: t13s.NOTIFICATION.BUSINESS_CREATED,
            options: { variant: 'success' },
          })
        );
        const [, businessResponse] = responses;
        const { data: business } = businessResponse;
        // refresh token
        axios
          .post('/api/user/refresh')
          .then(res => {
            const response = res.data;
            const token = response?.token;
            dispatch(loginWithToken(token));
            dispatch({
              type: COMPLETE_REGISTER_SUCCESS,
            });

            // Create set documents
            axios.post(`/api/business/${business.id}/document`);

            // show Welcome Tour
            dispatch(setShowTourPostRegister(true));
          })
          .catch(error => {
            const errorCode = error?.response?.data?.errorCode;
            dispatch({
              type: COMPLETE_REGISTER_ERROR,
              payload: { errorCode },
            });
          });
      })
      .catch(error => {
        const errorCode = error?.response?.data?.errorCode;
        dispatch({
          type: COMPLETE_REGISTER_ERROR,
          payload: { errorCode },
        });
      });
  };

export const verifyEmail = email => dispatch => {
  dispatch({ type: VERIFY_EMAIL_START });
  axios
    .post(`/api/user/validate/email`, { email })
    .then(res => {
      const resData = res.data;
      dispatch({ type: VERIFY_EMAIL_SUCCESS, payload: resData });
    })
    .catch(error => {
      const errorCode = error?.response?.data?.errorCode;
      dispatch({
        type: VERIFY_EMAIL_ERROR,
        payload: { errorCode },
      });
    });
};

export const verifyEmailReset = () => dispatch => {
  dispatch({ type: VERIFY_EMAIL_RESET });
};

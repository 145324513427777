import { Box } from '@material-ui/core';
import { useTranslation } from '@features/i18n/infra/useTranslation';
import LogoMessage from '../../../../components/elements/LogoMessage';
import invoicesEmpty from '../../../../assets/invoicesEmpty.png';

export function InvoicesNotFound() {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="400px"
    >
      <LogoMessage
        logo={invoicesEmpty.src}
        title={t('INVOICES_NOTFOUND_TITLE')}
        subtitle={t('INVOICES_NOTFOUND_DESCRIPTION')}
      />
    </Box>
  );
}

import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { enqueueSnackbar } from '../../../store/actions/notificationActions';
import { t13s } from '../../../config/translationKeys';

const useCreateWithdrawalAccountService = () => {
  const dispatch = useDispatch();

  const notifyCreateWithdrawalAccountError = useCallback(() => {
    dispatch(
      enqueueSnackbar({
        message: t13s.NOTIFICATION.CREATE_WITHDRAWAL_ACCOUNT_FAILURE,
        options: { variant: 'error' },
      })
    );
  }, [dispatch]);

  return { notifyCreateWithdrawalAccountError };
};

export default useCreateWithdrawalAccountService;

import React from 'react';
import { Fade } from '@material-ui/core';
import loading from '../../assets/loading.svg';

const Loader = () => {
  return (
    <Fade timeout={400} in>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img src={loading.src} alt="loading" />
      </div>
    </Fade>
  );
};

export default Loader;

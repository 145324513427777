import clsx from 'clsx';
import { Box, Button, Menu } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CallMadeRoundedIcon from '@material-ui/icons/CallMadeRounded';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';
import MenuItem from '../../../../components/elements/MenuItem';
import useWalletBalanceWidget from './useWalletBalanceWidget';
import CountryFormatHelper from '../../../../components/elements/CountryFormatHelper';
import { CashInInfoModal } from '../CashInInfoModal';
import styles from './styles';

function WalletBalanceWidget() {
  const classes = styles();
  const {
    handleClick,
    handleClose,
    setShouldShowCashInModal,
    isWalletEnabled,
    wallet,
    balance,
    anchorEl,
    menuItems,
    shouldShowCashInModal,
  } = useWalletBalanceWidget();

  const getIcon = icon => {
    let iconJsx = null;
    switch (icon) {
      case 'cashin':
        iconJsx = <AddRoundedIcon />;
        break;
      case 'cashout':
        iconJsx = <CallMadeRoundedIcon />;
        break;
      case 'payment':
        iconJsx = <AttachMoneyRoundedIcon />;
        break;
      default:
        break;
    }
    return iconJsx;
  };

  return isWalletEnabled && wallet ? (
    <Box marginRight={3}>
      <Button
        color="primary"
        variant="contained"
        // endIcon={<ExpandMoreIcon />}
        aria-controls="wallet-actions"
        aria-haspopup="true"
        onClick={handleClick}
        className={clsx(classes.widthMenu, classes.button)}
      >
        Saldo:
        <Box marginLeft={1}>
          <CountryFormatHelper
            variant="currency"
            value={balance}
            countryId="MX"
            dataQa="wallet-balance"
          />
        </Box>
      </Button>
      {false && ( // Se deshabilita esta función temporalmente
        <Menu
          id="wallet-actions-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          classes={{ paper: classes.widthMenu }}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {menuItems.map(({ label, key, icon, disabled, onClick }) => (
            <MenuItem
              key={key}
              icon={getIcon(icon)}
              onClick={onClick}
              disabled={disabled}
            >
              {label}
            </MenuItem>
          ))}
        </Menu>
      )}
      <CashInInfoModal
        show={shouldShowCashInModal}
        onClose={() => setShouldShowCashInModal(false)}
      />
    </Box>
  ) : null;
}

export default WalletBalanceWidget;

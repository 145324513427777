import { makeStyles } from '@material-ui/core';

interface IProps {
  wasCalculated?: boolean;
  paperSizes?: {
    width: number;
    height: number;
  };
}

const useTopbarPrivateStyles = makeStyles(theme => ({
  root: {
    cursor: 'pointer',
  },
  navItemButton: {
    color: theme.palette.text.secondary,
    padding: theme.spacing(0, 1),
    marginRight: theme.spacing(2),
    textDecoration: 'unset',
    minWidth: 0,
    '&:hover': {
      textDecoration: 'unset',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.grey[100],
    },
  },
  navItemButtonActive: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey[100],
  },
  backdrop: {
    zIndex: 50,
  },
  avatar: {
    marginRight: theme.spacing(0.5),
  },
  popper: {
    borderTop: '24px solid transparent',
    zIndex: 2000,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.71em',
      marginLeft: 4,
      marginRight: 4,
      '&::before': {
        transformOrigin: '0 100%',
      },
    },
  },
  arrow: {
    zIndex: 2001,
    overflow: 'hidden',
    position: 'absolute',
    width: '1em',
    height: '0.71em' /* = width / sqrt(2) = (length of the hypotenuse) */,
    boxSizing: 'border-box',
    color: theme.palette.background.paper,
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: '100%',
      height: '100%',
      boxShadow: theme.shadows[1],
      backgroundColor: 'currentColor',
      transform: 'rotate(45deg)',
    },
  },
  menuItemText: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  menuIcon: {
    marginLeft: theme.spacing(2),
    color: theme.palette.grey[600],
  },
  paper: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.spacing(2),
    width: ({ wasCalculated, paperSizes }: IProps = {}) =>
      wasCalculated ? paperSizes.width : '100%',
    height: ({ wasCalculated, paperSizes }: IProps = {}) =>
      wasCalculated ? paperSizes.height : '100%',
  },
  positionRelative: {
    position: 'relative',
  },
  positionAbsolute: {
    position: 'absolute',
  },
}));

export default useTopbarPrivateStyles;

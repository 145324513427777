import { PaymentInvoice } from '../../../types';
import InvoiceSelectionStore from './invoice-selection-store';
import { InvoiceSelectionState } from './types';

const useSelectedInvoices = (): PaymentInvoice[] =>
  InvoiceSelectionStore(
    (state: InvoiceSelectionState) => state.selectedInvoices
  );

const useTotalSelectedInvoices = (): number =>
  InvoiceSelectionStore(
    (state: InvoiceSelectionState) => state.totalSelectedInvoices
  );

const useSetSelection = () =>
  InvoiceSelectionStore((state: InvoiceSelectionState) => state.setSelection);

const useClearSelection = () =>
  InvoiceSelectionStore((state: InvoiceSelectionState) => state.clearSelection);

const useHandleSelectionChange = () =>
  InvoiceSelectionStore(
    (state: InvoiceSelectionState) => state.handleSelectionChange
  );

const useSetInvoiceWithDiscount = () =>
  InvoiceSelectionStore(
    (state: InvoiceSelectionState) => state.setInvoiceWithDiscount
  );

export {
  useSelectedInvoices,
  useTotalSelectedInvoices,
  useSetSelection,
  useClearSelection,
  useHandleSelectionChange,
  useSetInvoiceWithDiscount,
};

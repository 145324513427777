import { Box, Grid } from '@material-ui/core';
import { SubmitedOrder } from '@finance/components';
import { useGetSubmitedOrderType } from '@finance/hooks/useGetSubmitedOrderType';
import Loader from 'components/elements/Loader';
import { Layout } from 'components/layout';
import { HistoryProxy } from '@finance/hoc/HistoryProxy';
import { FetchBusiness } from '../../../features/shared/FetchBusiness';

function OrderPage() {
  const { isLoading, ...props } = useGetSubmitedOrderType();

  if (isLoading) {
    return (
      <Layout>
        <Grid container justifyContent="center">
          <Loader />
        </Grid>
      </Layout>
    );
  }

  return (
    <FetchBusiness>
      <Layout>
        <Grid container justifyContent="center">
          <Box mt={12}>
            <SubmitedOrder {...props} />
          </Box>
        </Grid>
      </Layout>
    </FetchBusiness>
  );
}

export default HistoryProxy(OrderPage);

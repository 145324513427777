import { Invoice } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';
import * as selectedCache from '@finance/services/selectedCache';

export const useInvoicesFromStorage = ({
  onSuccess,
}: {
  onSuccess: (invoices: Invoice[]) => void;
}) => {
  const invoices = selectedCache.getInvoices();

  if (invoices) {
    onSuccess(invoices);
    selectedCache.deleteInvoices();
  }
};

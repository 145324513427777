import create from 'zustand';
import { OperationLineValues } from '@finance/useCases/OperationLineValues.type';
import { Invoice } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';
import { cleanDataInvoices } from '@finance/utils/cleanDataInvoices';
import { getUniques } from '@finance/utils/getUniques';
import type { SelectionInvoiceStore } from './types';

const initialState = {
  simulation: null,
  selectedInvoices: [],
};

export const useSelectionInvoiceStore = create<SelectionInvoiceStore>(
  (set, get) => {
    return {
      ...initialState,
      handleSimulation: (simulation: OperationLineValues) => {
        set(state => ({ ...state, simulation }));
      },
      resetSelection: () => {
        set(state => ({ ...state, selectedInvoices: [] }));
      },
      setSelectedInvoices: (invoice: Invoice) => {
        const { selectedInvoices } = get();

        const exist =
          selectedInvoices?.findIndex(({ id }) => id === invoice.id) !== -1;

        const newInvoices = exist
          ? selectedInvoices.filter(({ id }) => id !== invoice.id)
          : [...selectedInvoices, invoice];

        set(state => ({
          ...state,
          selectedInvoices: newInvoices,
        }));
      },
      setSelectedAll: (invoices: Invoice[], allSelected: boolean) => {
        if (!invoices?.length) return;
        const { selectedInvoices } = get();

        const invoicesIds = invoices.map(({ id }) => id);

        if (allSelected) {
          const newInvoices = selectedInvoices.filter(
            item => !invoicesIds.includes(item.id)
          );

          set(state => ({
            ...state,
            selectedInvoices: newInvoices,
          }));
          return;
        }

        const cleanSelected = selectedInvoices.map(cleanDataInvoices);

        const cleanNewInvoices = invoices.map(cleanDataInvoices);

        set(state => ({
          ...state,
          selectedInvoices: getUniques([...cleanSelected, ...cleanNewInvoices]),
        }));
      },
      setInvoicesFromStorage: (invoices: Invoice[]) => {
        set(state => ({ ...state, selectedInvoices: invoices }));
      },
      totalSelectedCartInvoices: () => {
        const { selectedInvoices } = get();
        return (
          selectedInvoices.reduce(
            (prevVal, currentVal) => ({
              amount: prevVal.amount + currentVal.amount,
            }),
            { amount: 0 }
          ).amount || 0
        );
      },
      resetState: () => {
        set(state => ({ ...state, ...initialState }));
      },
    };
  }
);

import flagsmith from 'flagsmith/isomorphic';
import { flagValueToPathMap } from '@features/libs/flagValueToPathMap';
import { urls } from '../../libs/urls';
import publicPaths from '../common/publicPaths';
import { RouteRequirementsUseCase } from '../domain/RouteRequirementsUseCase';
import getPathnameFromUrl from '../libs/getPathnameFromUrl';

export const registerRequirementsUseCase: RouteRequirementsUseCase = (
  rawUrl,
  options
) => {
  const { user } = options;
  const userStatus = user.status;

  const whiteList = new Set(['/legal-representatives/register', '/404']);

  const pathname = getPathnameFromUrl(rawUrl);

  if (whiteList.has(pathname)) return null;

  if (!userStatus.isAuthenticated) {
    if (publicPaths.has(pathname) || pathname.includes(urls.impersonate))
      return null;
    return urls.login;
  }
  if (!userStatus.isCompleteUserRegistered) {
    if (pathname === urls.completeRegister) return null;
    return urls.completeRegister;
  }
  if (!userStatus.isBusinessRegistered) {
    if (pathname === urls.businessRegister) return null;
    return urls.businessRegister;
  }
  if (userStatus.isAuthenticated && publicPaths.has(pathname)) {
    const loginRedirectFlagValue = flagsmith.getValue(
      'saas_login_redirect_path'
    ) as string;
    return flagValueToPathMap[loginRedirectFlagValue];
  }
  return null;
};

import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import { BoxProps } from '@material-ui/core';

const GridCenter: FC<BoxProps> = function GridCenter({
  children,
  ...restProps
}) {
  return (
    <Box
      height="100%"
      display="grid"
      maxHeight="100%"
      alignItems="center"
      justifyContent="center"
      {...restProps}
    >
      {children}
    </Box>
  );
};
export default GridCenter;

import React from 'react';
import { useSelector } from 'react-redux';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import GetCreationOperationLines from '@finance/useCases/GetCreationOperationLines';
import {
  OperationLineValues,
  OrderType,
} from '@finance/useCases/OperationLineValues.type';
import { RootState } from '../../store/reducers/rootReducer';
import CountryFormatHelper from './CountryFormatHelper';
import OperationLine from './OperationLine';
import OperationResumeAlert from './OperationResumeAlert';
import { Typography } from '../../design-system/atoms/index';
import { useTranslation } from '../../../features/i18n/infra/useTranslation';

type CreationOperationResumeProps = {
  operationType: `${OrderType}`;
  simulation: OperationLineValues;
  showButton: boolean;
  buttonComponent: JSX.Element;
};

const useStyles = makeStyles(theme => ({
  disabled: { color: theme.palette.text.disabled },
  green: {
    color: theme.palette.success.main,
  },
  tooltipIcon: {
    marginLeft: 6,
  },
}));

function CreationOperationResume({
  operationType,
  simulation,
  showButton,
  buttonComponent,
}: CreationOperationResumeProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { countryId } = useSelector(
    (state: RootState) => state.businessState.business
  );

  const lines = GetCreationOperationLines({
    countryId,
    operationType,
    values: simulation,
  });

  const monthlyRate = lines.find(line => line.hasPromotions);

  const getAmountToTransfer = () => {
    if (!simulation?.transferWithPromotions) return simulation?.transfer;
    return simulation?.transferWithPromotions;
  };

  const showAmountToTransferBefore = () =>
    simulation?.hasPromotions && getAmountToTransfer() > simulation?.transfer;

  return (
    <Box p={1}>
      {lines.map(line => (
        <OperationLine key={line.title} line={line} countryId={countryId} />
      ))}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={2}
        mb={1}
      >
        <Box>
          <Typography variant="body2">{t('OPERATION_RESUME_TOTAL')}</Typography>
        </Box>
        <Tooltip title={t('OPERATION_RESUME_TOTAL_TOOLTIP')}>
          <InfoOutlinedIcon
            color="primary"
            fontSize="medium"
            className={classes.tooltipIcon}
          />
        </Tooltip>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h6" className={classes.green}>
          {CountryFormatHelper({
            value: getAmountToTransfer() || '-',
            variant: 'currency',
            countryId,
            dataQa: 'operation-resume-total-transferir-string',
          })}
        </Typography>
        {showAmountToTransferBefore() && (
          <Typography variant="tiny" className={classes.disabled}>
            Antes:{' '}
            {CountryFormatHelper({
              value: simulation?.transfer || '-',
              variant: 'currency',
              countryId,
              dataQa: 'operation-resume-total-transferir-string',
            })}
          </Typography>
        )}
      </Box>
      {monthlyRate && (
        <OperationResumeAlert
          countryId={countryId}
          totalAmount={monthlyRate.totalAmount}
          promotionsLimitAmount={monthlyRate.promotionsLimitAmount}
        />
      )}
      {showButton && <Box pt={2.5}>{buttonComponent}</Box>}
    </Box>
  );
}

export default CreationOperationResume;

import { theme } from '../../../design-system/theme';

const CARD_VARIANTS = {
  blue: {
    code: 'blue',
    background: theme.palette.primary.light,
    border: theme.palette.primary.main,
  },
  green: {
    code: 'green',
    background: theme.palette.success.light,
    border: theme.palette.success.main,
  },
  gradient: {
    code: 'gradient',
    background: theme.palette.gradient,
    border: theme.palette.secondary.light,
  },
  grey: {
    code: 'grey',
    background: theme.palette.grey[300],
    border: theme.palette.grey[600],
  },
  white: {
    code: 'white',
    background: theme.palette.common.white,
    border: theme.palette.grey[200],
  },
};

export { CARD_VARIANTS };

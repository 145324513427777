import axios from 'helpers/axios';

export const scrapInvoicesService = async (
  businessId: string,
  countryId: string
) => {
  try {
    const response = await axios.post(
      `/api/business/${businessId}/invoices/scrap`,
      {},
      {
        headers: {
          country: countryId,
        },
      }
    );
    return { statusCode: response.status, statusText: response.statusText };
  } catch (error) {
    throw new Error(error?.response?.data?.errorCode);
  }
};

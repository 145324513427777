import type { PaginationResponse } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';

let cache: PaginationResponse | null = null;

export const cachingPagination = (pagination: PaginationResponse) => {
  if (!pagination) return cache;

  cache = pagination;

  return cache;
};

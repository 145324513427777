import { Box, Button, makeStyles } from '@material-ui/core';
import Image from 'next/image';
import { useTranslation } from '@features/i18n/infra/useTranslation';
import useTracker from '@finance/hooks/useTracker';
import { TitleFinance } from '@finance/components';
import { useState } from 'react';
import { useSpacingStyles } from '../../../../features/shared/styles/spacings';
import { Typography } from '../../../../design-system/atoms';

const useStyles = makeStyles({
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  rotateAnimation: {
    animation: '$rotation 1s linear infinite',
  },
});

export function TableHeader() {
  const { t } = useTranslation();
  const spacing = useSpacingStyles();
  const classes = useStyles();
  const [shouldSendEvent, setShouldSendEvent] = useState(true);
  const { financeCallbacks: trackerCallbacks } = useTracker();

  const handleClickRefresh = () => {
    if (shouldSendEvent) trackerCallbacks.trackRefreshInvoices();
    setShouldSendEvent(false);
    setTimeout(() => {
      setShouldSendEvent(true);
    }, 3000);
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <TitleFinance />
      <Button
        color="primary"
        style={{ height: 40, borderRadius: 8 }}
        variant="outlined"
        onClick={handleClickRefresh}
        disabled={!shouldSendEvent}
      >
        <Image
          alt="XepelinLogo"
          src="/assets/refresh.svg"
          width={16}
          height={16}
          unoptimized
          className={!shouldSendEvent && classes.rotateAnimation}
        />
        <Typography
          variant="body2"
          style={{ fontWeight: 'bold' }}
          align="center"
          className={spacing.ml1}
        >
          {t('REFRESH')}
        </Typography>
      </Button>
    </Box>
  );
}

import isEmpty from './validations/isEmpty';

const styleConstants = {
  XS: 5,
  SM: 10,
  MD: 15,
  LG: 20,
  XL: 30,
};

export const convertSpacingToCss = spec => {
  let value = spec;
  if (isEmpty(value)) {
    value = 'md';
  }

  return value
    .replace(/zero/g, '0')
    .replace(/xs/g, `${styleConstants.XS}px`)
    .replace(/sm/g, `${styleConstants.SM}px`)
    .replace(/md/g, `${styleConstants.MD}px`)
    .replace(/lg/g, `${styleConstants.LG}px`)
    .replace(/xl/g, `${styleConstants.XL}px`);
};

export const getThemeColor = (theme, foundColor) => {
  let color;
  switch (foundColor) {
    case 'primary':
      color = theme.palette.primary.main;
      break;
    case 'secondary':
      color = theme.palette.secondary.main;
      break;
    case 'textPrimary':
      color = theme.palette.text.primary;
      break;
    case 'textsecondary':
      color = theme.palette.text.secondary;
      break;
    case 'contrast1':
      color = theme.palette.text.contrast1;
      break;
    case 'contrast2':
      color = theme.palette.text.contrast2;
      break;
    default:
      color = foundColor;
      break;
  }
  return color;
};

import React from 'react';
import { Box } from '@material-ui/core';
import { esES } from '@material-ui/data-grid';
import useGetInvoices from '@integration-services/xepelin-server-global/invoices/hooks/useGetInvoices';
import { useInvoiceSelection } from '@finance/hooks/migration/useInvoiceSelection';
import { useGetInvoiceLoadingState } from '@finance/hooks/migration/useGetInvoiceLoadingState';
import { useOperationType } from '@finance/hooks/migration/useOperationType';
import { useSelector } from 'react-redux';
import type { BusinessState, RootState } from '@finance/types';
import {
  HistoryProcessingHeaderMessage,
  FinanceCart,
  FinanceWrapper,
  TableOptions,
  FilteredInvoicesNotFound,
} from '@finance/components';
import { getEmptyState } from '@finance/utils/getEmptyState';
import useGetInvoiceStatus from '@finance/hooks/useGetInvoiceStatus';
import { useScrapInvoices } from '@integration-services/xepelin-server-global/invoices/hooks/useScrapInvoices';
import { HistoryProxy } from '@finance/hoc/HistoryProxy';
import PaginatedServerTable from '../../components/elements/tables/PaginatedServerTable';
import { useSpacingStyles } from '../../features/shared/styles/spacings';
import { useStyles } from './styles';
import { TableHeader } from '../../modules/finance/components/table-header';

function HomeFinance() {
  const classes = useStyles();
  const spacing = useSpacingStyles();

  const {
    onboardingStep,
    hasInvoiceHistory,
    id: businessId,
    countryId,
  } = useSelector<RootState, BusinessState>(
    state => state.businessState.business
  );

  useScrapInvoices(businessId, countryId);

  const { pagination, data, isLoading, allInvoices } = useGetInvoices();

  const operationType = useOperationType();

  const invoices = data?.data || [];

  const rowCount = data?.pagination?.totalData || 0;

  const { state, tableCallbacks } = useInvoiceSelection();

  const loadingState = useGetInvoiceLoadingState(
    hasInvoiceHistory,
    !!invoices.length,
    onboardingStep
  );

  const EmptyState = !isLoading ? getEmptyState(loadingState) : () => null;

  const shouldRenderTable =
    !loadingState || isLoading || state.currentPage !== 0 || state.filter;

  const isFinanceableInvoice = (categories: []) => {
    return useGetInvoiceStatus(categories, operationType);
  };

  const classNameTable = `${spacing.mt4} ${classes.MuiDataGrid} ${
    allInvoices && classes.table
  }`;

  const getRowClassName = ({ row }) => {
    if (!Array.isArray(row.categories)) return '';

    const isFinanceable = isFinanceableInvoice(
      row.categories
    )?.isFinanceableInvoice;

    return !isFinanceable && 'MuiDataGrid-inactiveRow';
  };

  return (
    <FinanceWrapper>
      <TableHeader />
      <Box
        className={`${classes.tableContainer} ${spacing.py3} ${spacing.px4}`}
      >
        <HistoryProcessingHeaderMessage />
        <TableOptions />
        {shouldRenderTable ? (
          <PaginatedServerTable
            components={{ NoRowsOverlay: FilteredInvoicesNotFound }}
            className={classNameTable}
            columns={state.columns}
            rows={invoices}
            pageSize={pagination.size}
            rowCount={rowCount}
            loading={isLoading}
            getRowClassName={getRowClassName}
            page={state.currentPage}
            localeText={(esES as any).props.MuiDataGrid.localeText}
            {...tableCallbacks}
          />
        ) : (
          <EmptyState />
        )}
      </Box>
      <FinanceCart />
    </FinanceWrapper>
  );
}

export default HistoryProxy(HomeFinance);

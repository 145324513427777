import React from 'react';
import { SvgIcon, styled } from '@material-ui/core';
import PropTypes from 'prop-types';
import { xTheme } from '@xepelinapp/x-ds';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const LatePaymentIcon = props => {
  const { style } = props;

  return (
    <CustomSvgIcon
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      style={style}
    >
      <circle cx="24" cy="24" r="24" fill={xTheme.tertiary.light} />
      <path
        d="M20.27 15L15 20.27V27.73L20.27 33H27.73C29.5 31.24 33 27.73 33 27.73V20.27L27.73 15H20.27ZM21.1 17H26.9L31 21.1V26.9L26.9 31H21.1L17 26.9V21.1L21.1 17ZM23 27H25V29H23V27ZM23 19H25V25H23V19Z"
        fill={xTheme.tertiary.main}
      />
    </CustomSvgIcon>
  );
};

LatePaymentIcon.defaultProps = {
  style: {},
};

LatePaymentIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default LatePaymentIcon;

import {
  FETCH_ORDER_DETAIL_START,
  FETCH_ORDER_DETAIL_SUCCESS,
  FETCH_ORDER_DETAIL_ERROR,
  FETCH_ORDER_INVOICES_START,
  FETCH_ORDER_INVOICES_SUCCESS,
  FETCH_ORDER_INVOICES_ERROR,
  LOCAL_PURGE,
  RESET_ORDER_DETAILS,
  FETCH_INVOICE_DOCUMENTS_START,
  FETCH_INVOICE_DOCUMENTS_SUCCESS,
  FETCH_INVOICE_DOCUMENTS_ERROR,
  DOWNLOAD_INVOICE_DOCUMENTS_START,
  DOWNLOAD_INVOICE_DOCUMENTS_SUCCESS,
  DOWNLOAD_INVOICE_DOCUMENTS_ERROR,
  UPLOAD_ORDER_INVOICE_PDF_START,
  UPLOAD_ORDER_INVOICE_PDF_SUCCESS,
  UPLOAD_ORDER_INVOICE_PDF_FAILURE,
  DELETE_ORDER_INVOICE_PDF_START,
  DELETE_ORDER_INVOICE_PDF_SUCCESS,
  DELETE_ORDER_INVOICE_PDF_FAILURE,
  DOWNLOAD_ORDER_INVOICE_SIMULATION_START,
  DOWNLOAD_ORDER_INVOICE_SIMULATION_SUCCESS,
  DOWNLOAD_ORDER_INVOICE_SIMULATION_FAILURE,
  FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_START,
  FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_SUCCESS,
  FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_FAILURE,
  RESET_PDF_WAS_UPLOADED_STATE,
} from '../actions/types';
import { t13s } from '../../config/translationKeys';

const initialState = {
  resume: {},
  type: '',
  orderDetailIsLoading: false,
  orderDetailError: null,

  invoiceDocuments: [],
  fetchInvoiceDocumentsIsLoading: false,
  fetchInvoiceDocumentsError: null,

  downloadInvoiceDocumentsIsLoading: false,
  invoiceDocumentWasDownloaded: false,
  downloadInvoiceDocumentsError: null,

  invoices: [],
  pagination: { totalCount: 0, pageSize: 20, page: 1 },
  orderInvoicesIsLoading: false,
  orderInvoicesError: null,

  updatingInvoiceId: null,
  pdfIsUploading: false,
  pdfIsUploadingError: null,
  pdfWasUploaded: false,

  pdfIsDeleting: false,
  pdfIsDeletingError: null,

  orderSimulationPdfIsDownloading: false,
  downloadOrderSimulationPdfError: null,
  orderSimulationPdfWasDownloaded: false,

  fetchOrderInvoiceHasAttachmentsIsLoading: false,
  fetchOrderInvoiceHasAttachmentsError: null,
};

const fetchOrderDetailStart = state => {
  return {
    ...state,
    orderDetailIsLoading: true,
  };
};

const fetchOrderDetailSuccess = (state, payload) => {
  return {
    ...state,
    orderDetailIsLoading: false,
    ...payload,
  };
};

const fetchOrderDetailError = (state, payload) => {
  return {
    ...state,
    resume: {},
    type: '',
    orderDetailIsLoading: false,
    orderDetailError: payload.error,
  };
};

const fetchOrderInvoicesStart = state => {
  return {
    ...state,
    orderInvoicesIsLoading: true,
  };
};

const fetchOrderInvoicesSuccess = (state, payload) => {
  return {
    ...state,
    orderInvoicesIsLoading: false,
    ...payload,
  };
};

const fetchOrderInvoicesError = (state, payload) => {
  return {
    ...state,
    invoices: [],
    orderInvoicesIsLoading: false,
    orderInvoiceslError: payload.error,
  };
};

const fetchInvoiceDocumentsStart = state => {
  return {
    ...state,
    fetchInvoiceDocumentsIsLoading: true,
    fetchInvoiceDocumentsError: null,
  };
};

const fetchInvoiceDocumentsSuccess = (state, payload) => {
  return {
    ...state,
    fetchInvoiceDocumentsIsLoading: false,
    invoiceDocuments: payload.invoiceDocuments,
  };
};

const fetchInvoiceDocumentsError = (state, payload) => {
  return {
    ...state,
    fetchInvoiceDocumentsError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
    fetchInvoiceDocumentsIsLoading: false,
  };
};

const downloadInvoiceDocumentsStart = state => {
  return {
    ...state,
    downloadInvoiceDocumentsIsLoading: true,
    invoiceDocumentWasDownloaded: false,
    downloadInvoiceDocumentsError: null,
  };
};

const downloadInvoiceDocumentsSuccess = state => {
  return {
    ...state,
    downloadInvoiceDocumentsIsLoading: false,
    invoiceDocumentWasDownloaded: true,
  };
};

const downloadInvoiceDocumentsError = (state, payload) => {
  return {
    ...state,
    downloadInvoiceDocumentsError:
      payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
    downloadInvoiceDocumentsIsLoading: false,
    invoiceDocumentWasDownloaded: true,
  };
};

const uploadOrderInvoicePdfStart = (state, payload) => {
  return {
    ...state,
    pdfIsUploading: true,
    pdfIsUploadingError: null,
    updatingInvoiceId: payload.updatingInvoiceId,
    pdfWasUploaded: false,
  };
};
const uploadOrderInvoicePdfSuccess = (state, payload) => {
  const { invoices } = state;
  const { attachmentId, orderInvoiceId } = payload;

  const updatedInvoices = invoices.map(invoice => {
    if (invoice.id === orderInvoiceId) {
      return { ...invoice, attachmentId };
    }
    return invoice;
  });

  return {
    ...state,
    pdfIsUploading: false,
    updatingInvoiceId: null,
    invoices: updatedInvoices,
    pdfWasUploaded: true,
  };
};
const uploadOrderInvoicePdfFailure = (state, payload) => {
  return {
    ...state,
    pdfIsUploading: false,
    pdfIsUploadingError: payload.errorCode,
    updatingInvoiceId: null,
    pdfWasUploaded: false,
  };
};
const deleteOrderInvoicePdfStart = (state, payload) => {
  return {
    ...state,
    pdfIsDeleting: true,
    pdfIsDeletingError: null,
    updatingInvoiceId: payload.updatingInvoiceId,
  };
};
const deleteOrderInvoicePdfSuccess = (state, payload) => {
  const { invoices } = state;
  const { deletedPdfId } = payload;

  const updatedInvoices = invoices.map(invoice => {
    if (invoice.attachmentId === deletedPdfId) {
      return { ...invoice, attachmentId: null };
    }
    return invoice;
  });

  return {
    ...state,
    pdfIsDeleting: false,
    invoices: updatedInvoices,
    updatingInvoiceId: null,
  };
};

const deleteOrderInvoicePdfFailure = (state, payload) => {
  return {
    ...state,
    pdfIsDeleting: false,
    pdfIsDeletingError: payload.errorCode,
    updatingInvoiceId: null,
  };
};

const downloadOrderSimulationStart = state => {
  return {
    ...state,
    orderSimulationPdfIsDownloading: true,
    downloadOrderSimulationPdfError: null,
    orderSimulationPdfWasDownloaded: false,
  };
};

const downloadOrderSimulationSuccess = state => {
  return {
    ...state,
    orderSimulationPdfIsDownloading: false,
    orderSimulationPdfWasDownloaded: true,
  };
};

const downloadOrderSimulationFailure = (state, payload) => {
  return {
    ...state,
    orderSimulationPdfIsDownloading: true,
    downloadOrderSimulationPdfError: payload.errorCode,
    orderSimulationPdfWasDownloaded: false,
  };
};

const fetchOrderInvoiceHasAttachmentsStart = state => {
  return {
    ...state,
    fetchOrderInvoiceHasAttachmentsIsLoading: true,
    fetchOrderInvoiceHasAttachmentsError: null,
  };
};

const fetchOrderInvoiceHasAttachmentsSuccess = (state, payload) => {
  const { invoices } = state;

  const invoicesWithAttachmentStatus = invoices.map(invoice => {
    const attachment = payload.find(
      attachmentResponse =>
        attachmentResponse.id &&
        attachmentResponse.orderInvoiceId === invoice.id
    );

    return { ...invoice, attachmentId: attachment?.id ?? null };
  });

  return {
    ...state,
    invoices: invoicesWithAttachmentStatus,
    fetchOrderInvoiceHasAttachmentsIsLoading: false,
  };
};

const fetchOrderInvoiceHasAttachmentsFailure = (state, payload) => {
  return {
    ...state,
    fetchOrderInvoiceHasAttachmentsIsLoading: false,
    fetchOrderInvoiceHasAttachmentsError: payload.errorCode,
  };
};

const resetPdfWasUploadedState = state => {
  return {
    ...state,
    pdfWasUploaded: false,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ORDER_DETAIL_START:
      return fetchOrderDetailStart(state);
    case FETCH_ORDER_DETAIL_SUCCESS:
      return fetchOrderDetailSuccess(state, payload);
    case FETCH_ORDER_DETAIL_ERROR:
      return fetchOrderDetailError(state, payload);
    case FETCH_ORDER_INVOICES_START:
      return fetchOrderInvoicesStart(state);
    case FETCH_ORDER_INVOICES_SUCCESS:
      return fetchOrderInvoicesSuccess(state, payload);
    case FETCH_ORDER_INVOICES_ERROR:
      return fetchOrderInvoicesError(state, payload);
    case FETCH_INVOICE_DOCUMENTS_START:
      return fetchInvoiceDocumentsStart(state);
    case FETCH_INVOICE_DOCUMENTS_SUCCESS:
      return fetchInvoiceDocumentsSuccess(state, payload);
    case FETCH_INVOICE_DOCUMENTS_ERROR:
      return fetchInvoiceDocumentsError(state, payload);
    case DOWNLOAD_INVOICE_DOCUMENTS_START:
      return downloadInvoiceDocumentsStart(state);
    case DOWNLOAD_INVOICE_DOCUMENTS_SUCCESS:
      return downloadInvoiceDocumentsSuccess(state);
    case DOWNLOAD_INVOICE_DOCUMENTS_ERROR:
      return downloadInvoiceDocumentsError(state, payload);
    case UPLOAD_ORDER_INVOICE_PDF_START:
      return uploadOrderInvoicePdfStart(state, payload);
    case UPLOAD_ORDER_INVOICE_PDF_SUCCESS:
      return uploadOrderInvoicePdfSuccess(state, payload);
    case UPLOAD_ORDER_INVOICE_PDF_FAILURE:
      return uploadOrderInvoicePdfFailure(state, payload);
    case DELETE_ORDER_INVOICE_PDF_START:
      return deleteOrderInvoicePdfStart(state, payload);
    case DELETE_ORDER_INVOICE_PDF_SUCCESS:
      return deleteOrderInvoicePdfSuccess(state, payload);
    case DELETE_ORDER_INVOICE_PDF_FAILURE:
      return deleteOrderInvoicePdfFailure(state, payload);
    case DOWNLOAD_ORDER_INVOICE_SIMULATION_START:
      return downloadOrderSimulationStart(state);
    case DOWNLOAD_ORDER_INVOICE_SIMULATION_SUCCESS:
      return downloadOrderSimulationSuccess(state);
    case DOWNLOAD_ORDER_INVOICE_SIMULATION_FAILURE:
      return downloadOrderSimulationFailure(state, payload);
    case FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_START:
      return fetchOrderInvoiceHasAttachmentsStart(state);
    case FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_SUCCESS:
      return fetchOrderInvoiceHasAttachmentsSuccess(state, payload);
    case FETCH_ORDER_INVOICE_HAS_ATTACHMENTS_FAILURE:
      return fetchOrderInvoiceHasAttachmentsFailure(state, payload);
    case RESET_PDF_WAS_UPLOADED_STATE:
      return resetPdfWasUploadedState(state);
    case RESET_ORDER_DETAILS:
      return initialState;
    case LOCAL_PURGE:
      return initialState;
    default:
      return state;
  }
};

import { DropdownItem } from 'components/elements/Dropdown';
import Typography from 'design-system/atoms/Typography';
import { SortingTypes } from '../useManageFilters';

export const createSortingItems = ({
  t,
}: {
  t: (value: string) => string;
}): DropdownItem[] => [
  {
    label: (
      <Typography variant="body3">
        {t('INVOICES_SELECTION_SORTING_ISSUE_DATE_DESC')}
      </Typography>
    ),
    value: SortingTypes['issueDate:desc'],
  },
  {
    label: (
      <Typography variant="body3">
        {t('INVOICES_SELECTION_SORTING_ISSUE_DATE_ASC')}
      </Typography>
    ),
    value: SortingTypes['issueDate:asc'],
  },
  {
    label: (
      <Typography variant="body3">
        {t('INVOICES_SELECTION_SORTING_AMOUNT_DESC')}
      </Typography>
    ),
    value: SortingTypes['amount:desc'],
  },
  {
    label: (
      <Typography variant="body3">
        {t('INVOICES_SELECTION_SORTING_AMOUNT_ASC')}
      </Typography>
    ),
    value: SortingTypes['amount:asc'],
  },
  {
    label: (
      <Typography variant="body3">
        {t('INVOICES_SELECTION_SORTING_FOLIO_DESC')}
      </Typography>
    ),
    value: SortingTypes['folio:desc'],
  },
  {
    label: (
      <Typography variant="body3">
        {t('INVOICES_SELECTION_SORTING_FOLIO_ASC')}
      </Typography>
    ),
    value: SortingTypes['folio:asc'],
  },
];

import { OrderType } from '@finance/useCases/OperationLineValues.type';

export const getPaths = (name: `${OrderType}`) => {
  const paths: {
    [key: string]: string;
  } = {
    [OrderType.EARLY_PAYMENT]: 'prontopago',
    [OrderType.DIRECT_FINANCING]: 'directo',
  };

  return paths[name] || 'directo';
};

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useWalletRepository } from '../infrastructure/repository';
import { useWalletStore } from '../infrastructure/store';
import fetchBusinessWalletUseCase from '../useCases/fetchBusinessWalletUseCase';
import fetchWalletBalanceUseCase from '../useCases/fetchWalletBalanceUseCase';

const useWallet = () => {
  const walletRepository = useWalletRepository();
  const walletStore = useWalletStore();

  const wallet = walletStore.getBusinessWallet();
  const isFetchingWallet = walletStore.getFetchingWallet();

  const balance = walletStore.getWalletBalance();
  const isFetchingBalance = walletStore.getFetchingBalance();

  const { entityFeature, business } = useSelector(
    (state: any) => state.authState
  );
  const isWalletEnabled = !!entityFeature.find(
    entity => entity.featureId === 12 && entity.active
  );

  const getBusinessWallet = async businessId => {
    return fetchBusinessWalletUseCase({
      businessId,
      walletRepository,
      walletStore,
    });
  };

  const getWalletBalance = async businessId => {
    return fetchWalletBalanceUseCase({
      businessId,
      walletRepository,
      walletStore,
    });
  };

  useEffect(() => {
    const businessId = business?.id;
    if (
      isWalletEnabled &&
      !isFetchingWallet &&
      businessId &&
      wallet?.businessId !== businessId
    ) {
      walletStore.resetWalletStore();
      getBusinessWallet(businessId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [business]);

  useEffect(() => {
    const businessId = business?.id;
    if (wallet && businessId && !isFetchingBalance) {
      getWalletBalance(businessId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  return {
    getBusinessWallet,
    getWalletBalance,
    wallet,
    balance,
    businessName: business?.name,
    isWalletEnabled,
    isFetchingBalance,
  };
};

export default useWallet;

import { useRouter } from 'next/router';
import { AppBar, Tabs } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { OrderType } from '@finance/useCases/OperationLineValues.type';
import { useOperationType } from '@finance/hooks/migration/useOperationType';
import { useInvoiceSelection } from '@finance/hooks/migration/useInvoiceSelection';
import { useInvoicesFromTab } from '@finance/hooks/migration/useInvoicesFromTab';
import { DIRECT_FINANCING_LABEL, EARLY_PAYMENT_LABEL } from 'helpers/constants';
import { setWasInFinance } from '../../../../store/actions/uiStateActions';
import { useStyles } from './finance-tabs-styles';
import { getPaths } from '../../utils/utils';
import Tab from '../../../../components/elements/Tab';
import type { RootState, CartState } from '../../types';

export function FinanceTabs() {
  const classes = useStyles();
  const router = useRouter();
  const dispatch = useDispatch();

  const { earlyPaymentCount, directFinancingCount } = useSelector<
    RootState,
    CartState
  >(state => state.cartState);

  const operationType = useOperationType();

  const { callbacks } = useInvoiceSelection();

  const handleTabChange = (value: OrderType) => {
    const tab = getPaths(value);
    router.push(
      {
        pathname: `/finance/[tab]`,
        query: { tab },
      },
      `/finance/${tab}`,
      { shallow: true }
    );
    callbacks.resetTableStates();
    dispatch(setWasInFinance(true));
  };

  useInvoicesFromTab();

  return (
    <AppBar position="static" className={classes.appBar} elevation={0}>
      <Tabs
        value={operationType}
        indicatorColor="primary"
        textColor="inherit"
        variant="scrollable"
        scrollButtons="auto"
        onChange={(_, value) => handleTabChange(value)}
      >
        <Tab
          value={OrderType.DIRECT_FINANCING}
          label={DIRECT_FINANCING_LABEL}
          pillContent={directFinancingCount.toString()}
          data-qa="finance-tab-direct-financing-action"
          pillDataQa="finance-tab-pill-direct-financing-text"
        />
        <Tab
          value={OrderType.EARLY_PAYMENT}
          label={EARLY_PAYMENT_LABEL}
          pillContent={earlyPaymentCount.toString()}
          data-qa="finance-tab-early-payment-action"
          pillDataQa="finance-tab-pill-eaerly-payment-text"
        />
      </Tabs>
    </AppBar>
  );
}

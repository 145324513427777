import React from 'react';
import { Box, Button } from '@material-ui/core';
import Image from 'next/image';
import { Spacer } from '@features/shared';
import { Typography } from 'design-system/atoms';
import { StepperStore } from '@wallet/withdrawal/components/common/Stepper/types';

interface SuccessProps {
  stepperStore?: StepperStore;
  imageSrc: string;
  title: string;
  content: string;
  buttonLabel: string;
}

function Success({
  stepperStore,
  imageSrc,
  title,
  content,
  buttonLabel,
}: SuccessProps) {
  const { onFinish } = stepperStore || {};
  return (
    <Box display="flex" textAlign="center" flexDirection="column">
      <Box>
        <Image
          alt="success step"
          width={240}
          height={240}
          src={imageSrc}
          unoptimized
        />
      </Box>
      <Spacer spacing={4} />
      <Typography variant="h6" color="textPrimary">
        {title}
      </Typography>
      <Spacer spacing={2} />
      <Typography variant="body2" color="textSecondary">
        {content}{' '}
      </Typography>
      <Spacer spacing={8} />
      <Button variant="contained" color="primary" fullWidth onClick={onFinish}>
        {buttonLabel}
      </Button>
    </Box>
  );
}

export default Success;

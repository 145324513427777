import { PURGE } from 'redux-persist';
import {
  UPDATE_USER_START,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  FETCH_USER_START,
  FETCH_USER_SUCCESS,
  FETCH_USER_ERROR,
  UPDATE_PASSWORD_START,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  LOCAL_PURGE,
  UPDATE_PASSWORD_RESET,
} from '../actions/types';
import { t13s } from '../../config/translationKeys';

const initialState = {
  user: {},

  userUpdated: false,
  updateUserIsLoading: false,
  userError: null,

  userFetched: false,
  fetchUserError: null,
  fetchUserIsLoading: false,

  updatePasswordIsLoading: false,
  updatePasswordError: null,
  passwordUpdated: false,
};

const updateUserStart = state => {
  return {
    ...state,
    userUpdated: false,
    updateUserIsLoading: true,
  };
};

const updateUserSuccess = (state, payload) => {
  return {
    ...state,
    user: payload.user,
    userUpdated: true,
    updateUserIsLoading: false,
  };
};

const updateUserError = (state, payload) => {
  return {
    ...state,
    userUpdated: false,
    userError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
    updateUserIsLoading: false,
  };
};

const fetchUserStart = state => {
  return {
    ...state,
    userFetched: false,
    fetchUserError: null,
    fetchUserIsLoading: true,
  };
};

const fetchUserSuccess = (state, payload) => {
  return {
    ...state,
    user: payload.user,
    userFetched: true,
    fetchUserIsLoading: false,
  };
};

const fetchUserError = (state, payload) => {
  return {
    ...state,
    userFetched: false,
    fetchUserIsLoading: false,
    userError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const updatePasswordStart = state => {
  return {
    ...state,
    passwordUpdated: false,
    updatePasswordIsLoading: true,
    updateUserError: null,
  };
};

const updatePasswordSuccess = state => {
  return {
    ...state,
    passwordUpdated: true,
    updatePasswordIsLoading: false,
  };
};

const updatePasswordError = (state, payload) => {
  return {
    ...state,
    passwordUpdated: false,
    updatePasswordIsLoading: false,
    updatePasswordError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const updatePasswordReset = state => {
  return {
    ...state,
    passwordUpdated: false,
    updatePasswordIsLoading: false,
    updatePasswordError: null,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_USER_START:
      return updateUserStart(state);
    case UPDATE_USER_SUCCESS:
      return updateUserSuccess(state, payload);
    case UPDATE_USER_ERROR:
      return updateUserError(state, payload);
    case FETCH_USER_START:
      return fetchUserStart(state);
    case FETCH_USER_SUCCESS:
      return fetchUserSuccess(state, payload);
    case FETCH_USER_ERROR:
      return fetchUserError(state, payload);
    case UPDATE_PASSWORD_START:
      return updatePasswordStart(state);
    case UPDATE_PASSWORD_SUCCESS:
      return updatePasswordSuccess(state);
    case UPDATE_PASSWORD_ERROR:
      return updatePasswordError(state, payload);
    case UPDATE_PASSWORD_RESET:
      return updatePasswordReset(state);
    case LOCAL_PURGE:
      return initialState;
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

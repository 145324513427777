import { Wallet, WalletStore, WalletStoreType } from '@wallet/account';
import WalletZustandStore from './WalletZustandStore';

export const useWalletStore = (): WalletStore => {
  const wallet = WalletZustandStore((state: WalletStoreType) => state.wallet);
  const setWallet = WalletZustandStore(
    (state: WalletStoreType) => state.setWallet
  );
  const fetchingWallet = WalletZustandStore(
    (state: WalletStoreType) => state.fetchingWallet
  );
  const setFetchingWalletStore = WalletZustandStore(
    (state: WalletStoreType) => state.setFetchingWallet
  );

  const balance = WalletZustandStore((state: WalletStoreType) => state.balance);
  const setBalance = WalletZustandStore(
    (state: WalletStoreType) => state.setBalance
  );
  const fetchingBalance = WalletZustandStore(
    (state: WalletStoreType) => state.fetchingBalance
  );
  const setFetchingBalanceStore = WalletZustandStore(
    (state: WalletStoreType) => state.setFetchingBalance
  );

  const resetStore = WalletZustandStore(
    (state: WalletStoreType) => state.resetStore
  );

  const getBusinessWallet = () => {
    return wallet;
  };

  const setBusinessWallet = (businessWallet: Wallet) => {
    setWallet(businessWallet);
  };

  const getWalletBalance = () => {
    return balance;
  };

  const setWalletBalance = (amount: number) => {
    setBalance(amount);
  };

  const resetWalletStore = () => {
    resetStore();
  };

  const getFetchingBalance = () => {
    return fetchingBalance;
  };

  const setFetchingBalance = (isFetching: boolean) => {
    setFetchingBalanceStore(isFetching);
  };

  const getFetchingWallet = () => {
    return fetchingWallet;
  };

  const setFetchingWallet = (isFetching: boolean) => {
    setFetchingWalletStore(isFetching);
  };

  return {
    getBusinessWallet,
    setBusinessWallet,
    getWalletBalance,
    setWalletBalance,
    resetWalletStore,
    getFetchingBalance,
    setFetchingBalance,
    getFetchingWallet,
    setFetchingWallet,
  };
};

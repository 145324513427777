import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import styles from './styles';

type BaseDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
  title?: string;
  description?: string;
  children: React.ReactNode;
  hideCloseButton?: boolean;
  size?: 'md' | 'lg';
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
};

const baseDialogDefaultProps = {
  title: null,
  description: null,
  hideCloseButton: false,
  size: 'md',
  maxWidth: 'sm',
};

function BaseDialog(props: BaseDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = styles();
  const {
    isOpen,
    handleClose,
    title,
    description,
    children,
    hideCloseButton,
    size,
    maxWidth,
  } = props;

  const closeDialogHandler = (event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialogHandler}
      classes={{
        paper: classes.rootDialog,
      }}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
    >
      <DialogTitle classes={{ root: classes.dialogTitle }} disableTypography>
        {!hideCloseButton && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
            data-qa="dialog-close-button-action"
          >
            <CloseIcon />
          </IconButton>
        )}
        {title && (
          <Typography
            variant="h5"
            color="textPrimary"
            align={size === 'lg' ? 'left' : 'center'}
            component="div"
            className={classes.item}
          >
            <Box fontWeight="fontWeightBold">{title}</Box>
          </Typography>
        )}
        {description && (
          <Typography
            variant="body1"
            color="textPrimary"
            align={size === 'lg' ? 'left' : 'center'}
            component="div"
            className={classes.item}
          >
            {description}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        {children}
      </DialogContent>
    </Dialog>
  );
}

BaseDialog.defaultProps = baseDialogDefaultProps;

export default BaseDialog;

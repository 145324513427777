import React from 'react';
import { styled, SvgIcon } from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const CheckedCircleIcon = props => {
  const { style, checked } = props;
  if (checked) {
    return (
      <CustomSvgIcon
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path
          d="M8.5 0C4.08885 0 0.5 3.58885 0.5 8C0.5 12.4112 4.08885 16 8.5 16C12.9112 16 16.5 12.4112 16.5 8C16.5 3.58885 12.9112 0 8.5 0ZM12.6635 5.31885L7.49423 11.4727C7.43753 11.5402 7.36697 11.5948 7.28734 11.6327C7.20771 11.6706 7.12087 11.6909 7.03269 11.6923H7.02231C6.93605 11.6923 6.85077 11.6741 6.77199 11.639C6.6932 11.6039 6.62268 11.5526 6.565 11.4885L4.34962 9.02692C4.29335 8.96725 4.24959 8.89693 4.22089 8.8201C4.19219 8.74327 4.17914 8.66148 4.1825 8.57953C4.18587 8.49758 4.20558 8.41714 4.24047 8.34292C4.27537 8.2687 4.32476 8.2022 4.38572 8.14734C4.44668 8.09247 4.518 8.05035 4.59548 8.02345C4.67295 7.99654 4.75502 7.98539 4.83687 7.99065C4.91872 7.99592 4.99869 8.01749 5.07208 8.0541C5.14547 8.09071 5.2108 8.14162 5.26423 8.20385L7.00615 10.1392L11.7212 4.52731C11.8269 4.40503 11.9766 4.32928 12.1377 4.31644C12.2989 4.3036 12.4586 4.35469 12.5824 4.45868C12.7062 4.56266 12.7841 4.71119 12.7993 4.87215C12.8144 5.0331 12.7656 5.19356 12.6635 5.31885Z"
          fill="url(#paint0_linear_115_13740)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_115_13740"
            x1="2.02"
            y1="17.576"
            x2="21.3898"
            y2="4.73171"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D9732D" />
            <stop offset="0.470306" stopColor="#DF5B5B" />
            <stop offset="0.981571" stopColor="#C721DF" />
          </linearGradient>
        </defs>
      </CustomSvgIcon>
    );
  }
  return (
    <CustomSvgIcon
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <circle cx="8" cy="8" r="7.5" fill="white" stroke="#6D789F" />
    </CustomSvgIcon>
  );
};

CheckedCircleIcon.defaultProps = {
  style: {},
  checked: false,
};

CheckedCircleIcon.propTypes = {
  style: PropTypes.objectOf(Object),
  checked: PropTypes.bool,
};

export default CheckedCircleIcon;

import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { COUNTRY_CODE_CL, COUNTRY_CODE_MX } from '../helpers/constants';
import { fetchDebtService } from '../store/actions/debtServiceActions';
import { DebtServiceCard, SurplusCard, TgrCard } from './elements/Card';
import { features } from '../config/features';
import { fetchTgrDebt } from '../store/actions/businessActions';
import {
  fetchWalletStatus,
  createWalletRequest,
} from '../store/actions/walletActions';
import DocumentSummaryCard from './elements/Card/DocumentSummaryCard';
import CreditBalanceCard from './elements/Card/CreditBalanceCard';

const HomeCards = () => {
  const dispatch = useDispatch();
  const countryId = useSelector(
    state => state.businessState.business.countryId
  );
  const businessId = useSelector(state => state.businessState.business.id);
  const { balance, lastPendingWalletRequest, walletStatusIsLoading } =
    useSelector(state => state.walletState);
  const countryFeatures = features[countryId];

  useEffect(() => {
    if (businessId) {
      dispatch(fetchDebtService(businessId, 'business'));
      dispatch(fetchWalletStatus(businessId));
      if (countryId === COUNTRY_CODE_CL) {
        dispatch(fetchTgrDebt(businessId, 'business'));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cards = [
    {
      id: 'credit-balance-card',
      component: <CreditBalanceCard />,
      condition: () => countryId === COUNTRY_CODE_MX,
    },
    {
      id: 'tgr-card',
      component: <TgrCard />,
      condition: () => countryId === COUNTRY_CODE_CL,
    },
    {
      id: 'debt-service-card',
      component: <DebtServiceCard countryId={countryId} />,
    },
    {
      id: 'document-summary-card',
      component: <DocumentSummaryCard />,
      condition: () => countryId === COUNTRY_CODE_MX,
    },
    {
      id: 'surplus-card',
      component: (
        <SurplusCard
          loading={walletStatusIsLoading}
          countryId={countryId}
          balance={balance}
          lastPendingWalletRequest={lastPendingWalletRequest}
          createWalletRequest={() =>
            dispatch(createWalletRequest(businessId, 'EXCEDENTE'))
          }
        />
      ),
      condition: () => countryFeatures.cards.hasSurplus,
    },
  ];

  const filteredCards = cards.filter(
    card => !card.condition || card.condition()
  );

  return (
    <Grid container spacing={3}>
      {filteredCards.map(card => (
        <Grid id={card.id} item xs={12} md={4}>
          {card.component}
        </Grid>
      ))}
    </Grid>
  );
};

export default HomeCards;

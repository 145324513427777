import { GridCellParams, GridColDef } from '@material-ui/data-grid';
import CountryFormatHelper from 'components/elements/CountryFormatHelper';
import { Typography } from 'design-system/atoms';
import { PaymentDateSelector } from '@finance/components';
import {
  Box,
  IconButton,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import { DeleteIcon } from '@payments/commons/icons';
import { InfoIconTooltip } from '../../../components/tables-by-clients/info-icon-tooltip';
import { useTranslation } from '../../../../../../features/i18n/infra/useTranslation';

const useStyles = makeStyles(theme => ({
  expirationDate: {
    fontWeight: 'bold',
  },
  promotion: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    lineHeight: 1,
  },
  disabled: { color: theme.palette.text.disabled },
  green: {
    color: theme.palette.success.main,
  },
}));

function HeaderExpirationDate() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center">
      <Box pr={1}>
        <Typography variant="body3" className={classes.expirationDate}>
          {t('INVOICES_SELECTED_CART_TABLE_COLUMN_4')}
        </Typography>
      </Box>
      <InfoIconTooltip
        tooltipContent={t('INVOICES_SELECTED_DATE_PAY_DATE_HELP_TEXT')}
      />
    </Box>
  );
}

function CustomCountryFormatHelper({
  value,
  variant,
  countryId,
}: {
  value: string;
  variant: string;
  countryId: string;
}) {
  const percentageProps =
    variant === 'percentage' ? { useFixedDecimalPrecision: 2 } : {};
  return (
    <CountryFormatHelper
      value={value}
      variant={variant}
      countryId={countryId}
      {...percentageProps}
    />
  );
}

function RateColumn({ params, countryId }) {
  const classes = useStyles();

  if (!params.value) return <CircularProgress size={24} color="inherit" />;

  const { row, value } = params;
  const { promotionRate } = row;

  if (!promotionRate || promotionRate >= value)
    return (
      <CustomCountryFormatHelper
        value={value}
        variant="percentage"
        countryId={countryId}
      />
    );

  return (
    <Box className={classes.promotion}>
      <Box
        display="flex"
        alignItems="center"
        className={classes.green}
        fontWeight="fontWeightMedium"
      >
        <ArrowDropDown />
        <CustomCountryFormatHelper
          value={promotionRate}
          variant="percentage"
          countryId={countryId}
        />
      </Box>
      <Typography variant="tiny" className={classes.disabled}>
        Antes:{' '}
        <CustomCountryFormatHelper
          value={value}
          variant="percentage"
          countryId={countryId}
        />
      </Typography>
    </Box>
  );
}

function ButtonDelete({
  params,
  handleDelete,
}: {
  params: GridCellParams;
  handleDelete: (params: GridCellParams) => void;
}) {
  return (
    <IconButton size="small" onClick={() => handleDelete(params)}>
      <DeleteIcon />
    </IconButton>
  );
}

export const createColumns = ({
  t,
  countryId,
  handleDelete,
  handleChangeDate,
}): GridColDef[] => [
  {
    field: 'folio',
    headerName: t('INVOICES_SELECTED_CART_TABLE_COLUMN_1'),
    type: 'number',
    width: 78,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'payerName',
    headerName: t('INVOICES_SELECTED_CART_TABLE_COLUMN_2'),
    type: 'string',
    width: 200,
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'issueDate',
    headerName: t('INVOICES_SELECTED_CART_TABLE_COLUMN_3'),
    type: 'date',
    width: 140,
    renderCell: (params: GridCellParams) => (
      <CustomCountryFormatHelper
        value={String(params.value)}
        countryId={countryId}
        variant="longdate"
      />
    ),
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'expirationDate',
    renderHeader: () => <HeaderExpirationDate />,
    type: 'date',
    width: 170,
    renderCell: ({ row: invoice }: GridCellParams) => (
      <PaymentDateSelector
        invoice={invoice}
        handleChangeDate={handleChangeDate}
      />
    ),
    align: 'left',
    headerAlign: 'left',
  },
  {
    field: 'baseRate',
    headerName: t('INVOICES_SELECTED_CART_TABLE_COLUMN_5'),
    type: 'number',
    width: 90,
    renderCell: (params: GridCellParams) => (
      <RateColumn params={params} countryId={countryId} />
    ),
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'amount',
    headerName: t('INVOICES_SELECTED_CART_TABLE_COLUMN_6'),
    type: 'number',
    width: 130,
    renderCell: (params: GridCellParams) => (
      <CustomCountryFormatHelper
        countryId={countryId}
        value={String(params.value)}
        variant="currency"
      />
    ),
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: ' ',
    width: 25,
    renderCell: (params: GridCellParams) => (
      <ButtonDelete params={params} handleDelete={handleDelete} />
    ),
    align: 'right',
    headerAlign: 'right',
    sortable: false,
  },
];

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  tooltip: {
    background: theme.palette.background.paper,
    borderRadius: '0.5rem',
    boxShadow: '0px 0px 24px rgba(163, 171, 204, 0.16)',
    minHeight: '11rem',
    width: '19.25rem',
    padding: 0,
  },
  tooltipContent: {
    padding: '24px',
  },
  buttonContained: {
    marginTop: '1rem',
    minWidth: '10.6rem',
    height: '2.5rem',
    borderRadius: '10px',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    width: '31.5rem',
    height: '19.9rem',
    background: theme.palette.background.default,
    borderRadius: '1rem',
    position: 'relative',
    padding: '48px',
  },
  closeButton: {
    margin: 0,
    padding: 0,
    border: 'none',
    background: 'inherit',
    height: '15px',
    width: '15px',
    position: 'absolute',
    top: 10,
    right: 15,
    cursor: 'pointer',
  },
  buttonModal: {
    flexBasis: '48%',
    height: '56px',
    borderRadius: '8px',
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  titleModal: {
    textAlign: 'center',
    padding: '0px 20px',
    marginBottom: '1rem',
  },
  subtitleModal: {
    textAlign: 'center',
    marginBottom: '40px',
  },
}));

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { xTheme } from '@xepelinapp/x-ds';

const InvoiceFileIcon = () => {
  return (
    <SvgIcon
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill={xTheme.grey[300]} />
      <path
        d="M33 20V32.993C33.0009 33.1243 32.976 33.2545 32.9266 33.3762C32.8772 33.4979 32.8043 33.6087 32.7121 33.7022C32.6199 33.7957 32.5101 33.8701 32.3892 33.9212C32.2682 33.9723 32.1383 33.9991 32.007 34H15.993C15.7298 34 15.4774 33.8955 15.2912 33.7095C15.105 33.5235 15.0003 33.2712 15 33.008V14.992C15 14.455 15.449 14 16.002 14H26.997L33 20ZM31 21H26V16H17V32H31V21ZM20 19H23V21H20V19ZM20 23H28V25H20V23ZM20 27H28V29H20V27Z"
        fill={xTheme.grey[900]}
      />
    </SvgIcon>
  );
};

export default InvoiceFileIcon;

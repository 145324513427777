import { fetchBusinessDocumentSummary } from '../services/BusinessDocumentFetcher';
import { DocumentsSummary } from '../types/DocumentSummary.type';

export default class UnexpectedError extends Error {}

export const getDocumentStatusSummary = async (
  business: number
): Promise<DocumentsSummary> => {
  try {
    const docsSummary = await fetchBusinessDocumentSummary(business);

    return docsSummary;
  } catch (err) {
    throw new Error('Error fetching documents');
  }
};

import React from 'react';
import { Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CountryFormatHelper from './CountryFormatHelper';
import { CountryId } from '../../features/shared/types/Country.type';
import { Typography } from '../../design-system/atoms/index';

type OperationResumeAlertProps = {
  totalAmount: number;
  promotionsLimitAmount: number;
  countryId: CountryId;
};

function OperationResumeAlert({
  totalAmount,
  promotionsLimitAmount,
  countryId,
}: OperationResumeAlertProps) {
  return (
    <Box mt={2}>
      {totalAmount <= promotionsLimitAmount ? (
        <Alert severity="success">
          <Typography variant="body3">
            <Box fontWeight="fontWeightMedium" component="span">
              Descuento aplicado
            </Box>
          </Typography>
        </Alert>
      ) : (
        <Alert severity="warning">
          <Typography variant="body3" gutterBottom>
            <Box fontWeight="fontWeightMedium" component="span">
              Descuento no aplicado
            </Box>
          </Typography>
          <Typography variant="tiny">
            ¡Lo sentimos! Tu descuento no pudo ser aplicado porque la operación
            supera los{' '}
            {CountryFormatHelper({
              value: promotionsLimitAmount,
              variant: 'currency',
              countryId,
              dataQa: '',
            })}
          </Typography>
        </Alert>
      )}
    </Box>
  );
}

export default OperationResumeAlert;

import { Box, Link as MUILink, Grid } from '@material-ui/core';
import { CalendarIcon } from '@payments/commons/icons';
import moment from 'moment';
import Typography from 'design-system/atoms/Typography';
import { Dispatch, SetStateAction, useState } from 'react';
import { DATE_FORMAT } from 'helpers/constants';
import { GridRowData } from '@material-ui/data-grid';
import { Invoice } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';
import { theme } from 'design-system/theme';
import { DatePickerDialog } from '../date-picker-dialog';

type InvoiceDateProps = {
  inputDate: string;
  setDateDialog: Dispatch<SetStateAction<boolean>>;
};

function InvoiceDate({ inputDate, setDateDialog }: InvoiceDateProps) {
  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={() => setDateDialog(prev => !prev)}
    >
      <MUILink underline="always">
        <Box fontWeight="fontWeightBold">
          <Typography
            color="primary"
            variant="body3"
            style={{ fontWeight: theme.typography.fontWeightBold }}
          >
            {inputDate}
          </Typography>
        </Box>
      </MUILink>
      <Box ml={1} mt={1}>
        <CalendarIcon />
      </Box>
    </Box>
  );
}

type PaymentDateSelectorProps = {
  invoice: GridRowData;
  handleChangeDate: (invoice: Invoice) => void;
};

export function PaymentDateSelector({
  invoice,
  handleChangeDate,
}: PaymentDateSelectorProps) {
  const { expirationDate, isConfirmed } = invoice;

  const [renderDateDialog, setDateDialog] = useState<boolean>(false);
  const inputDate = moment.utc(expirationDate).format(DATE_FORMAT);

  return (
    <Grid container justifyContent="space-between">
      <InvoiceDate inputDate={inputDate} setDateDialog={setDateDialog} />
      {!isConfirmed && (
        <DatePickerDialog
          invoice={invoice}
          open={renderDateDialog}
          handleChangeDate={handleChangeDate}
          handleClose={() => {
            setDateDialog(false);
          }}
          setDateDialog={setDateDialog}
          invoiceDate={expirationDate}
        />
      )}
    </Grid>
  );
}

import { OrderType } from '@finance/useCases/OperationLineValues.type';

export type GetInvoicesParams = {
  token: string;
  businessId: string;
  pagination: PaginationParams;
  shoudFetchAllInvoices?: boolean;
};

export type Invoice = {
  id: number;
  folio: string;
  issueDate: string;
  amount: number;
  payerName: string;
  payerIdentifier: string;
  expirationDate: string | Date;
  prevExpirationDate?: string | Date;
  averageDaysLate: number;
  paymentMethod: string;
  totalAmountCreditNotes: number;
  categories?: Array<InvoiceCategories>;
};

export enum InvoiceCategories {
  earlyPayment = 'earlyPayment',
  directFinancing = 'directFinancing',
  exceedDaysToFinance = 'exceedDaysToFinance',
  blacklist = 'blacklist',
  rejectedInvoice = 'rejectedInvoice',
  invoiceAlreadyOrdered = 'invoiceAlreadyOrdered',
  transferedInvoice = 'transferedInvoice',
  noBillableCFDI = 'noBillableCFDI',
  noBillableVoucherType = 'noBillableVoucherType',
  noBillablePaymentMehod = 'noBillablePaymentMehod',
  noBillablePaymentType = 'noBillablePaymentType',
  noBillableCurrency = 'noBillableCurrency',
  claimed = 'claimed',
  cashPayment = 'cashPayment',
  freePayment = 'freePayment',
}

export type PaginationParams = {
  orderType: string;
  page: number;
  size: number;
  filter: string;
  order: string;
};

export type PaginationResponse = {
  page?: number;
  totalPages?: number;
  pageSize?: number;
  totalData?: number;
};

export type InvoicesResponse = {
  data: Invoice[];
  pagination?: PaginationResponse;
};

export type Order = {
  createdOrder: {
    isLocked: boolean;
    id: number;
    businessId: number;
    orderType: `${OrderType}`;
    status: string;
  };
};

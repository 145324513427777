import { urls } from '@features/libs/urls';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '../../features/i18n/infra/useTranslation';
import { COUNTRY_CODE_CL, ONBOARDING_STEP_CASES } from '../helpers/constants';
import { RootState } from '../store/reducers/rootReducer';
import { t13s } from '../config/translationKeys';

const useOnboardingStepper = () => {
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(1);

  const { identifier, onboardingStep, countryId } = useSelector(
    (state: RootState) => state.businessState?.business
  );

  // Check RFC character count to know if user is a legal entity (Persona Moral)
  const isLegalEntity = identifier?.length === 12;

  const {
    ADD_TAX_CREDENTIALS,
    AUTHORIZE_DEBT_SERVICE,
    ADD_LEGAL_REPRESENTATIVE,
    ONBOARDING_COMPLETED,
  } = ONBOARDING_STEP_CASES;

  const isOnboardingCompleted = onboardingStep === ONBOARDING_COMPLETED;

  useEffect(() => {
    switch (onboardingStep) {
      case ADD_TAX_CREDENTIALS:
        setActiveStep(1);
        break;
      case AUTHORIZE_DEBT_SERVICE:
        setActiveStep(2);
        break;
      case ADD_LEGAL_REPRESENTATIVE:
        setActiveStep(3);
        break;
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingStep]);

  function getSteps() {
    if (countryId === COUNTRY_CODE_CL) {
      return ['Registrar usuario', 'Vincular SII'];
    }
    return [
      'Registrar usuario',
      'Vincular SAT',
      'Autorizar Buró',
      ...(isLegalEntity ? ['Añadir representante'] : []),
    ];
  }

  const steps = getSteps();

  const isLastStep = steps.length - activeStep === 1;
  const stepContentDescription = isLastStep
    ? 'Estás a un paso de completar tu perfil para acceder a financiamiento'
    : 'Termina de completar tu perfil para acceder a financiamiento';

  const authBuroLink = isLegalEntity
    ? urls.legalEntityBuroAuth
    : urls.naturalPersonBuroAuth;

  const stepContent = [
    // Skip unnecessary content for first step
    null,
    {
      description: stepContentDescription,
      buttonLinkLabel: `Vincular cuenta ${t(
        t13s.LABEL.TAX_SERVICE_SHORT_NAME
      )}`,
      buttonLinkPath: '/auth/tax-credentials/',
    },
    {
      description: stepContentDescription,
      buttonLinkLabel: 'Autorizar Buró',
      buttonLinkPath: authBuroLink,
    },
    {
      description: stepContentDescription,
      buttonLinkLabel: 'Añadir representante',
      buttonLinkPath: urls.addOnboardingLegalRepresentative,
    },
  ];

  return {
    activeStep,
    steps,
    stepContent,
    isOnboardingCompleted,
  };
};

export default useOnboardingStepper;

import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Card from './Card';
import { COUNTRY_CODE_CL } from '../../../helpers/constants';
import CountryFormatHelper from '../CountryFormatHelper';
import TgrIcon from '../../icons/TgrIcon';
import { Typography } from '../../../design-system/atoms/index.ts';

const useStyles = makeStyles({
  cardRoot: {
    height: '100%',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '100%',
    minWidth: 48,
    height: 48,
  },
});

const TgrCard = () => {
  const classes = useStyles();
  const { tgrDebt, tgrDebtIsLoading } = useSelector(
    state => state.businessState
  );
  const { totalTGRDebt: totalAmount, updatedAt } = tgrDebt || {};

  if (tgrDebtIsLoading) {
    return (
      <Card variant="white" border className={classes.cardRoot}>
        <Box p={2} display="flex">
          <Box className={classes.iconContainer} bgcolor="#F5F5F6" />
          <Box pl={2}>
            <Typography variant="body2" color="textPrimary">
              <Skeleton width="100%" />
            </Typography>
            <Skeleton />
            <Box pt={2} />
          </Box>
        </Box>
      </Card>
    );
  }

  return (
    <Card variant="white" border className={classes.cardRoot}>
      <Box p={3} display="flex">
        <Box className={classes.iconContainer} bgcolor="#F5F5F6">
          <TgrIcon color="secondary" />
        </Box>
        <Box pl={2}>
          <Typography variant="body2" color="textPrimary">
            <Box fontWeight="fontWeightBold">TGR</Box>
          </Typography>
          <Typography variant="body2" color="textPrimary" component="p">
            <CountryFormatHelper
              value={totalAmount}
              countryId={COUNTRY_CODE_CL}
              variant="currency"
            />
          </Typography>
          <Box pt={2} />
          <Typography variant="tiny" color="textPrimary" component="p">
            Última consulta
          </Typography>
          <Typography variant="body3" color="textPrimary">
            <Box mb={0.5} fontWeight="fontWeightBold">
              <CountryFormatHelper
                value={updatedAt}
                variant="longdate"
                countryId={COUNTRY_CODE_CL}
              />
            </Box>
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default TgrCard;

import React from 'react';
import { Box } from '@material-ui/core';
import { Typography } from 'design-system/atoms';
import { useSpacingStyles } from '../../../../features/shared/styles/spacings';
import { useTranslation } from '../../../../../features/i18n/infra/useTranslation';

export function TitleFinance() {
  const spacing = useSpacingStyles();

  const { t } = useTranslation();

  return (
    <Box className={spacing.my4}>
      <Typography variant="h4" className={spacing.my1}>
        {t('INVOICES_SELECTION_TITLE')}
      </Typography>
      <Typography variant="body2" className={spacing.my1}>
        {t('INVOICES_SELECTION_TITLE_DESCRIPTION')}
      </Typography>
    </Box>
  );
}

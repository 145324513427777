import axios from 'helpers/axios';
import { OrderType } from '@finance/useCases/OperationLineValues.type';
import { Invoice } from '../invoicesServicesTypes';

export const simulationService = async ({
  orderType,
  businessId,
  invoices,
}: {
  businessId: string;
  orderType: `${OrderType}`;
  invoices: Invoice[];
}) => {
  try {
    const response = await axios.post('/api/orders/calculate', {
      orderType,
      businessId,
      autoRate: 'NORMAL',
      invoices: invoices.map(invoice => {
        return {
          invoiceId: invoice.id,
          expirationDate: invoice.expirationDate,
        };
      }),
    });

    return response.data;
  } catch (error) {
    throw new Error(error?.response?.data?.errorCode);
  }
};

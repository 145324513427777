import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@features/i18n/infra/useTranslation';
import { RootState } from 'store/reducers/rootReducer';
import { GridCellParams } from '@material-ui/data-grid';
import useGetInvoicesByIds from '@integration-services/xepelin-server-global/invoices/hooks/useGetInvoicesById';
import useSimulation from '@integration-services/xepelin-server-global/invoices/hooks/useSimulation';
import useSubmitOrder from '@integration-services/xepelin-server-global/invoices/hooks/useSubmitOrder';
import { useRouter } from 'next/router';
import { encode } from 'helpers/base64';
import { useOperationType } from '@finance/hooks/migration/useOperationType';
import { useSnackbar } from 'notistack';
import { getPaths } from '@finance/utils/utils';
import useTracker from '@finance/hooks/useTracker';
import * as cacheService from '@finance/services/selectedCache';
import { Invoice } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';
import { createColumns } from './createColumns';
import { useSelectedInvoiceStore } from '../../../stores/useSelectedInvoiceStore';
import { useSelectionInvoiceStore } from '../../../stores/useSelectionInvoiceStore';
import { useManageFilters } from '../useManageFilters';

export const useInvoiceSelected = () => {
  const initialPageSize: number = 10;

  const [pageSize, setPageSize] = useState<number>(initialPageSize);

  const { enqueueSnackbar } = useSnackbar();

  const { financeCallbacks } = useTracker();

  const router = useRouter();

  const operationType = useOperationType();

  const { countryId } = useSelector(
    (state: RootState) => state.businessState.business
  );

  const { earlyPaymentCount, directFinancingCount } = useSelector<
    RootState,
    { earlyPaymentCount: number; directFinancingCount: number }
  >(state => state.cartState);

  const {
    selectedInvoices,
    simulation,
    handleSimulation,
    handleDelete: onDelete,
    resetState,
    handleAdd,
    onChangeDate,
  } = useSelectedInvoiceStore();

  const { resetState: resetSelection } = useSelectionInvoiceStore();

  const { mutate, isLoading: isLoadingSimulation } = useSimulation({
    onSuccess: (data, selected) => {
      handleSimulation(data);
      financeCallbacks.cartSimulateSuccess({
        simulation: data,
        availableInvoicesCount:
          operationType === 'DIRECT_FINANCING'
            ? directFinancingCount
            : earlyPaymentCount,
        selectedCartInvoices: selected,
      });
    },
    onError: () => {
      enqueueSnackbar('Error inesperado', {
        variant: 'error',
      });
    },
  });

  const { isLoading: isLoadingInvoices } = useGetInvoicesByIds({
    onSuccess: data => {
      handleAdd(data);
      mutate(data);
    },
    onError: () => {
      enqueueSnackbar('Error inesperado', {
        variant: 'error',
      });
    },
  });

  const { setInitialState } = useManageFilters();
  const { isLoading: isLoadingSubmit, handleSubmit } = useSubmitOrder({
    financeableInvoices: selectedInvoices,
    onSuccess: data => {
      router.push('/finance/order');
      financeCallbacks.orderSubmitedSuccess({
        selectedCartInvoices: selectedInvoices,
        availableInvoicesCount:
          operationType === 'DIRECT_FINANCING'
            ? directFinancingCount
            : earlyPaymentCount,
        simulation,
        orderId: data.createdOrder.id,
        businessId: data.createdOrder.businessId,
        isModified: false,
      });
      setInitialState();
      resetSelection();
      cacheService.deleteInvoices();
    },
    onError: () => {
      enqueueSnackbar('Error inesperado', {
        variant: 'error',
      });
    },
  });

  const { t } = useTranslation();

  const handlePageSize = (newPageSize: number) => setPageSize(newPageSize);

  const handleChangeDate = (invoice: Invoice) => {
    const newInvoices = onChangeDate(invoice);
    mutate(newInvoices);
  };

  const handleDelete = (params: GridCellParams) => {
    const newInvoices = onDelete(params);

    const tab = getPaths(operationType);

    router.replace(
      `/finance/${tab}/calculate/#${encode(newInvoices.map(({ id }) => id))}`,
      undefined,
      { shallow: true }
    );

    mutate(newInvoices);
  };

  const columns = createColumns({
    t,
    countryId,
    handleDelete,
    handleChangeDate,
  });

  return {
    state: {
      isLoadingInvoices,
      isLoadingSimulation,
      isLoadingSubmit,
      columns,
      selectedInvoices,
      simulation,
      pageSize,
    },
    callbacks: {
      handlePageSize,
      handleChangeDate,
      resetState,
      handleSubmit,
    },
  };
};

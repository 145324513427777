import { Invoice } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';
import { GridSelectionModel } from '@material-ui/data-grid';

export const hasAllSelected = (
  invoices: Invoice[],
  selectionModel: GridSelectionModel
) => {
  if (!invoices) return false;

  const allSelected = invoices.reduce((acc, element) => {
    if (selectionModel.includes(element.id)) {
      return [...acc, true];
    }

    return [...acc, false];
  }, []);

  return !allSelected.includes(false);
};

import { Box, CircularProgress } from '@material-ui/core';
import { Typography } from 'design-system/atoms';
import { theme } from 'design-system/theme';
import { useTranslation } from '../../../../../features/i18n/infra/useTranslation';
import { useSpacingStyles } from '../../../../features/shared/styles/spacings';

export function HistoryProcessingMessage() {
  const { t } = useTranslation();
  const spacing = useSpacingStyles();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      minHeight="400px"
    >
      <Box>
        <Box textAlign="center" className={spacing.mb4}>
          <CircularProgress style={{ color: theme.palette.grey[700] }} />
        </Box>
        <Box textAlign="center" className={spacing.mb1}>
          <Typography variant="body1" style={{ fontWeight: 'bold' }}>
            {t('INVOICES_HISTORY_PROCESSING_TITLE')}
          </Typography>
        </Box>
        <Box textAlign="center">
          <Typography variant="body2" color="textSecondary">
            {t('INVOICES_HISTORY_PROCESSING_DESCRIPTION')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

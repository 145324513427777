import * as actions from './actions/actionTypes';
import { StatsState, FetchStatsResponse } from './stats-types';

interface StatsActionParams {
  type: string;
  payload: FetchStatsResponse;
}

const initialState = {
  stats: null,
  isStatsLoading: false,
};

function statsReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  { type, payload }: StatsActionParams
): StatsState {
  switch (type) {
    case actions.FETCH_PAYMENTS_STATS_START:
      return {
        ...state,
        isStatsLoading: true,
      };

    case actions.FETCH_PAYMENTS_STATS_SUCCESS:
      return {
        isStatsLoading: false,
        stats: { ...payload.stats },
      };

    case actions.FETCH_PAYMENTS_STATS_ERROR:
      return {
        ...state,
        isStatsLoading: false,
      };

    default:
      return state;
  }
}

export default statsReducer;

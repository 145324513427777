export const urls = {
  addLegalRepresentative: '/legal-representatives/add',
  addOnboardingLegalRepresentative: '/auth/debt-service/legal-representative',
  authLogin: '/auth/login',
  authRegister: '/auth/register',
  blog: 'https://xepelin.com/blog',
  businessRegister: '/auth/business-register',
  completeRegister: '/auth/complete-register',
  directFinance: '/finance/directo',
  duePays: '/due-pays',
  duePaysSummary: '/due-pays/pay-summary',
  earlyPayment: '/finance/prontopago',
  forgotPassword: '/forgot-password',
  help: 'https://ayuda.xepelin.com/',
  home: '/home',
  impersonate: '/impersonate/',
  landing: 'https://xepelin.com',
  legalEntityBuroAuth: '/auth/debt-service/buro',
  legalRepresentativeRegister: '/legal-representatives/register',
  login: '/login',
  maintenance: '/maintenance',
  naturalPersonBuroAuth: '/auth/debt-service/natural-person',
  notFound: '/404',
  orders: '/orders',
  payments: '/payments',
  register: '/register',
  root: '/',
  suppliers: '/suppliers',
  taxCredentials: '/auth/tax-credentials',
  taxRegister: '/auth/tax-register',
  userProfile: '/user/profile',
  welcome: '/auth/welcome',
  xtracker: '/xtracker',
};

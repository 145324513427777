import React, { useCallback } from 'react';
import { createStyles, LinearProgress, makeStyles } from '@material-ui/core';
import { XThemeOptions } from 'design-system/theme/types';
import { Container } from 'design-system/atoms/layout';
import { Spacer } from '@features/shared';
import { StepperProps } from './types';
import useStepper from './useStepper';
import AppBarStepper from './AppBarStepper';

const useStyles = makeStyles((theme: XThemeOptions) =>
  createStyles({
    progress: {
      backgroundColor: theme.palette.grey[400],
      '& > div': {
        background: theme.palette.gradient,
      },
    },
  })
);

function Stepper({
  steps,
  stepCompleted,
  showAppbar = false,
  showProgressbar = false,
  onFinish,
}: StepperProps) {
  const { goBack, goNext, currentStepPosition, currentStep, getProgress } =
    useStepper({
      steps,
      stepCompleted,
    });
  const classes = useStyles();

  const newStepComponent = React.cloneElement(currentStep.component, {
    stepperStore: {
      goBack,
      goNext,
      onFinish,
      currentStepPosition,
      totalSteps: steps.length,
    },
  });

  return (
    <>
      {showAppbar && (
        <AppBarStepper
          onFinish={useCallback(() => {
            onFinish();
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [])}
          goBack={useCallback(() => {
            if (currentStepPosition === 0) {
              onFinish();
            } else {
              goBack();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [currentStepPosition])}
        />
      )}
      {showProgressbar && (
        <LinearProgress
          variant="determinate"
          value={getProgress()}
          className={classes.progress}
        />
      )}
      <Container maxWidth="xs">
        <Spacer spacing={8} />
        {newStepComponent}
      </Container>
    </>
  );
}

export default Stepper;

import { Box, Button, makeStyles } from '@material-ui/core';
import { useTranslation } from '@features/i18n/infra/useTranslation';
import { useEffect, useRef } from 'react';
import useTracker from '@finance/hooks/useTracker';
import LogoMessage from 'components/elements/LogoMessage';
import { useInvoiceSelection } from '@finance/hooks/migration/useInvoiceSelection';
import invoicesEmpty from '../../../../assets/invoicesEmpty.png';
import { Typography } from '../../../../design-system/atoms';

export const useStyles = makeStyles(theme => ({
  actionButton: {
    zIndex: 900,
    '&:hover': {
      cursor: 'pointer !important',
    },
  },
  buttonText: {
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
}));

export function FilteredInvoicesNotFound() {
  const { t } = useTranslation();
  const classes = useStyles();
  const { financeCallbacks: trackerCallbacks } = useTracker();
  const firstRender = useRef(true);
  const { state, callbacks } = useInvoiceSelection();

  useEffect(() => {
    if (firstRender.current) {
      trackerCallbacks.trackFilterNoResults(state.filter);
      firstRender.current = false;
    }
  }, [state.filter, trackerCallbacks]);

  const filteredNotFoundTitle = `${t('INVOICES_FILTERED_NOTFOUND_TITLE')} "${
    state.filter
  }"`;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      flexDirection="column"
      minHeight="400px"
    >
      <LogoMessage
        logo={invoicesEmpty.src}
        title={filteredNotFoundTitle}
        subtitle={t('INVOICES_FILTERED_NOTFOUND_DESCRIPTION')}
      />
      <Box mt={3} display="flex" justifyContent="center">
        <Button
          onClick={callbacks.clearInputRemote}
          className={classes.actionButton}
        >
          <Typography
            variant="body2"
            style={{ fontWeight: 'bold' }}
            className={classes.buttonText}
          >
            {t('INVOICES_CLEAN_SEARCH')}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}

import React, { useEffect } from 'react';
import { Spacer } from '@features/shared';
import { FullScreenDialog, Stepper } from '../common';
import { Amount, Account, Success } from './steps';
import useWithdrawalModal from './useWithdrawalModal';

interface WithdrawalModalProps {
  open: boolean;
  onClose: () => void;
}

const COMPLETE_STEP = {
  component: <Success />,
};

function WithdrawalModal({ open, onClose }: WithdrawalModalProps) {
  const { resetForm, fetchWithdrawalAccounts, fetchBanks } =
    useWithdrawalModal();

  const STEPS = [
    {
      component: <Amount />,
    },
    {
      component: <Account />,
    },
  ];

  useEffect(() => {
    fetchWithdrawalAccounts();
    resetForm();
    fetchBanks();
    // eslint-disable-next-line
  }, []);

  const onCloseDialog = () => {
    resetForm();
    onClose();
  };

  return (
    <FullScreenDialog open={open}>
      <Spacer spacing={8} />
      <Stepper
        steps={STEPS}
        stepCompleted={COMPLETE_STEP}
        showAppbar
        showProgressbar
        onFinish={onCloseDialog}
      />
    </FullScreenDialog>
  );
}

export default WithdrawalModal;

import {
  wsConnected,
  wsReconnect,
  wsDisconnected,
  wsBusinessNotified,
  wsConnect,
  wsAddNewMessage,
} from '../../store/actions/websocketActions';
import {
  WS_CONNECT_MIDDLEWARE,
  WS_DISCONNECT_MIDDLEWARE,
  WS_RECONNECT_MIDDLEWARE,
  WS_CREATE_CONNECTION_MIDDLEWARE,
} from '../../store/actions/types';
import { WS_COMMANDS } from '../../helpers/constants';

const { CREATE_WS_CONNECTION } = WS_COMMANDS;

const socketMiddleware = () => {
  let socket = null;
  const autoReconnectInterval = 1 * 5000;
  let autoReconnect;

  const onOpen =
    ({ dispatch }) =>
    () => {
      if (autoReconnect) clearTimeout(autoReconnect);
      dispatch(wsConnected());
    };

  const onClose =
    ({ dispatch }) =>
    () => {
      dispatch(wsReconnect());
    };

  const onMessage = store => event => {
    const { message } = JSON.parse(event.data);
    if (message?.command) {
      store.dispatch(wsAddNewMessage({ message }));
    }
  };

  // eslint-disable-next-line consistent-return
  return store => next => action => {
    const { type, payload } = action;

    switch (type) {
      case WS_CONNECT_MIDDLEWARE:
        if (socket) {
          socket.close();
        }
        // connect to the remote host
        socket = new WebSocket(process.env.NEXT_PUBLIC_WS_URL);
        // websocket handlers
        socket.onmessage = onMessage(store);
        socket.onclose = onClose(store);
        socket.onopen = onOpen(store);

        break;

      case WS_DISCONNECT_MIDDLEWARE:
        if (socket) {
          socket.close();
        }
        socket = null;
        break;

      case WS_RECONNECT_MIDDLEWARE:
        store.dispatch(wsDisconnected());
        autoReconnect = setTimeout(() => {
          store.dispatch(wsConnect());
        }, autoReconnectInterval);

        break;
      case WS_CREATE_CONNECTION_MIDDLEWARE:
        if (socket) {
          socket.send(
            JSON.stringify({
              command: CREATE_WS_CONNECTION,
              businessId: payload.businessId,
            })
          );
          store.dispatch(wsBusinessNotified());
        }
        break;

      default:
        return next(action);
    }
  };
};

export default socketMiddleware();

import React from 'react';
import {
  MenuItem as MenuItemMui,
  Box,
  makeStyles,
  MenuItemProps,
} from '@material-ui/core';
import { Typography } from '../../design-system/atoms';

const useStyles = makeStyles({
  root: {
    margin: '0 -1px',
    height: 36,
  },
  icon: {
    marginRight: 8,
  },
});

interface IMenuItemProps extends MenuItemProps {
  icon?: React.ReactNode;
  key: string;
  disabled?: boolean;
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const MenuItem = React.forwardRef<HTMLLIElement, IMenuItemProps>(
  ({ children, onClick, key, disabled, icon }, ref) => {
    const classes = useStyles();

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <MenuItemMui key={key} onClick={onClick} ref={ref} disabled={disabled}>
        <Box display="inline-flex" alignItems="center" className={classes.root}>
          {icon && (
            <Box className={classes.icon} display="flex" alignItems="center">
              {icon}
            </Box>
          )}
          <Typography variant="body3" color="textPrimary">
            {children}
          </Typography>
        </Box>
      </MenuItemMui>
    );
  }
);

MenuItem.defaultProps = {
  icon: null,
  disabled: false,
};

export default MenuItem;

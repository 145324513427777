import React, { ChangeEvent } from 'react';
import { Box, makeStyles, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[400],
    borderRadius: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
  },
  dropdown: {
    width: '100%',
  },
}));

export type DropdownItem = {
  label: JSX.Element | string;
  value: string | number;
};

type DropdownProps = {
  id: string;
  items: DropdownItem[];
  value: string | number;
  onChange: (value: string | number) => void;
};

function Dropdown(props: DropdownProps) {
  const styles = useStyles();
  const { id, items, value, onChange } = props;

  const handleOnChange = (event: ChangeEvent<{ value: string | number }>) => {
    onChange(event.target.value);
  };

  return (
    <Box className={styles.container}>
      <Select
        className={styles.dropdown}
        labelId={`${id}Label`}
        id={id}
        value={value}
        onChange={handleOnChange}
        disableUnderline
      >
        {items.map(item => (
          <MenuItem value={item.value}>{item.label}</MenuItem>
        ))}
      </Select>
    </Box>
  );
}

export default Dropdown;

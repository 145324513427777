import { useTranslation } from '@features/i18n/infra/useTranslation';
import { useOperationType } from '@finance/hooks/migration/useOperationType';
import { InvoiceCategories } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';
import { isFinanceableInvoice } from '@finance/utils/isFinanceableInvoice';
import { useRef } from 'react';

type PriorizedCategoriesInfoType = {
  [key in InvoiceCategories | 'default']: {
    priorizationNumber: number;
    nonFinancingReasonText: string;
  };
};

export const translationKeysReasons = {
  [InvoiceCategories.cashPayment]:
    'INVOICES_SELECTION_NON_FINANCING_BY_CASH_PAYMENT',
  [InvoiceCategories.freePayment]:
    'INVOICES_SELECTION_NON_FINANCING_BY_FREE_PAYMENT',
  [InvoiceCategories.noBillableCurrency]:
    'INVOICES_SELECTION_NON_FINANCING_BY_NO_BILLABLE_CURRENCY',
  [InvoiceCategories.noBillableCFDI]:
    'INVOICES_SELECTION_NON_FINANCING_BY_NO_BILLABLE_CFDI',
  [InvoiceCategories.noBillablePaymentMehod]:
    'INVOICES_SELECTION_NON_FINANCING_BY_NO_BILLABLE_PAYMENT_METHOD',
  [InvoiceCategories.noBillablePaymentType]:
    'INVOICES_SELECTION_NON_FINANCING_BY_NO_BILLABLE_PAYMENT_TYPE',
  [InvoiceCategories.noBillableVoucherType]:
    'INVOICES_SELECTION_NON_FINANCING_BY_NO_BILLABLE_VOUCHER_TYPE',
  [InvoiceCategories.invoiceAlreadyOrdered]:
    'INVOICES_SELECTION_NON_FINANCING_BY_INVOICE_ALREADY_ORDERED',
  [InvoiceCategories.exceedDaysToFinance]:
    'INVOICES_SELECTION_NON_FINANCING_BY_EXCEED_DAYS_TO_FINANCE',
  [InvoiceCategories.rejectedInvoice]:
    'INVOICES_SELECTION_NON_FINANCING_BY_REJECTED_INVOICE',
  [InvoiceCategories.claimed]: 'INVOICES_SELECTION_NON_FINANCING_BY_CLAIMED',
  [InvoiceCategories.transferedInvoice]:
    'INVOICES_SELECTION_NON_FINANCING_BY_TRANSFERED_INVOICE',
  [InvoiceCategories.earlyPayment]:
    'INVOICES_SELECTION_NON_FINANCING_BY_EARLY_PAYMENT',
  [InvoiceCategories.blacklist]:
    'INVOICES_SELECTION_NON_FINANCING_BY_BLACKLIST',
  [InvoiceCategories.directFinancing]:
    'INVOICES_SELECTION_NON_FINANCING_BY_DIRECT_FINANCING',
};

/**
 * This is the priority order of non-financing invoices reasons
 * You can find the prioritization definition in the description
 * of next ticket
 * @link https://xepelin.atlassian.net/browse/AR-93
 */
const priorizedCategoriesInfo: PriorizedCategoriesInfoType = {
  [InvoiceCategories.cashPayment]: {
    priorizationNumber: 1,
    nonFinancingReasonText:
      translationKeysReasons[InvoiceCategories.cashPayment],
  },
  [InvoiceCategories.freePayment]: {
    priorizationNumber: 2,
    nonFinancingReasonText:
      translationKeysReasons[InvoiceCategories.freePayment],
  },
  [InvoiceCategories.noBillableCurrency]: {
    priorizationNumber: 3,
    nonFinancingReasonText:
      translationKeysReasons[InvoiceCategories.noBillableCurrency],
  },
  [InvoiceCategories.noBillableCFDI]: {
    priorizationNumber: 4,
    nonFinancingReasonText:
      translationKeysReasons[InvoiceCategories.noBillableCFDI],
  },
  [InvoiceCategories.noBillablePaymentMehod]: {
    priorizationNumber: 5,
    nonFinancingReasonText:
      translationKeysReasons[InvoiceCategories.noBillablePaymentMehod],
  },
  [InvoiceCategories.noBillablePaymentType]: {
    priorizationNumber: 6,
    nonFinancingReasonText:
      translationKeysReasons[InvoiceCategories.noBillablePaymentType],
  },
  [InvoiceCategories.noBillableVoucherType]: {
    priorizationNumber: 7,
    nonFinancingReasonText:
      translationKeysReasons[InvoiceCategories.noBillableVoucherType],
  },
  [InvoiceCategories.invoiceAlreadyOrdered]: {
    priorizationNumber: 8,
    nonFinancingReasonText:
      translationKeysReasons[InvoiceCategories.invoiceAlreadyOrdered],
  },
  [InvoiceCategories.exceedDaysToFinance]: {
    priorizationNumber: 9,
    nonFinancingReasonText:
      translationKeysReasons[InvoiceCategories.exceedDaysToFinance],
  },
  [InvoiceCategories.rejectedInvoice]: {
    priorizationNumber: 10,
    nonFinancingReasonText:
      translationKeysReasons[InvoiceCategories.rejectedInvoice],
  },
  [InvoiceCategories.claimed]: {
    priorizationNumber: 11,
    nonFinancingReasonText: translationKeysReasons[InvoiceCategories.claimed],
  },
  [InvoiceCategories.transferedInvoice]: {
    priorizationNumber: 12,
    nonFinancingReasonText:
      translationKeysReasons[InvoiceCategories.transferedInvoice],
  },
  [InvoiceCategories.earlyPayment]: {
    priorizationNumber: 13,
    nonFinancingReasonText:
      translationKeysReasons[InvoiceCategories.earlyPayment],
  },
  [InvoiceCategories.blacklist]: {
    priorizationNumber: 14,
    nonFinancingReasonText: translationKeysReasons[InvoiceCategories.blacklist],
  },
  [InvoiceCategories.directFinancing]: {
    priorizationNumber: 15,
    nonFinancingReasonText:
      translationKeysReasons[InvoiceCategories.directFinancing],
  },
  default: {
    priorizationNumber: 16,
    nonFinancingReasonText: 'INVOICES_SELECTION_NON_FINANCING_BY_DEFAULT',
  },
};

const getNonFinancingReasonHighestPriority = (
  priorizedCategoriesInformation: PriorizedCategoriesInfoType,
  invoiceCategories: InvoiceCategories[]
): string => {
  const invoicesCategoriesWithPriorizationData = invoiceCategories?.map(
    invoiceCategory => ({
      ...(priorizedCategoriesInformation[invoiceCategory]
        ? priorizedCategoriesInformation[invoiceCategory]
        : priorizedCategoriesInformation.default),
    })
  );
  const sortedPriorizedInvoicesCategories =
    invoicesCategoriesWithPriorizationData?.sort(
      (a, b) => a.priorizationNumber - b.priorizationNumber
    );
  const [highestPriorityReason] = sortedPriorizedInvoicesCategories || [];
  return highestPriorityReason?.nonFinancingReasonText;
};

/**
 * This function returns a string with the non-financing reason.
 * The invoice could have different reasons to be non-financing, so this function returns
 * the most priority reason
 */
export const useGetNonFinancingReasonByCategory = (
  categories: InvoiceCategories[]
): string | undefined => {
  const memo = useRef(null);

  const operationType = useOperationType();

  const { t } = useTranslation();

  if (memo.current !== null) return memo.current;

  const isFinanciable = isFinanceableInvoice({
    categories,
    operationType,
  });
  if (isFinanciable) return undefined;
  const nonFinancingReason = getNonFinancingReasonHighestPriority(
    priorizedCategoriesInfo,
    categories
  );

  memo.current = nonFinancingReason ? t(nonFinancingReason) : undefined;

  return memo.current;
};

import { Invoice } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';

export const getUniques = (array: Invoice[]) => {
  const uniqueStrings = new Set(
    array.map(item => {
      return JSON.stringify(item);
    })
  );
  const uniqueStringsArray = Array.from(uniqueStrings);
  return uniqueStringsArray.map(item => JSON.parse(item));
};

/* eslint-disable no-param-reassign */
import produce from 'immer';
import create from 'zustand';
import { pipe } from 'ramda';
import { persist, devtools } from 'zustand/middleware';

const STORE_NAME = 'WalletMovementsStore';

const immer = config => (set, get, api) =>
  config(
    (partial, replace) => {
      const nextState =
        typeof partial === 'function' ? produce(partial) : partial;
      return set(nextState, replace);
    },
    get,
    api
  );

const persistence = config =>
  persist(config, {
    name: STORE_NAME,
  });

const createStore = pipe(persistence, immer, devtools, create);

const MovementsZustandStore = createStore(set => ({
  movements: [],
  setMovements: movements => {
    set(state => {
      state.movements = movements;
    });
  },
  isFetchingMovements: false,
  setIsFetchingMovements: isFetching => {
    set(state => {
      state.isFetchingMovements = isFetching;
    });
  },

  resetStore: () => {
    set(state => {
      state.movements = [];
    });
  },
}));

export default MovementsZustandStore;

import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import setAuthToken from '../../../../src/helpers/setAuthToken';
import { logoutUser } from '../../../../src/store/actions/authenticationActions';
import { enqueueSnackbar } from '../../../../src/store/actions/notificationActions';
import { t13s } from '../../../../src/config/translationKeys';
import { useAuthStore } from '../../../auth/infra/store/useAuthStore';

export const useCheckAuthExpiration = () => {
  const dispatch = useDispatch();
  const authStore = useAuthStore();

  useEffect(() => {
    let tokenData = null;
    if (authStore.token?.value) {
      tokenData = jwtDecode(authStore.token.value);
    }
    const date = new Date(Number(tokenData?.exp) * 1000);
    if (moment(date).isBefore()) {
      dispatch(
        enqueueSnackbar({
          message: t13s.NOTIFICATION.SESSION_EXPIRED,
          options: {
            variant: 'warning',
          },
        })
      );
      dispatch(logoutUser());
    } else if (tokenData !== null) {
      setAuthToken(authStore.token.value);
    }
  }, [authStore.token.value, dispatch]);
};

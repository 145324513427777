export enum SortingTypes {
  'issueDate:desc' = 'issueDate:desc',
  'issueDate:asc' = 'issueDate:asc',
  'amount:desc' = 'amount:desc',
  'amount:asc' = 'amount:asc',
  'folio:desc' = 'folio:desc',
  'folio:asc' = 'folio:asc',
}

export type ManageFilterState = {
  currentPage: number;
  pageSize: number;
  filter: null | string;
  sorting: `${SortingTypes}`;
  allInvoices: boolean;
  handleFilterChange: (filter: string | null) => void;
  handlePageChange: (newPage: number) => void;
  handlePageSize: (newPageSize: number) => void;
  handleSortingChange: (sorting: `${SortingTypes}`) => void;
  setInitialState: () => void;
  resetTableStates: () => void;
  handleAllInvoices: () => void;
  handleChangeAllInvoices: () => void;
  clearInputRemote: () => void;
  resetFilter: () => void;
};

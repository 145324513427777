import { ReactNode } from 'react';
import { useOperationType } from '@finance/hooks/migration/useOperationType';
import { useManageFilters } from '@finance/hooks/migration/useManageFilters';
import {
  GridColDef,
  GridCellParams,
  GridSelectionModel,
} from '@material-ui/data-grid';
import CountryFormatHelper from 'components/elements/CountryFormatHelper';
import TablePayerCell from 'features/finance/components/HelpTextCell';
import { Checkbox } from '@material-ui/core';
import {
  Invoice,
  InvoiceCategories,
} from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';
import { isFinanceableInvoice } from '@finance/utils/isFinanceableInvoice';
import { useGetNonFinancingReasonByCategory } from '@finance/utils/getNonFinancingReasonByCategory';
import VerifiedInvoice from 'components/elements/VerifiedInvoice';
import { t13s } from '../../../../../config/translationKeys';

type CreateColumnsProps = {
  t: (value: string) => string;
  countryId: string;
  allInvoices: boolean;
  selectionModel: GridSelectionModel;
  allSelected: boolean;
  onSelectOne: (invoice: Invoice) => void;
  onSelectAll: (checked: boolean) => void;
};

type PayerNameCellProps = {
  categories: InvoiceCategories[];
  payerName: string;
};

function PayerNameCell({ categories, payerName }: PayerNameCellProps) {
  const notFinanceableReason = useGetNonFinancingReasonByCategory(categories);

  return (
    <TablePayerCell payerName={payerName} tooltipText={notFinanceableReason} />
  );
}

function SelectAll({ onSelectAll, allSelected }) {
  return (
    <Checkbox
      style={{ outline: 'none' }}
      color="primary"
      checked={allSelected}
      onClick={onSelectAll}
    />
  );
}

function Select({ params, selectionModel, onSelectOne }) {
  const operationType = useOperationType();
  const { allInvoices } = useManageFilters();

  const checked = selectionModel.includes(params.id);
  const isNotFinanceable = isFinanceableInvoice({
    categories: params.row?.categories,
    allInvoices: !allInvoices,
    operationType,
  });

  return (
    <Checkbox
      {...params}
      color="primary"
      checked={checked}
      disabled={!isNotFinanceable}
      style={{ outline: 'none' }}
      onClick={() => onSelectOne(params.row)}
    />
  );
}

export const createColumns = ({
  t,
  countryId,
  selectionModel,
  allSelected,
  allInvoices,
  onSelectOne,
  onSelectAll,
}: CreateColumnsProps): GridColDef[] => {
  const specialHeaderName: string | ReactNode = (
    <SelectAll onSelectAll={onSelectAll} allSelected={allSelected} />
  );

  const restColumns: GridColDef[] = [
    {
      field: ' ',
      headerName: !allInvoices ? (specialHeaderName as string) : '',
      description: 'Seleccionar todas',
      type: 'string',
      width: 50,
      sortable: false,
      renderCell: params => (
        <Select
          selectionModel={selectionModel}
          params={params}
          onSelectOne={onSelectOne}
        />
      ),
    },
    {
      field: 'folio',
      headerName: t('INVOICES_SELECTION_CART_TABLE_COLUMN_1'),
      type: 'number',
      flex: 5,
      minWidth: 103,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'isConfirmed',
      headerName: ' ',
      type: 'string',
      width: 1,
      align: 'center',
      sortable: false,
      renderCell: (params: GridCellParams) => {
        if (params.row.isConfirmed) {
          return <VerifiedInvoice />;
        }
        return ' ';
      },
    },
    {
      field: 'payerIdentifier',
      headerName: `${t(t13s.LABEL.BUSINESS_IDENTIFIER)}`,
      type: 'string',
      flex: 10,
      minWidth: 158,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'payerName',
      headerName: t('INVOICES_SELECTION_CART_TABLE_COLUMN_3'),
      type: 'string',
      flex: 20,
      minWidth: 300,
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      renderCell: (params: GridCellParams) => (
        <PayerNameCell
          payerName={params.row.payerName}
          categories={params.row.categories}
        />
      ),
    },
    {
      field: 'issueDate',
      headerName: t('INVOICES_SELECTION_CART_TABLE_COLUMN_4'),
      type: 'date',
      flex: 8,
      minWidth: 150,
      sortable: false,
      renderCell: (params: GridCellParams & { value: string }) => (
        <CountryFormatHelper
          value={params.value}
          variant="longdate"
          countryId={countryId}
        />
      ),
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'amount',
      headerName: t('INVOICES_SELECTION_CART_TABLE_COLUMN_5'),
      type: 'number',
      flex: 10,
      minWidth: 172,
      sortable: false,
      renderCell: (params: GridCellParams & { value: string }) => (
        <CountryFormatHelper
          value={params.value}
          variant="currency"
          countryId={countryId}
        />
      ),
      align: 'right',
      headerAlign: 'right',
    },
  ];

  return restColumns;
};

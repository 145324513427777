import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers/rootReducer';
import { useQuery, UseQueryResult } from 'react-query';
import { useInvoicesFromRouting } from '@finance/hooks/migration/useInvoicesFromRouting';
import { isFinanceableInvoice } from '@finance/utils/isFinanceableInvoice';
import { useOperationType } from '@finance/hooks/migration/useOperationType';
import useTracker from '@finance/hooks/useTracker';
import { getInvoicesByIds } from '../services/invoicesIdsService/invoicesIdsService';
import { useAuthStore } from '../../../../../features/auth/infra/store/useAuthStore';
import { InvoicesResponse, Invoice } from '../services/invoicesServicesTypes';

const useGetInvoicesByIds = ({
  onSuccess,
  onError,
}: {
  onSuccess: (param: Invoice[]) => void;
  onError: (error: unknown) => void;
}): UseQueryResult<InvoicesResponse> => {
  const enabled = useRef(true);

  const { token } = useAuthStore();
  const operationType = useOperationType();

  const { financeCallbacks } = useTracker();

  const { id: businessId } = useSelector(
    (state: RootState) => state.businessState.business
  );

  const ids = useInvoicesFromRouting();

  const query = useQuery<unknown, unknown, InvoicesResponse>(
    [useGetInvoicesByIds.name],
    async () => {
      enabled.current = false;
      return getInvoicesByIds({
        token: token.value,
        ids,
        businessId,
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: enabled.current,
      onSuccess: data => {
        const financeableInvoices = data?.data.filter(invoice =>
          isFinanceableInvoice({
            categories: invoice.categories,
            operationType,
          })
        );

        if (data?.data.length !== financeableInvoices.length) {
          financeCallbacks.trackerStepTwoInvoicesNotFinanciables();
        }

        onSuccess(financeableInvoices);
      },
      onError,
    }
  );

  return query;
};

export default useGetInvoicesByIds;

import { Invoice } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';
import { OperationLineValues } from '@finance/useCases/OperationLineValues.type';
import tracker from '../../../helpers/tracker';
import { SEGMENT_EVENTS } from '../../../helpers/segment/constants';
import {
  useEventID,
  useSetEventID,
} from '../../../../features/shared/infrastructure/store/SegmentStore';
import { useOperationType } from './migration/useOperationType';
import { OrderType } from '../useCases/OperationLineValues.type';

type CartSimulateParams = {
  selectedCartInvoices: Invoice[];
  simulation: OperationLineValues;
  availableInvoicesCount: number;
};

type OrderSubmitParams = {
  simulation: OperationLineValues;
  selectedCartInvoices: Invoice[];
  availableInvoicesCount: number;
  orderId: number;
  businessId: number;
  isModified: boolean;
};

const useTracker = () => {
  const eventID = useEventID();

  const orderType = useOperationType();

  const trackViewTab = (name, payload) => {
    tracker.event(name, payload);
  };

  const trackFilterUsed = payload => {
    tracker.event(SEGMENT_EVENTS.AR_FILTER_USED, { id: eventID, ...payload });
  };

  const trackFilterNoResults = (searchText: string) => {
    tracker.event(SEGMENT_EVENTS.AR_SEARCH_NO_RESULTS, {
      id: eventID,
      search_text: searchText,
    });
  };

  const trackRefreshInvoices = () => {
    tracker.event(SEGMENT_EVENTS.AR_REFRESH_INVOICES, {
      id: eventID,
    });
  };

  const trackLegalDocumentDownloaded = (documentTitle: string) => {
    tracker.event(SEGMENT_EVENTS.LEGAL_DOCUMENT_DOWNLOAD, {
      id: eventID,
      document_title: documentTitle,
    });
  };

  const cartSimulateSuccess = ({
    selectedCartInvoices,
    simulation,
    availableInvoicesCount,
  }: CartSimulateParams) => {
    tracker.event(SEGMENT_EVENTS.CART_SIMULATE_SUCCESS, {
      id: eventID,
      simulation,
      selectedCartInvoices,
      extend: {
        orderType,
        availableInvoicesCount,
        availableInvoicesAmount: 0,
      },
    });
  };

  const orderSubmitedSuccess = ({
    simulation,
    selectedCartInvoices,
    availableInvoicesCount,
    orderId,
    businessId,
    isModified,
  }: OrderSubmitParams) => {
    tracker.event(SEGMENT_EVENTS.ORDER_SUBMIT_SUCCESS, {
      id: eventID,
      extend: {
        simulation,
        selectedInvoices: selectedCartInvoices,
        orderType,
        availableInvoicesCount,
        availableInvoicesAmount: 0,
        id: orderId,
      },
      businessId,
      isModified,
    });
  };

  const trackBackToStepOne = (button_used: string) => {
    tracker.event(SEGMENT_EVENTS.AR_BACK_TO_STEP_ONE, {
      id: eventID,
      button_used,
    });
  };

  const viewModalOtherOrderType = (to: `${OrderType}`) => {
    tracker.event(SEGMENT_EVENTS.AR_REDIRECT_OTHER_PRODUCT, {
      id: eventID,
      from: orderType,
      to,
    });
  };

  const viewModalAndChangeOrderType = (to: `${OrderType}`) => {
    tracker.event(SEGMENT_EVENTS.AR_REDIRECT_CONFIRMATION, {
      id: eventID,
      from: orderType,
      to,
    });
  };

  const trackPfxUpload = (button_used: string) => {
    tracker.event(SEGMENT_EVENTS.AR_UPLOAD_PFX, {
      id: eventID,
      button_used,
    });
  };

  const trackerViewOnlyStepTwo = () => {
    tracker.event(SEGMENT_EVENTS.AR_VIEW_DIRECT_STEP_TWO, {
      id: eventID,
    });
  };

  const trackerCreateOrderByUrl = () => {
    tracker.event(SEGMENT_EVENTS.AR_CREATE_ORDER_BY_URL, {
      id: eventID,
    });
  };

  const trackerStepTwoInvoicesNotFinanciables = () => {
    tracker.event(SEGMENT_EVENTS.AR_STEP_TWO_INVOICES_NOT_FINANCIABLES, {
      id: eventID,
    });
  };

  return {
    state: {
      useSetEventID,
    },
    financeCallbacks: {
      trackViewTab,
      viewModalOtherOrderType,
      viewModalAndChangeOrderType,
      trackFilterUsed,
      trackRefreshInvoices,
      trackFilterNoResults,
      trackLegalDocumentDownloaded,
      cartSimulateSuccess,
      orderSubmitedSuccess,
      trackBackToStepOne,
      trackPfxUpload,
      trackerViewOnlyStepTwo,
      trackerCreateOrderByUrl,
      trackerStepTwoInvoicesNotFinanciables,
    },
  };
};
export default useTracker;

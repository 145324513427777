import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import Image from 'next/image';
import { GridRowData } from '@material-ui/data-grid';
import { theme } from 'design-system/theme';
import moment, { Moment } from 'moment';
import { Dispatch, SetStateAction, useState, useRef } from 'react';
import CloseIcon from 'components/icons/CloseIcon';
import { Invoice } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers/rootReducer';
import { useTranslation } from '../../../../../features/i18n/infra/useTranslation';
import { CalendarDateSelector } from '../calendar-date-selector';
import { useSpacingStyles } from '../../../../features/shared/styles/spacings';
import { TermsDateSelector } from '../terms-date-selector';
import {
  COUNTRY_CODE_CL,
  COUNTRY_CODE_MX,
} from '../../../../helpers/constants';
import {
  getFirstBusinessDateFrom,
  isFinanceableDateFromTerm,
} from '../../../../helpers/dateHelpers';

export type DatePickerDialogProps = {
  open: boolean;
  invoiceDate: Moment;
  setDateDialog: Dispatch<SetStateAction<boolean>>;
  invoice: Invoice | GridRowData;
  handleClose: () => void;
  handleChangeDate: (invoice: Invoice) => void;
};

type ChipWithTermsProp = Array<{ term: number; date: Moment }>;

const useStyles = makeStyles((themeStyles: Theme) => ({
  closeButton: {
    position: 'absolute',
    top: themeStyles.spacing(1),
    right: themeStyles.spacing(1),
    color: themeStyles.palette.grey[700],
    '&:hover': {
      cursor: 'pointer',
    },
  },
  savePaymentTermButton: {
    minHeight: themeStyles.spacing(7),
    backgroundColor: themeStyles.palette.primary.main,
    borderRadius: 8,
    color: themeStyles.palette.common.white,
  },
  selectCalendar: {
    color: themeStyles.palette.primary.main,
    borderBottom: `1px solid ${themeStyles.palette.primary.main}`,
  },
}));

const COUNTRIES_PAYMENT_TERMS = {
  [COUNTRY_CODE_CL]: [30, 45, 60],
  [COUNTRY_CODE_MX]: [30, 60, 90],
};

const getFirstBusinessDateFromTerm = (
  startDate: Moment,
  term: number,
  holidays: Array<object>
) => {
  const selectedPaymentDate = moment(startDate).add(term, 'days');
  const selectedBussinessPaymentDate = getFirstBusinessDateFrom(
    selectedPaymentDate,
    holidays
  );
  return moment.utc(selectedBussinessPaymentDate);
};

function getCountryPaymentChips(
  countryTerms: Array<number>,
  issueDate: Moment,
  holidays: Array<object>
): ChipWithTermsProp {
  return countryTerms.map(countryTerm => {
    return {
      term: countryTerm,
      date: getFirstBusinessDateFromTerm(issueDate, countryTerm, holidays),
    };
  });
}

type UseCreateChipsProps = {
  chipsWithTerms: ChipWithTermsProp;
  averageDaysLate: number;
  issueDate: Moment;
};

const useCreateChips = ({
  chipsWithTerms,
  averageDaysLate,
  issueDate,
}: UseCreateChipsProps): ChipWithTermsProp => {
  const chipsCache = useRef<Array<{ term: number; date: Moment }>>(null);

  if (chipsCache.current) return chipsCache.current;
  chipsCache.current = chipsWithTerms
    .map(({ term }) => term)
    .includes(averageDaysLate)
    ? chipsWithTerms
    : [
        ...chipsWithTerms,
        {
          term: averageDaysLate,
          date: moment(issueDate).utc().add(averageDaysLate, 'days'),
        },
      ];

  return chipsCache.current;
};

export function DatePickerDialog({
  open,
  invoiceDate,
  setDateDialog,
  invoice,
  handleClose,
  handleChangeDate,
}: DatePickerDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const spacing = useSpacingStyles();
  const { averageDaysLate, issueDate } = invoice;
  const { holidays } = useSelector((state: RootState) => state.holidaysState);

  const { countryId } = useSelector(
    (state: RootState) => state.businessState?.business
  );

  const chipsWithTerms = getCountryPaymentChips(
    COUNTRIES_PAYMENT_TERMS[countryId],
    issueDate,
    holidays
  );

  const chips = useCreateChips({
    chipsWithTerms,
    averageDaysLate,
    issueDate,
  });
  chips.sort((a, b) => a.term - b.term);

  const [selectedPaymentDate, setSelectedPaymentDate] = useState(
    moment.utc(invoiceDate)
  );

  const [isCalendarModeOn, setIsCalendarModeOn] = useState(false);

  const handleChangeDatePaymentDate = (date: Moment) => {
    setSelectedPaymentDate(date);
  };

  const handleSubmitPaymentDate = () => {
    const actualInvoice = invoice as Invoice;
    const newInvoice = {
      ...actualInvoice,
      prevExpirationDate: actualInvoice?.expirationDate,
      expirationDate: moment.utc(selectedPaymentDate).toDate().toISOString(),
    };
    handleChangeDate(newInvoice);
    setDateDialog(false);
  };

  const handleSwitchCalendarMode = () => {
    setIsCalendarModeOn(!isCalendarModeOn);
  };

  const hasSomeValidTerm = chips.some(({ term }: { term: number }) =>
    isFinanceableDateFromTerm(issueDate, term, holidays)
  );

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: { padding: 50, borderRadius: 16, width: 521 },
      }}
    >
      <Grid container direction="column" justifyContent="center">
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}
          data-qa="dialog-close-button-action"
        >
          <CloseIcon />
        </IconButton>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h5"
            align="center"
            style={{ fontWeight: theme.typography.fontWeightBold }}
            color="textPrimary"
            className={spacing.mb3}
          >
            {t('SELECT_PAYMENT_TERM')}
          </Typography>
          {isCalendarModeOn || !hasSomeValidTerm ? (
            <CalendarDateSelector
              selectedDate={selectedPaymentDate}
              handleChange={handleChangeDatePaymentDate}
              issueDate={issueDate}
            />
          ) : (
            <TermsDateSelector
              chips={chips}
              selectedDate={selectedPaymentDate}
              suggestedPaymentTerm={averageDaysLate}
              handleChangeDate={handleChangeDatePaymentDate}
              issueDate={issueDate}
            />
          )}
          <Button
            fullWidth
            onClick={() => handleSubmitPaymentDate()}
            className={`${classes.savePaymentTermButton}  `}
          >
            <Box className={spacing.px7}>
              <Typography
                variant="body1"
                align="center"
                style={{ fontWeight: theme.typography.fontWeightBold }}
              >
                {t('SAVE_PAYMENT_TERM_DATE')}
              </Typography>
            </Box>
          </Button>
        </Box>
        {hasSomeValidTerm && (
          <>
            <Divider className={spacing.mt4} />
            <Box
              className={`${spacing.mt3}`}
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                cursor: 'pointer',
              }}
              onClick={handleSwitchCalendarMode}
            >
              {isCalendarModeOn ? (
                <Image
                  alt="XepelinLogo"
                  src="/assets/arrowLeft.svg"
                  width={17}
                  height={17}
                  unoptimized
                />
              ) : (
                <Image
                  alt="XepelinLogo"
                  src="/assets/calendar.svg"
                  width={20}
                  height={20}
                  unoptimized
                />
              )}

              <Typography
                variant="body1"
                align="center"
                style={{ fontWeight: theme.typography.fontWeightBold }}
                className={`${spacing.ml2} ${classes.selectCalendar}`}
              >
                {isCalendarModeOn
                  ? t('BACK_TO_SUGGESTED_PAYMENT_TERMS')
                  : t('SELECT_TERN_FROM_CALENDAR')}
              </Typography>
            </Box>
          </>
        )}
      </Grid>
    </Dialog>
  );
}

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../store/actions/authenticationActions';
import { Spacer } from '../../../features/shared';
import { TopbarPrivate } from './navigation';

interface LayoutProps {
  children: React.ReactNode;
  isMaintenance?: boolean;
}

function Layout({ children, isMaintenance }: LayoutProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMaintenance) {
      dispatch(logoutUser());
    }
  }, [isMaintenance, dispatch]);

  return (
    <>
      <TopbarPrivate />
      <Spacer spacing={8} />
      {children}
    </>
  );
}

Layout.defaultProps = {
  isMaintenance: false,
};

export default Layout;

interface GroupArrayByKeyParams {
  array: Array<any>;
  key: string;
}

function groupArrayByKey({ array, key }: GroupArrayByKeyParams): Array<any> {
  return array.reduce((result, currentValue) => {
    const cloneResult = { ...result };
    cloneResult[currentValue[key]] = cloneResult[currentValue[key]] || [];
    cloneResult[currentValue[key]].push(currentValue);
    return cloneResult;
  }, {});
}

export default groupArrayByKey;

export const encode = (data: object | unknown): string => {
  if (!data) throw new Error('The data to pass in encode must be valid');

  return Buffer.from(JSON.stringify(data)).toString('base64');
};

export const decode = <TypeData>(data: string | string[]): TypeData => {
  if (!data) throw new Error('The data to pass in decode must be valid');

  return JSON.parse(Buffer.from(String(data), 'base64').toString('ascii'));
};

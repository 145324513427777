import { PURGE, REHYDRATE } from 'redux-persist';
import {
  LOGIN_START,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  SET_CURRENT_USER,
  FORGOT_PASSWORD_START,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  REGISTER_USER_START,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  COMPLETE_REGISTER_START,
  COMPLETE_REGISTER_SUCCESS,
  COMPLETE_REGISTER_ERROR,
  VERIFY_EMAIL_START,
  VERIFY_EMAIL_SUCCESS,
  VERIFY_EMAIL_ERROR,
  VERIFY_EMAIL_RESET,
  LOCAL_PURGE,
  FETCH_ENTITY_FEATURE_START,
  FETCH_ENTITY_FEATURE_SUCCESS,
  FETCH_ENTITY_FEATURE_ERROR,
} from '../actions/types';
import isEmpty from '../../helpers/validations/isEmpty';
import { t13s } from '../../config/translationKeys';

const initialState = {
  isAuthenticated: false,
  token: null,
  user: {},
  business: {},
  loginIsLoading: false,
  loginError: null,

  userUpdateIsLoading: false,
  userUpdatedError: false,

  forgotPasswordIsLoading: false,
  forgotPasswordError: null,

  wasUserRegistered: false,
  registerUserIsLoading: false,
  registerUserError: null,

  completeRegisterIsLoading: false,
  completeRegisterError: null,

  emailVerification: null,
  verifyEmailIsLoading: false,
  verifyEmailError: null,

  passwordReseted: false,
  resetPasswordIsLoading: false,
  resetPasswordError: null,

  entityFeature: [],
  entityFeatureIsLoading: false,
  entityFeatureError: null,
};

const loginStart = state => {
  return {
    ...state,
    loginIsLoading: true,
    loginError: null,
  };
};

const loginError = (state, payload) => {
  return {
    ...state,
    loginIsLoading: false,
    loginError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const loginSuccess = state => {
  return {
    ...state,
    loginIsLoading: false,
    loginError: null,
  };
};

const setCurrentUser = (state, payload = {}) => {
  const { decoded: { user, business } = {}, token } = payload;
  return {
    ...state,
    isAuthenticated: !isEmpty(payload),
    user,
    business,
    token,
    loginError: null,
  };
};

const forgotPasswordStart = state => {
  return {
    ...state,
    forgotPasswordIsLoading: true,
    forgotPasswordError: null,
  };
};

const forgotPasswordSuccess = state => {
  return {
    ...state,
    forgotPasswordIsLoading: false,
    forgotPasswordError: null,
  };
};

const forgotPasswordError = (state, payload) => {
  return {
    ...state,
    forgotPasswordIsLoading: false,
    forgotPasswordError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const registerUserStart = state => {
  return {
    ...state,
    registerUserIsLoading: true,
    registerUserError: null,
    wasUserRegistered: false,
  };
};

const registerUserSuccess = state => {
  return {
    ...state,
    registerUserIsLoading: false,
    registerUserError: null,
    wasUserRegistered: true,
  };
};

const registerUserError = (state, payload) => {
  return {
    ...state,
    registerUserIsLoading: false,
    registerUserError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
    wasUserRegistered: false,
  };
};

const completeRegisterStart = state => {
  return {
    ...state,
    completeRegisterIsLoading: true,
    completeRegisterError: null,
  };
};

const completeRegisterSuccess = state => {
  return {
    ...state,
    completeRegisterIsLoading: false,
    completeRegisterError: null,
  };
};

const completeRegisterError = (state, payload) => {
  return {
    ...state,
    completeRegisterIsLoading: false,
    completeRegisterError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const verifyEmailStart = state => {
  return {
    ...state,
    emailVerification: null,
    verifyEmailIsLoading: true,
    verifyEmailError: null,
  };
};

const verifyEmailSuccess = (state, payload) => {
  return {
    ...state,
    emailVerification: payload,
    verifyEmailIsLoading: false,
    verifyEmailError: null,
  };
};

const verifyEmailError = (state, payload) => {
  return {
    ...state,
    verifyEmailIsLoading: false,
    verifyEmailError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const verifyEmailReset = state => {
  return {
    ...state,
    verifyEmailError: null,
    verifyEmailIsLoading: false,
    emailVerification: null,
  };
};

const rehydrate = payload => {
  return {
    ...initialState,
    isAuthenticated: payload?.authState?.isAuthenticated || false,
    token: payload?.authState?.token || null,
    user: payload?.authState?.user || {},
    business: payload?.authState?.business || {},
    entityFeature: payload?.authState?.entityFeature || [],
  };
};

const resetPasswordStart = state => {
  return {
    ...state,
    resetPasswordIsLoading: true,
    resetPasswordError: null,
    passwordReseted: false,
  };
};

const resetPasswordSuccess = state => {
  return {
    ...state,
    resetPasswordIsLoading: false,
    resetPasswordError: null,
    passwordReseted: true,
  };
};

const resetPasswordError = (state, payload) => {
  return {
    ...state,
    resetPasswordIsLoading: false,
    resetPasswordError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
    passwordReseted: false,
  };
};

const fetchEntityFeatureStart = state => {
  return {
    ...state,
    entityFeatureIsLoading: true,
    entityFeatureError: null,
  };
};

const fetchEntityFeatureSuccess = (state, payload) => {
  return {
    ...state,
    entityFeature: payload,
    entityFeatureIsLoading: false,
  };
};

const fetchEntityFeatureError = (state, payload) => {
  return {
    ...state,
    entityFeatureIsLoading: false,
    entityFeatureError: payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ENTITY_FEATURE_START:
      return fetchEntityFeatureStart(state);
    case FETCH_ENTITY_FEATURE_SUCCESS:
      return fetchEntityFeatureSuccess(state, payload);
    case FETCH_ENTITY_FEATURE_ERROR:
      return fetchEntityFeatureError(state, payload);
    case LOGIN_START:
      return loginStart(state);
    case LOGIN_ERROR:
      return loginError(state, payload);
    case LOGIN_SUCCESS:
      return loginSuccess(state);
    case SET_CURRENT_USER:
      return setCurrentUser(state, payload);
    case FORGOT_PASSWORD_START:
      return forgotPasswordStart(state);
    case FORGOT_PASSWORD_SUCCESS:
      return forgotPasswordSuccess(state);
    case FORGOT_PASSWORD_ERROR:
      return forgotPasswordError(state, payload);
    case REGISTER_USER_START:
      return registerUserStart(state);
    case REGISTER_USER_SUCCESS:
      return registerUserSuccess(state);
    case REGISTER_USER_ERROR:
      return registerUserError(state, payload);
    case COMPLETE_REGISTER_START:
      return completeRegisterStart(state);
    case COMPLETE_REGISTER_SUCCESS:
      return completeRegisterSuccess(state);
    case COMPLETE_REGISTER_ERROR:
      return completeRegisterError(state, payload);
    case VERIFY_EMAIL_START:
      return verifyEmailStart(state);
    case VERIFY_EMAIL_SUCCESS:
      return verifyEmailSuccess(state, payload);
    case VERIFY_EMAIL_ERROR:
      return verifyEmailError(state, payload);
    case VERIFY_EMAIL_RESET:
      return verifyEmailReset(state);
    case RESET_PASSWORD_START:
      return resetPasswordStart(state);
    case RESET_PASSWORD_SUCCESS:
      return resetPasswordSuccess(state);
    case RESET_PASSWORD_ERROR:
      return resetPasswordError(state, payload);
    case LOGOUT_USER:
      return initialState;
    case PURGE:
      return initialState;
    case LOCAL_PURGE:
      return initialState;
    case REHYDRATE:
      return rehydrate(payload);
    default:
      return state;
  }
};

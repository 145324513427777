import { useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';
import { useTranslation } from '@features/i18n/infra/useTranslation';
import { CreditLine } from '@integration-services/xepelin-server-global/credit-balance/types/creditBalance.type';
import { getCreditLineRequest } from '@integration-services/xepelin-server-global/credit-balance/services/creditBalanceService';
import { t13s } from 'config/translationKeys';
import { RootState } from '@finance/types';
import { useSelector } from 'react-redux';

function useCreditLine() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const businessId = useSelector(
    (state: RootState) => state.businessState.business.id
  );

  return useQuery<CreditLine, AxiosError>(
    'creditLineSaas',
    () => getCreditLineRequest({ businessId: Number(businessId) }),
    {
      onError: () => {
        enqueueSnackbar(t(t13s.NOTIFICATION.FETCH_CREDIT_LINE_FAILURE), {
          variant: 'error',
        });
      },
    }
  );
}

export default useCreditLine;

import React from 'react';
import { SvgIcon, styled } from '@material-ui/core';
import PropTypes from 'prop-types';
import { xTheme } from '@xepelinapp/x-ds';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  height: 48,
  width: 48,
  ...props.style,
}));

const GoBackArrowIcon = props => {
  const { style } = props;

  return (
    <CustomSvgIcon
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <circle
        cx="24"
        cy="24"
        r="23.5"
        stroke={xTheme.primary.main}
        fill="none"
      />
      <path
        d="M33.6 22.8V25.2H19.2L24 30L22.8 32.4L14.4 24L22.8 15.6L24 18L19.2 22.8H33.6Z"
        fill={xTheme.primary.main}
      />
      <defs>
        <linearGradient
          id="paint0_linear_2_7"
          x1="4.56"
          y1="52.728"
          x2="62.6694"
          y2="14.1951"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9732D" />
          <stop offset="0.470306" stopColor="#DF5B5B" />
          <stop offset="0.981571" stopColor="#C721DF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_7"
          x1="16.224"
          y1="34.0548"
          x2="37.4804"
          y2="17.9459"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9732D" />
          <stop offset="0.470306" stopColor="#DF5B5B" />
          <stop offset="0.981571" stopColor="#C721DF" />
        </linearGradient>
      </defs>
    </CustomSvgIcon>
  );
};

GoBackArrowIcon.defaultProps = {
  style: {},
};

GoBackArrowIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default GoBackArrowIcon;

import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface StyleProps {
  severity: 'error' | 'info' | 'success' | 'warning';
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      background: (props: StyleProps) => theme.palette[props.severity].light,
      color: (props: StyleProps) => theme.palette[props.severity].main,
      fontSize: theme.typography.body3.fontSize,
    },
    alertTitle: {
      fontSize: theme.typography.body2.fontSize,
      color: (props: StyleProps) => theme.palette[props.severity].main,
    },
  })
);

export { useStyles };

import { urls } from '@features/libs/urls';
import { decodeKey, HashKey } from 'helpers/hashKeys';
import { useRouter } from 'next/router';
import { BuroAuthorizationFormValues } from '@features/auth/adapters/DebtService/useDebtService';
import { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers/rootReducer';
import getBirthdateByIdentifier from 'helpers/getBirthdateByIdentifier';

/*

-> Es Onboarding -> url
-> Es persona física o moral -> url

*/
export interface DebtServiceStore {
  status: {
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
    error: string;
    setError: Dispatch<SetStateAction<string>>;
    autofillDataIsLoading: boolean;
    setAutofillDataIsLoading: Dispatch<SetStateAction<boolean>>;
  };

  flags: {
    sendToPhone: boolean;
    isPublicLink: boolean;
    isLegalEntity: boolean;
    postalCodeAutofillDataIsLoading: boolean;

    setSendToPhone: Dispatch<SetStateAction<boolean>>;
    setIsLegalEntity: Dispatch<SetStateAction<boolean>>;
    setPostalCodeAutofillDataIsLoading: Dispatch<SetStateAction<boolean>>;
  };

  verificationCode: string;
  phone: string;
  email: string;
  form: BuroAuthorizationFormValues;
  buroLink: string;
  businessId: string;
  onboardingStep: string;
  debtServiceFlow: DebtServiceFlow;
  registerStep: DebtServiceFormStep;

  colonies: string[];

  setEmail: Dispatch<SetStateAction<string>>;
  setVerificationCode: Dispatch<SetStateAction<string>>;
  setPhone: Dispatch<SetStateAction<string>>;
  setForm: Dispatch<SetStateAction<BuroAuthorizationFormValues>>;
  setColonies: Dispatch<SetStateAction<string[]>>;
  setRegisterStep: Dispatch<SetStateAction<DebtServiceFormStep>>;
  setDebtServiceFlow: Dispatch<SetStateAction<DebtServiceFlow>>;
}

export const debtServiceFormSteps = {
  SEND_VERIFICATION_CODE: 'SEND_VERIFICATION_CODE',
  VERIFY_CODE: 'VERIFY_CODE',
  FORM: 'FORM',
  CODE_REVERIFICATION: 'CODE_REVERIFICATION',
  SUCCESS: 'SUCCESS',
};

export type DebtServiceFormStep =
  | 'SEND_VERIFICATION_CODE'
  | 'VERIFY_CODE'
  | 'FORM'
  | 'CODE_REVERIFICATION'
  | 'SUCCESS';

export const debtServiceFlows = {
  AUTH_BURO: 'AUTH_BURO',
  ADD_LEGAL_REPRESENTATIVE: 'ADD_LEGAL_REPRESENTATIVE',
  DEFAULT: 'DEFAULT',
};
export type DebtServiceFlow =
  | 'AUTH_BURO'
  | 'ADD_LEGAL_REPRESENTATIVE'
  | 'DEFAULT';

export const useDebtServiceStore = (): DebtServiceStore => {
  const onboardingStep = useSelector(
    (state: RootState) => state.businessState?.business?.onboardingStep
  );
  const [registerStep, setRegisterStep]: [
    DebtServiceFormStep,
    Dispatch<SetStateAction<DebtServiceFormStep>>
  ] = useState('SEND_VERIFICATION_CODE');

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(onboardingStep !== undefined);

  const [sendToPhone, setSendToPhone] = useState(true);
  const [codeVerification, setCodeVerification] = useState('');
  const [autofillDataIsLoading, setAutofillDataIsLoading] = useState(false);
  const [postalCodeAutofillDataIsLoading, setPostalCodeAutofillDataIsLoading] =
    useState(false);
  const [debtServiceFlow, setDebtServiceFlow]: [
    DebtServiceFlow,
    Dispatch<SetStateAction<DebtServiceFlow>>
  ] = useState('DEFAULT');
  const [colonies, setColonies]: [
    string[],
    Dispatch<SetStateAction<string[]>>
  ] = useState([]);

  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const router = useRouter();

  let businessId = useSelector(
    (state: RootState) => state.businessState?.business?.id
  );
  const isPublicLink = router.asPath.includes(urls.legalRepresentativeRegister);
  // eslint-disable-next-line prefer-destructuring
  if (isPublicLink) businessId = decodeKey(router.query.id)[0];

  const identifier = useSelector(
    (state: RootState) => state.authState.business?.identifier
  );

  const [isLegalEntity, setIsLegalEntity]: [
    boolean,
    Dispatch<SetStateAction<boolean>>
  ] = useState(identifier?.length === 12);

  const hashedBusinessId = HashKey(businessId);
  const basePath = window.location.origin;
  const buroLink = `${basePath}${urls.legalRepresentativeRegister}/?id=${hashedBusinessId}`;

  const [form, setForm] = useState({
    businessId: '',
    curp: '',
    identifier,
    names: '',
    fatherLastName: '',
    motherLastName: '',
    birthDate: getBirthdateByIdentifier(identifier),
    email,
    phone,

    postalCode: '',
    state: '',
    municipality: '',
    colony: '',
    city: '',
    street: '',
    outdoorNumber: '',
    insideNumber: '',
  });

  return {
    status: {
      isLoading,
      setIsLoading,
      error,
      setError,
      autofillDataIsLoading,
      setAutofillDataIsLoading,
    },
    flags: {
      isPublicLink,
      sendToPhone,
      isLegalEntity,
      postalCodeAutofillDataIsLoading,

      setSendToPhone,
      setIsLegalEntity,
      setPostalCodeAutofillDataIsLoading,
    },

    email,
    phone,
    colonies,
    buroLink,
    verificationCode: codeVerification,
    form,
    businessId,
    onboardingStep,
    debtServiceFlow,
    registerStep,

    setEmail,
    setPhone,
    setVerificationCode: setCodeVerification,
    setForm,
    setColonies,
    setRegisterStep,
    setDebtServiceFlow,
  };
};

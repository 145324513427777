import { createTheme } from '@material-ui/core';
import palette from '../../../../../src/design-system/theme/palette.ts';
import mainTheme from '../../../../../src/design-system/theme/theme.ts';
import overrides from '../../../../../src/design-system/theme/overrides';

const theme = createTheme({
  ...mainTheme,
  palette: {
    divider: '#DDE6F6',
    ...palette,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 8,
        textTransform: 'none',
        padding: '6px 32px',
        height: 48,
        fontSize: '1.143rem',
        '&:focus-visible': {
          outline: `2px dashed ${palette.secondary.dark}`,
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: palette.primary.light,
        },
      },
      containedSizeLarge: {
        padding: '6px 40px',
        height: 56,
        fontSize: '1.286rem',
      },
      containedSizeSmall: {
        padding: '6px 32px',
        height: 40,
        fontSize: '1rem',
      },
      outlined: {
        padding: '6px 32px',
        height: 48,
        fontSize: '1.143rem',
        '&.Mui-disabled': {
          borderColor: palette.grey[400],
        },
      },
      outlinedPrimary: {
        '&:hover': {
          border: `1px solid ${palette.primary.light}`,
          color: palette.primary.light,
        },
      },
      outlinedSizeLarge: {
        padding: '6px 40px',
        height: 56,
        fontSize: '1.286rem',
      },
      outlinedSizeSmall: {
        padding: '6px 32px',
        height: 40,
        fontSize: '1rem',
      },
      text: {
        textDecoration: 'underline',
        textDecorationThickness: '2px',
        textUnderlineOffset: '2px',
        '&:hover': {
          textDecoration: 'underline',
          textDecorationThickness: '2px',
          textUnderlineOffset: '2px',
        },
        padding: 0,
        height: 48,
        fontSize: '1.143rem',
      },
      textSizeLarge: {
        padding: 0,
        height: 56,
        fontSize: '1.286rem',
      },
      textSizeSmall: {
        padding: 0,
        height: 40,
        fontSize: '1rem',
      },
    },
    MuiTypography: {
      button: {
        textTransform: 'none',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
        '& .Mui-disabled': {
          background: palette.grey[200],
          borderRadius: 8,
        },
      },
    },
    MuiAccordion: {
      root: {
        boxShadow: 'none',
      },
    },
    MuiStepper: {
      root: {
        padding: 0,
        backgroundColor: 'inherit',
      },
    },
    MuiLinearProgress: {
      root: {
        height: 6,
        borderRadius: 3,
      },
    },
    MuiDataGrid: overrides.MuiDataGrid,
  },
  props: {
    MuiLink: {
      underline: 'always',
    },
    MuiButton: {
      // Disable box shadows in all buttons
      disableElevation: true,
      disableFocusRipple: true,
    },
    MuiSkeleton: {
      animation: 'wave',
    },
  },
});
export { theme };

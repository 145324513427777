import { useRouter } from 'next/router';
import { useEffect } from 'react';

const usePrefetchHomeHook = () => {
  const router = useRouter();
  useEffect(() => {
    router.prefetch('/');
  }, [router]);
};

export { usePrefetchHomeHook };

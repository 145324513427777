import { styled } from '@material-ui/core/styles';
import { AppBar } from '@material-ui/core/';

interface XAppBarProps {
  theme?: { palette: any };
  isPublic?: boolean;
}

const XAppBar = styled(AppBar)((props: XAppBarProps) => ({
  elevation: 0,
  background: props.theme.palette.common.white,
  borderBottom: `1px solid ${props.theme.palette.divider}`,
  boxShadow: 'none',
  justifyContent: 'center',
  alignItems: props.isPublic ? 'center' : 'auto',
  height: 64,
}));

export { XAppBar };

import { useBusiness } from '@payments/store/business';
import { useWithdrawalRepository } from '@wallet/withdrawal/infraestructure/repository';
import { useWithdrawalStore } from '@wallet/withdrawal/infraestructure/store';
import { fetchBankAccountsUseCase } from '@wallet/withdrawal/useCases';
import { useDispatch } from 'react-redux';
import { fetchBanks } from '../../../../store/actions/businessBankAccountsActions';

const useWithdrawalModal = () => {
  const dispatch = useDispatch();
  const { businessId } = useBusiness();
  const withdrawalRepository = useWithdrawalRepository();
  const withdrawalStore = useWithdrawalStore();
  const { resetForm } = useWithdrawalStore();

  const fetchWithdrawalAccounts = async () => {
    await fetchBankAccountsUseCase({
      businessId,
      withdrawalRepository,
      withdrawalStore,
    });
  };

  const fetchBanksDispatch = () => {
    dispatch(fetchBanks());
  };

  return { resetForm, fetchBanks: fetchBanksDispatch, fetchWithdrawalAccounts };
};

export default useWithdrawalModal;

import { makeStyles } from '@material-ui/core';

export const useSpacingStyles = makeStyles(theme => ({
  mt1: {
    marginTop: theme.spacing(1),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  mt3: {
    marginTop: theme.spacing(3),
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
  mb1: {
    marginBottom: theme.spacing(1),
  },
  mb2: {
    marginBottom: theme.spacing(2),
  },
  mb3: {
    marginBottom: theme.spacing(3),
  },
  mb4: {
    marginBottom: theme.spacing(4),
  },
  mb5: {
    marginBottom: theme.spacing(5),
  },
  ml1: {
    marginLeft: theme.spacing(1),
  },
  ml2: {
    marginLeft: theme.spacing(2),
  },
  ml3: {
    marginLeft: theme.spacing(3),
  },
  ml4: {
    marginLeft: theme.spacing(4),
  },
  ml10: {
    marginLeft: theme.spacing(10),
  },
  // y-axis margin
  my1: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  my2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  my3: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  my4: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  my5: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  my6: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  my7: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
  },
  my8: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },

  // x and y axis padding
  p1: {
    padding: theme.spacing(1),
  },
  p2: {
    padding: theme.spacing(2),
  },
  p3: {
    padding: theme.spacing(3),
  },
  p4: {
    padding: theme.spacing(4),
  },
  p5: {
    padding: theme.spacing(5),
  },
  p6: {
    padding: theme.spacing(6),
  },
  p7: {
    padding: theme.spacing(7),
  },
  p8: {
    padding: theme.spacing(8),
  },

  // x-axis padding
  px1: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  px2: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  px3: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  px4: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  px5: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  px6: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
  px7: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  px8: {
    paddingTop: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },

  // y-axis padding
  py1: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  py2: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  py3: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  py4: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  py5: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  py6: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  py7: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
  },
  py8: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },

  // borders
  br1: {
    borderRadius: theme.spacing(1),
  },
  br2: {
    borderRadius: theme.spacing(2),
  },
  br3: {
    borderRadius: theme.spacing(3),
  },
  br4: {
    borderRadius: theme.spacing(4),
  },
}));

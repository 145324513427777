import React from 'react';
import { SvgIcon, styled } from '@material-ui/core';
import PropTypes from 'prop-types';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const SupplierAccountIcon = props => {
  const { style } = props;
  return (
    <CustomSvgIcon
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M2.49271 8.58327V11.9166C2.49271 12.6083 3.07775 13.1666 3.8025 13.1666C4.52724 13.1666 5.11228 12.6083 5.11228 11.9166V8.58327C5.11228 7.8916 4.52724 7.33327 3.8025 7.33327C3.07775 7.33327 2.49271 7.8916 2.49271 8.58327ZM7.73185 8.58327V11.9166C7.73185 12.6083 8.31688 13.1666 9.04163 13.1666C9.76637 13.1666 10.3514 12.6083 10.3514 11.9166V8.58327C10.3514 7.8916 9.76637 7.33327 9.04163 7.33327C8.31688 7.33327 7.73185 7.8916 7.73185 8.58327ZM2.05612 17.3333H16.0271C16.7519 17.3333 17.3369 16.7749 17.3369 16.0833C17.3369 15.3916 16.7519 14.8333 16.0271 14.8333H2.05612C1.33137 14.8333 0.746338 15.3916 0.746338 16.0833C0.746338 16.7749 1.33137 17.3333 2.05612 17.3333ZM12.971 8.58327V11.9166C12.971 12.6083 13.556 13.1666 14.2808 13.1666C15.0055 13.1666 15.5905 12.6083 15.5905 11.9166V8.58327C15.5905 7.8916 15.0055 7.33327 14.2808 7.33327C13.556 7.33327 12.971 7.8916 12.971 8.58327ZM8.22956 0.241602L1.33137 3.70827C0.973367 3.88327 0.746338 4.2416 0.746338 4.62494C0.746338 5.19993 1.23532 5.6666 1.83782 5.6666H16.2542C16.8479 5.6666 17.3369 5.19993 17.3369 4.62494C17.3369 4.2416 17.1099 3.88327 16.7519 3.70827L9.85369 0.241602C9.34724 -0.0167318 8.73601 -0.0167318 8.22956 0.241602Z"
        fill="#828795"
      />
    </CustomSvgIcon>
  );
};

SupplierAccountIcon.defaultProps = {
  style: {},
};

SupplierAccountIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default SupplierAccountIcon;

import { Box, Divider, Grid } from '@material-ui/core';
import { DocumentsSummary } from '../../features/documents/types/DocumentSummary.type';
import { Typography } from '../../design-system/atoms';

function DocumentCounterBreakdown({
  documentSummary,
}: {
  documentSummary: DocumentsSummary;
}) {
  return (
    <Grid container>
      <Grid item xs={5}>
        <Grid container justifyContent="space-between">
          <Typography variant="tiny" color="textPrimary">
            Req. aclaración
          </Typography>
          <Typography variant="tiny" color="textPrimary">
            <Box fontWeight="fontWeightBold">
              {documentSummary?.requireClarification}
            </Box>
          </Typography>
        </Grid>
        <Grid container justifyContent="space-between">
          <Typography variant="tiny" color="textPrimary">
            En revisión
          </Typography>
          <Typography variant="tiny" color="textPrimary">
            <Box fontWeight="fontWeightBold">
              {documentSummary?.pendingToReviewLength}
            </Box>
          </Typography>
        </Grid>
      </Grid>
      <Box px={3}>
        <Divider orientation="vertical" />
      </Box>
      <Grid item xs={4}>
        <Grid container justifyContent="space-between">
          <Typography variant="tiny" color="textPrimary">
            Por subir
          </Typography>
          <Typography variant="tiny" color="textPrimary">
            <Box fontWeight="fontWeightBold">
              {documentSummary?.pendingToUploadLength}
            </Box>
          </Typography>
        </Grid>
        <Grid container justifyContent="space-between">
          <Typography variant="tiny" color="textPrimary">
            Aprobados
          </Typography>
          <Typography variant="tiny" color="textPrimary">
            <Box fontWeight="fontWeightBold">
              {documentSummary?.approvedLength}
            </Box>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default DocumentCounterBreakdown;

import React from 'react';
import { SvgIcon, styled } from '@material-ui/core';
import PropTypes from 'prop-types';
import { xTheme } from '@xepelinapp/x-ds';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  width: 40,
  height: 40,
  fill: 'none',
  ...props.style,
}));

const CloseModalOutlinedIcon = props => {
  const { style } = props;

  return (
    <CustomSvgIcon
      style={style}
      viewBox="0 0 40 41"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.5"
        y="-0.5"
        width="39"
        height="39"
        rx="19.5"
        transform="matrix(1 0 0 -1 0 39.7744)"
        stroke={xTheme.grey[900]}
      />
      <path
        d="M27.0913 25.6831C27.5697 26.1615 27.5697 26.9372 27.0913 27.4155C26.6129 27.8939 25.8373 27.8939 25.3589 27.4155L20.225 22.2817L15.0912 27.4155C14.6128 27.8939 13.8372 27.8939 13.3588 27.4155C12.8804 26.9372 12.8804 26.1615 13.3588 25.6831L18.4926 20.5493L13.3588 15.4155C12.8805 14.9372 12.8805 14.1615 13.3588 13.6831C13.8372 13.2047 14.6129 13.2047 15.0913 13.6831L20.225 18.8169L25.3588 13.6831C25.8372 13.2047 26.6128 13.2047 27.0912 13.6831C27.5696 14.1615 27.5696 14.9372 27.0912 15.4155L21.9575 20.5493L27.0913 25.6831Z"
        fill={xTheme.grey[900]}
      />
    </CustomSvgIcon>
  );
};

CloseModalOutlinedIcon.defaultProps = {
  style: {},
};

CloseModalOutlinedIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default CloseModalOutlinedIcon;

import React from 'react';
import { Box, Container, Link } from '@material-ui/core';
import { urls } from '@features/libs/urls';
import { NavbarLogo } from 'design-system/logos';
import { XAppBar } from '../XAppBar';

const TopbarPublic = React.memo(() => {
  return (
    <XAppBar isPublic>
      <Container>
        <Box
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Link href={urls.landing}>
            <NavbarLogo />
          </Link>
        </Box>
      </Container>
    </XAppBar>
  );
});

export default TopbarPublic;

import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Box, makeStyles } from '@material-ui/core';
import CountryFormatHelper from './CountryFormatHelper';
import { Typography } from '../../design-system/atoms/index.ts';

const useStyles = makeStyles(theme => ({
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  top: {
    color: theme.palette.primary,
    animationDuration: '800ms',
    position: 'absolute',
    left: 0,
    top: 0,
  },
  circle: {
    strokeLinecap: 'round',
  },
}));
const CircularProgressWithLabel = props => {
  const classes = useStyles();
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={80}
        thickness={4}
        value={100}
        color="primary"
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={80}
        thickness={4}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="body3" component="div" color="textSecondary">
          <Box fontWeight="fontWeightBold">
            <CountryFormatHelper
              value={Math.round(props.value)}
              variant="percentage"
              countryId="CL"
              useFixedDecimalPrecision={0}
            />
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and static variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default CircularProgressWithLabel;

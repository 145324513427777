/* eslint-disable @next/next/no-img-element */
import { Box } from '@material-ui/core';
import { Typography } from '../../design-system/atoms/index';
import { useSpacingStyles } from '../../features/shared/styles/spacings';

type LogoMessageProps = {
  logo?: string;
  title: string;
  subtitle: string;
};

function LogoMessage({ logo, title, subtitle }: LogoMessageProps) {
  const spacing = useSpacingStyles();

  return (
    <Box>
      <Box textAlign="center">
        <img src={logo} alt="Cargando..." />
      </Box>
      <Box textAlign="center">
        <Typography
          variant="body1"
          style={{ fontWeight: 'bold' }}
          className={spacing.mb1}
        >
          {title}
        </Typography>
      </Box>
      <Box textAlign="center">
        <Typography variant="body2" color="textSecondary">
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
}

export default LogoMessage;

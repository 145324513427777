import React from 'react';
import { DataGrid, DataGridProps } from '@material-ui/data-grid';
import { Invoice } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';

export type PaginatedServerTableProps = DataGridProps & {
  serverSideFilter?: boolean;
  serverSideSorting?: boolean;
  enableColumnMenu?: boolean;
  onPageChangeSize?: (newPageSize: number) => number;
  onSelectionModel?: (model: Invoice[]) => void;
};
function PaginatedServerTable(props: PaginatedServerTableProps) {
  const {
    onPageChange,
    onPageSizeChange,
    onSelectionModel,
    page,
    enableColumnMenu,
    ...restProps
  } = props;

  const getServerSideFilteringProps = () => {
    const { serverSideFilter = true, onFilterModelChange } = props;

    if (!serverSideFilter) {
      return {};
    }

    const filterProps = {
      filterMode: 'server',
      onFilterModelChange,
    };

    return filterProps;
  };

  const getServerSideSortingProps = () => {
    const { serverSideSorting = true, sortModel, onSortModelChange } = props;

    if (!serverSideSorting) {
      return {};
    }

    const sortingProps = {
      sortingMode: 'server',
      sortModel,
      onSortModelChange,
    };

    return sortingProps;
  };

  return (
    <div style={{ width: '100%' }}>
      <DataGrid
        autoHeight
        pagination
        paginationMode="server"
        rowsPerPageOptions={[10, 25, 50, 100]}
        page={page}
        onPageChange={onPageChange}
        disableSelectionOnClick
        disableColumnMenu={!enableColumnMenu}
        onPageSizeChange={onPageSizeChange}
        {...getServerSideSortingProps()}
        {...getServerSideFilteringProps()}
        {...restProps}
      />
    </div>
  );
}

export default PaginatedServerTable;

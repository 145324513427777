import React from 'react';
import { SvgIcon, styled } from '@material-ui/core';
import PropTypes from 'prop-types';
import { xTheme } from '@xepelinapp/x-ds';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const EyeIcon = props => {
  const { style } = props;

  return (
    <CustomSvgIcon
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M7.87934 0C11.474 0 14.4647 2.58667 15.092 6C14.4653 9.41333 11.474 12 7.87934 12C4.28467 12 1.29401 9.41333 0.666672 6C1.29334 2.58667 4.28467 0 7.87934 0ZM7.87934 10.6667C9.23898 10.6664 10.5583 10.2045 11.6212 9.35678C12.6842 8.50901 13.4279 7.32552 13.7307 6C13.4268 4.67554 12.6826 3.49334 11.6198 2.64668C10.5569 1.80003 9.23821 1.33902 7.87934 1.33902C6.52047 1.33902 5.2018 1.80003 4.13892 2.64668C3.07605 3.49334 2.33185 4.67554 2.02801 6C2.33074 7.32552 3.07445 8.50901 4.13743 9.35678C5.20041 10.2045 6.51969 10.6664 7.87934 10.6667V10.6667ZM7.87934 9C7.08369 9 6.32063 8.68393 5.75802 8.12132C5.19541 7.55871 4.87934 6.79565 4.87934 6C4.87934 5.20435 5.19541 4.44129 5.75802 3.87868C6.32063 3.31607 7.08369 3 7.87934 3C8.67499 3 9.43805 3.31607 10.0007 3.87868C10.5633 4.44129 10.8793 5.20435 10.8793 6C10.8793 6.79565 10.5633 7.55871 10.0007 8.12132C9.43805 8.68393 8.67499 9 7.87934 9ZM7.87934 7.66667C8.32137 7.66667 8.74529 7.49107 9.05785 7.17851C9.37041 6.86595 9.546 6.44203 9.546 6C9.546 5.55797 9.37041 5.13405 9.05785 4.82149C8.74529 4.50893 8.32137 4.33333 7.87934 4.33333C7.43731 4.33333 7.01339 4.50893 6.70083 4.82149C6.38827 5.13405 6.21267 5.55797 6.21267 6C6.21267 6.44203 6.38827 6.86595 6.70083 7.17851C7.01339 7.49107 7.43731 7.66667 7.87934 7.66667Z"
        fill={xTheme.text.secondary}
      />
    </CustomSvgIcon>
  );
};

EyeIcon.defaultProps = {
  style: {},
};

EyeIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default EyeIcon;

import { useEffect } from 'react';
import useWebSocketManager from './useWebsocketManager';

function WebsocketManager() {
  const {
    wsconnected,
    business,
    shouldNotifyBusinessId,
    connectWithWebSocketServer,
    notifyBusinessForBroadcast,
  } = useWebSocketManager();

  useEffect(() => {
    if (!wsconnected && process.env.NEXT_PUBLIC_WS_URL) {
      connectWithWebSocketServer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldNotifyBusinessId) {
      notifyBusinessForBroadcast(business?.id);
    }
  }, [shouldNotifyBusinessId, business?.id, notifyBusinessForBroadcast]);

  return null;
}

export default WebsocketManager;

import React, { memo } from 'react';
import { Box } from '@material-ui/core';
import { faChevronLeft, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { Container } from 'design-system/atoms/layout';
import Icon from 'design-system/icons/Icon';
import { NavbarLogo } from 'design-system/logos';
import { XAppBar } from 'components/layout/navigation/XAppBar';
import AppBarButton from './AppBarButton';

interface AppBarStepperProps {
  goBack: () => void;
  onFinish: () => void;
}

function AppBarStepper({ goBack, onFinish }: AppBarStepperProps) {
  return (
    <XAppBar>
      <Container>
        <Box justifyContent="space-between" display="flex" alignItems="center">
          <AppBarButton onClick={goBack}>
            <Icon iconDefinition={faChevronLeft} />
          </AppBarButton>
          <NavbarLogo />
          <AppBarButton onClick={onFinish}>
            <Icon iconDefinition={faXmark} color="action" />
          </AppBarButton>
        </Box>
      </Container>
    </XAppBar>
  );
}
export default memo(AppBarStepper);

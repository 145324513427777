import { decode } from 'helpers/base64';
import { GridSelectionModel } from '@material-ui/data-grid';

export const useInvoicesFromRouting = (): GridSelectionModel[] => {
  const invoicesIds = decode<GridSelectionModel[]>(
    window.location.hash.substring(1, window.location.hash.length)
  );

  return invoicesIds;
};

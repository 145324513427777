import React from 'react';
import { SvgIcon, styled } from '@material-ui/core';
import PropTypes from 'prop-types';
import { xTheme } from '@xepelinapp/x-ds';

const CustomSvgIcon = styled(SvgIcon)(props => ({
  ...props.style,
}));

const PaymentDateIcon = props => {
  const { style } = props;

  return (
    <CustomSvgIcon
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <circle cx="24" cy="24" r="24" fill={xTheme.grey[300]} />
      <path
        d="M29 15H33C33.2652 15 33.5196 15.1054 33.7071 15.2929C33.8946 15.4804 34 15.7348 34 16V32C34 32.2652 33.8946 32.5196 33.7071 32.7071C33.5196 32.8946 33.2652 33 33 33H15C14.7348 33 14.4804 32.8946 14.2929 32.7071C14.1054 32.5196 14 32.2652 14 32V16C14 15.7348 14.1054 15.4804 14.2929 15.2929C14.4804 15.1054 14.7348 15 15 15H19V13H21V15H27V13H29V15ZM32 21V17H29V19H27V17H21V19H19V17H16V21H32ZM32 23H16V31H32V23ZM18 25H23V29H18V25Z"
        fill={xTheme.grey[900]}
      />
    </CustomSvgIcon>
  );
};

PaymentDateIcon.defaultProps = {
  style: {},
};

PaymentDateIcon.propTypes = {
  style: PropTypes.objectOf(Object),
};

export default PaymentDateIcon;

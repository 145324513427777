import palette from './palette';
import oldTypography from './typography';
import overrides from './overrides';
import { XThemeOptions } from './types';

const theme: XThemeOptions = {
  palette,
  typography: oldTypography,
  overrides,
};

export default theme;

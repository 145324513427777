import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles/createTheme';

type Props = {
  spacing: number;
};

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    width: '100%',
    height: ({ spacing }) => theme.spacing(spacing),
  },
}));

function Spacer({ spacing }: Props) {
  const classes = useStyles({ spacing });
  return <div className={classes.root} />;
}

export default Spacer;

import { useRouter } from 'next/router';
import { OrderType } from '../../../useCases/OperationLineValues.type';

export const useOperationType = (): `${OrderType}` => {
  const router = useRouter();

  return router.asPath.includes('prontopago')
    ? OrderType.EARLY_PAYMENT
    : OrderType.DIRECT_FINANCING;
};

import { COUNTRY_CODE_CL } from '../constants';

const validateAccountNumber = (accountNumber, countryId) => {
  if (countryId === COUNTRY_CODE_CL) return true;
  const regex = /^(\d{18})$/;

  const weights = [3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7];
  let result = 0;
  for (let i = 0; i < 17; i += 1) {
    result += (Number(accountNumber[i]) * weights[i]) % 10;
  }

  const digit = (10 - (result % 10)) % 10;

  return regex.test(accountNumber) && digit === Number(accountNumber[17]);
};

export { validateAccountNumber };

import Loader from '../../src/components/elements/Loader';
import { useFetchBusiness } from './hooks/useFetchBusiness';

interface FetchBusinessProps {
  children: JSX.Element;
}

export function FetchBusiness({ children }: FetchBusinessProps) {
  const { isLoading: businessIsLoading } = useFetchBusiness();
  if (businessIsLoading) return <Loader />;
  return children;
}

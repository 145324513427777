import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { Typography } from '../../../../../design-system/atoms';
import CountryFormatHelper from '../../../../../components/elements/CountryFormatHelper';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      maxHeight: 18,
    },
  },
}));

interface RowInfoProps {
  label: string;
  countryId: string;
  value?: string | number;
  amount?: number;
}

export default function RowInfo({
  label,
  countryId,
  value = '',
  amount = null,
}: RowInfoProps) {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={6}>
        <Typography component="div" variant="body3" color="textPrimary">
          <Box fontWeight="fontWeightMedium">{label}</Box>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        {!amount ? (
          <Typography component="div" variant="body3" color="textSecondary">
            <Box fontWeight="fontWeightRegular">{value}</Box>
          </Typography>
        ) : (
          <Typography component="div" variant="body3" color="primary">
            <Box fontWeight="fontWeightMedium">
              <CountryFormatHelper
                variant="currency"
                dataQa="amount"
                value={amount}
                countryId={countryId}
              />
            </Box>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

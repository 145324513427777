import React from 'react';
import { Box } from '@material-ui/core';
import useTracker from '@finance/hooks/useTracker';
import { useTranslation } from '../../../features/i18n/infra/useTranslation';
import { Typography } from '../../design-system/atoms';
import { useStyles } from './SwitchFilterButton';

type SwitchFilterClientButtonProps = {
  handleSwitchButton: (mode: boolean) => void;
  isLeftButtonActivated: boolean;
};

export function SwitchFilterClientButton({
  handleSwitchButton,
  isLeftButtonActivated,
}: SwitchFilterClientButtonProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { financeCallbacks: trackerCallbacks } = useTracker();

  return (
    <Box display="flex" mt={2}>
      <Box
        onClick={() => {
          handleSwitchButton(true);
          trackerCallbacks.trackFilterUsed({
            filter_used: t('INVOICES_GROUPED_BY_CLIENT'),
          });
        }}
        fontWeight="fontWeightBold"
        sx={{ borderRadius: '8px 0 0 8px' }}
        px={2}
        py={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={`${classes.leftFilterToggleButton} ${
          isLeftButtonActivated ? classes.activeButton : classes.inactiveButton
        }`}
      >
        <Typography
          align="center"
          variant="body3"
          style={{ fontWeight: 'bold' }}
        >
          {t('INVOICES_GROUPED_BY_CLIENT')}
        </Typography>
      </Box>
      <Box
        onClick={() => {
          handleSwitchButton(false);
          trackerCallbacks.trackFilterUsed({
            filter_used: t('ALL_INVOICES'),
          });
        }}
        sx={{ borderRadius: '0 8px 8px 0' }}
        px={2}
        py={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={`${classes.rightFilterToggleButton} ${
          isLeftButtonActivated ? classes.inactiveButton : classes.activeButton
        }`}
      >
        <Typography
          align="center"
          variant="body3"
          style={{ fontWeight: 'bold' }}
        >
          {t('ALL_INVOICES')}
        </Typography>
      </Box>
    </Box>
  );
}

// eslint-disable-next-line import/prefer-default-export
export const CL = {
  cards: {
    hasSurplus: true,
  },
  orderCreation: {
    totalTableTooltip: false,
    resumeInteresRateTooltip: false,
  },
  orderDetails: {
    hasDocuments: true,
  },
  legal: {
    stakeholdersConjugal: true,
    stakeholdersHasDocuments: false,
  },
  invoicesTable: {
    totalAmountTooltip: false,
  },
  bankAccountActions: {
    hasXepelinAccountType: false,
    showCurrencyInput: false,
  },
  profileSidebar: [
    {
      key: 'profile',
      label: 'Información general',
      path: '/user/profile',
      dataQa: 'topbar-profile-navigation-link',
    },
    {
      key: 'legal',
      label: 'Información legal',
      path: '/user/profile/legal',
      dataQa: 'topbar-profile-legal-navigation-link',
    },
    {
      key: 'bankAccounts',
      label: 'Cuentas bancarias',
      path: '/user/profile/bankaccounts',
      dataQa: 'topbar-profile-bankaccounts-navigation-link',
    },
    {
      key: 'sii',
      label: 'Servicio de Impuestos Internos',
      path: '/user/profile/sii',
      dataQa: 'topbar-profile-sii-navigation-link',
    },
  ],
};

import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alert: {
      width: '100%',
      borderRadius: 8,
      padding: theme.spacing(2),
      background: theme.palette.error.light,
    },
  })
);

export { useStyles };

import { useEffect, useState } from 'react';
import { AuthStore } from '../../auth/domain/AuthStore';
import { useAuthStore } from '../../auth/infra/store/useAuthStore';

export interface User {
  isLoading: boolean;
  status: AuthStore;
}

export const useUser = (): User => {
  const authStore = useAuthStore();
  const status = authStore;
  const [isLoading, setIsLoading] = useState(!authStore.isRehydrated);

  useEffect(() => {
    if (authStore.isRehydrated) setIsLoading(false);
  }, [authStore.isRehydrated]);

  return {
    isLoading,
    status,
  };
};

import { WithdrawalZustandStoreType } from '@wallet/withdrawal';
import WithdrawalZustandStore from './WithdrawalZustandStore';

export const useClearWithdrawalStore = (): { clear: () => void } => {
  return {
    clear: WithdrawalZustandStore(
      (state: WithdrawalZustandStoreType) => state.resetStore
    ),
  };
};

import { Box, makeStyles, Slide, SlideProps } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  selectionBarContainer: {
    background: theme.palette.primary.main,
    borderRadius: '8px 8px 0px 0px',
    width: '100%',
    minHeight: 96,
    bottom: -5,
    position: 'sticky',
    marginTop: 20,
    zIndex: 15,
    color: 'white',
    marginBottom: theme.spacing(-1), // fixing a bug in AR table
  },
}));

type SelectionBarProps = SlideProps & {
  children: React.ReactNode;
};

function SelectionBar(props: SelectionBarProps) {
  const classes = useStyles();
  const { children, ...slideProps } = props;

  return (
    <Slide {...slideProps}>
      <Box className={classes.selectionBarContainer} px={4} py={2}>
        {children}
      </Box>
    </Slide>
  );
}

export default SelectionBar;

import { WalletError, WalletRepository } from '@wallet/account';
import axios from '../../../../helpers/axios';
import { WalletMap } from '../mappers';

export const useWalletRepository = (): WalletRepository => {
  const getBusinessWallet = async (businessIdentifier: string) => {
    try {
      const { data: response } = await axios.get(
        `/api/wallet/business/${businessIdentifier}/account`
      );
      const wallet = response?.accounts?.[0];
      return wallet ? WalletMap.toDomain(wallet) : null;
    } catch (error) {
      const errorCode = error?.response?.data?.errorCode;
      throw new WalletError(errorCode);
    }
  };

  const getWalletBalance = async (businessIdentifier: string) => {
    try {
      const { data } = await axios.get(
        `/api/wallet/business/${businessIdentifier}/balance`
      );
      return data?.balance;
    } catch (error) {
      const errorCode = error?.response?.data?.errorCode;
      throw new WalletError(errorCode);
    }
  };

  return {
    getBusinessWallet,
    getWalletBalance,
  };
};

import { useState } from 'react';
import { Translator } from '../domain/Translator';
import chileTranslations from '../../../public/locales/CL/translations.json';
import mexicoTranslations from '../../../public/locales/MX/translations.json';
import spanishFallback from '../../../public/locales/es/translations.json';
import { LanguageMap } from './mappers/language.map';

export const useTranslation = (): Translator => {
  const translationDictionary = {
    CL: chileTranslations,
    MX: mexicoTranslations,
    es: spanishFallback,
  };

  const [language, setLanguage] = useState<Translator['i18n']['language']>(
    LanguageMap.toInstance(process.env.NEXT_PUBLIC_COUNTRY_ID)
  );

  const changeLanguage: Translator['i18n']['changeLanguage'] = newLanguage =>
    setLanguage(newLanguage);

  const t = (text: string) => {
    if (translationDictionary[language]![text]) {
      return translationDictionary[language]![text];
    }
    if (translationDictionary.es[text]) {
      return translationDictionary.es[text];
    }
    return text;
  };
  return { t, i18n: { language, changeLanguage } };
};

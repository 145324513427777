/* eslint-disable @next/next/no-img-element */
import { Box, Typography, Button } from '@material-ui/core';
import { useStyles } from './SwitchToOtherTab.styles';
import { ModalContentProps } from './types';

export function ModalContent({
  typeOfOperation,
  closeModal,
  navigateToOtherTab,
}: ModalContentProps) {
  const classes = useStyles();
  return (
    <Box className={classes.modalContent}>
      <button
        onClick={closeModal}
        type="button"
        className={classes.closeButton}
      >
        <img src="/assets/close.svg" alt="close" width={20} height={20} />
      </button>
      <Typography className={classes.titleModal} variant="h6">
        ¿Estás seguro que deseas ir a {typeOfOperation.label}?
      </Typography>
      <Typography
        color="textSecondary"
        className={classes.subtitleModal}
        variant="body2"
      >
        Ten en cuenta que al hacerlo, perderás las facturas seleccionadas hasta
        el momento.
      </Typography>
      <Box width="100%" display="flex" justifyContent="space-between">
        <Button
          className={classes.buttonModal}
          color="primary"
          variant="outlined"
          onClick={closeModal}
        >
          <Typography variant="body2">Cancelar</Typography>
        </Button>
        <Button
          className={classes.buttonModal}
          color="primary"
          variant="contained"
          onClick={navigateToOtherTab}
        >
          <Typography variant="body2">Ir de todos modos</Typography>
        </Button>
      </Box>
    </Box>
  );
}

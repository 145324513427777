import React from 'react';
import { Spacer } from '@features/shared';
import { FullScreenDialog, Stepper, Success } from '../common';
import { Account } from './steps';

interface CreateWithdrawalAccountModalProps {
  open: boolean;
  onClose: () => void;
}

function CreateWithdrawalAccountModal({
  open,
  onClose,
}: CreateWithdrawalAccountModalProps) {
  const STEPS = [
    {
      component: <Account />,
      nextLabel: 'Agregar',
    },
  ];

  const COMPLETE_STEP = {
    component: (
      <Success
        imageSrc="/assets/successStep.png"
        title="¡Cuenta agregada con éxito!"
        content="Tu cuenta ha sido agregada con éxito. Te comunicaremos cuando tu cuenta esté validada y lista para operar."
        buttonLabel="Terminar"
      />
    ),
  };

  return (
    <FullScreenDialog open={open}>
      <Spacer spacing={8} />
      <Stepper
        steps={STEPS}
        stepCompleted={COMPLETE_STEP}
        showAppbar
        onFinish={onClose}
      />
    </FullScreenDialog>
  );
}

export default CreateWithdrawalAccountModal;

/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import { translationKeysReasons } from '@finance/utils/getNonFinancingReasonByCategory';
import { InvoiceCategories } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';
import { useTranslation } from '@features/i18n/infra/useTranslation';
import { SwitchToOtherTab } from '@finance/components/switch-to-other-tab';
import { OrderType } from '@finance/useCases/OperationLineValues.type';
import infoTooltipDisabled from '../../../assets/infoTooltipDisabled.svg';

const useStyles = makeStyles(theme => ({
  tooltipIcon: {
    marginRight: theme.spacing(2),
    pointerEvents: 'all',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

type TablePayerCellProps = {
  payerName: string;
  tooltipText: string;
};

function TablePayerCell({ payerName, tooltipText }: TablePayerCellProps) {
  const classes = useStyles();

  const { t } = useTranslation();

  const isDirectFinancing =
    t(translationKeysReasons[InvoiceCategories.directFinancing]) ===
    tooltipText;

  const isEarlyPayment =
    t(translationKeysReasons[InvoiceCategories.earlyPayment]) === tooltipText;

  const isOtherOrderType = [isDirectFinancing, isEarlyPayment].includes(true);

  const icon = (
    <img
      src={infoTooltipDisabled.src}
      alt="infoTooltipDisabled"
      className={classes.tooltipIcon}
    />
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      py={1.5}
    >
      {isOtherOrderType ? (
        <SwitchToOtherTab
          orderType={
            isEarlyPayment
              ? OrderType.EARLY_PAYMENT
              : OrderType.DIRECT_FINANCING
          }
        >
          {icon}
        </SwitchToOtherTab>
      ) : (
        !!tooltipText && (
          <Tooltip
            className={classes.tooltipIcon}
            title={tooltipText}
            placement="top"
            arrow
            onClick={e => {
              e.stopPropagation();
            }}
          >
            {icon}
          </Tooltip>
        )
      )}

      {payerName}
    </Box>
  );
}

export default TablePayerCell;

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Paper } from '@material-ui/core';
import { convertSpacingToCss } from '../../../helpers/stylesHelpers';
import { CARD_VARIANTS } from './styles';

const useStyles = makeStyles({
  rootCards: {
    borderRadius: 17,
    padding: props => (props.padding ? convertSpacingToCss(props.padding) : 0),
  },
});

const Card = props => {
  const { padding } = props;
  const classes = useStyles({ padding });
  const { border, className, children, variant } = props;
  return (
    <Paper
      spacing={3}
      elevation={0}
      className={`${classes.rootCards} ${className}`}
      style={{
        border: border ? `1px solid ${CARD_VARIANTS[variant].border}` : '',
        background: CARD_VARIANTS[variant].background,
      }}
    >
      {children}
    </Paper>
  );
};

Card.defaultProps = {
  border: false,
  className: '',
};
Card.defaultProps = {
  padding: 'zero',
};

Card.propTypes = {
  padding: PropTypes.string,
  children: PropTypes.element.isRequired,
  border: PropTypes.bool,
  variant: PropTypes.oneOf(
    Object.keys(CARD_VARIANTS).map(variant => CARD_VARIANTS[variant].code)
  ).isRequired,
  className: PropTypes.string,
};

export default Card;

import React from 'react';
import { Container } from 'design-system/atoms/layout';
import { Grid } from '@material-ui/core';
import Spacer from '../../../../../features/shared/Spacer';

export function CalculateWrapper({ children }) {
  return (
    <Container>
      <Spacer spacing={2} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

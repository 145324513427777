// eslint-disable-next-line import/prefer-default-export
export const getEmailMask = (email: string) => {
  const maskedEmail = email.replace(/([^@.])/g, '*').split('');
  let previous = '';
  for (let i = 0; i < maskedEmail.length; i += 1) {
    if (i <= 1 || previous === '.' || previous === '@') {
      maskedEmail[i] = email[i];
    }
    previous = email[i];
  }
  return maskedEmail.join('');
};

export const verifyEmail = (emailInput: string) => {
  // Una implementación del Estandard Official: RFC 5322 (valida en el 99.99% de los emails existentes)
  const emailValue = emailInput.toLocaleLowerCase();
  const regexEmail =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}(\.\d{1,3}){3}\])|(([\w\-]+\.)+[a-z]{2,}))$/i;
  if (!regexEmail.test(emailValue)) {
    return false;
  }
  return true;
};

import { WalletError, WalletRepository, WalletStore } from '@wallet/account';

const fetchBusinessWalletUseCase = async ({
  businessId,
  walletRepository,
  walletStore,
}: {
  businessId: string;
  walletRepository: WalletRepository;
  walletStore: WalletStore;
}) => {
  try {
    walletStore.setFetchingWallet(true);
    const wallet = await walletRepository.getBusinessWallet(businessId);
    walletStore.setBusinessWallet(wallet);
    return wallet;
  } catch (error) {
    if (error instanceof WalletError) {
      return undefined;
    }
    throw new Error(error);
  } finally {
    walletStore.setFetchingWallet(false);
  }
};

export default fetchBusinessWalletUseCase;

import { IconButton, withStyles } from '@material-ui/core';

const AppBarButton = withStyles(({ palette }) => ({
  root: {
    borderRadius: 8,
    border: `1px solid ${palette.grey[600]}`,
  },
}))(IconButton);

export default AppBarButton;

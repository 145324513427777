import { Box, Tooltip } from '@material-ui/core';
import React from 'react';
import Image from 'next/image';
import { Typography } from '../../../../design-system/atoms';
import infoTooltip from '../../../../assets/infoTooltip.svg';
import { InfoIconTooltipProps } from './tables-by-clients-props';
import { useStyles } from './tables-by-clients-styles';

export function InfoIconTooltip({ tooltipContent }: InfoIconTooltipProps) {
  const classes = useStyles();

  return (
    <Box mt={1} justifyContent="space-between">
      <Tooltip
        className={classes.tooltipIcon}
        title={
          <Box>
            <Typography variant="body3">{`${tooltipContent}`}</Typography>
          </Box>
        }
        placement="top"
        arrow
      >
        <div>
          <Image
            src={infoTooltip.src}
            alt="infoTooltip"
            width="16px"
            height="16px"
            unoptimized
          />
        </div>
      </Tooltip>
    </Box>
  );
}

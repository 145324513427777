import { Invoice } from '@integration-services/xepelin-server-global/invoices/services/invoicesServicesTypes';

export const cleanDataInvoices = (invoice: Invoice): Invoice => ({
  id: invoice.id,
  folio: invoice.folio,
  issueDate: invoice.issueDate,
  amount: invoice.amount,
  payerName: invoice.payerName,
  payerIdentifier: invoice.payerIdentifier,
  expirationDate: invoice?.prevExpirationDate || invoice.expirationDate,
  averageDaysLate: invoice.averageDaysLate,
  paymentMethod: invoice.paymentMethod,
  totalAmountCreditNotes: invoice.totalAmountCreditNotes,
  categories: invoice.categories,
});

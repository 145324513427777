import moment, { Moment } from 'moment';
import { Grid, Chip, Box, makeStyles } from '@material-ui/core';
import { Typography } from 'design-system/atoms';
import { Theme, withStyles } from '@material-ui/core/styles';
import colors from 'design-system/theme/colors';
import { DATE_FORMAT } from 'helpers/constants';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers/rootReducer';
import { isFinanceableDateFromTerm } from 'helpers/dateHelpers';
import { theme } from 'design-system/theme';
import { useTranslation } from '../../../../../features/i18n/infra/useTranslation';
import { useSpacingStyles } from '../../../../features/shared/styles/spacings';
import InfoIconSquare from '../../../../components/icons/InfoIconSquare';

type ChipWithTermsProp = Array<{ term: number; date: Moment }>;

type TermsDateSelectorProps = {
  chips: ChipWithTermsProp;
  selectedDate: Moment;
  suggestedPaymentTerm: number;
  handleChangeDate: Function;
  issueDate: Moment;
};

const useStyles = makeStyles(({ palette }) => ({
  selectedChip: {
    color: palette.primary.main,
    backgroundColor: `${palette.grey[200]} !important`,
    border: `1px solid ${palette.primary.main} !important`,
  },
  selectedChipText: {
    color: `${palette.primary.main} !important`,
  },
}));

const StyledChip = withStyles((themeStyles: Theme) => ({
  root: {
    height: themeStyles.spacing(5),
    width: themeStyles.spacing(12),
    background: colors.background.paper,
    borderRadius: themeStyles.spacing(4),
    border: `1px solid ${colors.divider.dark}`,
    '&&:hover': {
      cursor: 'pointer',
    },
    marginRight: themeStyles.spacing(1),
  },
}))(Chip);

export function TermsDateSelector({
  chips,
  selectedDate,
  suggestedPaymentTerm,
  handleChangeDate,
  issueDate,
}: TermsDateSelectorProps) {
  const { t } = useTranslation();
  const spacing = useSpacingStyles();
  const classes = useStyles();
  const { holidays } = useSelector((state: RootState) => state.holidaysState);

  const validate = (term: number) =>
    isFinanceableDateFromTerm(issueDate, term, holidays);

  return (
    <>
      <Typography
        variant="body3"
        align="center"
        style={{ fontWeight: theme.typography.fontWeightRegular }}
        color="textSecondary"
      >
        {t('TERMS_DIALOG_SUBTITLE')}
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor={colors.grey.m200}
        className={`${spacing.mb4} ${spacing.mt2} ${spacing.px2} ${spacing.py1} ${spacing.br2}`}
      >
        <Box display="flex" justifyContent="center" alignItems="center" mr={1}>
          <InfoIconSquare color={theme.palette.text.secondary} />
        </Box>
        <Box textAlign="left">
          <Typography variant="body3" color="textSecondary">
            {t('SUGGESTED_HISTORICAL_PAYMENT_DATE')}:{' '}
            <Box fontWeight="fontWeightBold" component="span">
              {suggestedPaymentTerm} {t('SELECTED_PAYMENT_TERM_DAYS')}
            </Box>
            .
          </Typography>
        </Box>
      </Box>
      <Grid>
        {chips.map(({ term, date }) => {
          if (!validate(term)) return null;
          const isSelectedChip =
            date.utc().toDate().toISOString() ===
            moment(selectedDate).utc().toDate().toISOString();
          return (
            <StyledChip
              key={term}
              className={`${isSelectedChip && classes.selectedChip}`}
              label={
                <Typography
                  className={`${isSelectedChip && classes.selectedChipText}`}
                  variant="body3"
                  color="textSecondary"
                  align="center"
                  style={{ fontWeight: theme.typography.fontWeightBold }}
                >
                  {term} {t('SELECTED_PAYMENT_TERM_DAYS')}
                </Typography>
              }
              variant="default"
              onClick={() => handleChangeDate(date)}
            />
          );
        })}
      </Grid>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={`${spacing.mb4} ${spacing.mt3}`}
      >
        <Typography variant="body3" color="textSecondary">
          {t('PAYMENT_TERM_DATE')}:
        </Typography>
        <Box className={spacing.ml1}>
          {selectedDate.utc().format(DATE_FORMAT)}
        </Box>
      </Box>
    </>
  );
}

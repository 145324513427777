import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  rootDialog: {
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
    },
    padding: theme.spacing(7),
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  dialogTitle: {
    padding: 0,
    marginBottom: theme.spacing(1),
  },
  item: {
    marginBottom: theme.spacing(2),
  },
  content: {
    padding: 0,
    overflow: 'unset',
  },
}));

export default styles;

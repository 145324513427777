import { ONBOARDING_STEP_CASES } from 'helpers/constants';

export const enum StatesOfLoading {
  UPLOAD_DOCUMENTATION_REQUIRED_STATE = 'UPLOAD_DOCUMENTATION_REQUIRED_STATE',
  HISTORY_FINISHED_WITH_INVOICES_STATE = 'HISTORY_FINISHED_WITH_INVOICES_STATE',
  HISTORY_UNFINISHED_WITHOUT_INVOICES_STATE = 'HISTORY_UNFINISHED_WITHOUT_INVOICES_STATE',
  HISTORY_FINISHED_WITHOUT_INVOICES_STATE = 'HISTORY_FINISHED_WITHOUT_INVOICES_STATE',
}

export const addCredentailsStep = ONBOARDING_STEP_CASES.ADD_TAX_CREDENTIALS;

export const useGetInvoiceLoadingState = (
  hasInvoiceHistory: boolean,
  hasInvoices: boolean,
  onboardingStep: string
) => {
  const uploadDocumentationRequired =
    !hasInvoiceHistory && !hasInvoices && onboardingStep === addCredentailsStep;

  const unfinishedWithoutInvoices =
    !hasInvoiceHistory && !hasInvoices && onboardingStep !== addCredentailsStep;

  const finishedWithInvoicesState =
    hasInvoiceHistory && hasInvoices && onboardingStep !== addCredentailsStep;

  const finishedWithoutInvoices =
    hasInvoiceHistory && !hasInvoices && onboardingStep !== addCredentailsStep;

  if (unfinishedWithoutInvoices) {
    return StatesOfLoading.HISTORY_UNFINISHED_WITHOUT_INVOICES_STATE;
  }

  if (uploadDocumentationRequired) {
    return StatesOfLoading.UPLOAD_DOCUMENTATION_REQUIRED_STATE;
  }

  if (finishedWithoutInvoices) {
    return StatesOfLoading.HISTORY_FINISHED_WITHOUT_INVOICES_STATE;
  }

  if (finishedWithInvoicesState) {
    return null;
  }

  return null;
};

import { WalletError, WalletRepository, WalletStore } from '@wallet/account';

const fetchWalletBalanceUseCase = async ({
  businessId,
  walletRepository,
  walletStore,
}: {
  businessId: string;
  walletRepository: WalletRepository;
  walletStore: WalletStore;
}) => {
  try {
    walletStore.setFetchingBalance(true);
    const balance = await walletRepository.getWalletBalance(businessId);
    walletStore.setWalletBalance(balance);
    return balance;
  } catch (error) {
    if (error instanceof WalletError) {
      return undefined;
    }
    throw new Error(error);
  } finally {
    walletStore.setFetchingBalance(false);
  }
};

export default fetchWalletBalanceUseCase;

import { useEffect } from 'react';

export const useBackListener = ({
  onBack,
  urls,
}: {
  onBack: () => void;
  urls: string[];
}) => {
  useEffect(() => {
    window.onpopstate = (event: PopStateEvent) => {
      if (urls.includes(event.state.as)) {
        onBack();
      }
    };
  }, [onBack, urls]);
};

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  hidden: {
    display: 'none',
  },
  content: {
    padding: 30,
  },
  root: {
    flexGrow: 1,
    marginTop: 60,
  },
  appBar: {
    background: theme.palette.background.default,
    borderBottom: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
    zIndex: 1,
  },
  cards: {
    background: theme.palette.common.white,
    position: 'sticky',
    top: 50,
    zIndex: 100,
  },
  appBarRoot: {
    position: 'sticky',
    top: 230,
    zIndex: 100,
  },
}));

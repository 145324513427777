export type HistoryProxyObject = {
  setPage: (page: Function) => void;
  hasPage: (page: Function) => boolean;
  deleteHistory: () => void;
  getLengthOfHistory: () => number;
  getHistory: () => string[];
};

export const createHistoryProxy = (
  navigationKey: string
): HistoryProxyObject => {
  const setPage = (page: Function): void => {
    const history = sessionStorage.getItem(navigationKey);

    if (!history) {
      const newList = Array.from(new Set([page.name]));
      sessionStorage.setItem(navigationKey, JSON.stringify(newList));
      return;
    }

    const historyToJSON = JSON.parse(history);

    const deleteEquals = Array.from(new Set([...historyToJSON, page.name]));

    sessionStorage.setItem(navigationKey, JSON.stringify(deleteEquals));
  };

  const hasPage = (page: Function): boolean => {
    const history = sessionStorage.getItem(navigationKey);

    if (!history) return false;

    const historyToJSON = JSON.parse(history).includes(page.name);

    return historyToJSON;
  };

  const deleteHistory = (): void => {
    sessionStorage.removeItem(navigationKey);
  };

  const getLengthOfHistory = () => {
    const history = sessionStorage.getItem(navigationKey);

    if (!history) return 0;

    const historyParse = JSON.parse(history);

    return historyParse.length;
  };

  const getHistory = () => {
    const history = sessionStorage.getItem(navigationKey);

    if (!history) return [];

    return JSON.parse(history);
  };

  return {
    setPage,
    hasPage,
    deleteHistory,
    getLengthOfHistory,
    getHistory,
  };
};

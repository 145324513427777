import { PURGE } from 'redux-persist';
import {
  CHECK_TAX_SERVICE_CREDENTIAL_SUCCESS,
  CHECK_TAX_SERVICE_CREDENTIAL_ERROR,
  CHECK_TAX_SERVICE_CREDENTIAL_START,
  CREATE_TAX_SERVICE_CREDENTIAL_START,
  CREATE_TAX_SERVICE_CREDENTIAL_SUCCESS,
  CREATE_TAX_SERVICE_CREDENTIAL_ERROR,
  CREATE_TAX_SERVICE_CREDENTIAL_RESET,
  LOCAL_PURGE,
} from '../actions/types';
import { t13s } from '../../config/translationKeys';

const initialState = {
  taxStatus: {},
  checkTaxServiceCredentialIsLoading: false,
  checkTaxServiceCredentialError: null,

  taxServiceCredentialCreated: false,
  createTaxServiceCredentialIsLoading: false,
  createTaxServiceCredentialError: null,
};

const checkTaxServiceCredentialStart = state => {
  return {
    ...state,
    checkTaxServiceCredentialIsLoading: true,
    checkTaxServiceCredentialError: null,
  };
};

const checkTaxServiceCredentialSuccess = (state, payload) => {
  return {
    ...state,
    taxStatus: payload.taxStatus,
    checkTaxServiceCredentialIsLoading: false,
    checkTaxServiceCredentialError: null,
  };
};

const checkTaxServiceCredentialError = (state, payload) => {
  return {
    ...state,
    taxStatus: {},
    checkTaxServiceCredentialIsLoading: false,
    checkTaxServiceCredentialError:
      payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const createTaxServiceCredentialStart = state => {
  return {
    ...state,
    taxServiceCredentialCreated: false,
    createTaxServiceCredentialIsLoading: true,
    createTaxServiceCredentialError: null,
  };
};

const createTaxServiceCredentialSuccess = state => {
  return {
    ...state,
    taxServiceCredentialCreated: true,
    createTaxServiceCredentialIsLoading: false,
    createTaxServiceCredentialError: null,
  };
};

const createTaxServiceCredentialError = (state, payload) => {
  return {
    ...state,
    taxServiceCredentialCreated: false,
    createTaxServiceCredentialIsLoading: false,
    createTaxServiceCredentialError:
      payload.errorCode || t13s.ERROR.UNKNOWN_ERROR,
  };
};

const createTaxServiceCredentialReset = state => {
  return {
    ...state,
    taxServiceCredentialCreated: false,
    createTaxServiceCredentialIsLoading: false,
    createTaxServiceCredentialError: null,
  };
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CHECK_TAX_SERVICE_CREDENTIAL_START:
      return checkTaxServiceCredentialStart(state);
    case CHECK_TAX_SERVICE_CREDENTIAL_SUCCESS:
      return checkTaxServiceCredentialSuccess(state, payload);
    case CHECK_TAX_SERVICE_CREDENTIAL_ERROR:
      return checkTaxServiceCredentialError(state, payload);
    case CREATE_TAX_SERVICE_CREDENTIAL_START:
      return createTaxServiceCredentialStart(state);
    case CREATE_TAX_SERVICE_CREDENTIAL_SUCCESS:
      return createTaxServiceCredentialSuccess(state);
    case CREATE_TAX_SERVICE_CREDENTIAL_ERROR:
      return createTaxServiceCredentialError(state, payload);
    case CREATE_TAX_SERVICE_CREDENTIAL_RESET:
      return createTaxServiceCredentialReset(state);
    case LOCAL_PURGE:
      return initialState;
    case PURGE:
      return initialState;
    default:
      return state;
  }
};

import React, { useEffect } from 'react';
import { Typography } from 'design-system/atoms';
import { Spacer } from '@features/shared';
import Icon from 'design-system/icons/Icon';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { Box, Button, TextField, FormHelperText } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import CountryFormatHelper from 'components/elements/CountryFormatHelper';
import { StepperStore } from '../../../common/Stepper/types';
import useAmount from './useAmount';
import { useStyles } from './styles';

interface AmountProps {
  stepperStore?: StepperStore;
}

function Amount({ stepperStore }: AmountProps) {
  const classes = useStyles();
  const {
    amount,
    amountRules,
    balance,
    countryId,
    currentStepPosition,
    totalSteps,
    onSubmit,
    setAmount,
  } = useAmount(stepperStore);

  const { control, handleSubmit, formState, setValue, reset } = useForm({
    mode: 'onChange',
  });

  const locales = {
    STEP: `Paso ${currentStepPosition + 1} de ${totalSteps}`,
    TITLE: 'Monto a retirar',
    BODY: 'Escribe el monto que deseas retirar de tu cuenta.',
    AVAILABLE_BALANCE: 'Saldo disponible',
    AMOUNT_LABEL: 'Monto',
    INSUFICIENT_BALANCE:
      'Estás intentando transferir más dinero del disponible en tu cuenta Xepelin. Asegúrate de disponer de los fondos antes de retirar.',
    NEXT: 'Siguiente',
  };

  useEffect(() => {
    reset();
    if (amount) {
      setValue('amount', amount, {
        shouldValidate: true,
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box>
      <Typography variant="body2" color="primary">
        {locales.STEP}
      </Typography>
      <Spacer spacing={2} />
      <Typography variant="h6">{locales.TITLE}</Typography>
      <Spacer spacing={2} />
      <Typography variant="body2" color="textSecondary">
        {locales.BODY}
      </Typography>
      <Spacer spacing={2} />
      <Typography variant="body2" color="textSecondary" component="div">
        {locales.AVAILABLE_BALANCE}:{' '}
        <Box color="success.main" fontWeight="fontWeightBold" display="inline">
          <CountryFormatHelper
            countryId={countryId}
            value={balance}
            variant="currency"
          />
        </Box>
      </Typography>
      <Spacer spacing={8} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="amount"
          rules={amountRules}
          control={control}
          render={({ field, fieldState }) => (
            <>
              <TextField
                {...field}
                onChange={event => {
                  field.onChange(event);
                  setAmount(Number(event.target.value));
                }}
                autoComplete="new-password"
                type="number"
                variant="outlined"
                placeholder={locales.AMOUNT_LABEL}
                label={locales.AMOUNT_LABEL}
                value={field.value || ''}
                fullWidth
                error={!!fieldState.error}
              />
              <FormHelperText component="div">
                {fieldState.error?.message && (
                  <Box display="flex" alignItems="center" mt={2}>
                    <Box display="flex" alignItems="center" mr={1}>
                      <Icon
                        iconDefinition={faCircleExclamation}
                        color="error"
                      />
                    </Box>
                    <Typography variant="body2" color="error">
                      {fieldState.error.message}
                    </Typography>
                  </Box>
                )}
                {fieldState.error?.type === 'max' && (
                  <>
                    <Spacer spacing={2} />
                    <Box className={classes.alert}>
                      <Typography variant="body3" color="error">
                        {locales.INSUFICIENT_BALANCE}
                      </Typography>
                    </Box>
                  </>
                )}
              </FormHelperText>
            </>
          )}
        />
        <Spacer spacing={6} />
        <Button
          disabled={!formState.isValid}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
        >
          {locales.NEXT}
        </Button>
      </form>
    </Box>
  );
}

export default Amount;

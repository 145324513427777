import {
  Box,
  CircularProgress,
  CircularProgressProps,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Typography } from '../../src/design-system/atoms';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomCircularProgress: {
      color: theme.palette.grey[400],
    },
    topCircularProgress: {
      color: theme.palette.success.main,
      animationDuration: '550ms',
      position: 'absolute',
      left: 0,
    },
  })
);

function CircularProgressWithLabel(
  props: CircularProgressProps & { label: string }
) {
  const { label } = props;
  const classes = useStyles();
  return (
    <Box position="relative" display="inline-flex">
      {/* CircularProgress background */}
      <CircularProgress
        className={classes.bottomCircularProgress}
        variant="determinate"
        value={100}
        size={40}
        thickness={6}
      />
      {/* CircularProgress  */}
      <CircularProgress
        className={classes.topCircularProgress}
        variant="determinate"
        size={40}
        thickness={6}
        {...props}
      />
      {/* Label */}
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="tiny" component="div" color="textSecondary">
          <Box fontWeight="fontWeightMedium">{label}</Box>
        </Typography>
      </Box>
    </Box>
  );
}

export default CircularProgressWithLabel;

export enum OrderType {
  EARLY_PAYMENT = 'EARLY_PAYMENT',
  DIRECT_FINANCING = 'DIRECT_FINANCING',
}

export type OperationLineValues = {
  totalAmount: number;
  retentionPct: number;
  retention: number;
  baseRate: number;
  transfer: number;
  advance: number;
  interest: number;
  operationCost: number;
  amountOrderInvoiceDiscounts: number;
  amountOrderDiscounts: number;
  totalAmountCreditNotes: number;
  orderType?: OrderType;
  status?: string;
  invoiceStatuses?: string[];
  hasPromotions?: boolean;
  promotionBaseRate?: number;
  transferWithPromotions?: number;
  interestWithPromotions?: number;
  promotionsLimitAmount?: number;
};

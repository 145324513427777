import React from 'react';
import { isMobile } from 'react-device-detect';
import { Typography } from 'design-system/atoms';
import { Box, Button, MenuItem } from '@material-ui/core';
import { Spacer } from '@features/shared';
import useAccount from '@wallet/withdrawal/components/CreateWithdrawalAccountModal/steps/useAccount';
import TextField from '@material-ui/core/TextField';
import { Controller, useForm } from 'react-hook-form';
import { FormInputHelperText } from '@wallet/withdrawal/components/common/FormInputHelperText';
import { StepperStore } from '../../common/Stepper/types';

interface AccountProps {
  stepperStore?: StepperStore;
}

function Account({ stepperStore }: AccountProps) {
  const {
    accountRules,
    bankRules,
    t,
    emailRules,
    onSubmit,
    identifier,
    banks,
    userEmail,
    isAddingBankAccount,
  } = useAccount({
    stepperStore,
  });
  const { control, handleSubmit, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: userEmail,
      id: identifier,
      accountNumber: '',
      bankId: '',
      alias: '',
    },
  });

  const locales = {
    TITLE: 'Agrega una cuenta',
    SUBTITLE:
      'Para verificar tu información bancaria, el titular de la CLABE debe tener el mismo RFC con la que diste de alta tu cuenta Xepelin.',
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Spacer spacing={2} />
      <Typography variant="h6">{locales.TITLE}</Typography>
      <Spacer spacing={2} />
      <Typography variant="body2" color="textSecondary">
        {locales.SUBTITLE}
      </Typography>
      <Spacer spacing={4} />
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Controller
          name="id"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              disabled
              type="text"
              variant="outlined"
              label={t('LABEL_BUSINESS_IDENTIFIER')}
              fullWidth
            />
          )}
        />
        <Spacer spacing={3} />
        <Controller
          name="accountNumber"
          rules={accountRules}
          control={control}
          render={({ field, fieldState }) => (
            <>
              <TextField
                {...field}
                type="text"
                variant="outlined"
                label={t('LABEL_BANK_ACCOUNT_IDENTIFIER')}
                value={field.value || ''}
                fullWidth
                error={fieldState.isTouched && !!fieldState.error}
                autoFocus={!isMobile}
              />
              <FormInputHelperText
                show={!!(fieldState.isTouched && fieldState.error?.message)}
                message={fieldState.error?.message}
              />
            </>
          )}
        />
        <Spacer spacing={3} />
        <Controller
          name="bankId"
          rules={bankRules}
          control={control}
          render={({ field, fieldState }) => (
            <>
              <TextField
                {...field}
                select
                variant="outlined"
                label="Banco"
                value={field.value || ''}
                fullWidth
                error={fieldState.isTouched && !!fieldState.error}
              >
                {banks.map(bank => (
                  <MenuItem value={bank.id} key={bank.name}>
                    {bank.name}
                  </MenuItem>
                ))}
              </TextField>
              <FormInputHelperText
                show={!!(fieldState.isTouched && fieldState.error?.message)}
                message={fieldState.error?.message}
              />
            </>
          )}
        />
        <Spacer spacing={3} />
        <Controller
          name="email"
          rules={emailRules}
          control={control}
          render={({ field, fieldState }) => (
            <>
              <TextField
                {...field}
                type="email"
                variant="outlined"
                label={t('LABEL_EMAIL')}
                value={field.value || ''}
                fullWidth
                error={fieldState.isTouched && !!fieldState.error}
              />
              <FormInputHelperText
                show={!!(fieldState.isTouched && fieldState.error?.message)}
                message={fieldState.error?.message}
              />
            </>
          )}
        />
        <Spacer spacing={3} />
        <Controller
          name="alias"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              variant="outlined"
              label="Alias (Opcional)"
              value={field.value || ''}
              fullWidth
              error={fieldState.isTouched && !!fieldState.error}
            />
          )}
        />
        <Spacer spacing={6} />
        <Button
          disabled={!formState.isValid || isAddingBankAccount}
          type="submit"
          variant="contained"
          color="primary"
        >
          Siguiente
        </Button>
      </form>
    </Box>
  );
}

export default React.memo(Account);

import { useState } from 'react';

const useStepper = ({ steps, stepCompleted }) => {
  const [flowSteps] = useState([...steps, stepCompleted]);
  const [currentStep, setCurrentStep] = useState(0);

  const goNext = () => {
    if (currentStep + 1 <= flowSteps?.length) {
      setCurrentStep(value => value + 1);
    }
  };

  const goBack = () => {
    setCurrentStep(value => {
      if (value - 1 >= 0) {
        return value - 1;
      }
      return value;
    });
  };

  const getProgress = () => {
    return Math.round((currentStep * 100) / steps.length);
  };

  return {
    goNext,
    goBack,
    currentStepPosition: currentStep,
    currentStep: flowSteps[currentStep],
    getProgress,
  };
};

export default useStepper;

import { useManageFilters } from '@finance/hooks/migration/useManageFilters';
import { useOperationType } from '@finance/hooks/migration/useOperationType';
import { useSnackbar } from 'notistack';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { cachingPagination } from '@finance/utils/cachingPagination';
import { useAuthStore } from '../../../../../features/auth/infra/store/useAuthStore';
import { RootState } from '../../../../store/reducers/rootReducer';
import { getInvoices, InvoicesResponse } from '../services/invoicesService';

const useGetInvoices = () => {
  const operationType = useOperationType();

  const { token } = useAuthStore();
  const { enqueueSnackbar } = useSnackbar();

  const business = useSelector(
    (state: RootState) => state.businessState.business
  );

  const {
    filter,
    pageSize,
    currentPage,
    sorting,
    allInvoices,
    handleAllInvoices,
  } = useManageFilters();

  const pagination = {
    filter,
    size: pageSize,
    page: currentPage + 1,
    order: sorting,
    orderType: operationType,
  };

  const canFetchAllInvoices = [allInvoices, Boolean(filter)].includes(true);

  const useQueryObj = useQuery<unknown, unknown, InvoicesResponse>(
    [useGetInvoices.name, canFetchAllInvoices, pagination],
    async () => {
      return getInvoices({
        businessId: business?.id,
        token: token.value,
        pagination,
        shoudFetchAllInvoices: canFetchAllInvoices,
      }).catch(() => {
        enqueueSnackbar('Error inesperado', {
          variant: 'error',
        });
      });
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const paginationServer = cachingPagination(useQueryObj?.data?.pagination);

  return {
    pagination,
    allInvoices,
    handleAllInvoices,
    ...useQueryObj,
    data: {
      ...useQueryObj.data,
      pagination: paginationServer,
    },
  };
};

export default useGetInvoices;

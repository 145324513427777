import { useEffect } from 'react';
import { historyProxy } from '../hoc/HistoryProxy';

export function ProviderFinance({ children }) {
  useEffect(() => {
    return () => {
      historyProxy.deleteHistory();
    };
  }, []);

  return children;
}

import { StatesOfLoading } from '@finance/hooks/migration/useGetInvoiceLoadingState';

import {
  InvoicesNotFound,
  HistoryProcessingMessage,
  InvoicesDocumentationRequired,
} from '@finance/components';

export const getEmptyState = (value: string | null) => {
  if (!value) return null;

  const emptyStatesComponents = {
    [StatesOfLoading.UPLOAD_DOCUMENTATION_REQUIRED_STATE]:
      InvoicesDocumentationRequired,
    [StatesOfLoading.HISTORY_FINISHED_WITHOUT_INVOICES_STATE]: InvoicesNotFound,
    [StatesOfLoading.HISTORY_UNFINISHED_WITHOUT_INVOICES_STATE]:
      HistoryProcessingMessage,
  }[value];

  return emptyStatesComponents;
};

import { Container as MuiContainer } from '@material-ui/core';

interface ContainerProps {
  /**
   * If `true`, left and right padding are removed.
   */
  disableGutters?: boolean;
  /**
   * Sets max-width to match min-width of current breakpoint.
   * This is useful if you'd prefer to design for a fixed set of sizes
   * instead of trying to accommodate a fully fluid viewport.
   * It's fluid by default.
   */
  fixed?: boolean;
  /**
   * Determines max-width of container.
   * Container's width grows with size of the screen.
   * Set to `false` to disable `maxWidth`.
   */
  maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  children: React.ReactNode;
}

function Container({
  disableGutters = false,
  fixed = false,
  maxWidth = 'lg',
  children,
}: ContainerProps) {
  return (
    <MuiContainer
      disableGutters={disableGutters}
      fixed={fixed}
      maxWidth={maxWidth}
    >
      {children}
    </MuiContainer>
  );
}

export default Container;

import useBusinessDocumentsSummary from 'features/documents/hooks/useBusinessDocumentsSummary';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers/rootReducer';
import { ONBOARDING_STEP_CASES } from 'helpers/constants';
import { useTranslation } from '@features/i18n/infra/useTranslation';
import { urls } from '@features/libs/urls';
import { Link } from '@material-ui/core';
import { CountryId } from '../../../features/shared/types/Country.type';
import { DocumentsSummary } from '../../../features/documents/types/DocumentSummary.type';

export function useGetSubmitedOrderType() {
  const { t } = useTranslation();
  const business = useSelector((state: RootState) => state.businessState);

  const getAuthBuroLink = (id: string): string => {
    const isLegalEntity = id?.length === 12;
    if (isLegalEntity) return urls.legalEntityBuroAuth;
    return urls.naturalPersonBuroAuth;
  };

  const { AUTHORIZE_DEBT_SERVICE } = ONBOARDING_STEP_CASES;
  const { documentSummary, loading: isLoading } = useBusinessDocumentsSummary(
    business?.id
  );

  const areMissingDocuments = (documents: DocumentsSummary): boolean => {
    const hasDocumentsToUpload = documents?.pendingToUploadLength > 0;
    const hasDocumentsRejected = documents?.requireClarification > 0;
    return hasDocumentsToUpload || hasDocumentsRejected;
  };
  const isMissingAuthBuro =
    business?.onboardingStep === AUTHORIZE_DEBT_SERVICE &&
    business?.countryId === CountryId.MEXICO;

  if (isMissingAuthBuro) {
    return {
      headerTitle: t('SUBMITED_ORDER_WITH_PENDING_BURO_AUTH_TITLE'),
      subTitle: t('SUBMITED_ORDER_WITH_PENDING_BURO_AUTH_SUBTITLE'),
      ctaText: t('SUBMITED_ORDER_WITH_PENDING_BURO_AUTH_CTA_TEXT'),
      imgSrc: '/assets/finance/hold.png',
      href: getAuthBuroLink(business?.identifier),
      isLoading,
    };
  }

  if (areMissingDocuments(documentSummary)) {
    return {
      headerTitle: t('SUBMITED_ORDER_WITH_PENDING_DOCUMENTS_TITLE'),
      subTitle: t('SUBMITED_ORDER_WITH_PENDING_DOCUMENTS_SUBTITLE'),
      ctaText: t('SUBMITED_ORDER_WITH_PENDING_DOCUMENTS_CTA_TEXT'),
      imgSrc: '/assets/finance/hold.png',
      href: '/documents',
      isLoading,
    };
  }

  return {
    headerTitle: t('SUBMITED_ORDER_SUCCESS_TITLE'),
    subTitle: (
      <>
        {t('SUBMITED_ORDER_SUCCESS_SUBTITLE')}{' '}
        <Link
          component="a"
          variant="body2"
          href={`mailto:${t('SUBMITED_ORDER_EMAIL_TO')}`}
        >
          {t('SUBMITED_ORDER_EMAIL_TO')}
        </Link>
      </>
    ),
    ctaText: t('SUBMITED_ORDER_SUCCESS_CTA_TEXT'),
    imgSrc: '/assets/finance/completed.png',
    href: '/orders',
    isLoading,
  };
}

import React from 'react';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import { InfoOutlined, ArrowDropDown } from '@material-ui/icons';
import CountryFormatHelper from './CountryFormatHelper';
import { CountryId } from '../../features/shared/types/Country.type';
import { Typography } from '../../design-system/atoms/index';

const useStyles = makeStyles(theme => ({
  disabled: { color: theme.palette.text.disabled },
  green: { color: theme.palette.success.main },
  tooltipIcon: {
    marginLeft: 6,
  },
}));

type OperationLineProps = {
  line: any;
  countryId: CountryId;
};

function OperationLine({ line, countryId }: OperationLineProps) {
  const classes = useStyles();

  const showLineWithPromotion = () =>
    line.hasPromotions && line.promotionBaseRate < line.value;

  return (
    <Box display="flex" justifyContent="space-between" py={1.5}>
      <Typography variant="tiny" color="textSecondary">
        {line.title}
        {line.tooltip && (
          <Tooltip title={line.tooltip}>
            <InfoOutlined
              color="primary"
              fontSize="small"
              className={classes.tooltipIcon}
            />
          </Tooltip>
        )}
      </Typography>

      {showLineWithPromotion() ? (
        <Typography variant="tiny" align="right">
          <Box
            className={classes.green}
            display="flex"
            alignItems="center"
            justifyContent="end"
          >
            <ArrowDropDown />
            <Box fontWeight="fontWeightMedium" component="span">
              {CountryFormatHelper({
                value: line.promotionBaseRate,
                variant: line.variant,
                countryId,
                useFixedDecimalPrecision: line.useFixedDecimalPrecision,
              })}
            </Box>
          </Box>
          <Box className={classes.disabled}>
            Antes:{' '}
            {CountryFormatHelper({
              value: line.value,
              variant: line.variant,
              countryId,
              useFixedDecimalPrecision: line.useFixedDecimalPrecision,
            })}
          </Box>
        </Typography>
      ) : (
        <Typography variant="tiny" color="textPrimary">
          <Box fontWeight="fontWeightMedium" component="span">
            {CountryFormatHelper({
              value: line.value,
              variant: line.variant,
              countryId,
              useFixedDecimalPrecision: line.useFixedDecimalPrecision,
            })}
          </Box>
        </Typography>
      )}
    </Box>
  );
}

export default OperationLine;
